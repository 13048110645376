import { Injectable } from '@angular/core';
import { TnDialogService } from '../../../utilities/tn-dialog/tn-dialog.service';
import { ScheduleMessageSetComponent } from './schedule-message-set.component';


@Injectable()
export class ScheduleMessageSetService {

  constructor(private _tnDialogService: TnDialogService,) { }

  openSetScheduleMessageDialog(scheduleMessage: any, callback?: Function): void {
    let dialogRef = this._tnDialogService.openTnDialog(
      ScheduleMessageSetComponent,
      { 
        targetScheduleMessage: scheduleMessage,
        callback: callback
      },
      {
        width: '65vw',
        minWidth: '800px',
        maxWidth: '1000px',
        height: '80vh'
      }
    )
  }
}
