import { Injectable, Inject, forwardRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LocalStorageManagerService } from '../../../utilities/local-storage/local-storage-manager.service';
import { SocketService } from '../socket/socket.service';

// Data
import { UserContactService } from './user-contact/user-contact.service';
import { ChatService } from './chat/chat.service';
import { UserGroupService } from './user-group/user-group.service';
import { MessagesService } from './messages/messages.service';
import { NewsMessageService } from './messages/news-message/news-message.service';

import * as _ from 'lodash';
import { UserGroup } from '../../../models/user-group';
import { UserContact } from '../../../models/user-contact';
import { AccountManagerService } from '../account/account-manager.service';
import { TnLoaderService } from '../../../utilities/tn-loader/tn-loader.service';
import { ChatMessageService } from './messages/chat-message/chat-message.service';
import { LoggerService } from '../../../utilities/logger/logger.service';
import { InfoMessageService } from './messages/info-message/info-message.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { PresenceTypeConstant } from '../../../constants/presence-type.constant';
import { AMQPRoutingKey } from '../../../constants/amqp-routing-key.constant';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import { TeamNoteLocalStorageKeyConstants } from '../../../constants/local-storage-key.constant';
import { PageUrlConstant } from '../../../constants/page-url.constant';
import { TeamNoteGeneralConstant } from '../../../constants/general.constant';
import { Message } from '../../../models/message';
import { ModuleKeyDefinition } from '../../../constants/module.constant';
import { SideNavService } from '../../../utilities/tn-side-nav/side-nav.service';
import { NewsConstant } from '../../../constants/news.constant';
import { MessageNewsBody } from '../../../models/message-news-body';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';
import { TnDialogService } from '../../../utilities/tn-dialog/tn-dialog.service';
import { NewsModalService } from '../../news/news-modal/news-modal.service';
import {TeamnoteApiService} from '../../../api/teamnote-api.service';
import {WebclientService} from '../../webclient.service';
import {MultiChatRoomService} from '../../chat/multi-chat-room.service';
import { ModuleManagerService } from '../module/module-manager.service';
import { ExerciseService } from '../../exercise/exercise.service';

class RosterCollection {
  userId?: string;
  presenceQueueName?: string;
  messageQueueName?: string;
  // isRequestedRecentMessage?: boolean = false;
  // isRequestedRecentNews?: boolean = false;

  prevSessionRoute?: string = null;
  isGotMissingGroupsUnderRoot?: boolean = false;
  isGottedRoster?: boolean = false;

  isChatLoaded?: boolean = false;

  _isRequestedRecentMessage?: boolean = false;
  _isRequestedRecentNews?: boolean = false;
  isInitEnd?: boolean = false;
}
interface Roster {
  [userId: string]: RosterCollection
}
@Injectable()
export class DataManagerService {

  presenceQueueName: string;
  messageQueueName: string;
  private _isRequestedRecentMessage: boolean = false;
  private _isRequestedRecentNews: boolean = false;
  private _isInitEnd: boolean = false;

  // track previous session
  prevSessionRoute: string = null;

  // track is got missing group under root
  isGotMissingGroupsUnderRoot: boolean = false;

  isGottedRoster: boolean = false;

  isChatLoaded: boolean = false;
  isChatLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  rosterGroupByAccountUserId: Roster = {};
  isDefaultSwiched: boolean = false;
  
  constructor(
    private _localStorageManagerService: LocalStorageManagerService,
    private _socketService: SocketService,
    private _userContactService: UserContactService,
    private _chatService: ChatService,
    private _userGroupService: UserGroupService,
    private _messagesService: MessagesService,
    private _newsMessageService: NewsMessageService,
    private _accountManagerService: AccountManagerService,
    private _tnLoaderService: TnLoaderService,
    private _chatMessageService: ChatMessageService,
    private _loggerService: LoggerService,
    private _infoMessageService: InfoMessageService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _sideNavService: SideNavService,
    private _teamnoteApiService: TeamnoteApiService,
    private _moduleManagerService: ModuleManagerService,
    // private _fileFactoryService: FileFactoryService,
    // private _tnDialogService: TnDialogService,
    // private _newsModalService: NewsModalService,
    private _multiChatRoomService: MultiChatRoomService,
    private _exerciseService: ExerciseService,
  ) { 
    this._loggerService.debug("Data manager start initializing...");
    this.resetAllData();
  }

  /**
   * Reset all app data
   * 
   * @memberof DataManagerService
   */
  resetAllData(): void {
    this._loggerService.debug("Resetting app data...");
    this._isRequestedRecentMessage = false;
    this._isRequestedRecentNews = false;
    this._isInitEnd = false;

    this.rosterGroupByAccountUserId = {};
    this.isDefaultSwiched = false;

    this.initAllData();
    this.initRoster();
  }

  /**
   * Init messages
   * 
   * @memberof DataManagerService
   */
  initAllData(): void {
    this._loggerService.debug("Resetting messages...");
    this._messagesService.initMessages();
  }

  /**
   * Init roster
   * - User contact
   * - Chat
   * - User group
   * 
   * @memberof DataManagerService
   */
  initRoster(): void {
    this._loggerService.debug("Resetting roster...");
    this.isGottedRoster = false;
    this._userContactService.initUserContacts();
    this._chatService.initChats();
    this._userGroupService.initUserGroups();
  }

  /**
   * For debugging purpose, gather all useful data and build into object
   * 
   * @returns {*} - Object including all rosters & messages
   * @memberof DataManagerService
   */
  tnDebugger(): any {
    return {
      roster: {
        contacts: this._userContactService.userContacts,
        chats: this._chatService.chats,
        userGroup: this._userGroupService.userGroups
      },
      messages: this._messagesService.messages,
      chatMessages: this._chatMessageService.chatMessages,
      newsMessages: this._newsMessageService.newsMessages,
      infoMessages: {
        read: this._infoMessageService.messageReads,
        delivery: this._infoMessageService.messageDeliveries
      },
      unreads: {
        chatMessages: this._chatMessageService.unreadMessages,
        newsMessages: this._newsMessageService.unreadNews
      }
    };
  }

  /**
   * Base handler for incoming presence
   * 
   * 1. Get roster after queue binding
   * 2. Handle presences by type
   * 
   * @param {any} incomingPresence - Socket incoming message body
   * @returns {void} 
   * @memberof DataManagerService
   */
  receivePresenceCallback(incomingPresence, isBinding?: boolean): void {
    if (isBinding) {
      this._loggerService.debug("Type = queue binding, update presence queue name and get roster");
      this.presenceQueueName = incomingPresence.headers.temp_qname;
      this.getRoster();
      return;  
    }
    let correlationId = incomingPresence.headers['correlation-id'];
    if (!incomingPresence.body) {
      return;
    }
    let presences = JSON.parse(incomingPresence.body);

    let needUpdateContact = false;
    let needUpdateChat = false;
    let needUpdateUserGroup = false;

    _.each(presences, (p) => {
      switch (p.t) {
        case PresenceTypeConstant.WIPE_DEVICE:
          console.error('WIPE');
          this._teamnoteApiService.logoutWebClient(false);
          break;
        case PresenceTypeConstant.USER_CONTACT:
        case PresenceTypeConstant.CONTACT_ABSENCE:
        case PresenceTypeConstant.USER_ONLINE:
        case PresenceTypeConstant.USER_OFFLINE:
          this._userContactService.receiveUserContactPresence(p, correlationId);
          needUpdateContact = true;
          break;
        case PresenceTypeConstant.INDIVIDUAL_CHAT:
        case PresenceTypeConstant.GROUP_CHAT:
        case PresenceTypeConstant.GROUP_BROADCAST:
        case PresenceTypeConstant.CHAT_ABSENCE:
        case PresenceTypeConstant.NEWS_CHANNEL:
        case PresenceTypeConstant.NEWS_CATEGORY:
        case PresenceTypeConstant.MUTE_CHAT:
        case PresenceTypeConstant.MUTE_CHAT_ABSENSE:
          this._chatService.receiveChatPresence(p, correlationId);
          needUpdateChat = true;
          break;
        case PresenceTypeConstant.USER_GROUP:
        case PresenceTypeConstant.USER_GROUP_ABSENCE:
          this._userGroupService.receiveUserGroupPresence(p);
          needUpdateUserGroup = true;
          break;
      }
    });

    // If this is a response from GET_ROSTER, do postGetRosterChecking
    if (correlationId == AMQPRoutingKey.CORRELATION_ID.GET_ROSTER) {
      this.postGetRosterChecking();
    }

    // Update roster subject when received corresponding presences, only do update after the whole presence array message is processed
    if (needUpdateContact) {
      this._userContactService.updateContactSubject();
    }
    if (needUpdateChat) {
      this._chatService.updateChatSubject();
    }
    if (needUpdateUserGroup) {
      this._userGroupService.updateUserGroupSubject();
    }
  }

  /**
   * Base handler for incoming messages
   * 
   * Redirect to handler in messageService
   * 
   * @param {any} incomingMessage - Socket incoming message body
   * @returns {void}
   * @memberof DataManagerService
   */
  receiveMessageCallback(incomingMessage, isBinding?: boolean): void{ 
    if (isBinding) {
      this._loggerService.debug("Type = queue binding, update message queue name");
      this.messageQueueName = incomingMessage.headers.temp_qname;
      this._messagesService._messageQueueName = this.messageQueueName;
      this._newsMessageService._messageQueueName = this.messageQueueName;

      this._loggerService.debug('[messageQueueName in loadOfflineMessage]' + this.messageQueueName);

      // request the offline message
      let disconnectTime = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DISCONNECT_TIME);
      if (disconnectTime) {
        this.loadOfflineMessage(disconnectTime);
      }
      return;
    }
    let correlationId = incomingMessage.headers['correlation-id'];

    if (correlationId == AMQPRoutingKey.CORRELATION_ID.LOAD_RECENT_MESSAGE) {
      this._isInitEnd = true;
    }

    if (!incomingMessage.body) {
      return;
    }
    
    this._messagesService.receiveMessages(incomingMessage);
  }

  /**
   * Get Roster
   * 
   * Roster will be returned in order:
   * 1. user contacts
   * 2. chat
   * 3. user group
   * 4. mute group
   * 
   * @returns {void}
   * @memberof DataManagerService
   */
  getRoster(): void {
    this._loggerService.debug("Start getting roster");
    // No need to get roster if reconnecting to socket
    if (this.isGottedRoster) {
      this._loggerService.debug("Roster is gotten before, no need to get roster");
      this.postGetRosterChecking();
      return;
    }
    this.initRoster();
    let correlationId = AMQPRoutingKey.CORRELATION_ID.GET_ROSTER;
    let routingKey = AMQPRoutingKey[correlationId];
    let headers = {
      'correlation-id': correlationId,
      'reply-to': this.presenceQueueName,
      'device_token': this._localStorageManagerService.getDeviceToken()
    };
    let body = '';

    let callback = (incomingPresence) => {
      this.receivePresenceCallback(incomingPresence);
    };

    this._tnLoaderService.showSpinner('LOADING.LOADING_CONTACTS');
    this._socketService.sendMessage(routingKey, headers, body, correlationId, callback);
  }

  /**
   * Post get roster checking
   * 
   * 1. After news channels received, get recent News
   * 2. After user groups received (end of get roster), get recent messages
   * 3. Restore previous session route
   * 4. Try to get missing user group under root group
   * 
   * @returns {void}
   * @memberof DataManagerService
   */
  postGetRosterChecking(): void {
    this._loggerService.debug("postGetRosterChecking");

    this.isGottedRoster = true;

    // check if user_group info is gotten,
    // if yes, getRecentMessage
    if (this._chatService.getNewsChannels().length > 0) {
      this._loggerService.debug("There are news channels, try to get recent news");
      this.getRecentNews();
    }
    if (_.size(this._userGroupService.userGroups) > 0) {
      this._loggerService.debug("Last chunk of get_roster (user_group) is gotten, try to get recent message");
      this.getRecentMessage();
      this.tryToGetMissingUserGroupUnderRoot();

      // if (!this.isChatLoaded) {
      //   this.updateIsChatLoaded(true)
      // }
    }
    /* set flag of loading starred messages to true after After chat is completely loaded */
    if (!this.isChatLoaded) {
      this.updateIsChatLoaded(true)
    }
    
    this.restorePreviousSession();
    this._tnLoaderService.hideSpinner();
  }

  /**
   * Restore last view from previous session
   * 
   * The view's route is stored in cookies, we try to get the previous route nad its meta data in order to show the last view.
   * TODO: check if module is enabled before redirecting. 2018-04-30
   * 
   * @returns {void}
   * @memberof DataManagerService
   */
  restorePreviousSession(): void {
    let prevMetaData = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA);
    if (prevMetaData && this.prevSessionRoute) {
      if (this.prevSessionRoute.indexOf(PageUrlConstant.WEBCLIENT.CHAT) != -1) {
        this._chatService.restoreActiveChatByChatId(prevMetaData);
      } else if (this.prevSessionRoute.indexOf(PageUrlConstant.WEBCLIENT.CONTACT) != -1) {
        this._userContactService.restoreUserGroupIdStack(prevMetaData.split(","));
      }
    }
  }

  /**
   * Get recent News
   * 
   * Only request recent news if it was not requested before.
   * Flag = isRequestedRecentNews
   * 
   * @returns {void}
   * @memberof DataManagerService
   */
  getRecentNews(): void {
    if (!this._isRequestedRecentNews) {
      this._loggerService.debug("Recent News were not requested before, loading news history...");
      this._isRequestedRecentNews = true;
      this.loadNewsHistory(
        null,
        (news) => {
          this._newsMessageService.openAdvertisementNews();
        }
      );
    }
  }

  /**
   * Get recent messages
   * 
   * 1. Check if there is disconnect time stored in cookies. If yes, call loadOfflineMessage & loadOfflinePresence function. (We will check if we really need to load inside the two functions)
   * 2. Only request recent messages if it was not requested before.
   * Flag = _isRequestedRecentMessage
   * 
   * @returns {void}
   * @memberof DataManagerService
   */
  getRecentMessage(): void {
    let disconnectTime = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DISCONNECT_TIME);
    if (disconnectTime) {
      // this.loadOfflineMessage(disconnectTime);
      this.loadOfflinePresence(disconnectTime);
    }

    if (!this._isRequestedRecentMessage) {
      this._loggerService.debug("Recent messages were not requested before, loading recent messages...");
      this._isRequestedRecentMessage = true;
      let correlationId = AMQPRoutingKey.CORRELATION_ID.LOAD_RECENT_MESSAGE;
      let routingKey = AMQPRoutingKey[correlationId];
      let headers = {
        'correlation-id': correlationId,
        'reply-to': this.messageQueueName,
        'size': this._teamnoteConfigService.config.WEBCLIENT.AMQP.LOAD_RECENT_MESSAGE_SIZE
      };
      let body = '';

      this._socketService.sendMessage(routingKey, headers, body, correlationId);
    } else {
      //if no need to get recent message, init end here
      this._isInitEnd = true;
    }
  }

  /**
   * Load offline message with disconnect time
   * 
   * Only load if web client is reconnecting
   * 
   * @param {string} disconnectTime - disconnect time of previous session (last active time)
   * @returns {void}
   * @memberof DataManagerService
   */
  loadOfflineMessage(disconnectTime: string): void {
    this._loggerService.debug('[messageQueueName in loadOfflineMessage]' + this.messageQueueName);
    if (this._socketService._isNeedReconnect) {
      this._loggerService.debug("Getting offline messages...");
      let correlationId = AMQPRoutingKey.CORRELATION_ID.LOAD_OFFLINE_MESSAGE;
      let routingKey = AMQPRoutingKey[correlationId];
      let headers = {
        'correlation-id': correlationId,
        'device_token': this._localStorageManagerService.getDeviceToken(),
        'message_time': disconnectTime,
        'reply-to': this.messageQueueName
      };
      let body = '';

      this._socketService.sendMessage(routingKey, headers, body, correlationId);
      this._socketService.setOfflineMsgGot();
      this._chatMessageService.sendFirstMessageInQueue(true);
    }
  }

  /**
   * Load offline presence with disconnet time, existing chat ids
   * 
   * Only load if web client is reconnecting.
   * 
   * @param {string} disconnectTime - disconnect time of previous session (last active time)
   * @returns {void}
   * @memberof DataManagerService
   */
  loadOfflinePresence(disconnectTime: string): void {
    this._loggerService.debug('[presenceQueueName in loadOfflinePresence]' + this.presenceQueueName)
    if (this._socketService._isNeedReconnect) {
      this._loggerService.debug("Getting offline presences...");
      let correlationId = AMQPRoutingKey.CORRELATION_ID.LOAD_OFFLINE_PRESENCE;
      let routingKey = AMQPRoutingKey[correlationId];
      let headers = {
        'correlation-id': correlationId,
        'device_token': this._localStorageManagerService.getDeviceToken(),
        'message_time': disconnectTime,
        'reply-to': this.presenceQueueName
      };

      let allChatIds = this._chatService.getAllChatIds();
      let body = JSON.stringify({
        existing_chat_ids: allChatIds
      });

      this._socketService.sendMessage(routingKey, headers, body, correlationId);
      this._socketService.setOfflinePresenceGot();
    }
  }

  findNumberOfContentMessage(incomingMessage): number {
    let body = JSON.parse(incomingMessage.body);
    let targetMessageTypes = [
      MessageTypeConstant.TEXT,
      MessageTypeConstant.ATTACHMENT,
      MessageTypeConstant.LOCATION,
      MessageTypeConstant.NEWS,
      MessageTypeConstant.VOTE
    ];
    let contentMessage = _.filter(body, (p) => {
      return _.indexOf(targetMessageTypes, _.toInteger(p.type)) != -1;
    });
    return contentMessage.length;
  }

  /**
   * Load news history under all news channels
   * 
   * @param {Function} [callback] - custom callback function
   * @memberof DataManagerService
   */
  loadNewsHistory(newsCategoryId?: string, callback?: Function): void {
    let allNewsChannelChatIds = _.map(this._chatService.getNewsChannels(), 'chat_id');

    if (allNewsChannelChatIds.length == 0) {
      this._loggerService.warn("Trying to load news history without news channel id, rejected.");
      return;
    }

    this._loggerService.debug("Getting news history...");
    let correlationId = AMQPRoutingKey.CORRELATION_ID.LOAD_HISTORY;
    let routingKey = AMQPRoutingKey[correlationId];
    correlationId += '_NEWS_' + allNewsChannelChatIds.length + '_' + _.now();
    let headers = {
      'correlation-id': correlationId,
      'chat_id': JSON.stringify(allNewsChannelChatIds),
      'reply_to': this.messageQueueName,
      'size': this._teamnoteConfigService.config.WEBCLIENT.AMQP.NEWS_LOAD_HISTORY_SIZE
    };
    let firstNews;
    if (newsCategoryId) {
      headers['news_category_chat_id'] = newsCategoryId;
      firstNews = this._newsMessageService.getEarliestNewsUnderNewsCategory(newsCategoryId);
    } else {
      firstNews = this._newsMessageService.getEarliestNewsUnderNewsChannels(allNewsChannelChatIds);
    }
    if (firstNews) {
      headers['message_time'] = firstNews.timestamp.toString();
    }
    let body = '';

    let loadHistoryCallback = (incomingMessage) => {
      if (!this._accountManagerService.isEnableMultiAccountLogin()) {
        this.receiveMessageCallback(incomingMessage);
      } else {
        this.receiveMessageCallback_v2(incomingMessage, false, this._accountManagerService.userId);
      }
      let bodyLength = this.findNumberOfContentMessage(incomingMessage);
      if (callback) {
        callback(bodyLength);
      }
    };

    if (!this._accountManagerService.isEnableMultiAccountLogin()) {
      this._socketService.sendMessage(routingKey, headers, body, correlationId, loadHistoryCallback);
    } else {
      this._socketService.sendMessage_v2(this._accountManagerService.userId, routingKey, headers, body, correlationId, loadHistoryCallback);
    }
  }


  /**
   * Load chat history of chat
   * 
   * @param {string} chatId - target chat id
   * @param {number} timestamp - local earliest timestamp of messages under chat
   * @param {number} size - target size of messages
   * @param {Function} callback - callback function afer received chat history
   * @returns {void} 
   * @memberof DataManagerService
   */
  loadChatHistory(chatId: string, timestamp: number, size: number, callback?: Function): void {
    if (!chatId) {
      this._loggerService.warn("Trying to load chat history without chat id, rejected.");
      return;
    }
    this._loggerService.debug("Getting chat history..." + chatId);
    let correlationId = 'LOAD_HISTORY_' + chatId + '_' + _.now();
    let routingKey = AMQPRoutingKey.LOAD_HISTORY;
    let headers = {
      'correlation-id': correlationId,
      'reply-to': this.messageQueueName,
      'chat_id': chatId,
      'size': size
    };
    if (timestamp) {
      headers['message_time'] = timestamp
    }
    let body = '';

    let loadHistoryCallback = (incomingMessage) => {
      if (!this._accountManagerService.isEnableMultiAccountLogin()) {
        this.receiveMessageCallback(incomingMessage);
      } else {
        this.receiveMessageCallback_v2(incomingMessage, false, this._accountManagerService.userId);
      }

      let bodyLength = this.findNumberOfContentMessage(incomingMessage);
      console.log('bodyLength', bodyLength);
      if (callback) {
        callback(bodyLength);
      }
    };

    if (!this._accountManagerService.isEnableMultiAccountLogin()) {
      this._socketService.sendMessage(routingKey, headers, body, correlationId, loadHistoryCallback);
    } else {
      this._socketService.sendMessage_v2(this._accountManagerService.userId, routingKey, headers, body, correlationId, loadHistoryCallback);
    }
  }

  /**
   * Subscribe to chat
   * 
   * This will receive user status change under this chat
   * 
   * @param {string} chatId - target chat id
   * @returns {void} 
   * @memberof DataManagerService
   */
  subscribeChat(chatId: string): void {
    if (!chatId) {
      this._loggerService.warn("Trying to subscribe chat without chat id, rejected.");
      return;
    }
    this._loggerService.debug("Subscribing to chat..." + chatId);
    let correlationId = 'SUBSCRIBE_CHAT_' + chatId + '_' + _.now();
    let routingKey = AMQPRoutingKey.SUBSCRIBE_CHAT;
    let headers = {
      'correlation-id': correlationId,
      'reply-to': this.presenceQueueName,
      'chat_id': chatId
    };
    let body = '';

    if (!this._accountManagerService.isEnableMultiAccountLogin()) {
      this._socketService.sendMessage(routingKey, headers, body, correlationId);
    } else {
      this._socketService.sendMessage_v2(this._accountManagerService.userId, routingKey, headers, body, correlationId);
    }
  }



  sendMessageToChat(chatId: string, msgBody: Object, msgType: number, whisperingUser?: UserContact, callback?: Function) {
    if (!chatId || !msgBody || !msgType) {
      this._loggerService.warn('Trying to send message to chat without chat id / message type / message body, rejected');
      return;
    }
    let correlationId = 'SEND_MESSAGE' + chatId + '_' + _.now();
    let routingKey = '/' + chatId;
    let device_token = this._localStorageManagerService.getDeviceToken();
    if (this._accountManagerService.isEnableMultiAccountLogin()) {
      device_token = this._accountManagerService.getAccountUserFieldByFieldNameByAccount(
        'device_token',
        this._accountManagerService.userId
      )
    }
          
    let headers = {
      'correlation-id': correlationId,
      // 'device_token': this._localStorageManagerService.getDeviceToken(),
      'device_token': device_token,
      'type': msgType,
      'priority': 1
    };
    let body = JSON.stringify(msgBody);

    if (whisperingUser) {
      headers['received_by'] = whisperingUser.user_id;
    }

    let newMessage = new Message;
    newMessage.body = body;
    newMessage.sent_by = this._accountManagerService.userId;
    newMessage.type = msgType;
    newMessage.timestamp = (_.now() / 1000).toString();
    newMessage.received_by = whisperingUser ? whisperingUser.user_id : "";
    newMessage.correlation_id = correlationId;
    this._chatMessageService.insertOrUpdateChatMessageUnderChat(chatId, newMessage);
    
    let sendMessageCallback = (incomingMessage) => {
      if (!this._accountManagerService.isEnableMultiAccountLogin()) {
        this.receiveMessageCallback(incomingMessage);
      } else {
        this.receiveMessageCallback_v2(incomingMessage, false, this._accountManagerService.userId);
      }
      
      if (callback) {
        callback();
      }
    };

    this._chatMessageService.addChatMessageToQueue(correlationId, routingKey, headers, body, sendMessageCallback);
    this._chatMessageService.sendFirstMessageInQueue();

    // this._socketService.sendMessage(routingKey, headers, body, correlationId, sendMessageCallback);

    this._chatMessageService.updateActiveChatRoomMessageSubject();
    
    if (this._multiChatRoomService.activeMultiChatRoomsPanel) {
      this._chatMessageService.tryUpdateMultiChatRoomMessageSubjects([chatId]);
    }
  }

  /**
   * Get missing user groups / users under root group
   * 
   * Only get if it hasn't try to get before.
   * 
   * Usage: 
   * As we will only try to get missing contacts or user group after receiving user group presence when the user group is under ROOT, we may not have gotten ROOT group before this new presence.
   * We then try to get all missing contact / user group under ROOT after GET_ROSTER has received user group presences.
   * This is to ensure that we have the full contact user / contact user group list.
   * 
   * @returns {void} 
   * @memberof DataManagerService
   */
  tryToGetMissingUserGroupUnderRoot(): void {
    if (this.isGotMissingGroupsUnderRoot) {
      return;
    }
    // Get missing contacts under ROOT
    this._userGroupService.getMissingContactsAndUserGroupContactsUnderUserGroupByUserGroupId(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID);
    this.isGotMissingGroupsUnderRoot = true;

    // Find all child user groups under ROOT recursively
    let allChildGroupIds = this._userGroupService.getAllUserGroupIdsUnderUserGroupByUserGroupId(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID);
    // Get missing contacts under each child group
    _.each(allChildGroupIds, (id) => {
      this._userGroupService.getMissingContactsAndUserGroupContactsUnderUserGroupByUserGroupId(id);
    });
  }

  /**
   * Get user contacts under user group
   * 
   * This generates UserGroup object with "childUserGroup" and "contactsWithoutSelf" under target user group. (With UserGroup object and UserContact object)
   * 
   * @param {string} userGroupId - target user group ID
   * @returns {UserGroup} - parsed user group object
   * @memberof DataManagerService
   */
  getUserContactsUnderUserGroup(userGroupId: string): UserGroup {
    this._loggerService.debug("Getting child user groups and child contacts under user group: " + userGroupId);
    let targetUserGroup = this._userGroupService.getUserGroupByUserGroupId(userGroupId);
    if (!targetUserGroup) {
      this._loggerService.debug("User group not found");
      return;
    }
    targetUserGroup.childUserGroup = [];
    targetUserGroup.contactsWithoutSelf = [];
    _.each(targetUserGroup.children, (childUserGroupId) => {
      targetUserGroup.childUserGroup.push(this._userGroupService.getUserGroupByUserGroupId(childUserGroupId));
    });

    let userIdWithoutSelf = _.without(targetUserGroup.members, this._accountManagerService.userId);
    _.each(userIdWithoutSelf, (memberUserId) => {
      targetUserGroup.contactsWithoutSelf.push(this._userContactService.getUserContactByUserId(memberUserId));
    });
    
    this._loggerService.debug("No. of child user group: " + targetUserGroup.childUserGroup.length + "\nNo. of child contacts without 'self': " + targetUserGroup.contactsWithoutSelf.length);

    return targetUserGroup;
  }

  /**
   * Get all contact users under user group
   * 
   * This will recuresively get all conatct users under user group
   * 
   * @param {string} userGroupId - target user group ID
   * @returns {UserContact[]} - all UserContact under user group (gotten recursively)
   * @memberof DataManagerService
   */
  getAllContactUsersUnderUserGroup(userGroupId: string): UserContact[] {
    this._loggerService.debug("Getting all contact users under user group: " + userGroupId);
    let targetUserGroup = this._userGroupService.getUserGroupByUserGroupId(userGroupId);
    if (!targetUserGroup) {
      this._loggerService.debug("User group not found");
      return [];
    }
    let allMembers = [];
    // Get UserContact object without current user
    let membersIdWithoutSelf = _.without(targetUserGroup.members, this._accountManagerService.userId);
    for (var i in membersIdWithoutSelf) {
      allMembers.push(this._userContactService.getUserContactByUserId(membersIdWithoutSelf[i]));
    }

    // Recursively get all contact users under its childrens
    for (var j in targetUserGroup.children) {
      let childMembers = this.getAllContactUsersUnderUserGroup(targetUserGroup.children[j]);
      allMembers = _.union(allMembers, childMembers);
    }
    this._loggerService.debug("User group: " + userGroupId + " \nNo. of contact users: " + allMembers.length);
    return allMembers;
  }

  /* for starred messages page usage, check for when should to get data */
  updateIsChatLoaded(isLoaded: boolean): void {
    this.isChatLoaded = isLoaded;
    this.isChatLoaded$.next(this.isChatLoaded);
  }

  resetAllMultiAccountData(): void {
    console.log('resetAllData for multi accounts');
    this.initMuitiAccountMessageData();
    this.initMuitiAccountRoster();
  }

  initMuitiAccountMessageData(): void {
    this._loggerService.debug("Resetting Multi account messages...");
    this._messagesService.clearMultiAccountMessages();
  }

  initMuitiAccountRoster(): void {
    this._loggerService.debug("Resetting Multi account roster...");
    this._userContactService.clearMultiAccountUserContacts();
    this._chatService.clearMultiAccountChats();
    this._userGroupService.clearMultiAccountUserGroups();
  }

  initRosterByAccount(userId: string): void {
    if (!this.rosterGroupByAccountUserId[userId]) {
      this.rosterGroupByAccountUserId[userId] = {
        userId: userId
      };
    }

    console.log('this.rosterGroupByAccountUserId', this.rosterGroupByAccountUserId);
  }

  switchAccountRoster(accUserId: string): void {
    let roster = this.getRosterObjectByUserId(accUserId)
    
    this.presenceQueueName = roster.presenceQueueName
    this.messageQueueName = roster.messageQueueName
    this.prevSessionRoute = roster.prevSessionRoute
    this.isGottedRoster = roster.isGottedRoster

    this._isRequestedRecentMessage = roster._isRequestedRecentMessage
    this._isRequestedRecentNews = roster._isRequestedRecentNews

    this.isGotMissingGroupsUnderRoot = roster.isGotMissingGroupsUnderRoot

    // this.updateIsChatLoaded(roster.isChatLoaded)
  }

  switchToTargetAccountByUserId(accUserId: string): void {
    // equal to switchToTargetAccountByUserId function in class WebclientService
  }
  
  initRoster_v2(userId: string): void {
    let roster = this.getRosterObjectByUserId(userId)

    roster.isGottedRoster = false;

    this._userContactService.initUserContactPresencesByAccountUserId(userId)
    this._chatService.initChats_v2(userId);
    this._userGroupService.initUserGroupsByAccountUserId(userId)
  }

  switchAccountPresence(accUserId: string): void {
    this._userContactService.switchAccountUserContacts(accUserId)
    this._userGroupService.switchAccountUserGroups(accUserId)
    this._chatService.switchAccountChats(accUserId);

    console.log('this.updateIsChatLoaded(roster.isChatLoaded)');
    let roster = this.getRosterObjectByUserId(accUserId)
    this.updateIsChatLoaded(roster.isChatLoaded)

    this._multiChatRoomService.switchAccountMultiChatRoom(accUserId)
  }

  switchAccountMessages(accUserId: string): void {
    this._messagesService.switchAccountMessages(accUserId)
  }

  getRosterObjectByUserId(userId: string): RosterCollection {
    return this.rosterGroupByAccountUserId[userId]
  }

  getRoster_v2(accUserId: string): void {
    console.log('getRoster_v2 should be called once');
    // this._loggerService.debug("Start getting roster")
    let roster = this.getRosterObjectByUserId(accUserId)
    // No need to get roster if reconnecting to socket
    if (roster.isGottedRoster) {
      // this._loggerService.debug("Roster is gotten before, no need to get roster");
      console.log(`Roster is gotten before, no need to get roster ${accUserId}`);
      this.postGetRosterChecking_v2(accUserId);
      return;
    }

    this.initRoster_v2(accUserId);

    // inited roster obj by account, ready to send ws message to get roster  
    let correlationId = AMQPRoutingKey.CORRELATION_ID.GET_ROSTER;
    let routingKey = AMQPRoutingKey[correlationId];
    let headers = {
      'correlation-id': correlationId,
      'reply-to': roster.presenceQueueName,
      // 'device_token': this._localStorageManagerService.getDeviceToken(),
      'device_token': this._accountManagerService.getAccountUserFieldByFieldNameByAccount('device_token', accUserId),
    };
    let body = '';

    let callback = (incomingPresence, isBinding) => {
      console.log('GET_ROSTER callback', incomingPresence, isBinding);
      this.receivePresenceCallback_v2(incomingPresence, isBinding, accUserId);
    };

    if (accUserId === this._exerciseService.activeExercise?.user_id) {
      console.log('LOADING_CONTACTS');
      this._tnLoaderService.showSpinner('LOADING.LOADING_CONTACTS');
    }
    this._exerciseService.setExerciseLoadingStatus(accUserId, true);

    this._socketService.sendMessage_v2(accUserId, routingKey, headers, body, correlationId, callback);
  }

  postGetRosterChecking_v2(accUserId: string): void {
    let roster = this.getRosterObjectByUserId(accUserId)
    // console.log(" " + roster.userId);

    roster.isGottedRoster = true;

    // check if user_group info is gotten,
    // if yes, getRecentMessage
    // console.log('getNewsChannels_v2', this._chatService.getNewsChannels_v2(accUserId));
    if (this._chatService.getNewsChannels_v2(accUserId).length > 0) {
      // this._loggerService.debug(`There are news channels, try to get recent news by account id ${accUserId}`);
      // console.log(`There are news channels, try to get recent news by account id ${accUserId}`);
      this.getRecentNews_v2(accUserId);
    }

    let ugsObj = this._userGroupService.getUserGroupsByAccountUserId(accUserId)

    if (_.size(ugsObj.userGroups) > 0) {
      // this._loggerService.debug("Last chunk of get_roster (user_group) is gotten, try to get recent message");
      this.getRecentMessage_v2(accUserId);
      this.tryToGetMissingUserGroupUnderRoot_v2(accUserId);

      // if (!roster.isChatLoaded) {
      //   this.updateIsChatLoaded_v2(true, accUserId)
      // }
    }
    // this.restorePreviousSession();

    /* set flag of loading starred messages to true after After chat is completely loaded */
    if (!roster.isChatLoaded) {
      this.updateIsChatLoaded_v2(true, accUserId)
    }
    
    /* switch default exercise user account */
    if (!this.isDefaultSwiched) {
      console.log('this.isDefaultSwiched');
      
      if (this._exerciseService.activeExercise?.user_id === accUserId) {
        // the default account switch
        console.log('activeExercise is ', accUserId);
        this.switchToTargetAccountByUserId(accUserId)
        this.isDefaultSwiched = true;
      }
    }

    this._tnLoaderService.hideSpinner();
    this._exerciseService.setExerciseLoadingStatus(accUserId, false);
  }
  
  receivePresenceCallback_v2(incomingPresence, isBinding?: boolean, accUserId?: string): void {
    if (isBinding) {
      // this._loggerService.debug("Type = queue binding, update presence queue name and get roster");
      console.log('Type = queue binding, update presence queue name and get roster by userId ' + accUserId);
      const roster = this.getRosterObjectByUserId(accUserId)
      roster.presenceQueueName = incomingPresence.headers.temp_qname;

      if (this._accountManagerService.userId === accUserId) {
        this.presenceQueueName = roster.presenceQueueName;
      }
      
      this.getRoster_v2(accUserId);

      // /* switch default exercise user account */
      // if (this.isDefaultSwiched) {
      //   console.log('this.isDefaultSwiched');
      //   return;
      // }

      // if (this._exerciseService.activeExercise?.user_id === accUserId) {
      //   // the default account switch
      //   console.log('activeExercise is ', accUserId);
      //   this.switchToTargetAccountByUserId(accUserId)
      //   this.isDefaultSwiched = true;
      // }

      return;  
    }
    
    let correlationId = incomingPresence.headers['correlation-id'];
    if (!incomingPresence.body) {
      return;
    }
    let presences = JSON.parse(incomingPresence.body);

    let needUpdateContact = false;
    let needUpdateChat = false;
    let needUpdateUserGroup = false;

    _.each(presences, (p) => {
      switch (p.t) {
        case PresenceTypeConstant.USER_CONTACT:
        case PresenceTypeConstant.CONTACT_ABSENCE:
        case PresenceTypeConstant.USER_ONLINE:
        case PresenceTypeConstant.USER_OFFLINE:
          this._userContactService.receiveUserContactPresence_v2(p, correlationId, accUserId);
          needUpdateContact = true;
          break;

        case PresenceTypeConstant.INDIVIDUAL_CHAT:
        case PresenceTypeConstant.GROUP_CHAT:
        case PresenceTypeConstant.GROUP_BROADCAST:
        case PresenceTypeConstant.CHAT_ABSENCE:
        case PresenceTypeConstant.NEWS_CHANNEL:
        case PresenceTypeConstant.NEWS_CATEGORY:
        case PresenceTypeConstant.MUTE_CHAT:
        case PresenceTypeConstant.MUTE_CHAT_ABSENSE:
          this._chatService.receiveChatPresence_v2(p, correlationId, accUserId);
          needUpdateChat = true;
          break;

        case PresenceTypeConstant.USER_GROUP:
        case PresenceTypeConstant.USER_GROUP_ABSENCE:
          this._userGroupService.receiveUserGroupPresence_v2(p, accUserId);
          needUpdateUserGroup = true;
          break;
      }
    })

    // // If this is a response from GET_ROSTER, do postGetRosterChecking
    // let isPresenceForTargetAccount = incomingPresence.headers['destination'].indexOf(accUserId);
    if (correlationId == AMQPRoutingKey.CORRELATION_ID.GET_ROSTER) {
      this.postGetRosterChecking_v2(accUserId);
    }
    // no need to update UI display of roster data for the account data in the background
    if (this._accountManagerService.userId !== accUserId) {
      return
    }

    if (needUpdateContact) {
      this._userContactService.updateContactSubject_v2(accUserId);
    }
    if (needUpdateChat) {
      this._chatService.updateChatSubject_v2(accUserId);
    }
    if (needUpdateUserGroup) {
      this._userGroupService.updateUserGroupSubject_v2(accUserId);
    }
  }

  tryToGetMissingUserGroupUnderRoot_v2(accUserId: string): void {
    const roster = this.getRosterObjectByUserId(accUserId)

    if (roster.isGotMissingGroupsUnderRoot) {
      return;
    }
    // Get missing contacts under ROOT by account
    this._userGroupService.getMissingContactsAndUserGroupContactsUnderUserGroupByUserGroupId_v2(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID, accUserId);
    roster.isGotMissingGroupsUnderRoot = true;

    // Find all child user groups under ROOT recursively by account
    let allChildGroupIds = this._userGroupService.getAllUserGroupIdsUnderUserGroupByUserGroupId_v2(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID, accUserId);
    // console.log('allChildGroupIds', allChildGroupIds);
    // Get missing contacts under each child group by account
    _.each(allChildGroupIds, (id) => {
      this._userGroupService.getMissingContactsAndUserGroupContactsUnderUserGroupByUserGroupId_v2(id, accUserId);
    });
  }

  getUserContactsUnderUserGroup_v2(userGroupId: string, accUserId: string): UserGroup {
    // this._loggerService.debug("Getting child user groups and child contacts under user group: " + userGroupId);
    let ugsObj = this._userGroupService.getUserGroupsByAccountUserId(accUserId)
    let targetUserGroup = ugsObj.userGroups[userGroupId]
    
    if (!targetUserGroup) {
      // this._loggerService.debug("User group not found");
      console.log('User group not found for account:' + accUserId);
      return;
    }
    targetUserGroup.childUserGroup = [];
    targetUserGroup.contactsWithoutSelf = [];
    _.each(targetUserGroup.children, (childUserGroupId) => {
      targetUserGroup.childUserGroup.push(ugsObj.userGroups[childUserGroupId]);
    });

    let userIdWithoutSelf = _.without(targetUserGroup.members, accUserId);

    _.each(userIdWithoutSelf, (memberUserId) => {
      targetUserGroup.contactsWithoutSelf.push(this._userContactService.getUserContactByUserIdAndAccountUserId(memberUserId, accUserId));
    });
    
    console.log("No. of child user group: " + targetUserGroup.childUserGroup.length + "\nNo. of child contacts without 'self': " + targetUserGroup.contactsWithoutSelf.length);

    return targetUserGroup;
  }

  receiveMessageCallback_v2(incomingMessage, isBinding?: boolean, accUserId?: string): void{ 
    if (isBinding) {
      // this._loggerService.debug("Type = queue binding, update message queue name");

      const roster = this.getRosterObjectByUserId(accUserId)
      roster.messageQueueName = incomingMessage.headers.temp_qname;

      if (this._accountManagerService.userId === accUserId) {
        this.messageQueueName = roster.messageQueueName;
        this._messagesService._messageQueueName = roster.messageQueueName;
        this._newsMessageService._messageQueueName = roster.messageQueueName;
      }

      this._newsMessageService.setMessageQueueNameByAccount(roster.messageQueueName, accUserId)

      // this._loggerService.debug('[messageQueueName in loadOfflineMessage]' + roster.messageQueueName);
      console.log('[messageQueueName in loadOfflineMessage]' + roster.messageQueueName);

      // request the offline message
      let accDisconnectTime = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT.COOKIES.DISCONNECT_TIME + `_${accUserId}`);
      if (accDisconnectTime) {
        this.loadOfflineMessage_v2(accDisconnectTime, accUserId);
      }
      return;
    }
    
    let correlationId = incomingMessage.headers['correlation-id'];

    if (correlationId == AMQPRoutingKey.CORRELATION_ID.LOAD_RECENT_MESSAGE) {
      this._isInitEnd = true;
    }

    if (!incomingMessage.body) {
      return;
    }
    
    this._messagesService.receiveMessages_v2(incomingMessage, accUserId);
  }

  getRecentNews_v2(accUserId: string): void {
    let roster = this.getRosterObjectByUserId(accUserId)

    if (!roster._isRequestedRecentNews) {
      // this._loggerService.debug("Recent News were not requested before, loading news history...");
      roster._isRequestedRecentNews = true;
      
      this.loadNewsHistory_v2(
        null,
        (news) => {
          if (this._accountManagerService.userId !== accUserId) {
            return
          }
          
          this._newsMessageService.openAdvertisementNews();
        },
        accUserId
      );
    }
  }

  getRecentMessage_v2(accUserId: string): void {
    let accDisconnectTime = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT.COOKIES.DISCONNECT_TIME + `_${accUserId}`);
    if (accDisconnectTime) {
      this.loadOfflinePresence_v2(accDisconnectTime, accUserId);
    }
    let roster = this.getRosterObjectByUserId(accUserId)

    if (!roster._isRequestedRecentMessage) {
      // this._loggerService.debug("Recent messages were not requested before, loading recent messages...");
      roster._isRequestedRecentMessage = true;

      let correlationId = AMQPRoutingKey.CORRELATION_ID.LOAD_RECENT_MESSAGE;
      let routingKey = AMQPRoutingKey[correlationId];
      let headers = {
        'correlation-id': correlationId,
        'reply-to': roster.messageQueueName,
        'size': this._teamnoteConfigService.config.WEBCLIENT.AMQP.LOAD_RECENT_MESSAGE_SIZE
      };
      let body = '';

      this._socketService.sendMessage_v2(accUserId, routingKey, headers, body, correlationId);
    } else {
      //if no need to get recent message, init end here
      this._isInitEnd = true;
    }
  }

  loadNewsHistory_v2(newsCategoryId?: string, callback?: Function, accUserId?: string): void {
    let allNewsChannelChatIdsByAccount = _.map(this._chatService.getNewsChannels_v2(accUserId), 'chat_id');

    if (allNewsChannelChatIdsByAccount.length == 0) {
      // this._loggerService.warn("Trying to load news history without news channel id, rejected.");
      this._loggerService.warn(`Trying to load news history without news channel id, rejected. by account user id ${accUserId}`);
      return;
    }

    let roster = this.getRosterObjectByUserId(accUserId)

    this._loggerService.debug(`Getting news history... for account user id ${accUserId}`);
    let correlationId = AMQPRoutingKey.CORRELATION_ID.LOAD_HISTORY;
    let routingKey = AMQPRoutingKey[correlationId];
    correlationId += '_NEWS_' + allNewsChannelChatIdsByAccount.length + '_' + _.now();
    let headers = {
      'correlation-id': correlationId,
      'chat_id': JSON.stringify(allNewsChannelChatIdsByAccount),
      'reply_to': roster.messageQueueName,
      'size': this._teamnoteConfigService.config.WEBCLIENT.AMQP.NEWS_LOAD_HISTORY_SIZE
    };
    let firstNews;
    if (newsCategoryId) {
      headers['news_category_chat_id'] = newsCategoryId;
      firstNews = this._newsMessageService.getEarliestNewsUnderNewsCategory_v2(newsCategoryId, accUserId);
    } else {
      firstNews = this._newsMessageService.getEarliestNewsUnderNewsChannels_v2(allNewsChannelChatIdsByAccount, accUserId);
    }
    if (firstNews) {
      headers['message_time'] = firstNews.timestamp.toString();
    }
    let body = '';

    let loadHistoryCallback = (incomingMessage) => {
      this.receiveMessageCallback_v2(incomingMessage, false, accUserId);
      let bodyLength = this.findNumberOfContentMessage(incomingMessage);
      if (callback) {
        callback(bodyLength);
      }
    };

    this._socketService.sendMessage_v2(accUserId, routingKey, headers, body, correlationId, loadHistoryCallback);
  }

  loadOfflineMessage_v2(accDisconnectTime: string, accUserId: string): void {
    let conn = this._socketService.getWebSocketConnectionByUserId(accUserId);
    let roster = this.getRosterObjectByUserId(accUserId)

    // this._loggerService.debug(`[Account ${accUserId}] [messageQueueName in loadOfflineMessage]` + this.messageQueueName);
    console.log(`[Account ${accUserId}] [messageQueueName in loadOfflineMessage]` + roster.messageQueueName);

    if (conn.isNeedReconnect) {
      // this._loggerService.debug(`[Account ${accUserId}] Getting offline messages...`);
      console.log(`[Account ${accUserId}] Getting offline messages...`);
      let correlationId = AMQPRoutingKey.CORRELATION_ID.LOAD_OFFLINE_MESSAGE;
      let routingKey = AMQPRoutingKey[correlationId];
      let headers = {
        'correlation-id': correlationId,
        // 'device_token': this._localStorageManagerService.getDeviceToken(),
        'device_token': this._accountManagerService.getAccountUserFieldByFieldNameByAccount('device_token', accUserId),
        'message_time': accDisconnectTime,
        'reply-to': roster.messageQueueName || conn.messageQueueName
      };
      let body = '';

      this._socketService.sendMessage_v2(accUserId, routingKey, headers, body, correlationId);
      this._socketService.setOfflineMsgGot_v2(accUserId);
      // this._chatMessageService.sendFirstMessageInQueue_v2(true);
    }
  }

  loadOfflinePresence_v2(accDisconnectTime: string, accUserId: string): void {
    let conn = this._socketService.getWebSocketConnectionByUserId(accUserId);
    let roster = this.getRosterObjectByUserId(accUserId)

    // this._loggerService.debug(`[Account ${accUserId}] [presenceQueueName in loadOfflinePresence]` + this.presenceQueueName)
    console.log(`[Account ${accUserId}] [presenceQueueName in loadOfflinePresence]` + this.presenceQueueName)

    if (conn.isNeedReconnect) {
      // this._loggerService.debug(`[Account ${accUserId}] Getting offline presences...`);
      console.log(`[Account ${accUserId}] Getting offline presences...`);

      let correlationId = AMQPRoutingKey.CORRELATION_ID.LOAD_OFFLINE_PRESENCE;
      let routingKey = AMQPRoutingKey[correlationId];
      let headers = {
        'correlation-id': correlationId,
        // 'device_token': this._localStorageManagerService.getDeviceToken(),
        'device_token': this._accountManagerService.getAccountUserFieldByFieldNameByAccount('device_token', accUserId),
        'message_time': accDisconnectTime,
        'reply-to': roster.presenceQueueName || conn.presenceQueueName
      };

      let allAccountChatIds = this._chatService.getAllChatIds_v2(accUserId);
      let body = JSON.stringify({
        existing_chat_ids: allAccountChatIds
      });

      this._socketService.sendMessage_v2(accUserId, routingKey, headers, body, correlationId);
      this._socketService.setOfflinePresenceGot_v2(accUserId);
    }
  }

  updateIsChatLoaded_v2(isLoaded: boolean, accUserId: string): void {
    let roster = this.getRosterObjectByUserId(accUserId)

    roster.isChatLoaded = isLoaded;

    if (accUserId !== this._accountManagerService.userId) {
      return;
    }

    this.updateIsChatLoaded(roster.isChatLoaded)
  }
}
