import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

import { AccountManagerService } from '../../account/account-manager.service';
import { SocketService } from '../../socket/socket.service';

import { UserContact } from "../../../../models/user-contact";
import { UserGroup } from '../../../../models/user-group';

import * as _ from "lodash";
import { LoggerService } from "../../../../utilities/logger/logger.service";
import { LocalStorageManagerService } from "../../../../utilities/local-storage/local-storage-manager.service";
import { UtilitiesService } from "../../../../utilities/service/utilities.service";
import { PresenceTypeConstant } from "../../../../constants/presence-type.constant";
import { TeamNoteGeneralConstant } from "../../../../constants/general.constant";
import { TeamNoteLocalStorageKeyConstants } from "../../../../constants/local-storage-key.constant";
import { AMQPRoutingKey } from "../../../../constants/amqp-routing-key.constant";
import { UserConstant, ImportantUsers } from "../../../../constants/user.constant";
import { TimestampService } from "../../../../utilities/timestamp/timestamp.service";
import { FileManagerService } from "../../../../utilities/file-manager/file-manager.service";
import { TeamNoteApiConstant } from "../../../../constants/api.constant";
import { TeamnoteApiService } from "../../../../api/teamnote-api.service";
import { TnNotificationService } from "../../../../utilities/tn-notification/tn-notification.service";

class UserContactPresence {
  accUserId?;
  userContacts?: UserContacts = null;
  requestedGetContactUserIds?: string[] = [];
  userGroupIdStack?: string[] = [];
}
interface UserContactPresenceCollection {
  [userId: string]: UserContactPresence
}
interface UserContacts {
  [userId: string]: UserContact;
}

@Injectable()
export class UserContactService {
  userContacts: UserContacts = {};
  userContacts$: BehaviorSubject<UserContact[]> = new BehaviorSubject<UserContact[]>([]);

  trackingUserContactIds: string[] = [];
  trackingUserContacts$: BehaviorSubject<UserContact[]> = new BehaviorSubject<UserContact[]>([]);

  userGroupIdStack: string[] = [];

  requestedGetContactUserIds: string[] = [];

  importantUsers: ImportantUsers = null;
  isImportantUsersLoaded: boolean = true;
  isImportantUsersLoaded$: BehaviorSubject<ImportantUsers> = new BehaviorSubject<ImportantUsers>({});

  _userContactPresences: UserContactPresenceCollection = {}; // group by logged in account 

  constructor(
    private _accountManagerService: AccountManagerService,
    private _socketService: SocketService,
    private _fileManagerService: FileManagerService,
    private _loggerService: LoggerService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _utilitiesService: UtilitiesService,
    private _timestampService: TimestampService,
    private _teamnoteApiService: TeamnoteApiService,
    private _tnNotificationService: TnNotificationService
  ) {}

  initUserContacts(): void {
    this._loggerService.debug("Reset userContacts");
    this.userContacts = {};
    this.initUserGroupIdStack();
    this.updateContactSubject();
  }

  receiveUserContactPresence(u: UserContact, correlationId: string): void {
    let isLoadOfflinePresence = correlationId ? correlationId.indexOf(AMQPRoutingKey.CORRELATION_ID.LOAD_OFFLINE_PRESENCE) != -1 : false;
    switch (u.t) {
      case PresenceTypeConstant.USER_CONTACT:
        this.insertOrUpdateUserContact(u, isLoadOfflinePresence);
        break;
      case PresenceTypeConstant.CONTACT_ABSENCE:
        this.deleteUserContactByUserId(u.user_id);
        break;
      case PresenceTypeConstant.USER_ONLINE:
        this.updateOnlineStatus(u.user_id, true, u.timestamp);
        break;
      case PresenceTypeConstant.USER_OFFLINE:
        this.updateOnlineStatus(u.user_id, false, u.timestamp);
        break;
    }

    // if this is the logged-in user, update presence somewhere
    if (u.user_id == this._accountManagerService.userId) {
      this._accountManagerService.updateSelfContactPresence(this.getUserContactByUserId(u.user_id));
    }
    
    // Try to update tracking users
    this.tryToUpdateTrackingUserByUserId(u.user_id);
  }
  updateContactSubject(): void {
    this.userContacts$.next(this.getAllUserConacts());
  }

  insertOrUpdateUserContact(u: UserContact, isLoadOfflinePresence: boolean): void {
    // remove from requested contact id
    this.requestedGetContactUserIds = _.without(u.user_id);
    
    // If loading offline presence, and received NEW deleted user presence, ignore it.
    if (isLoadOfflinePresence) {
      if (!this.userContacts[u.user_id] && u.deleted) {
        return;
      }
    }
    this.userContacts[u.user_id] = _.assign(this.userContacts[u.user_id], u);
  }
  deleteUserContactByUserId(userId: string): void {
    delete this.userContacts[userId];
  }
  updateOnlineStatus(userId: string, isOnline: boolean, lastSeen: number): void {
    let u = this.getUserContactByUserId(userId);
    if (u) {
      this._loggerService.debug(`
        Updating user online status:\n
        User id: ` + userId + `\n
        is_online: ` + isOnline + `\n
        last_seen: ` + lastSeen);
      u.is_online = isOnline;
      u.last_seen = lastSeen;
    }
  }

  // Track User State
  /**
   * Set tracking user contact ids
   * 
   * - for chat room user state (out of office)
   * 
   * @param {string[]} userIds - to be tracked user's ids
   * @memberof UserContactService
   */
  setTrackingUserContactIds(userIds: string[]): void {
    this.trackingUserContactIds = userIds;
  }

  /**
   * Update tracking users' user contact object arrays
   * 
   * @memberof UserContactService
   */
  updateTrackingUserContacts(): void {
    let trackingUsers = _.map(this.trackingUserContactIds, (uid) => {
      return this.getUserContactByUserId(uid);
    });
    this.trackingUserContacts$.next(trackingUsers);
  }

  /**
   * See if received user is currently tracking, if yes, update subject.
   * 
   * @param {string} userId - updated user's id
   * @memberof UserContactService
   */
  tryToUpdateTrackingUserByUserId(userId: string): void {
    if (_.indexOf(this.trackingUserContactIds, userId) != -1) {
      this.updateTrackingUserContacts();
    }
  }

  checkIfUserIsOutOfOffice(user: UserContact): boolean {
    if (user.user_state.user_state == UserConstant.USER_STATE.OUT_OF_OFFICE) {
      if (this._timestampService.checkIfTimeAfterToday(user.user_state.expired_at)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // Contact list user group id stack
  initUserGroupIdStack() {
    this.userGroupIdStack = [TeamNoteGeneralConstant.ROOT_USER_GROUP_ID];
  }
  restoreUserGroupIdStack(idStack: string[]) {
    this._loggerService.debug("Restoring userGroupIdStack from previous session: " + idStack.join(","));
    this.userGroupIdStack = idStack;
  }
  addUserGroupIdToStack(userGroupId: string) {
    this.userGroupIdStack.push(userGroupId);
    this.updateUserGroupIdStackMetaData();
  }
  popUserGroupIdStack() {
    this.userGroupIdStack.pop();
    this.updateUserGroupIdStackMetaData();
  }
  updateUserGroupIdStackMetaData() {
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA, this.userGroupIdStack);
  }
  clearUserGroupIdStackMetaData() {
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA, "");
  }

  getAllUserConacts(): UserContact[] {
    return _.toArray(this.userContacts);
  }
  getAllUserContactsWithoutSelf(): UserContact[] {
    let contacts = this.getAllUserConacts();
    return _.filter(contacts, (c) => {
      return c.user_id !== this._accountManagerService.userId;
    });
  }
  getUserContactByUserId(userId: string): UserContact {
    let contact = this.userContacts[userId];
    return contact ? contact : new UserContact;
  }

  getMissingContacts(userIds: string[]) {
    let missings = [];
    _.each(userIds, (uid) => {
      let existing = this.getUserContactByUserId(uid);
      if (!existing.user_id) {
        missings.push(uid);
      }
    });
    if (missings.length > 0) {
      this.getContact(missings);
    }
  }

  // AMQP
  updateSelfProfilePic(fileId: string): void {
    if (!fileId) {
      this._loggerService.warn("Trying to update self profile pic without pic's file id, rejected.");
      return;
    }
    let correlationId = 'DECLARE_PROFILE_' + fileId + '_' + _.now();
    let routingKey = AMQPRoutingKey.DECLARE_PROFILE;
    let headers = {
      pic: fileId
    };
    let body = '';

    if (!this._accountManagerService.isEnableMultiAccountLogin()) {
      this._socketService.sendMessage(routingKey, headers, body, correlationId);
    } else {
      this._socketService.sendMessage_v2(this._accountManagerService.userId, routingKey, headers, body, correlationId);
    }
  }
  updateSelfPublicMessage(publicMessage: string): void {
    if (!publicMessage) {
      this._loggerService.warn("Trying to update self profile public message without content, rejected.");
      return;
    }
    let correlationId = 'DECLARE_PROFILE_' + publicMessage + '_' + _.now();
    let routingKey = AMQPRoutingKey.DECLARE_PROFILE;
    let headers = {
      public_message: publicMessage
    };
    let body = '';

    if (!this._accountManagerService.isEnableMultiAccountLogin()) {
      this._socketService.sendMessage(routingKey, headers, body, correlationId);
    } else {
      this._socketService.sendMessage_v2(this._accountManagerService.userId, routingKey, headers, body, correlationId);
    }
  }
  getContact(uids: string[]): void {
    if (uids.length == 0) {
      this._loggerService.warn("Trying to get contact without user ids, rejected.");
      return;
    }
    // do not repeatedly request
    let ids = [];
    _.each(uids, (uid) => {
      if (_.indexOf(this.requestedGetContactUserIds, uid) === -1) {
        this.requestedGetContactUserIds.push(uid);
        ids.push(uid);
      }
    });

    let chunks = this._utilitiesService.getChunkedMessageArr(ids);
    _.each(chunks, (c) => {
      this.getContactRequest(c);
    });
  }
  getContactRequest(uids: string[], callback?: Function): void {
    let uidString = _.uniq(uids).join(' ');
    let correlationId = 'GET_CONTACT_' + _.now();
    let routingKey = AMQPRoutingKey.GET_CONTACT;
    let headers = {
      'correlation-id': correlationId,
      uid: uidString
    };
    let body = '';

    if (!this._accountManagerService.isEnableMultiAccountLogin()) {
      this._socketService.sendMessage(routingKey, headers, body, correlationId, callback);
    } else {
      this._socketService.sendMessage_v2(this._accountManagerService.userId, routingKey, headers, body, correlationId, callback);
    }
  }
  updateSelfUserState(userState: number, expiredAt?: number): void {
    let correlationId = 'DECLARE_USER_STATE_' + _.now();
    let routingKey = AMQPRoutingKey.DECLARE_USER_STATE;
    let headers = {
      'correlation-id': correlationId,
      user_state: userState
    };
    if (expiredAt) {
      headers['expired_at'] = expiredAt;
    }
    let body = '';

    if (!this._accountManagerService.isEnableMultiAccountLogin()) {
      this._socketService.sendMessage(routingKey, headers, body, correlationId);
    } else {
      this._socketService.sendMessage_v2(this._accountManagerService.userId, routingKey, headers, body, correlationId);
    }
  }

  storeImportantUsers(iu: ImportantUsers): void {
    this.importantUsers = iu
  }

  getImportantUsers(): ImportantUsers | null {
    return this.importantUsers
  }

  checkIfUserIsImportant(userId: string): number {
    let vipUsers = this.getImportantUsers();
    if (!vipUsers) {
      return UserConstant.IMPORTANT_LEVEL.NORMAL
    }

    let { company: global_vips, user: individual_vips } = vipUsers

    if (global_vips && global_vips.length) {
      if (_.includes(global_vips, userId)) {
        return UserConstant.IMPORTANT_LEVEL.GLOBAL
      }
    } 

    if (individual_vips && individual_vips.length) {
      if (_.includes(individual_vips, userId)) {
        return UserConstant.IMPORTANT_LEVEL.INDIVIDUAL
      }
    }

    return UserConstant.IMPORTANT_LEVEL.NORMAL
  }

  setOrUnsetImportantUsers(option: string, userId: string, callback?: Function): void {
    let url = '';

    if (option === 'SET') {
      url = TeamNoteApiConstant.IMPORTANT_USERS.SET;
    } else {
      url = TeamNoteApiConstant.IMPORTANT_USERS.UNSET;
    }

    let params = {
      user_id: userId
    };

    this._teamnoteApiService.callApi(
      url,
      params,
      (resp: any) => {
        if (callback) {
          callback();
        }
      },
      (err) => {
        this._tnNotificationService.showSystemError();
      },
      null,
      null,
      true
    );
  }

  updateImportantUsers(iu: ImportantUsers): void {
    this.isImportantUsersLoaded$.next(iu);
  }

  switchAccountUserContacts(accUserId: string): void {
    console.log("switch userContacts", accUserId);
    let ucp = this.getUserContactPresencesByUserId(accUserId)

    this.userContacts = ucp.userContacts;
    this.setTrackingUserContactIds([]); // reset trackingUserContactIds when switch account
    
    this.initUserGroupIdStack();
    this.updateContactSubject();
  }

  clearMultiAccountUserContacts(): void {
    this._userContactPresences = {};
  }

  initUserContactPresencesByAccountUserId(accUserId: string): void {
    console.log('initUserContactPresencesByAccountUserId');

    if (!this._userContactPresences[accUserId]) {
      // init userContacts for each _userContactPresences grouped by account's UserId
      this._userContactPresences[accUserId] = {
        accUserId: accUserId,
        userContacts: {},
        requestedGetContactUserIds: []
      };
    }

    console.log('this._userContactPresences', this._userContactPresences);

  }

  getUserContactPresencesByUserId(accUserId: string): UserContactPresence {
    return this._userContactPresences[accUserId];
  }

  receiveUserContactPresence_v2(u: UserContact, correlationId: string, accUserId?: string): void {
    let isLoadOfflinePresence = correlationId ? correlationId.indexOf(AMQPRoutingKey.CORRELATION_ID.LOAD_OFFLINE_PRESENCE) != -1 : false;
    switch (u.t) {
      case PresenceTypeConstant.USER_CONTACT:
        this.insertOrUpdateUserContact_v2(u, isLoadOfflinePresence, accUserId);
        break;
      case PresenceTypeConstant.CONTACT_ABSENCE:
        this.deleteUserContactByUserId_v2(u.user_id, accUserId);
        break;
      case PresenceTypeConstant.USER_ONLINE:
        this.updateOnlineStatus_v2(u.user_id, true, u.timestamp, accUserId);
        break;
      case PresenceTypeConstant.USER_OFFLINE:
        this.updateOnlineStatus_v2(u.user_id, false, u.timestamp, accUserId);
        break;
    }


    // if this is the logged-in user, and it is active, update presence somewhere
    if (u.user_id == accUserId) {
      this._accountManagerService.updateSelfContactPresence_v2(this.getUserContactByUserIdAndAccountUserId(u.user_id, accUserId), accUserId);
    }

    if (this._accountManagerService.userId !== accUserId) {
      return;
    }
  
    // Try to update tracking users
    this.tryToUpdateTrackingUserByUserId_v2(u.user_id, accUserId);
  }

  insertOrUpdateUserContact_v2(u: UserContact, isLoadOfflinePresence: boolean, userId: string): void {
    let ucp = this.getUserContactPresencesByUserId(userId)
    // remove from requested contact id
    // this.requestedGetContactUserIds = _.without(this.requestedGetContactUserIds, u.user_id);
    
    // If loading offline presence, and received NEW deleted user presence, ignore it.
    if (isLoadOfflinePresence) {
      if (!ucp.userContacts[u.user_id] && u.deleted) {
        return;
      }
    }

    // console.log('insertOrUpdateUserContact_v2', u);
    ucp.userContacts[u.user_id] = _.assign(ucp.userContacts[u.user_id], u);
  }

  getUserContactByUserIdAndAccountUserId(userId: string, accUserId: string): UserContact {
    let ucp = this.getUserContactPresencesByUserId(accUserId)

    let contact = ucp.userContacts[userId];
    return contact ? contact : new UserContact;
  }

  getMissingContacts_v2(userIds: string[], accUserId: string) {    
    let missings = [];
    _.each(userIds, (uid) => {
      let existing = this.getUserContactByUserIdAndAccountUserId(uid, accUserId);
      if (!existing.user_id) {
        missings.push(uid);
      }
    });
    if (missings.length > 0) {
      this.getContact_v2(missings, accUserId);
    }
  }

  getContact_v2(uids: string[], accUserId: string): void {
    if (uids.length == 0) {
      this._loggerService.warn("Trying to get contact without user ids, rejected.");
      return;
    }

    let ucp = this.getUserContactPresencesByUserId(accUserId);
    // do not repeatedly request
    let ids = [];
    _.each(uids, (uid) => {
      if (_.indexOf(ucp.requestedGetContactUserIds, uid) === -1) {
        ucp.requestedGetContactUserIds.push(uid);
        ids.push(uid);
      }
    });

    let chunks = this._utilitiesService.getChunkedMessageArr(ids);
    _.each(chunks, (c) => {
      this.getContactRequest_v2(c, null, accUserId);
    });
  }

  getContactRequest_v2(uids: string[], callback?: Function, accUserId?: string): void {
    let uidString = _.uniq(uids).join(' ');
    let correlationId = 'GET_CONTACT_' + _.now();
    let routingKey = AMQPRoutingKey.GET_CONTACT;
    let headers = {
      'correlation-id': correlationId,
      uid: uidString
    };
    let body = '';
    this._socketService.sendMessage_v2(accUserId, routingKey, headers, body, correlationId, callback);
  }

  deleteUserContactByUserId_v2(userId: string, accUserId: string): void {
    let ucp = this.getUserContactPresencesByUserId(accUserId)

    delete ucp.userContacts[userId];
  }

  updateOnlineStatus_v2(userId: string, isOnline: boolean, lastSeen: number, accUserId: string): void {
    let u = this.getUserContactByUserIdAndAccountUserId(userId, accUserId);
    if (u) {
      // console.log(`
      //   Updating user online status:\n
      //   User id: ` + userId + `\n
      //   is_online: ` + isOnline + `\n
      //   last_seen: ` + lastSeen);
        
      u.is_online = isOnline;
      u.last_seen = lastSeen;
    }
  }

  updateContactSubject_v2(accUserId: string): void {
    this.userContacts$.next(this.getAllUserConacts_v2(accUserId));
  }

  getAllUserConacts_v2(accUserId: string): UserContact[] {
    let ucp = this.getUserContactPresencesByUserId(accUserId)
    return _.toArray(ucp.userContacts);
  }

  // for tracking target user's data in chatroom
  updateTrackingUserContacts_v2(accUserId: string): void {
    let trackingUsers = _.map(this.trackingUserContactIds, (uid) => {
      // return this.getUserContactByUserId(uid);
      return this.getUserContactByUserIdAndAccountUserId(uid, accUserId);
    });
    this.trackingUserContacts$.next(trackingUsers);
  }

  /**
   * See if received user is currently tracking, if yes, update subject.
   * 
   * @param {string} userId - updated user's id
   * @memberof UserContactService
   */
  tryToUpdateTrackingUserByUserId_v2(userId: string, accUserId: string): void {
    if (_.indexOf(this.trackingUserContactIds, userId) != -1) {
      this.updateTrackingUserContacts_v2(accUserId);
    }
  }
}
