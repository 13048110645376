import { Injectable } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'

import { TeamnoteApiService } from '../../api/teamnote-api.service'
import { TeamNoteApiConstant } from '../../constants/api.constant'
import { ChatService } from '../services/data/chat/chat.service'
import { UserContact } from '../../models/user-contact'
import { TnDialogService } from '../../utilities/tn-dialog/tn-dialog.service'
import { LoggerService } from '../../utilities/logger/logger.service'
import { ScheduleMessageSetService } from './schedule-message-set/schedule-message-set.service'
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service'
import { DataManagerService } from '../services/data/data-manager.service'
import { PageUrlConstant } from '../../constants/page-url.constant';
import { Router } from '@angular/router';
import { TimestampService } from '../../utilities/timestamp/timestamp.service'
import { ModuleManagerService } from '../services/module/module-manager.service'
import { ModuleKeyDefinition } from '../../constants/module.constant'
import { SideNavService } from '../../utilities/tn-side-nav/side-nav.service'

import { addSeconds, addMinutes, getSeconds, getMilliseconds } from 'date-fns';
import { WebclientRoutingService } from '../route/webclient-routing.service'
import { TeamnoteConfigService } from '../../configs/teamnote-config.service'
import * as _ from 'lodash';

@Injectable()
export class ScheduledMessageService {
  private isChatLoadedSub: Subscription;
  // isChatLoaded: boolean = false;

  scheduleMessages: any = [];
  scheduleMessages$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  unapprovedScheduleMessages: any = [];
  unapprovedScheduleMessages$: BehaviorSubject<any[] | { allMessages: any[], isPeriodicCall: boolean }> = new BehaviorSubject<any[] | { allMessages: any[], isPeriodicCall: boolean }>([]);

  isInUnapprovedScheduleMessagesView: boolean = false;
  isInUnapprovedScheduleMessagesView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  startToPeriodCallScheduleMessagesTimer = null;
  periodScheduleMessagesTimer = null;

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _chatService: ChatService,
    private _tnDialogService: TnDialogService,
    private _loggerService: LoggerService,
    private _scheduleMessageSetService: ScheduleMessageSetService,
    private _tnNotificationService: TnNotificationService,
    private _dataManagerService: DataManagerService,
    private _router: Router,
    private _timestampService: TimestampService,
    private _moduleManagerService: ModuleManagerService,
    private _sideNavService: SideNavService,
    private _webclientRoutingService: WebclientRoutingService,
    private _teamnoteConfigService: TeamnoteConfigService,

    
  ) {
    this.isChatLoadedSub = this._dataManagerService.isChatLoaded$.subscribe(isChatLoaded => {
      if (isChatLoaded) {
        this.updateAllScheduleMessages([]);
        this.updateUnapprovedScheduleMessagesSubject([]); 

        if (this.startToPeriodCallScheduleMessagesTimer) {
          clearInterval(this.startToPeriodCallScheduleMessagesTimer)
        }

        if (this.periodScheduleMessagesTimer) {
          // console.log('clear periodScheduleMessagesTimer first');
          clearInterval(this.periodScheduleMessagesTimer);
        }

        // console.log('checkIfExerciseRoleModuleExists', this._moduleManagerService.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.SCHEDULE_MESSAGE));
        if (this._moduleManagerService.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.SCHEDULE_MESSAGE)) {
          // console.log('inited unapprovedScheduleMessages');
          // clear the existing records after switch account
          
          this.getAllScheduleMessages();
         
          const now = new Date()
          const nowSecs = getSeconds(now)
          const milliseconds = getMilliseconds(now);
          const secsWithMillisecs = ((nowSecs * 1000) + milliseconds) / 1000;
          // console.log('secsWithMillisecs', secsWithMillisecs);
  
          let untilSecs = 60 - secsWithMillisecs;
          untilSecs = _.toNumber(untilSecs.toFixed(3));
          // console.log(`nowSecs: ${nowSecs}, after ${untilSecs} will start to periodic calling`);
  
  
          this.startToPeriodCallScheduleMessagesTimer = setTimeout(() => {
            // console.log(`now is :00 sec, now will periodic calling every 60s`, this._timestampService.dateObjToSecondString(new Date()));
            this.getAllScheduleMessages(null, false, false, true, true);
  
            this.periodScheduleMessagesTimer = setInterval(() => {
              // console.log('periodScheduleMessagesTimer');
              this.getAllScheduleMessages(null, false, false, true, true);
            }, 1000 * 60);
          }, (untilSecs) * 1000);
        } else {
          // console.log('currentActiveItemKey', this._sideNavService.currentActiveItemKey);
          if (this._sideNavService.currentActiveItemKey === ModuleKeyDefinition.SCHEDULE_MESSAGE) {
            this._sideNavService.updateActiveSideNav(this._teamnoteConfigService.config.WEBCLIENT.GENERAL.WEBCLIENT_DEFAULT_PAGE_KEY);
            this._webclientRoutingService.goToDefaultPage();
          }
        }

      } else {
        console.log('isChatLoaded reseted, ready to clear the timer');
        this.updateAllScheduleMessages([]);
        this.updateUnapprovedScheduleMessagesSubject([]); 

        if (this.periodScheduleMessagesTimer) {
          clearInterval(this.periodScheduleMessagesTimer);
          this.periodScheduleMessagesTimer = null;
        }

        if (this.startToPeriodCallScheduleMessagesTimer) {
          clearInterval(this.startToPeriodCallScheduleMessagesTimer);
          this.startToPeriodCallScheduleMessagesTimer = null;
        }
      }
    })
  }

  getScheduleMessages(userId: string, companyDomain: string, success: Function, failure: Function, isShowLoding?: boolean, requireConfirm?: number, is_confirmed?: number, bufferTimestamp?: string, status?: number): void {
    let url = TeamNoteApiConstant.SCHEDULE_MESSAGE.LIST

    let params = {
      // user_id: userId
      // access_token: accessToken
      // company_domain: companyDomain
      require_confirm: requireConfirm,
      is_confirmed: is_confirmed,
      scheduled_send_time: bufferTimestamp,
      status: status
    }

    this._teamnoteApiService.callApi(url, params, success, failure, false, false, isShowLoding)
  }

  getAllScheduleMessages(callback?: Function, isNoNeedTimeBuffer?: boolean, isShowLoading?: boolean, isShowNotification?: boolean, isPeriodicCall?: boolean): void {
    // console.log(this._timestampService.dateObjToSecondString(new Date()));

    this.getScheduleMessages(
      null,
      null,
      allScheduleMessageResp => {
        // console.log('allScheduleMessageResp', allScheduleMessageResp);
        
        this.scheduleMessages = _.map(allScheduleMessageResp, (m) => {
          let nowAfterThirtySecs = this._timestampService.dateObjToSecondString(addSeconds(new Date(), 30));
          
          if (isNoNeedTimeBuffer) {
            nowAfterThirtySecs = this._timestampService.getNowSecondString();
          }

          return {
            ...m,
            isToBeApproved: this._timestampService.checkIfTimeCorrectOrder(m.scheduled_send_time, nowAfterThirtySecs) && m.require_confirm == 1 && m.status == 0
          }
        })

        this.scheduleMessages = _.orderBy(this.scheduleMessages, ['require_confirm', 'isToBeApproved', 'scheduled_send_time'], ['desc', 'desc', 'desc']);

        // console.log('parsed', this.scheduleMessages);
        const tempUnapprovedScheduleMessages = _.filter(this.scheduleMessages, { isToBeApproved: true });
        // console.log('tempUnapprovedScheduleMessages', tempUnapprovedScheduleMessages);
        if (!isPeriodicCall) {
          this.updateUnapprovedScheduleMessagesSubject(tempUnapprovedScheduleMessages);
        } else {
          this.updateUnapprovedScheduleMessagesSubject(tempUnapprovedScheduleMessages, isShowNotification, isPeriodicCall);
        }

        if (callback) {
          callback();
        }
      },
      (err) => {
        this._tnNotificationService.showSystemError()
      },
      !isShowLoading, //true,
      undefined,
      0,
      this._timestampService.dateObjToSecondString(addSeconds(new Date(), 30)), // 30 secs buffer
      // 0 // undefined 
    )
  }
  
  // unsubscribeChatLoadedSub(): void {
  //   if (this.isChatLoadedSub) {
  //     this.isChatLoadedSub.unsubscribe()
  //   }
  // }

  updateAllScheduleMessages(messages): void {
    this.scheduleMessages = messages;
  }

  updateUnapprovedScheduleMessagesSubject(messages, isShowNotification?: boolean, isPeriodicCall?: boolean): void {
    this.unapprovedScheduleMessages = messages;

    if (!isPeriodicCall) {
      this.unapprovedScheduleMessages$.next(this.unapprovedScheduleMessages);
    } else {
      this.unapprovedScheduleMessages$.next({allMessages: this.unapprovedScheduleMessages, isPeriodicCall: isPeriodicCall});
    }

    if (!isShowNotification) {
      return
    }

    if (this.unapprovedScheduleMessages.length && isPeriodicCall) {
      this._tnNotificationService.showBrowserNotification('Schedule Message', () => {
        window.focus();
        
        this._sideNavService.updateActiveSideNav(ModuleKeyDefinition.SCHEDULE_MESSAGE)
        this.goToScheduleMessagePage();
      });
    }
  }

  updateIsInUnapprovedScheduleMessagesViewSubject(isInUnapprovalView: boolean): void {
    this.isInUnapprovedScheduleMessagesView = isInUnapprovalView;
    this.isInUnapprovedScheduleMessagesView$.next(this.isInUnapprovedScheduleMessagesView);
  }

  addScheduleMessage(
    // accessToken: string,
    companyDomain: string,
    msgType: number,
    msgBody: string,
    userIds: string[],
    chatIds: string[],
    sentBy: string,
    msgSendTimeStamp: string,
    isRequiredConfirm: number,
    success: Function,
    failure: Function,
    // is_confirmed?: number,
    // status?: number,
  ): void {
    let url = TeamNoteApiConstant.SCHEDULE_MESSAGE.CREATE

    let params = {
      company_domain: companyDomain,
      message_type: msgType,
      message: msgBody,
      user_ids: userIds,
      chat_ids: chatIds,
      // sent_by: sentBy,
      scheduled_send_time: msgSendTimeStamp,
      require_confirm: isRequiredConfirm,
    }

    this._teamnoteApiService.callApi(url, params, success, failure, false, false, false)
  }

  // openSetScheduleMessageDialog(): void {
  //   this._scheduleMessageSetService.openSetScheduleMessageDialog();
  // }

  updateScheduleMessage(
    scheduledMessageId: string,
    // accessToken: string,
    companyDomain: string,
    msgType: number,
    msgBody: string,
    userIds: string[],
    chatIds: string[],
    sentBy: string,
    msgSendTimeStamp: string,
    isRequiredConfirm: number,
    status?: number,
    success?: Function,
    failure?: Function,
  ): void {
    let url = TeamNoteApiConstant.SCHEDULE_MESSAGE.UPDATE

    let params = {
      scheduled_message_id: scheduledMessageId,
      company_domain: companyDomain,
      message_type: msgType,
      message: msgBody,
      user_ids: userIds,
      chat_ids: chatIds,
      // sent_by: sentBy,
      scheduled_send_time: msgSendTimeStamp,
      require_confirm: isRequiredConfirm,
    }

    if (status !== null) {
      params['status'] = status;
    }

    this._teamnoteApiService.callApi(url, params, success, failure, false, false, false)
  }
  
  updateApprovalScheduleMessage(
    scheduledMessageId: string,
    companyDomain: string,
    sentBy: string,
    success: Function,
    failure: Function,
    status?: number,
    is_confirmed?: number
  ): void {
    let url = TeamNoteApiConstant.SCHEDULE_MESSAGE.UPDATE

    let params = {
      scheduled_message_id: scheduledMessageId,
      company_domain: companyDomain,
      // sent_by: sentBy,
      is_confirmed: is_confirmed,
      status: status
    }

    // if (status !== null) {
    //   params['status'] = status;
    // }

    this._teamnoteApiService.callApi(url, params, success, failure, false, false, false)
  }

  deleteScheduleMessage(
    accessToken: string,
    companyDomain: string,
    scheduledMessageId: string,
    success: Function,
    failure: Function
  ): void {
    let url = TeamNoteApiConstant.SCHEDULE_MESSAGE.DELETE

    let params = {
      scheduled_message_id: scheduledMessageId
      // company_domain: companyDomain
    }

    this._teamnoteApiService.callApi(url, params, success, failure, false, false, false)
  }

  sendScheduleMessage(
    // accessToken: string,
    // companyDomain: string,
    scheduledMessageId: string,
    success: Function,
    failure: Function
  ): void {
    let url = TeamNoteApiConstant.SCHEDULE_MESSAGE.SEND

    let params = {
      scheduled_message_id: scheduledMessageId
      // company_domain: companyDomain
    }

    this._teamnoteApiService.callApi(url, params, success, failure, false, false, false)
  }

  goToScheduleMessagePage(): void {
    this._loggerService.log("Redirecting to /webclient/schedule-message");
    this._router.navigate(['../' + PageUrlConstant.WEBCLIENT.BASE + '/' + PageUrlConstant.WEBCLIENT.SCHEDULE_MESSAGE]);
  }
}
