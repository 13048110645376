import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { TeamNoteApiConstant } from '../../constants/api.constant'
import { ChatRoomService } from '../chat/chat-room/chat-room.service'
import { LoggerService } from '../../utilities/logger/logger.service'
import { Chat } from '../../models/chat';
import { ChatService } from '../services/data/chat/chat.service'
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant'
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service'
import { AccountManagerService } from '../services/account/account-manager.service'
import { Message } from '../../models/message';
import { TeamnoteApiService } from '../../api/teamnote-api.service'
import { AccountService } from '../../account/account.service'
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service'
import { ModuleManagerService } from '../services/module/module-manager.service'
import { ModuleKeyDefinition } from '../../constants/module.constant'

import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';

export interface MultiChatRoomsPanel {
  panelId: string;
  name?: string;
  multiChatrooms?: Chat[];
}

@Injectable()
export class MultiChatRoomService {
  multiChatRoomsPanels: MultiChatRoomsPanel[] = [];
  multiChatRoomsPanels$: BehaviorSubject<MultiChatRoomsPanel[]> = new BehaviorSubject<MultiChatRoomsPanel[]>([]);

  activeMultiChatRoomsPanel: MultiChatRoomsPanel = null;
  activeMultiChatRoomsPanel$: BehaviorSubject<MultiChatRoomsPanel> = new BehaviorSubject<MultiChatRoomsPanel>(null);

  constructor(
    private _loggerService: LoggerService,
    private _chatService: ChatService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _accountManagerService: AccountManagerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _accountService: AccountService,
    private _tnNotificationService: TnNotificationService,
    private _moduleManagerService: ModuleManagerService,

  ) {

  }

  initMultiChatRooms(isReset?: boolean): void {
    this.multiChatRoomsPanels = [];

    const updateMultiChatRoomsCallback = () => {
      this.updateMultiChatRoomsPanelsSubject();

      this.activeMultiChatRoomsPanel = null;
      this.updateActiveMultiChatRoomsPanelSubject(null);
    }

    if (!isReset) {
      this.getMultiChatsByApi(
        (multiChatPanels) => {
          console.log('multiChatPanels', multiChatPanels);
          console.log('all exercise chats', _.cloneDeep(this._chatService.chats));

          let filtered = this.checkIfChatsNeedToBeRemoved(multiChatPanels);
          // this.multiChatRoomsPanels = [...multiChatPanels];

          if (_.isEqual(filtered, multiChatPanels)) {
            // if there is no removed chats in any multiChatPanels, then use directly
            this._loggerService.debug(`Account ${this._accountManagerService.userId}: no removed chats in multiChatPanels, use directly`);

            this.multiChatRoomsPanels = [...multiChatPanels];

            updateMultiChatRoomsCallback();
          } else {
            // if there are removed chats multiChatPanels, then update first
            this._loggerService.debug(`Account ${this._accountManagerService.userId}: some chats in multiChatPanels have been removed, need update panel first`);

            this.updateMultiChatsByApi(
              JSON.stringify(filtered), 
              () => {
                this.multiChatRoomsPanels = [...filtered];
    
                updateMultiChatRoomsCallback();
              },
              () => {
                console.error('Update multi chat view failed, please try again');
                this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.UPDATE_COMMANDER_VIEW_FAIL");
              }
            )
          }
        },
        () => {
          //  callback of empty record or failure
          updateMultiChatRoomsCallback();
        }
      );

      return;

      // const multiChatroomsCookies = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.MULTI_CHATROOM_PANEL + `_${this._accountManagerService.userId}`);

      // console.log('multiChatroomsCookies', multiChatroomsCookies);
      // if (multiChatroomsCookies) {
      //   try {
      //     this.multiChatRoomsPanels = [...JSON.parse(multiChatroomsCookies)];
      //   } catch (error) {}
      // }
    }

    updateMultiChatRoomsCallback();
  }

  switchAccountMultiChatRoom(accUserId?: string): void {
    console.log("Switch multi-chatrooms", accUserId);

    console.log('this._moduleManagerService.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.WEB_COMMANDER_VIEW)', this._moduleManagerService.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.WEB_COMMANDER_VIEW));
    if (this._moduleManagerService.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.WEB_COMMANDER_VIEW)) {
      this.initMultiChatRooms(false);
    } else {
      console.log('no WEB_COMMANDER_VIEW module');
      this.initMultiChatRooms(true);
    }
  }

  checkIfChatsNeedToBeRemoved(ApiPanels: MultiChatRoomsPanel[]): MultiChatRoomsPanel[] {
    let multiChatsAfterChecking = [];

    multiChatsAfterChecking = _.map(ApiPanels, (p) => {
      let filtered = _.filter(p.multiChatrooms, (chat_id: string) => {
        return this._chatService.getChatByChatId(chat_id);
      })

      return {
        ...p,
        multiChatrooms: filtered
      }
    })

    return multiChatsAfterChecking
  }

  appendMultiChatroomPanel(): void {
    const panelId = uuidv4();
    console.log('panelId', panelId);
    
    let panel: MultiChatRoomsPanel = { 
      panelId,
      // name: `#Panel-${(Math.floor(Math.random() * 20) + 1).toString()}`,
      name: `#Unnamed-Panel`,
      multiChatrooms: [] 
    };

    // this.multiChatRoomsPanels.push(panel);
    // this.updateMultiChatroomDataByApi();

    // this.updateMultiChatRoomsPanelsSubject();
    // this.updateActiveMultiChatRoomsPanelSubject(panel.panelId);

    /* storing to database */
    this._loggerService.debug(`Account ${this._accountManagerService.userId}: trying to append new multi chat panel`);
    
    this.updateMultiChatroomDataByApi(
      () => {
        this._loggerService.debug(`Account ${this._accountManagerService.userId}: append new multi chat panel success`);

        this.multiChatRoomsPanels.push(panel);
        this.updateMultiChatRoomsPanelsSubject();
        this.updateActiveMultiChatRoomsPanelSubject(panel.panelId);
      }, 
      'add',
      panel
    );
  }

  removeMultiChatroomPanel(panel_id: string): void {
    // let deleteIndex = _.findIndex(this.multiChatRoomsPanels, { panelId: panel_id });
    // this.multiChatRoomsPanels.splice(deleteIndex, 1)
    
    // this.updateMultiChatroomDataByApi();

    // this.updateActiveMultiChatRoomsPanelSubject(null);

    /* storing to database */
    let deletePanel = _.find(this.multiChatRoomsPanels, { panelId: panel_id });
    
    this._loggerService.debug(`Account ${this._accountManagerService.userId}: trying to remove target multi chat panel`);

    this.updateMultiChatroomDataByApi(
      () => {
        this._loggerService.debug(`Account ${this._accountManagerService.userId}: remove target multi chat panel success`);

        let deleteIndex = _.findIndex(this.multiChatRoomsPanels, { panelId: panel_id });
        this.multiChatRoomsPanels.splice(deleteIndex, 1)
        this.updateActiveMultiChatRoomsPanelSubject(null);
      },
      'remove',
      deletePanel
    );
  }

  getMultiChatroomPanelByPanelId(panel_id: string) {
    return _.find(this.multiChatRoomsPanels, { panelId: panel_id }) || null
  }
  
  updateActiveMultiChatRoomsPanelSubject(panel_id: string): void {
    this.activeMultiChatRoomsPanel = this.getMultiChatroomPanelByPanelId(panel_id);

    if (panel_id) {
      const chats = this.activeMultiChatRoomsPanel.multiChatrooms;
      this.activeMultiChatRoomsPanel.multiChatrooms = _.map(chats, (obj: any) => {
        if (_.isString(obj)) {
          return this._chatService.getChatByChatId(obj);
        }

        return this._chatService.getChatByChatId(obj.chat_id);
      })

      console.log('this.activeMultiChatRoomsPanel to be passed', this.activeMultiChatRoomsPanel);
    }

    this.activeMultiChatRoomsPanel$.next(this.activeMultiChatRoomsPanel);
  }

  updateMultiChatRoomsPanelsSubject(panels?: any): void {
    this.multiChatRoomsPanels$.next(this.multiChatRoomsPanels);
  }

  // updated_crs: updated charrooms ids in array
  storeActiveMultiChatroomPanel(panel_id: string, panel_name: string, chatrooms: Chat[]): void {
    if (!this.activeMultiChatRoomsPanel) {
      return;
    }

    if (this.activeMultiChatRoomsPanel.panelId === panel_id) {
      // let panel = this.getMultiChatroomPanelByPanelId(panel_id);
      // panel.name = panel_name;
      // panel.multiChatrooms = chatrooms;
      // console.log('updated', this.multiChatRoomsPanels);

      // this.updateMultiChatroomDataByApi();
      // this.updateMultiChatRoomsPanelsSubject();

      /* storing to database */
      let panelToBeUpdated = { 
        panelId: panel_id,
        name: panel_name,
        multiChatrooms: chatrooms
      };

      this._loggerService.debug(`Account ${this._accountManagerService.userId}: trying to update the active multi chatroom panel`);

      this.updateMultiChatroomDataByApi(
        () => {
          this._loggerService.debug(`Account ${this._accountManagerService.userId}: update the active multi chatroom panel success`);
          
          let panel = this.getMultiChatroomPanelByPanelId(panel_id);
          panel.name = panel_name;
          panel.multiChatrooms = chatrooms;
          
          this._tnNotificationService.showCustomInfoByTranslateKey(
            'WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.UPDATE_COMMANDER_VIEW_SUCCESS'
          )

          this.updateMultiChatRoomsPanelsSubject();
        },
        'update',
        panelToBeUpdated
      );
    }
  }

  updateMultiChatroomDataByApi(callback?: Function, action?: string, panel?: any): any {
    // let submitPanels = [...this.multiChatRoomsPanels];
    let submitPanels = _.cloneDeep(this.multiChatRoomsPanels);

    if (action && panel) {
      switch (action) {
        case 'add':
          submitPanels = [...submitPanels, panel];
          break;

        case 'update':
          let p = _.find(submitPanels, { panelId: panel.panelId })
          p.name = panel.name;
          p.multiChatrooms = panel.multiChatrooms;
          break;

        case 'remove':
          let deleteIndex = _.findIndex(submitPanels, { panelId: panel.panelId });
          submitPanels.splice(deleteIndex, 1);
          break;

        case 'remove_chats':
          _.forEach(panel, (delIndex, panelId) => {
            let targetPanel = _.find(submitPanels, { panelId: panelId });
            if (targetPanel) {
              targetPanel.multiChatrooms.splice(delIndex, 1);
            }
          })
          break;
      }
    }
    
    const list = _.map(submitPanels, (p) => {

      const chatroomIds = _.map(p.multiChatrooms, (item) => {
        if (_.isString(item)) {
          // return this._chatService.getChatByChatId(item);
          return item;
        }

        return item.chat_id;
      });

      return {
        ...p,
        multiChatrooms: chatroomIds
      }
    })

    console.log('list to be stored', list);

    this.updateMultiChatsByApi(
      JSON.stringify(list), 
      () => {
        if (callback) {
          callback();
        }
      },
      () => {
        console.error('Update multi chat view failed, please try again');
        this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.UPDATE_COMMANDER_VIEW_FAIL");
      }
    )

    // this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.MULTI_CHATROOM_PANEL + `_${this._accountManagerService.userId}`, JSON.stringify(list));
  }

  checkIfNeedToRemoveChatInMultiChatRoomsPanels(chatId: string): void {
    let allPanels = {};

    // find all chats to be removed for all multi chat panel
    _.forEach(this.multiChatRoomsPanels, (panel) => {
      const panelChatIds = _.map(panel.multiChatrooms, (c, index) => {
        if (_.isString(c)) {
          return c;
        }

        return c.chat_id;
      });

      let deleteChatIndex = _.indexOf(panelChatIds, chatId);
      if (deleteChatIndex !== -1) {
        allPanels[panel.panelId] = deleteChatIndex;
      }
    })

    this._loggerService.debug('Trying to update multi-chat panels after receive CHAT_ABSENCE');
    
    // _.forEach(allPanels, (delIndex, panelId) => {
    //   let targetPanel = _.find(this.multiChatRoomsPanels, { panelId: panelId });
    //   if (targetPanel) {
    //     targetPanel.multiChatrooms.splice(delIndex, 1);
    //   }
    // })

    // this.updateMultiChatroomDataByApi();
    
    // if (this.activeMultiChatRoomsPanel) {
    //   this.activeMultiChatRoomsPanel$.next(this.activeMultiChatRoomsPanel);
    // }

    /* storing to database */
    this.updateMultiChatroomDataByApi(
      () => {
        this._loggerService.debug(`Account ${this._accountManagerService.userId}: update multi-chat panels after receive CHAT_ABSENCE`);

        console.log('allPanels', allPanels);
        console.log('before this.multiChatRoomsPanels', _.cloneDeep(this.multiChatRoomsPanels));

        // check all panels, remove target deleted chats
        _.forEach(allPanels, (delIndex, panelId) => {
          let targetPanel = _.find(this.multiChatRoomsPanels, { panelId: panelId });
          if (targetPanel) {
            targetPanel.multiChatrooms.splice(delIndex, 1);
          }
        })

        console.log('after this.multiChatRoomsPanels', _.cloneDeep(this.multiChatRoomsPanels));

        if (this.activeMultiChatRoomsPanel) {
          this._tnNotificationService.showCustomInfoByTranslateKey("WEBCLIENT.CHAT.CHAT_GROUP.CHAT_REMOVED_TIPS");

          console.log('this.activeMultiChatRoomsPane', this.activeMultiChatRoomsPanel);
          // this.activeMultiChatRoomsPanel$.next(this.activeMultiChatRoomsPanel);
          this.updateMultiChatRoomsPanelsSubject();
          this.updateActiveMultiChatRoomsPanelSubject(this.activeMultiChatRoomsPanel.panelId);
        }
      },
      'remove_chats',
      allPanels
    );
  }

  getMultiChatsByApi(success: Function, failure?: Function): void {
    this._teamnoteApiService.getCommanderView(
      this._accountService.accessToken,
      this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DOMAIN),
      (resp) => {
        console.log('all commander view chats', resp);
        if (!_.isEmpty(resp)) {
          let panel_view = JSON.parse(resp.panel_view);

          if (success) {
            success(panel_view);
          }
        } else {
          // null response
          console.log('null response');
          if (failure) {
            failure();
          }
        }
      },
      (err) => {
        if (failure) {
          failure();
        }
      }
    )
  }

  updateMultiChatsByApi(panelBody: string, successCallback?: Function, failureCallback?: Function): void {
    this._teamnoteApiService.storeCommanderView(
      this._accountService.accessToken,
      panelBody,
      (resp) => {
        console.log('all commander view chats', resp);
        if (successCallback) {
          successCallback();
        }
      },
      (err) => {
        if (failureCallback) {
          failureCallback();
        }
      }
    )
  }
}
