import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// import { NguiMapModule } from '@ngui/map';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { CookieModule } from 'ngx-cookie';
import { TeamnoteApiService } from './api/teamnote-api.service';
import { AppComponent } from './app.component';
import { TeamNoteDefaultConfig } from './configs/default.config';
import { TeamnoteConfigService } from './configs/teamnote-config.service';
import { LoginModule } from './login/login.module';
import { StringHelperService } from './strings/string-helper.service';
import { UtilitiesModule } from './utilities/utilities.module';
import { VersionCheckingService } from './utilities/version-checking/version-checking.service';
import { AccountService } from './account/account.service';
import { AppRoutingModule } from './routing/app-routing.module';
import { WebclientModule } from './webclient/webclient.module';
import { QRCodeModule } from 'angularx-qrcode';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CookieModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoginModule,
    TranslateModule.forRoot(),
    UtilitiesModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    // NguiMapModule.forRoot({
    //   apiUrl: 'https://maps.google.com/maps/api/js?key=' + TeamNoteDefaultConfig.MAP.API_KEY
    // }),
    GoogleMapsModule,
    AppRoutingModule,
    WebclientModule,
    QRCodeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // PdfJsViewerModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    TeamnoteApiService,
    TeamnoteConfigService,
    VersionCheckingService,
    StringHelperService,
    AccountService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
