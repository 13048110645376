export class MessageAnnotate {
  parent_message_id: string // message_id of target message
  sent_by: string // the user_id of users who have starred target message
  timestamp: string
  type: number

  annotation_type: number
  body: string
  is_recalled: number
  message_annotation_id?: string
}

export const AnnotationType = {
  ATTACHED_MESSAGE: 0,
  STANDALONE_MESSAGE: 1
};
