export const TeamNoteApiConstant = {
  // WEBCLIENT_LOGIN: '/webclient/login',
  // ADMIN_LOGIN: '/admin/login',
  MODULE: '/module',
  WATERMARK: '/module/watermark',
  UPLOAD: '/upload',
  GET_TNC: '/get_tnc',
  LOGIN: {
    GET_AREA_CODE: '/country',
    REG_GET_STATE: '/webclient/get-state',
    REG_GET_STATE_FOR_FORGET_PASSWORD: '/registration/get-state',
    REG_INIT: '/registration/init',
    REG_OTP: '/registration/otp',
    REG_FINISH: '/registration/finish',
    REG_CHANGE_PW: '/registration/change-password',
    WEBCLIENT_LOGIN: '/webclient/login',
    WEBCLIENT_REG_DEVICE: '/webclient/register',
    WEBCLIENT_REG_INIT: '/webclient/init',
    WEBCLIENT_REG_FINISH: '/webclient/finish',
    LOGIN_METHODS: '/login/methods',
    OAUTH_ME: '/oauth/me',
    OAUTH_LOGIN: '/webclient/login/oauth',
    SAML_ME: '/saml/me',
    SAML_LOGIN: '/webclient/login/saml',
    QRCODE_LOGIN: '/webclient/login/qrcode'
  },
  LOGOUT: '/logout',
  NEWS: {
    RECALL_COMMENT: '/news/comment/recall',
    READ: '/news/read',
    READ_LIST: '/news/read_list',
    LIST: '/news/list',
    CATEGORY: '/news/category',
  },
  VOTE: {
    GET_VOTE_DETAILS: '/module/vote',
    SUBMIT_VOTE: '/module/vote/answer'
  },
  MESSAGE: {
    MESSAGE_DETAIL: '/message_detail',
    RECALL: '/recall',
    REPORT: '/report_message',
    SEARCH: '/search_msg',
    LOAD_HISTORY: '/load_history',
    EXPORT: '/group/message_log/export'
  },
  TRAINING: {
    GET_TRAINING_LIST: '/module/training'
  },
  QUESTIONNAIRE: {
    GET_QUESTIONNAIRE_LIST: '/questionnaire/list',
    ANSWER_QUESTIONNAIRE: '/questionnaire/answer'
  },
  JOB_REPORT: {
    STORE_REPORT: {
      GET_STORE_REPORT_LIST: '/store_report/list',
      GET_STORE_REPORT_OPTIONS: '/store_report/options',
      SUBMIT_STORE_REPORT: '/store_report/submit',
      EXPORT_STORE_REPORT: '/store_report/export'
    },
    JOB_DISPATCH: {
      GET_JOB_DISPATCH_LIST: '/store_report/job_dispatch/list',
      DISPATCH_JOB: '/store_report/job_dispatch/set',
      ACCEPT_JOB: '/store_report/job_dispatch/accept',
      DECLINE_JOB: '/store_report/job_dispatch/decline',
      START_JOB: '/store_report/job_dispatch/start',
      FINISH_JOB: '/store_report/job_dispatch/finish',
      FOLLOW_UP_JOB: '/store_report/job_dispatch/follow_up',
      EXPORT_JOB: '/store_report/job_dispatch/export'
    }
  },
  CORPORATE_MATERIAL: {
    GET_CORPORATE_MATERIAL_LIST: '/corporate_material',
    GET_CORPORATE_MATERIAL_EBOOK: '/corporate_material/ebook'
  },
  WEB_DAV_MATERIAL: {
    GET_WEB_DAV_MATERIAL_LIST: '/webdav_material',
    GET_WEB_DAV_MATERIAL_EBOOK: '/webdav_material/ebook'
  },
  CRONJOB: {
    GET: '/admin/cronjob'
  },
  WORKFLOW: {
    OPTION: '/workflow/options',
    SET_FORM: '/workflow/set',
    CHANGE_STAGE: '/workflow/change_state',
    LIST: '/workflow/list',
    GET: '/workflow',
    DELETE: '/workflow/delete',
    EXPORT: '/workflow/export',
    SET_OBSERVERS: '/workflow/set_observers'
  },
  MESSAGE_STAR: {
    GET_STARRED_MESSAGES_LIST: '/messages/starred',
  },
  GROUP: {
    TAGS: '/group/tags'
  },
  VIDEO_STREAMING: {
    ACTIVE_STREAMS: '/srs/chat/active_streams'
  },
  STICKER: {
    CATEGORIES: '/stickers/categories',
    STICKERS: '/stickers'
  },
  USER_FIELDS: '/module/config',
  IMPORTANT_USERS: {
    GET: '/important_users',
    SET: '/important_users/set',
    UNSET: '/important_users/unset',
  },
  INSTALLATION_LINK: '/app/installation_link',
  MULTIPLE_COMPANY_DOMAIN: {
    GET_AVAILABLE_EXERCISE: '/module/ttx/user/exercise',
    LOGIN_EXERCISE: '/module/ttx/user/exchange_token',
  },
  WEB_COMMANDER_VIEW: {
    GET: '/module/ttx/multichat/get',
    SET: '/module/ttx/multichat/set'
  },
  SCHEDULE_MESSAGE: {
    LIST: '/module/ttx/scheduled_message',
    CREATE: '/module/ttx/scheduled_message/create',
    UPDATE: '/module/ttx/scheduled_message/update',
    DELETE: '/module/ttx/scheduled_message/delete',
    SEND: '/module/ttx/confirmed_scheduled_message/send'
  },
  ANNOTATION: {
    RECALL: '/module/ttx/message_annotation/recall'
  }
};
