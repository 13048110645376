import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ChatComponent} from './chat/chat.component';
import {ContactComponent} from './contact/contact.component';
import {CorporateMaterialComponent} from './corporate-material/corporate-material.component';
import {JobReportComponent} from './job-report/job-report.component';
import {NewsComponent} from './news/news.component';
import {QuestionnaireComponent} from './questionnaire/questionnaire.component';
import {TrainingComponent} from './training/training.component';
import {WebclientAuthGuardService} from './webclient-auth-guard.service';
import {WebclientComponent} from './webclient.component';
import {PageUrlConstant} from '../constants/page-url.constant';
import {NewsCategoryComponent} from './news-category/news-category.component';
import {WebviewComponent} from './webview/webview.component';
import {WorkflowComponent} from './workflow/workflow.component';
import {VideoStreamingComponent} from './video-streaming/video-streaming.component';
import {WebDavMaterialComponent} from './webdav-material/webdav-material.component';
import {TeamflareAppComponent} from './teamflare-app/teamflare-app.component';
import {DownloadComponent} from './download/download.component';
import {StarredMessagesComponent} from './starred-messages/starred-messages.component';
import {ScheduleMessageComponent} from './schedule-message/schedule-message.component';

export const LOGIN_ROUTES: Routes = [
  {
    path: PageUrlConstant.DOWNLOAD.BASE,
    component: DownloadComponent,
    canActivate: [WebclientAuthGuardService],
    children: [{
      path: PageUrlConstant.DOWNLOAD.BASE,
      component: DownloadComponent
    }]
  },
  {
    path: PageUrlConstant.WEBAPP.BASE,
    component: WebclientComponent,
    canActivate: [WebclientAuthGuardService],
    children: [{
      path: PageUrlConstant.WEBCLIENT.WORKFLOW,
      component: WorkflowComponent,
    }]
  },
  {
    path: PageUrlConstant.WEBCLIENT.BASE,
    component: WebclientComponent,
    canActivate: [WebclientAuthGuardService],
    children: [
      {
        path: PageUrlConstant.WEBCLIENT.NEWS,
        component: NewsComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.CONTACT,
        component: ContactComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.CHAT,
        component: ChatComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.SCHEDULE_MESSAGE,
        component: ScheduleMessageComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.TRAINING,
        component: TrainingComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.QUESTIONNAIRE,
        component: QuestionnaireComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.STORE_REPORT,
        component: JobReportComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.CORPORATE_MATERIAL,
        component: CorporateMaterialComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.NEWS_CATEGORY,
        component: NewsCategoryComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.WEBVIEW,
        component: WebviewComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.WORKFLOW,
        component: WorkflowComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.VIDEO_STREAMING,
        component: VideoStreamingComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.WEBDAV_MATERIAL,
        component: WebDavMaterialComponent
      },
      {
        path: PageUrlConstant.WEBCLIENT.MESSAGE_STAR,
        component: StarredMessagesComponent
      },
      {
        path: `${PageUrlConstant.WEBCLIENT.TEAMFLARE_APP}/:url`,
        component: TeamflareAppComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(LOGIN_ROUTES)],
  exports: [RouterModule]
})
export class WebclientRoutingModule {
}
