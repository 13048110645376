<div class="tn-content">
  <ng-container *ngIf="!isInMultiChatRoomMode">
    <div
      class="tn-content-left"
      [ngClass]="{
        'current-focus-content': !activeChat,
        'current-unfocus-content': activeChat
      }"
    >
      <tn-chat-list
        [activeChat]="activeChat"
        (chatCellOnClick)="chatCellOnClick($event)"
        (messageCellOnClick)="messageCellOnClick($event)"
        (searchMessageOnClick)="searchMessageOnClick($event)"
        (switchChatRoomMode)="switchChatRoomMode($event)"
      >
      </tn-chat-list>
    </div>
    <div
      class="tn-content-right"
      [ngClass]="{
        'current-focus-content': activeChat || isSearching,
        'current-unfocus-content': !activeChat && !isSearching
      }"
    >
      <tn-chat-room
        *ngIf="activeChat && !isSearching"
        [chat]="activeChat"
        [targetMessage]="targetMessage"
        (enterChatSearchMode)="enterChatSearchMode($event)"
        (exitSearchModeAndGoToChat)="exitSearchModeAndGoToChat($event)"
        (enterSearchModeAndGoToChat)="enterSearchModeAndGoToChat($event)"
      >
      </tn-chat-room>
      <tn-chat-search
        *ngIf="isSearching"
        [chat]="searchingChat"
        [keyword]="searchingKeyword"
        (exitSearchModeAndGoToChat)="exitSearchModeAndGoToChat($event)"
        (viewSearchedMessage)="viewSearchedMessage($event)"
      >
      </tn-chat-search>
    </div>
  </ng-container>
  <ng-container *ngIf="isInMultiChatRoomMode">
    <div class="multi-chat-room-panel">
      <div class="panel-top-bar">
        <!-- <span>{{ activePanel.name }}</span> -->
        <div class="name-display" *ngIf="!isPanelNameEditing">
          <div class="name">
            <span>{{ activePanel.name }}</span>
            <i class="fa fa-fw fa-pencil text-color-primary" (click)="startNameEditing()"></i>
          </div>
        </div>
        <div class="name-display" *ngIf="isPanelNameEditing">
          <div class="name-input">
            <div class="input-group">
              <input
                #panelNameInput
                type="text"
                class="form-control"
                [placeholder]="'WEBCLIENT.CHATROOM.MULTI_CHAT.PANEL_NAME_PLACEHOLDER' | translate"
                [(ngModel)]="tempPanelName"
                (keydown.enter)="savePanelName()"
                (blur)="savePanelName()"
              />
              <!-- <span class="input-group-addon clickable">
                <i class="fa fa-fw fa-check text-color-primary" (click)="savePanelName()"></i>
              </span>
              <span class="input-group-addon clickable">
                <i class="fa fa-fw fa-times text-color-primary" (click)="discardPanelName()"></i>
              </span> -->
            </div>
          </div>
        </div>
        <div style="flex: 1 1 0"></div>
        <!-- <button mat-stroked-button color="primary" style="margin-right: 10px" (click)="test()">test</button> -->
        <div
          class="filter-btn border-color-primary clickable"
          [ngClass]="{
            'unfiltered': !multiChatFilterOptions.selectedChatMembers.length,
            'filtered theme-color-primary': multiChatFilterOptions.selectedChatMembers.length
          }"
          (click)="onOpenMembersFilter($event)"
          [disabled]="multiChatrooms.length === 0"
        >
          <!-- {{ 'WEBCLIENT.WORKFLOW.FILTER.TITLE' | translate }}
          <span class="filtered-count" *ngIf="multiChatFilterOptions.selectedChatMembers.length">{{ multiChatFilterOptions.selectedChatMembers.length }}</span>
          <i class="fa fa-user-o fa-1"></i> -->
          <ng-container *ngIf="multiChatFilterOptions.selectedChatMembers.length === 0">
            <div class="unfiltered-icon"></div>
            <!-- <i class="fa fa-filter fa-1"></i> -->
            {{ 'WEBCLIENT.CHAT.SEARCH_UNFILTERED' | translate }}
          </ng-container>
          <ng-container *ngIf="multiChatFilterOptions.selectedChatMembers.length > 0">
            <div class="filtered-icon"></div>
            <span *ngIf="multiChatFilterOptions.selectedChatMembers.length === 1">{{
              'WEBCLIENT.CHAT.SEARCH_FILTERED_ONE'
                | translate : { COUNT: multiChatFilterOptions.selectedChatMembers.length }
            }}</span>
            <span *ngIf="multiChatFilterOptions.selectedChatMembers.length > 1">{{
              'WEBCLIENT.CHAT.SEARCH_FILTERED_MANY'
                | translate : { COUNT: multiChatFilterOptions.selectedChatMembers.length }
            }}</span>
          </ng-container>
        </div>

        <button mat-stroked-button style="margin-right: 10px" (click)="onOpenDateTimeSelection()" [disabled]="this.multiChatrooms.length === 0">
          <i class="fa fa-calendar fa-1" style="margin-right: 5px; font-size: 18px"></i>
          <span *ngIf="!multiChatFilterOptions.selectedDateRange"> Date </span>
          <span *ngIf="multiChatFilterOptions.selectedDateRange && dateTimeRangeDisplay()"> 
            {{ dateTimeRangeDisplay() }}
          </span>
        </button>

        <button mat-stroked-button style="margin-right: 10px" (click)="exitMultiChatRoomMode()">
          <i class="fa fa-floppy-o fa-1" style="margin-right: 5px; font-size: 18px"></i>
          <!-- Save and Exit -->
          Save
        </button>
        <button mat-stroked-button style="margin-right: 10px" (click)="removeCurrentPanel()">
          <i class="fa fa-trash-o fa-1" style="margin-right: 5px; font-size: 18px"></i>
          Delete Panel
        </button>
        <button
          mat-stroked-button
          style="margin-right: 10px"
          (click)="selectChatRoom()"
          [disabled]="multiChatrooms.length >= 5"
        >
          <i class="fa fa-plus-square-o fa-2" style="margin-right: 5px; font-size: 18px"></i>
          Append Chatroom
        </button>

        <!-- <button class="btn tn-button-primary btn-block" (click)="submit()">
            {{'LOGIN.GET_STATE.SUBMIT' | translate}}
        </button> -->
      </div>
      <!-- <mat-toolbar>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
          <mat-icon>menu</mat-icon>
        </button>
        <span>My App</span>
        <span class="example-spacer"></span>
        <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
          <mat-icon>share</mat-icon>
        </button>
      </mat-toolbar> -->

      <!-- 
        <div
          class="multi-chat-room-container"
          cdkDropList
          #upperChatroomList="cdkDropList"
          [cdkDropListData]="multiChatrooms"
          [cdkDropListConnectedTo]="[lowerChatroomList]"
          cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="drop($event)"
        >
      -->
      <div
        cdkDropList
        class="multi-chat-room-container"
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="dropChatroom($event)"
      >
        <!-- cdkDragLockAxis="x" -->
        <!-- [@myAnimationTrigger] -->
        <div
          class="chat-room-slot"
          *ngFor="let chatroom of multiChatrooms; let i = index"
          cdkDrag
          (cdkDragStarted)="onDragStart($event, i)"
          (cdkDragEnded)="onDragEnd($event)"
          (cdkDragDropped)="cdkDragDropped($event)"
          (cdkDragReleased)="cdkDragReleased($event)"
          [cdkDragDisabled]="chatroom.isDisabledDrag"
        >
          <!-- <div class="chat-room-slot-placeholder" *cdkDragPlaceholder>asdasdasd</div> -->
          <div class="drag-handler" [ngClass]="{ 'drag-disabled': chatroom.isDisabledDrag }" cdkDragHandle>
            <i class="fa fa-bars" style="color: grey"></i>
          </div>
          <div class="unread-msg-count unread-count unread-bubble" *ngIf="chatroom.newMessageCount > 0">
            {{ chatroom.newMessageCount }}
          </div>
          <div class="action-btn-container">
            <!-- text-color-primary -->
            <div [matTooltip]="enableAutoScrollByChat[chatroom.chat_id]?.enabled ? 'Enable Auto Scroll' : 'Disable Auto Scroll'" class="auto-scroll-container clickable" (click)="toggleAutoScroll($event, chatroom)">
              <i class="fa fa-fw fa-angle-double-down auto-scroll-icon" [ngClass]="{ 'auto-scroll-active': enableAutoScrollByChat[chatroom.chat_id]?.enabled }"></i>
            </div>
            <div matTooltip="Show/Hide Send Bar" class="clickable" (click)="onToggleMsgSendBar($event, chatroom)">
              <i class="fa fa-fw fa-commenting-o"></i>
            </div>
            <div matTooltip="Switch Chat" class="clickable" (click)="selectChatRoom($event, chatroom)">
              <i class="fa fa-fw fa-exchange"></i>
            </div>
            <div matTooltip="Close Chat" class="delete-chatroom-btn clickable" (click)="removeChatroom($event, i)">
              <i class="fa fa-fw fa-times"></i>
            </div>
          </div>

          <!-- {{ cr }} -->
          <!-- (updateChatLoadingHistoryState)="updateChatLoadingHistoryState($event)" -->
          <tn-chat-room
            *ngIf="!isMultiChatroomSearching[chatroom.chat_id]"
            #multiChatroomRef
            class="tn-chat-room"
            [chat]="chatroom"
            [isInMultiChatRoomMode]="isInMultiChatRoomMode"
            [targetMessage]="targetMessageGroupByChat[chatroom.chat_id]"
            [enableAutoScroll]="enableAutoScrollByChat[chatroom.chat_id]"
            [multiChatFilterOptions]="multiChatFilterOptions"
            (onFilterChanged)="onFilterChanged($event)"
            [prevFilterSettings]="prevFilterSettings"
            [currFilterSettings]="currFilterSettings"
            (updateChatDraggableState)="updateChatDraggableState($event)"
            (enterChatSearchMode)="enterChatSearchMode($event)"
            (exitSearchModeAndGoToChat)="exitSearchModeAndGoToChat($event)"
            (enterSearchModeAndGoToChat)="enterSearchModeAndGoToChat($event)"
            (enterSearchModeWithChatIdAndGoToChat)="enterSearchModeWithChatIdAndGoToChat($event)"
          >
          </tn-chat-room>
          <tn-chat-search
            *ngIf="isMultiChatroomSearching[chatroom.chat_id]"
            class="tn-chat-room"
            [chat]="chatroom"
            [isInMultiChatRoomMode]="isInMultiChatRoomMode"
            [keyword]="searchingKeywordGroupByChat[chatroom.chat_id]"
            (exitSearchModeAndGoToChat)="exitSearchModeAndGoToChat($event)"
            (viewSearchedMessage)="enterSearchModeWithChatIdAndGoToChat($event)"
          >
          </tn-chat-search>
          <!-- <tn-chat-room
            class="tn-chat-room"
            [chat]="chatroom"
            [isInMultiChatRoomMode]="isInMultiChatRoomMode"
            [targetMessage]="targetMessage"
            (enterChatSearchMode)="enterChatSearchMode($event)"
            (exitSearchModeAndGoToChat)="exitSearchModeAndGoToChat($event)"
            (enterSearchModeAndGoToChat)="enterSearchModeAndGoToChat($event)"
          >
          </tn-chat-room> -->

          <!-- switch chat at page-title-wrapper -->
          <!-- <tn-chat-room
            class="tn-chat-room"
            [chat]="chatroom"
            [isInMultiChatRoomMode]="isInMultiChatRoomMode"
            (selectChatRoom)="selectChatRoom($event)"
          >
          </tn-chat-room> -->
          <div class="loading-chat-history-masker" *ngIf="chatroom.isLoadingHistory">
            <!-- <i class="fa fa-spinner fa-pulse loading-icon fa-fw"></i> -->
          </div>
        </div>
        <div class="chat-room-slot add-masker" (click)="selectChatRoom()" *ngIf="multiChatrooms.length === 0">
          <span>
            <i class="fa fa-plus-square-o fa-3"></i>
          </span>
        </div>
      </div>
      <!-- <div
        class="multi-chat-room-container"
        cdkDropList
        #lowerChatroomList="cdkDropList"
        [cdkDropListData]="extraChatrooms"
        [cdkDropListConnectedTo]="[upperChatroomList]"
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          class="chat-room-slot"
          *ngFor="let chatroom of extraChatrooms; let i = index"
          cdkDrag
          (CdkDragStart)="dragStart($event)"
        >
          <div class="drag-handler" cdkDragHandle>Drag Handler</div>
          <div class="action-btn-container">
            <div matTooltip="Show/Hide Send Bar" class="clickable" (click)="toggleMsgSendBar($event, chatroom)">
              <i class="fa fa-fw fa-commenting-o"></i>
            </div>
            <div matTooltip="Switch Chat" class="clickable" (click)="selectChatRoom($event, chatroom)">
              <i class="fa fa-fw fa-exchange"></i>
            </div>
            <div matTooltip="Close Chat" class="delete-chatroom-btn clickable" (click)="removeChatroom($event, i)">
              <i class="fa fa-fw fa-times"></i>
            </div>
          </div>

          <tn-chat-room
            class="tn-chat-room"
            [chat]="chatroom"
            [isInMultiChatRoomMode]="isInMultiChatRoomMode"
          >
          </tn-chat-room>
        </div>
        <div
          class="chat-room-slot add-masker"
          (click)="selectChatRoom()"
          *ngIf="multiChatrooms.lengt
          hatrooms.length === 0"
        >
          <span>
            <i class="fa fa-plus-square-o fa-3"></i>
          </span>
        </div>
      </div> -->
    </div>
  </ng-container>
</div>