import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { TeamnoteApiService } from '../../api/teamnote-api.service'
import { TeamNoteApiConstant } from '../../constants/api.constant'
import { SideNavService } from '../../utilities/tn-side-nav/side-nav.service'
import { ChatService } from '../services/data/chat/chat.service'
import { ChatRoomService } from '../chat/chat-room/chat-room.service'
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service'
import { Message } from '../../models/message'
import { TnDialogService } from '../../utilities/tn-dialog/tn-dialog.service'
import { ContextMenuService } from '../../utilities/context-menu/context-menu.service'
import { LoggerService } from '../../utilities/logger/logger.service'

@Injectable()
export class StarredMessagesService {
  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _sideNavService: SideNavService,
    private _chatService: ChatService,
    private _chatRoomService: ChatRoomService,
    private _tnNotificationService: TnNotificationService,
    private _tnDialogService: TnDialogService,
    private _contextMenuService: ContextMenuService,
    private _loggerService: LoggerService
  ) {}

  getStarredMessages(size: number, page: number, chatId: string, success: Function, failure: Function): void {
    let url = TeamNoteApiConstant.MESSAGE_STAR.GET_STARRED_MESSAGES_LIST

    let params = {
      chat_id: chatId,
      page: page,
      size: size
    }

    // global star message list
    if (!chatId) {
      delete params.chat_id
    }

    this._teamnoteApiService.callApi(url, params, success, failure, false, false, true)
  }

  goToChatWithAction(sm: Message, action?: string, chatId?: string): void {
    let targetChat = this._chatService.getChatByChatId(sm.chat_ids[0])

    if (!targetChat) {
      this._tnNotificationService.showCustomWarningByTranslateKey(
        'WEBCLIENT.MESSAGE_STAR.ERROR_MSG.IN_INIT_CHATROOM'
      )
      return
    }

    switch (action) {
      case 'message_jumping':
        this._chatService.updateTargetMessageSubject(sm, chatId)
        break
      case 'reply_message':
        this._chatRoomService.updateReplyingMessage(sm, chatId)
        this._chatService.updateActiveChatSubject(targetChat)
        break
      case 'forward_message':
        // nothing to do here
        break
    }

    this.hideContextMenu() // close opened context menu
    this._tnDialogService.closeAllDialogs()

    this._sideNavService.updateActiveSideNav('chat') // update active side bar nav
    // this._sideNavService.goToActiveItemPath()
    this._chatService.goToChatPage()
  }

  hideContextMenu(): void {
    if (this._contextMenuService.isOpening) {
      this._loggerService.log('Clicked on modal, try to hide existing context menus...')
      this._contextMenuService.hideContextMenu()
    }
  }
}
