import { Component, OnInit, Inject } from '@angular/core';

import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { Chat } from '../../../models/chat';
import { UserContact } from '../../../models/user-contact';

import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { ChatService } from '../../services/data/chat/chat.service';
import { AccountManagerService } from '../../services/account/account-manager.service';

import * as _ from 'lodash';
import { DataManagerService } from '../../services/data/data-manager.service';
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { TeamNoteGeneralConstant } from '../../../constants/general.constant';
import { FtsContactService } from '../../../utilities/fts/contact/fts-contact.service';
import { FtsChatService } from '../../../utilities/fts/chat/fts-chat.service';
import { UserConstant } from '../../../constants/user.constant';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { PresenceTypeConstant } from '../../../constants/presence-type.constant';
import { TnDialogService } from '../../../utilities/tn-dialog/tn-dialog.service';
import { MuteGroupComponent, MutePeriodOption } from './mute-group/mute-group.component';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';
import { ContactCardService } from '../../contact/contact-card/contact-card.service';
import { BroadcastGroup } from '../../broadcast/models/broadcast';
import { BroadcastService } from '../../broadcast/broadcast.service';
import { ModuleManagerService } from '../../services/module/module-manager.service';
import { ModuleKeyDefinition } from '../../../constants/module.constant';

interface ChatMembers extends UserContact {
  isChatAdmin: boolean;
  isOutOfOffice: boolean;
}

@Component({
  selector: 'tn-chat-group-setting',
  templateUrl: './chat-group-setting.component.html',
  styleUrls: ['./chat-group-setting.component.scss']
})
export class ChatGroupSettingComponent implements OnInit {
  chat: Chat = <Chat>{};
  selfUserId: string;
  onMemberAvatarClick: Function = null;
  targetChat: any = null;

  isGroupChat: boolean = false;

  currentMembers: ChatMembers[] = [];
  currentMembersId: string[] = [];
  currentAdminsId: string[] = [];
  imgSrc: string = '';
  file: File;
  picAttachmentId: string = '';
  
  isChatAdmin: boolean = false;
  isSelectFromUser: boolean = null;

  canManageMember: boolean = true;
  canManageAdmin: boolean = true;

  isEditingGroupName: boolean = false;
  groupName: string = '';
  groupNameMax: number;

  availableUsers: UserContact[] = [];
  selectedMembers: UserContact[] = [];
  unSelectedMembers: UserContact[] = [];
  filteredUnSelectedMembers: UserContact[] = [];

  chatGroups: Chat[] = [];
  filteredChatGroups: Chat[] = [];

  IS_ALLOW_BROADCAST: boolean = false;
  broadcastGroups: BroadcastGroup[] = [];

  searchKeyword: string = '';

  title: string;
  ACTION_CODE = {
    VIEW_INFO: 0,
    ADD_MEMBER: 1,
    REMOVE_MEMBER: 2,
    ADD_MEMBER_FROM_GROUP: 3,
    ADD_GROUP_ADMIN: 4,
    ADD_MEMBER_FROM_BROADCAST: 5
  };
  currentAction: number;

  // mute
  isAllowMute: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ChatGroupSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _chatService: ChatService,
    private _userContactService: UserContactService,
    private _fileManagerService: FileManagerService,
    private _accountManagerService: AccountManagerService,
    private _dataManagerService: DataManagerService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _ftsContactService: FtsContactService,
    private _ftsChatService: FtsChatService,
    private _tnDialogService: TnDialogService,
    private _fileFactoryService: FileFactoryService,
    private _contactCardService: ContactCardService,
    private _broadcastService: BroadcastService,
    private _moduleManagerService: ModuleManagerService
  ) { 
    this._teamnoteConfigService.config$.subscribe((config) => {
      this.groupNameMax = config.GENERAL.CHAT_GROUP_NAME_MAX_LENGTH;
    });
  }

  ngOnInit() {
    this.onMemberAvatarClick = this.data.onMemberAvatarClick;
    this.targetChat = this.data.targetChat;

    this.selfUserId = this._accountManagerService.userId;
    this.currentAction = this.ACTION_CODE.VIEW_INFO;
    this._chatService.activeChat$.subscribe(chat => {
      if (this.targetChat) {
        // Multi ChatRoom Mode
        this.parseChat();
        return;
      }

      if (!chat) {
        return;
      }
      this.parseChat();
      if (!this.isGroupChat) {
        this.dialogRef.updateSize("50vw");
      }
    });
    this.IS_ALLOW_BROADCAST = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.BROADCAST);
  }
  getAllAvailableUsers(): void {
    let contactUsers = this._dataManagerService.getAllContactUsersUnderUserGroup(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID);
    this.availableUsers = [];
    _.each(contactUsers, (u) => {
      if (_.indexOf(this.chat.members, u.user_id) == -1) {
        this.availableUsers.push(u);
      }
    });
  }
  getAllChatGroups(): void {
    this.chatGroups = this._chatService.getAllGroupChats();
  }

  parseChat(): void {
    if (this.targetChat) {
      this.chat = this.targetChat;
    } else {
      this.chat = this._chatService.activeChat;
    }

    // Get chat group icon again
    this._fileFactoryService.getFileByAttachmentId(
      this.chat.pic,
      (imageUrl) => {
        this.chat.picBgUrl = imageUrl;
      },
      (err) => {},
      false,
      false,
      true
    );

    this.currentMembers = [];
    _.each(this.chat.members, (userId) => {
      let user = <ChatMembers>this._userContactService.getUserContactByUserId(userId);
      user.isChatAdmin = _.indexOf(this.chat.admins, userId) !== -1;
      if (userId == this._accountManagerService.userId && user.isChatAdmin) {
        this.isChatAdmin = true;
      }
      user.isOutOfOffice = this._userContactService.checkIfUserIsOutOfOffice(user);
      this.currentMembers.push(user);
    });
    this.currentMembers = _.orderBy(this.currentMembers, [function(f) { return f.name.toLowerCase()}]);

    this.groupName = this.chat.name;
    this.picAttachmentId = this.chat.pic;
    this.currentMembersId = this.chat.members;
    this.currentAdminsId = this.chat.admins;

    this.canManageMember = this.chat.can_app_manage_member == undefined ? true : this.chat.can_app_manage_member == 1;
    this.canManageAdmin = this.chat.can_app_manage_admin == undefined ? true : this.chat.can_app_manage_admin == 1;

    this.isGroupChat = this.chat.t == PresenceTypeConstant.GROUP_CHAT || this.chat.t == PresenceTypeConstant.GROUP_BROADCAST;

    if (this.chat.can_mute == null) {
      this.isAllowMute = this._accountManagerService.fullLoginResponse.allow_mute == 1;
    } else {
      this.isAllowMute = this.chat.can_mute == 1;
    }

    this._chatService.checkIfChatIsStillMuted(this.chat.chat_id);

    this.getAllAvailableUsers();
    this.getAllChatGroups();
  }

  updateGroup(): void {
    if (this.groupName.length === 0) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.CHAT.CHAT_GROUP.ERROR_MSG.NO_GROUP_NAME');
      return;
    }
    this._chatService.updateChatGroup(this.groupName, this.picAttachmentId, this.currentMembersId, this.chat.chat_id);
    this.isEditingGroupName = false;
  }

  handleFileInputChange(e): void {
    this.file = e.target.files[0];
    this.uploadChatPic();
  }
  uploadChatPic(): void {    
    this._fileManagerService.uploadFileHub(
      this.file,
      (fileId) => {
        this.picAttachmentId = fileId;
        this.updateGroup();
      },
      false
    );
  }

  addMember(): void {
    this.currentAction = this.ACTION_CODE.ADD_MEMBER;
    this.selectedMembers = _.clone(this.currentMembers);
    this.unSelectedMembers = this.availableUsers;
    this.updateUnselectedList(this.searchKeyword);
  }
  addMemberFromGroup(): void {
    this.currentAction = this.ACTION_CODE.ADD_MEMBER_FROM_GROUP;
    this.selectedMembers = _.clone(this.currentMembers);
    this.unSelectedMembers = this.availableUsers;
    this.updateUnselectedList(this.searchKeyword);
  }

  leaveGroup(): void {
    this._chatService.leaveChatGroup(this.chat.chat_id);
    this.cancel();
  }
  
  updateUnselectedList(keyword: string): void {
    this.searchKeyword = keyword;
    this.filteredUnSelectedMembers = this._ftsContactService.searchContact(this.searchKeyword, this.unSelectedMembers);
    this.filteredChatGroups = this._ftsChatService.searchChat(this.searchKeyword, this.chatGroups);
  }
  selectMember(contact: UserContact): void {
    if (_.indexOf(this.currentMembersId, contact.user_id) !== -1) {
      return;
    }
    this.selectedMembers = _.union(this.selectedMembers, [contact]);
    this.unSelectedMembers = _.without(this.unSelectedMembers, contact);
    this.updateUnselectedList(this.searchKeyword);
  }

  selectMembers(contacts: UserContact[]): void {
    this.selectedMembers = _.union(this.selectedMembers, contacts);
    this.unSelectedMembers = _.difference(this.unSelectedMembers, contacts);
    this.updateUnselectedList(this.searchKeyword);
  }

  unSelectMember(contact: UserContact): void {
    this.selectedMembers = _.without(this.selectedMembers, contact);
    this.unSelectedMembers = _.union(this.unSelectedMembers, [contact]);
    this.updateUnselectedList(this.searchKeyword);
  }

  unSelectAllMembers(): void {
    this.unSelectedMembers = _.union(this.unSelectedMembers, this.selectedMembers);
    this.selectedMembers = _.clone(this.currentMembers);
    this.updateUnselectedList(this.searchKeyword);
  }

  chatGroupOnClick(chat: Chat): void {
    _.each(chat.members, (userId) => {
      if (userId !== this._accountManagerService.userId) {
        let u = this._userContactService.getUserContactByUserId(userId);
        this.selectMember(u);
      }
    });
    this.currentAction = this.ACTION_CODE.ADD_MEMBER;
  }
  back(): void {
    this.currentAction = this.ACTION_CODE.VIEW_INFO;
  }
  selectConfirm(): void {
    switch (this.currentAction) {
      case this.ACTION_CODE.ADD_MEMBER:
      case this.ACTION_CODE.ADD_MEMBER_FROM_GROUP:
      case this.ACTION_CODE.ADD_MEMBER_FROM_BROADCAST:
        this.submitAddMember();
        break;
    }
    this.back();
  }

  submitAddMember(): void {
    let members = _.map(this.selectedMembers, 'user_id');
    this.currentMembersId = _.uniq(_.union(this.currentMembersId, members));
    this.updateGroup();
  }
  submitRemoveMember(userId: string): void {
    this._chatService.kickMember(this.chat.chat_id, userId);
  }
  submitAddGroupAdmin(userId: string): void {
    this._chatService.addChatAdmin(this.chat.chat_id, userId);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  // Mute
  toggleMute(): void {
    if (!this.isAllowMute) {
      return;
    }
    if (this.chat.isMuted) {
      // do unmute action
      this._chatService.updateMuteChatStatusByChatId(this.chat.chat_id, false);
    } else {
      // do mute action
      this._tnDialogService.openTnDialog(
        MuteGroupComponent,
        {
          confirmCallback: (muteOption: MutePeriodOption) => {
            this._chatService.updateMuteChatStatusByChatId(this.chat.chat_id, true, muteOption.ttl);
          }
        }
      );
    }
  }

  onMemberDropdownClick(event): void {
    event.preventDefault();
  }
  openMemberContactCard(contact: UserContact): void {
    this.onMemberAvatarClick(contact);
  }

  checkShouldDisplayMemberExtra(member: ChatMembers): boolean {
    if (!member.isChatAdmin && !member.isOutOfOffice) {
      return false;
    }
    return true;
  }
  checkShouldDisplayMemberOption(member: ChatMembers): boolean {
    if (!this.isChatAdmin) {
      return false;
    }
    if (member.user_id == this.selfUserId) {
      return false;
    }
    if (!this.canManageAdmin && !this.canManageMember) {
      return false;
    }

    // If only possible option is "add admin", but member is already an admin, return false
    if (this.canManageAdmin && !this.canManageMember) {
      if (member.isChatAdmin) {
        return false;
      }
    }
    return true;
  }

  // broadcast
  addMemberFromBroadcast(): void {
    this._broadcastService.getBroadcastGroupList(
      resp => {
        this.broadcastGroups = resp;
        this.currentAction = this.ACTION_CODE.ADD_MEMBER_FROM_BROADCAST;
        this.selectedMembers = _.clone(this.currentMembers);
        this.unSelectedMembers = this.availableUsers;
      },
      err => {

      }
    );
  }

  selectBroadcastGroup(broadcastGroup: BroadcastGroup): void {
    this._broadcastService.getBroadcastGroupById(
      broadcastGroup.user_group_id,
      resp => {
        let group: BroadcastGroup = resp;
        let memberIds = _.map(group.user_group_members, 'user_id');
        _.each(memberIds, (id) => {
          let member = _.find(this.availableUsers, {user_id: id});
          if (member) {
            this.selectMember(member);
          }
        });
      },
      err => {

      }
    );
  }
}
