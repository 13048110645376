<div class="starred-messages-wrapper">
  <tn-page-title [title]="'WEBCLIENT.MENU.MESSAGE_STAR' | translate" *ngIf="!isInChat"> </tn-page-title>

  <tn-search-bar class="tn-search-bar" (onKeywordChanged)="searchStarredMessage($event)"> </tn-search-bar>

  <!-- using chat room image background -->
  <!-- <div class="starred-messages-list chatroom-bg"> -->
  <div class="starred-messages-list" #starMsgElement (scroll)="onMessageListScroll($event)">
    <tn-starred-messages-cell
      [@myanim]
      *ngFor="let sm of starredMessages"
      [isInChat]="isInChat"
      [chat]="chat"
      [isEnableReadTicks]="isEnableReadTicks"
      [isEnableStarMessage]="isEnableStarMessage"
      [isEnableImportantUsers]="isEnableImportantUsers"
      [starredMessage]="sm"
      (onStarredMessageClick)="onStarredMessageClick($event)"
      (updateMessages)="updateMessages($event)"
    >
    </tn-starred-messages-cell>

    <div *ngIf="isLoadingHistory" class="loading-history-indicator">
      <i class="fa fa-spinner fa-pulse fa-3x fa-fw loader-icon"></i>
    </div>

    <tn-empty-page *ngIf="starredMessages.length == 0 && !isLoadingHistory"></tn-empty-page>
  </div>
</div>
