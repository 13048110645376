import { Injectable } from '@angular/core';

import * as _ from 'lodash';
var addDays = require('date-fns/add_days');
import { CookieService } from 'ngx-cookie';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';


@Injectable()
export class LocalStorageManagerService {

  constructor(
    private _cookieService: CookieService
  ) { }

  // Local Storage
  getLocalStorageByKey(key: string): any {
    key = TeamNoteLocalStorageKeyConstants.PREFIX + key;
    return window.localStorage.getItem(key);
  }
  setLocalStorageByKey(key: string, value: any) {
    key = TeamNoteLocalStorageKeyConstants.PREFIX + key;
    window.localStorage.setItem(key, value);
  }
  removeLocalStorageByKey(key: string) {
    key = TeamNoteLocalStorageKeyConstants.PREFIX + key;
    window.localStorage.removeItem(key);
  }
  removeAllLocalStorage() {
    window.localStorage.clear();
  }

  // Session Storage
  getSessionStorageByKey(key: string): any {
    key = TeamNoteLocalStorageKeyConstants.PREFIX + key;
    return window.sessionStorage.getItem(key);
  }
  setSessionStorageByKey(key: string, value: any) {
    key = TeamNoteLocalStorageKeyConstants.PREFIX + key;
    window.sessionStorage.setItem(key, value);
  }
  removeSessionStorageByKey(key: string) {
    key = TeamNoteLocalStorageKeyConstants.PREFIX + key;
    window.sessionStorage.removeItem(key);
  }
  removeAllSessionStorage() {
    window.sessionStorage.clear();
  }

  // Cookies
  getCookiesByKey(key: string): any {
    key = TeamNoteLocalStorageKeyConstants.PREFIX + key;
    return this._cookieService.get(key);
  }
  setCookiesByKey(key: string, value: any, time?: Date) {
    if (!value) {
      return;
    }
    key = TeamNoteLocalStorageKeyConstants.PREFIX + key;
    value = value.toString();
    this._cookieService.put(key, value, {expires: time, secure: true});
  }
  removeCookiesByKey(key: string, withoutPrefix?: boolean) {
    if (!withoutPrefix) {
      key = TeamNoteLocalStorageKeyConstants.PREFIX + key;
    }
    
    this._cookieService.remove(key);
  }
  removeAllCookies() {
    this._cookieService.removeAll();
  }
  getAllCookies() {
    let all = this._cookieService.getAll();
    let tnCookies = [];
    _.each(all, (c, key) => {
      if (key.indexOf(TeamNoteLocalStorageKeyConstants.PREFIX) != -1) {
        tnCookies.push({[key]: c});
      }
    });
    return tnCookies;
  }

  // Device Token
  getDeviceToken() {
    function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
      }

      return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
          s4() + '-' + s4() + s4() + s4();
    }
    if (!this.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DEVICE_TOKEN)) {
      let time = addDays(new Date(), 100);
      this.setCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DEVICE_TOKEN, guid(), time);
    }
    return this.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.DEVICE_TOKEN);
  }

  // getDeviceTokenByDomain(exerciseDomain: string, exerciseUserName?: string) {
  getDeviceTokenByDomain(originDomainUserId: string, exerciseCompanyDomain: string, exerciseUserName?: string) {
    function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
      }

      return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
          s4() + '-' + s4() + s4() + s4();
    }

    return this.getCookiesByKey(TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT.COOKIES.DEVICE_TOKEN + `_${originDomainUserId}_${exerciseCompanyDomain}_${exerciseUserName}`) || guid();
  }

  // storeDeviceTokenByDomain(exerciseDomain: string, exerciseUserName: string, device_token: string) {
  storeDeviceTokenByDomain(originDomainUserId: string, exerciseCompanyDomain: string, exerciseUserName: string, device_token: string) {
    let time = addDays(new Date(), 100);
    this.setCookiesByKey(TeamNoteLocalStorageKeyConstants.MULTI_ACCOUNT.COOKIES.DEVICE_TOKEN + `_${originDomainUserId}_${exerciseCompanyDomain}_${exerciseUserName}`, device_token, time);
  }
}
