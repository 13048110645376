import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { ExerciseComponent } from './exercise.component'
import { ExerciseService } from './exercise.service'
import { UtilitiesModule } from '../../utilities/utilities.module'

@NgModule({
  imports: [CommonModule, FormsModule, UtilitiesModule],
  declarations: [ExerciseComponent],
  providers: [ExerciseService]
})
export class ExerciseModule {}
