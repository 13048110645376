import { Injectable } from '@angular/core';

var format = require('date-fns/format');
var isBefore = require('date-fns/is_before');
var isAfter = require('date-fns/is_after');
var setSeconds = require('date-fns/set_seconds');
var setMilliseconds = require('date-fns/set_milliseconds');

@Injectable()
export class TimestampService {

  constructor() { }

  getDateDisplay(timestamp): string {
    return format(parseFloat(timestamp) * 1000, "YYYY-MM-DD");
  }

  getTimeDisplay(timestamp): string {
    return format(parseFloat(timestamp) * 1000, "HH:mm");
  }

  getDateTimeDisplay(timestamp, isWithOutSecond?: boolean): string {
    let f = isWithOutSecond ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD HH:mm:ss';
    return format(parseFloat(timestamp) * 1000, f);
  }

  getFullDayDateTimeDisplay(timestamp): string {
    return format(parseFloat(timestamp) * 1000, 'dddd, MMMM DD, YYYY hh:mm A');
  }

  getWatermarkDateTimeDisplay(dateObj: Date): string {
    return format(dateObj, 'DD/MM/YYYY HH:mm:ss');
  }

  checkIfTimeBeforeToday(timestamp): boolean {
    return isBefore(parseFloat(timestamp) * 1000, new Date());
  }

  checkIfTimeAfterToday(timestamp): boolean {
    return isAfter(parseFloat(timestamp) * 1000, new Date());
  }

  checkIfTimeCorrectOrder(before, after): boolean {
    return isBefore(parseFloat(before) * 1000, parseFloat(after) * 1000);
  }

  secondToDateObj(timestamp): Date {
    if (timestamp) {
      return new Date(parseFloat(timestamp) * 1000);
    } else {
      return null;
    }
  }

  dateObjToTimeDisplay(date: Date, isWithTime: boolean): string {
    if (!date) {
      return '';
    }
    let targetFormat = 'YYYY-MM-DD';
    if (isWithTime) {
      targetFormat += ' HH:mm';
    }
    return format(date, targetFormat);
  }

  dateObjToSecondString(date: Date): string {
    return date ? (date.getTime() / 1000).toString() : "";
  }

  getNowSecondString(): string {
    // let now = setMilliseconds(setSeconds(new Date(), 0), 0);
    let now = new Date();
    return this.dateObjToSecondString(now);
  }

  getNowDisplayDateTime(): string {
    return format(new Date(), 'YYYY-MM-DD HH:mm:ss');
  }

  getMessageDateStringInChatrooom(timestamp): string {
    return format(parseFloat(timestamp) * 1000, 'ddd, D MMM YYYY');
  }

  getStartOfDateSecondStringByDateObj(date: Date): string {
    let start = date.setHours(0, 0, 0, 0);
    return (start / 1000).toString();
  }

  getEndOfDateSecondStringByDateObj(date: Date): string {
    let end = date.setHours(23, 59, 59, 0);
    return (end / 1000).toString();
  }


  getRoundToCeilingMinute(date: Date) {
    // Get the milliseconds since the Unix epoch for the provided date
    const ms = date.getTime();

    // Calculate the number of milliseconds in one minute
    const oneMinute = 60000;

    // Round up to the nearest minute using Math.ceil
    const roundedMs = Math.ceil(ms / oneMinute) * oneMinute;

    // Create a new Date object with the rounded milliseconds
    return (roundedMs / 1000).toString();
  }
}
