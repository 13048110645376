export const PageUrlConstant = {
  HOME: '',
  LOGIN: {
    BASE: 'login',
    GET_STATE: 'get-state',
    OTP: 'otp',
    PASSWORD: 'password',
    SECOND_FACTOR: 'second-factor',
    SET_PASSWORD: 'set-password',
    LOGIN_METHODS: 'login-methods'
  },
  WEBCLIENT: {
    BASE: 'webclient',
    NEWS: 'news',
    CONTACT: 'contact',
    CHAT: 'chat',
    TRAINING: 'training',
    QUESTIONNAIRE: 'questionnaire',
    STORE_REPORT: 'job-report',
    JOB_DISPATCH: 'job-dispatch',
    CORPORATE_MATERIAL: 'corporate-material',
    NEWS_CATEGORY: 'news-category',
    WEBVIEW: 'webview',
    WORKFLOW: 'workflow',
    VIDEO_STREAMING: 'video-streaming',
    WEBDAV_MATERIAL: 'webdav-material',
    TEAMFLARE_APP: 'teamflare_app',
    MESSAGE_STAR: 'starred-messages',
    SCHEDULE_MESSAGE: 'schedule-message',
  },
  WEBAPP:{
    BASE: 'webapp',
    STORE_REPORT: 'job-report',
  },
  DOWNLOAD:{
    BASE: 'download'
  }
};
