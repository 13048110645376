<div class="prompt">
  <div class="msg">
    <ng-container *ngIf="data.msg">{{data.msg}}</ng-container>
    <ng-container *ngIf="data.msgTranslateKey">{{data.msgTranslateKey | translate}}</ng-container>
  </div>
  <div class="prompt-input" *ngIf="!data.isNoNeedInput">
    <input *ngIf="!data.isNumberInput" #promptInputArea [type]="data.isPassword ? 'password' : 'text'" class="form-control" [(ngModel)]="promptInput" (keyup.enter)="submit()"/>
    <input *ngIf="data.isNumberInput" #promptInputArea type="number" min="1" class="form-control" [(ngModel)]="promptNumberInput" (keyup.enter)="submit()"/>
  </div>
  <div class="prompt-actions">
    <button class="btn btn-default cancel" (click)="cancel()">
      {{'GENERAL.CANCEL' | translate}}
    </button>
    <button class="btn tn-button-primary confirm" (click)="submit()">
      {{'GENERAL.SUBMIT' | translate}}
    </button>
  </div>
</div>