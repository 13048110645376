import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserContact } from '../../../models/user-contact';
import { ContactService } from '../contact.service';
import { LoggerService } from '../../../utilities/logger/logger.service';
import { TeamnoteConfigService } from '../../../configs/teamnote-config.service';
import { UserGroupService } from '../../services/data/user-group/user-group.service';
import { TeamNoteGeneralConstant } from '../../../constants/general.constant';
import { ModuleManagerService } from '../../services/module/module-manager.service';
import { ModuleKeyDefinition } from '../../../constants/module.constant';

import * as _ from 'lodash';
import { TnDialogService } from '../../../utilities/tn-dialog/tn-dialog.service';
import { AccountManagerService } from '../../services/account/account-manager.service';
import { FileFactoryService } from '../../../utilities/file-factory/file-factory.service';
import { WebclientService } from '../../webclient.service';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { UserConstant } from '../../../constants/user.constant';
import { ChatMessageService } from '../../services/data/messages/chat-message/chat-message.service';
import { MultiChatRoomService } from '../../chat/multi-chat-room.service';
@Component({
  selector: 'tn-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {

  contact: UserContact;
  isInContactList: boolean = false;
  isShowActions: boolean = false;
  isShowWhisper: boolean = false;
  whisperCallback: Function = null;

  descFields: string[] = [];
  avatarImageSrc: string = null;

  // Important user
  isEnableImportantUsers: boolean = false;
  IMPORTANT_LEVEL = UserConstant.IMPORTANT_LEVEL;
  tempImportantLv: any

  constructor(
    public dialogRef: MatDialogRef<ContactCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _contactService: ContactService,
    private _loggerService: LoggerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _userGroupService: UserGroupService,
    private _moduleManagerService: ModuleManagerService,
    private _tnDialogService: TnDialogService,
    private _accountManagerService: AccountManagerService,
    private _fileFactoryService: FileFactoryService,
    private _webclientService: WebclientService,
    private _userContactService: UserContactService,
    private _chatMessageService: ChatMessageService,
    private _multiChatRoomService: MultiChatRoomService,
  ) { 
    this.descFields = this._teamnoteConfigService.config.WEBCLIENT.CONTACT.CARD_DESC_FIELDS;
  }

  ngOnInit() {
    this.dialogRef.updateSize('400px');

    this.contact = this.data.contact;
    this.contact.importantLevel = this._userContactService.checkIfUserIsImportant(this.contact.user_id)
    this.tempImportantLv = this.contact.importantLevel

    if (this.contact.pic && this.contact.pic != 'None') {
      this._fileFactoryService.getFileByAttachmentId(
        this.contact.pic,
        (imageUrl) => {
          this.avatarImageSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }

    this.isShowWhisper = this.data.isShowWhisper && this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.ENABLE_WHISPER;
    this.whisperCallback = this.data.whisperCallback;
    
    this.isEnableImportantUsers = this.data.isShowImportantUser && this._webclientService.checkIfEnableImportantUsers()

    if (this.contact.deleted || this.contact.user_id == this._accountManagerService.userId) {
      this.isShowActions = false;
    } else {
      // If enabled to FORCE_CONTACT_ACTIONS, always show action buttons.
      // If not, show action buttons only if user is under Contact list
      if (this._teamnoteConfigService.config.WEBCLIENT.CONTACT.FORCE_CONTACT_ACTIONS) {
        this.isShowActions = true;
      } else {
        this.isShowActions = false;

        this.isInContactList = this._userGroupService.checkIfUserIsUnderUserGroupRecursive(this.contact.user_id, TeamNoteGeneralConstant.ROOT_USER_GROUP_ID);
        if (this.isInContactList) {
          this.isShowActions = true;
        }
      }
    }
    this.checkContactDialPermission();
  }

  checkContactDialPermission(): void {
    // If contact_dial is not enabled, remove "mobile" field display
    if (!this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.CONTACT_DIAL)) {
      this.descFields = _.without(this.descFields, "mobile");
    }
  }

  onWhisperClick() {
    this._loggerService.log("Clicked on contact card whisper button: " + this.contact.user_id);
    this.dialogRef.close();
    this._tnDialogService.closeAllDialogs();
    this.whisperCallback(this.contact);
  }

  onChatClick() {
    this._loggerService.log("Clicked on contact card chat button: " + this.contact.user_id);
    this.dialogRef.close();
    this._tnDialogService.closeAllDialogs();
    this._contactService.contactCellOnClick(this.contact);
  }

  get userImportantLevel() {
    return this.tempImportantLv;
  }

  // will update the active chatroom after setting or unsetting the important user 
  onImportantUserClick() {
    let callback = () => {}
    let chatroomUpdateCallback = null
    let activeChatId = this._chatMessageService.activeChatRoomId; // check if there is a active chatroom first
    let activeSearchModeChatIds = _.keys(this._chatMessageService.activeChatRoomSearchedMessagesSubjects);

    let isSearchMode = activeSearchModeChatIds.length;
    if (isSearchMode) {
      // the chat room is in searching mode, update the chat room by updating activeChatRoomSearchedMessages by chat_id
      // chatroomUpdateCallback = () => this._chatMessageService.updateActiveChatRoomSearchedMessageSubjectByChatId(chat_id)
      chatroomUpdateCallback = () => this._chatMessageService.tryUpdateActiveChatRoomSearchedMessageSubjects(activeSearchModeChatIds);
    } else {
      if (!this._multiChatRoomService.activeMultiChatRoomsPanel) {
        // the chat room is not in searching mode, update the chat room by updating chatMessages by chat_id
        chatroomUpdateCallback = () => this._chatMessageService.updateActiveChatRoomMessageSubject()
      } else {
        // in multi-chat mode
        const MultiChatIds = _.keys(this._chatMessageService.multiChatRoomMessageSubjects);
        if (MultiChatIds.length) {
          chatroomUpdateCallback = () => this._chatMessageService.tryUpdateMultiChatRoomMessageSubjects(MultiChatIds)
        }
      }
    }

    switch (this.tempImportantLv) {
      case UserConstant.IMPORTANT_LEVEL.INDIVIDUAL:
        this._loggerService.log("Clicked on contact card unimportant user button: " + this.contact.user_id);

        callback = () => {
          if (this.contact.importantLevel === UserConstant.IMPORTANT_LEVEL.GLOBAL) {
            this.tempImportantLv = UserConstant.IMPORTANT_LEVEL.GLOBAL;
          } else {
            this.tempImportantLv = UserConstant.IMPORTANT_LEVEL.NORMAL;
          }
    
          this._webclientService.getImportantUsers(chatroomUpdateCallback)
        }

        this._userContactService.setOrUnsetImportantUsers('UNSET', this.contact.user_id, callback)
        break;
    
      case UserConstant.IMPORTANT_LEVEL.NORMAL:
      case UserConstant.IMPORTANT_LEVEL.GLOBAL:
        this._loggerService.log("Clicked on contact card important user button: " + this.contact.user_id);
        
        callback = () => {
          if (this.contact.importantLevel !== UserConstant.IMPORTANT_LEVEL.GLOBAL) {
            this.tempImportantLv = UserConstant.IMPORTANT_LEVEL.INDIVIDUAL;
          } 
    
          this._webclientService.getImportantUsers(chatroomUpdateCallback)
        }

        this._userContactService.setOrUnsetImportantUsers('SET', this.contact.user_id, callback)
        break;
    }
  }

}
