export const EnScheduleMessageStrings = {
  ADD_SCHEDULE_MESSAGE: 'Add Schedule Message',
  EDIT_SCHEDULE_MESSAGE: 'Edit Schedule Message',
  BULK_ACTION: 'Toggle Mass Update',

  USERS_SELECTION: 'Select Users',
  USERS_SELECTION_COUNT: '{{COUNT}} User(s) selected',

  GROUPS_SELECTION: ' Select Groups',
  GROUPS_SELECTION_COUNT: '{{COUNT}} Group(s) selected',

  SEARCH_PLACEHOLDER: 'Search Schedule Message',

  ERROR_MSG: {
    INCORRECT_DATE: "Please check if the timestamp is correct",
    EMPTY_TEXT_MESSAGE: "Please enter the message",
    EMPTY_ATTACHMENT_MESSAGE: "Please upload a attachment",
    EMPTY_STICKER_MESSAGE: "Please select a sticker",
    EMPTY_LOCATION_MESSAGE: "Please select a location",
    EMPTY_TARGETS: "Please select target Users or Chat Groups",
    INVALID_DELAY_INPUT: "Please enter a valid value."
  },

  TIPS_MSG: {
    ADD_SUCCESS: "Schedule message created successfully",
    UPDATE_SUCCESS: "Schedule message updated successfully",
    DELETE_SUCCESS: "Schedule message delete successfully",
    DELETE_CONFIRM: "Confirm to delete the schedule message?",
    DELAY_INPUT_CONFIRM: "Please specify how long(minutes) the message should be delayed.",

    SEND_SUCCESS: "Send approval schedule message successfully",
    CANCEL_SUCCESS: "Canceled approval schedule message successfully",
    RESCHEDULE_SUCCESS: "Re-scheduled approval schedule message successfully",
  },

  LOCATION_SELECT: 'Select Location',
  STICKER_SELECT: 'Select Sticker',

  SCHEDULED_MESSAGE_TITLE: 'Waiting For Confirm Message',

  STATUS: {
    WATING_FOR_APPROVAL: 'Waiting For Approval'
  },

  TABLE: {
    EMPTY_MESSAGE_PLACEHOLDER: 'No Approval Schedule Message To Be Confirmed',
    NO_SCHEDULE_MESSAGE_PLACEHOLDER: 'No Schedule Message',
    DATETIME: 'Scheduled Time',
    TARGETS: 'Message Recipients',
    MESSAGE_CONTENT: 'Message Content',
    REQUIRED_CONFIRM: 'Require Confirm',
    STATUS: 'Status',
    ACTION: 'Action'
  }
}
