import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { UtilitiesModule } from '../utilities/utilities.module';

// Services
import { WebclientService } from './webclient.service';
import { WebclientAuthGuardService } from './webclient-auth-guard.service';

// Routing
import { WebclientRoutingModule } from './webclient-routing.module';

// Components
import { WebclientComponent } from './webclient.component';

// Sub Modules
import { ContactModule } from './contact/contact.module';
import { ChatModule } from './chat/chat.module';
import { NewsModule } from './news/news.module';
import { ProfileModule } from './profile/profile.module';
import { LanguageModule } from './language/language.module';
import { ContactPickerModule } from './contact-picker/contact-picker.module';
import { TrainingModule } from './training/training.module';
import { QuestionnaireModule } from './questionnaire/questionnaire.module';
import { JobReportModule } from './job-report/job-report.module';
import { CorporateMaterialModule } from './corporate-material/corporate-material.module';
import { OutOfOfficeModule } from './out-of-office/out-of-office.module';
import { NewsCategoryModule } from './news-category/news-category.module';
import { WebviewModule } from './webview/webview.module';
import { BroadcastModule } from './broadcast/broadcast.module';
import { TeamNoteServicesModule } from './services/teamnote-services.module';
import { WebclientRoutingService } from './route/webclient-routing.service';
import { WorkflowModule } from './workflow/workflow.module';
import { CorporateMaterialPickerModule } from './shared/corporate-material-picker/corporate-material-picker.module';
import { VideoStreamingModule } from './video-streaming/video-streaming.module';
import { TeamflareAppModule } from './teamflare-app/teamflare-app.module';
import { WebDavMaterialModule } from './webdav-material/webdav-material.module';
import { DownloadComponent } from './download/download.component';
import { StarredMessagesModule } from './starred-messages/starred-messages.module';
import { ExerciseModule } from './exercise/exercise.module';
import { ScheduleMessageModule } from './schedule-message/schedule-message.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    UtilitiesModule,
    ContactModule,
    ChatModule,
    NewsModule,
    ProfileModule,
    LanguageModule,
    ContactPickerModule,
    TrainingModule,
    QuestionnaireModule,
    JobReportModule,
    CorporateMaterialModule,
    OutOfOfficeModule,
    NewsCategoryModule,
    WebviewModule,
    BroadcastModule,
    WebclientRoutingModule,
    TeamNoteServicesModule,
    WorkflowModule,
    CorporateMaterialPickerModule,
    VideoStreamingModule,
    WebDavMaterialModule,
    TeamflareAppModule,
    StarredMessagesModule,
    ScheduleMessageModule,
    ExerciseModule
  ],
  declarations: [
    WebclientComponent,
    DownloadComponent
  ],
  providers: [
    WebclientService,
    WebclientAuthGuardService,
    WebclientRoutingService
  ]
})
export class WebclientModule { }
