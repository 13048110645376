import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Message } from '../../../../../models/message';
import { MessageRead } from '../../../../../models/message-read';

import { SocketService } from '../../../socket/socket.service';

import * as _ from 'lodash';
import { MessageDelivery } from '../../../../../models/message-delivery';
import { AccountManagerService } from '../../../account/account-manager.service';
import { UserContactService } from '../../user-contact/user-contact.service';
import { UserContact } from '../../../../../models/user-contact';
import { Chat } from '../../../../../models/chat';
import { InfoMessageService } from '../info-message/info-message.service';
import { LoggerService } from '../../../../../utilities/logger/logger.service';
import { UtilitiesService } from '../../../../../utilities/service/utilities.service';
import { MessageTypeConstant } from '../../../../../constants/message-type.constant';
import { TeamnoteApiService } from '../../../../../api/teamnote-api.service';
import { TeamNoteApiConstant } from '../../../../../constants/api.constant';
import { TeamnoteConfigService } from '../../../../../configs/teamnote-config.service';
import { FileManagerService } from '../../../../../utilities/file-manager/file-manager.service';
import { ImageHelperService } from '../../../../../utilities/image-helper/image-helper.service';
import { FileFactoryService } from '../../../../../utilities/file-factory/file-factory.service';
import { SideNavService } from '../../../../../utilities/tn-side-nav/side-nav.service';
import { ModuleKeyDefinition } from '../../../../../constants/module.constant';
import { UserConstant } from '../../../../../constants/user.constant';
import { AnnotationType } from '../../../../../models/message-annotate';

class ChatMessagesByAccount {
  chatMessages?: ChatMessages = {};
  unreadMessages?: UnreadMessages = {};

  queuingMessages?: {[correlationId: string] : any} = {};
  queuingMessagesCorrelationId?: string[] = [];
  currentSendingMessageCorrelationId?: string = null;
  messageResendTimer?;
}
interface ChatMessagesObjCollection {
  [userId: string]: ChatMessagesByAccount
}
interface ChatMessages {
  [chatId: string]: Message[]
}
interface UnreadMessages {
  [messageId: string] : Message
}

interface MessageBSObject {
  [chatId: string]: BehaviorSubject<Message[]>
}

interface SearchedMessageBSObject {
  // [chatId: string]: BehaviorSubject<ChatMessages>
  [chatId: string]: BehaviorSubject<Message[]>
}

@Injectable()
export class ChatMessageService {
  // for tracking all messages (to update chat list last msg)
  chatMessages: ChatMessages = {};
  chatMessages$: BehaviorSubject<ChatMessages> = new BehaviorSubject<ChatMessages>({});

  // for tracking all unread  messages (to update side menu and page title)
  unreadMessages: UnreadMessages = {};
  unreadMessages$: BehaviorSubject<UnreadMessages>  = new BehaviorSubject<UnreadMessages>({});

  // for tracking current active chat room
  activeChatRoomId: string = '';
  activeChatRoomMessages$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  
  activeChatRoomSearchedMessages: ChatMessages = {};
  // activeChatRoomSearchedMessages$: BehaviorSubject<ChatMessages> = new BehaviorSubject<ChatMessages>({});
  activeChatRoomSearchedMessagesSubjects: SearchedMessageBSObject = {};
  // for tracking all messages of each chatroom in Multi-chatroom mode
  multiChatRoomMessageSubjects: MessageBSObject = {};

  queuingMessages: {[correlationId: string] : any} = {};
  queuingMessagesCorrelationId: string[] = [];
  currentSendingMessageCorrelationId: string = null;

  messageResendTimer: any = null

  _chatMessagesObjCollection: ChatMessagesObjCollection = {};
  
  constructor(
    private _socketService: SocketService,
    private _accountManagerService: AccountManagerService,
    private _userContactService: UserContactService,
    private _infoMessageService: InfoMessageService,
    private _loggerService: LoggerService,
    private _utilitiesService: UtilitiesService,
    private _teamnoteApiService: TeamnoteApiService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _fileManagerService: FileManagerService,
    private _imageHelperService: ImageHelperService,
    private _fileFactoryService: FileFactoryService,
    private _sideNavService: SideNavService,
  ) { }

  initChatMessages(): void {
    this.chatMessages = {};
    this.updateChatMessageSubject();
    this.activeChatRoomSearchedMessages = {};
    // this.updateChatSearchedMessageSubject();
    this.unreadMessages = {};
    this.updateUnreadMessageSubject();
    this.activeChatRoomId = '';
    this.updateActiveChatRoomMessageSubject();
    this.multiChatRoomMessageSubjects = {};
    this.activeChatRoomSearchedMessagesSubjects = {};
  }

  updateChatMessage(m: Message): void {
    _.each(m.chat_ids, (chatId) => {
      this.insertOrUpdateChatMessageUnderChat(chatId, m);
      this.sortChatMessageUnderChat(chatId);
    });
    // this.updateChatMessageSubject();
  }
  updateChatMessageSubject(): void {
    this.chatMessages$.next(this.chatMessages);
  }

  removeChatMessageByChatId(chatId: string): void {
    this.chatMessages[chatId] = [];
    this.updateChatMessageSubject();
  }

  // Unreads Message
  getUnreadMessageByMessageId(messageId: string) {
    return this.unreadMessages[messageId];
  }
  insertUnreadMessage(message: Message) {
    this.unreadMessages[message.message_id] = message;
  }
  removeUnreadMessageByMessageId(messageId: string) {
    delete this.unreadMessages[messageId];
  }
  getUnreadMessagesUnderChat(chatId: string) {
    let targetMessages = _.filter(this.unreadMessages, (m) => {
      return _.indexOf(m.chat_ids, chatId) !== -1 && !m.isLocalRead;
    });
    return targetMessages;
  }
  updateUnreadMessageSubject(): void {
    this._loggerService.debug("Update unread message count: " + _.size(this.unreadMessages));
    this._sideNavService.updateSideNavCountByKey(
      ModuleKeyDefinition.CHAT,
      _.size(this.unreadMessages)
    );
  }
  removeUnreadMessageUnderChat(chatId: string) {
    _.each(this.unreadMessages, (message, messageId) => {
      if (message.chat_ids.indexOf(chatId) != -1) {
        this.removeUnreadMessageByMessageId(messageId);
      }
    });
  }

  // Deliveries
  updateMessageDeliveryStatus(md: MessageDelivery) {
    let messageIds = md.body.split(' ');
    _.each(messageIds, (id) => {
      let m = this.getChatMessageByMessageId(id);
      if (m) {
        if (!m.delivery) {
          m.delivery = [];
        }
        let d = {
          body: id,
          sent_by: md.sent_by,
          timestamp: md.timestamp,
          type: md.type
        };
        if (!_.find(m.delivery, d)) {
          m.delivery.push(d);
        }
      }
    });
  }
  

  insertOrUpdateChatMessageUnderChat(chatId: string, m: Message): void {
    if (!this.chatMessages[chatId]) {
      this.chatMessages[chatId] = [];
    }
    let existing = this.getChatMessageUnderChatByMessageId(chatId, m.message_id);
    if (existing.length > 0) {
      let targetMessage = existing[0];
      targetMessage = _.assign(targetMessage, m);
    } else {
      let newlySent = this.getChatMessageUnderChatByCorrelationId(chatId, m.correlation_id);
      if (newlySent.length > 0) {
        let targetMessage = newlySent[0];
        targetMessage = _.assign(targetMessage, m);
        this.removeChatMessageFromQueueByCorrelationId(m.correlation_id);
      } else {
        this.chatMessages[chatId].push(m);
      }
    }
  }
  getChatMessageUnderChatByMessageId(chatId: string, messageId: string): Message[] {
    return _.filter(this.chatMessages[chatId], {'message_id': messageId});
  }
  getChatMessageUnderChatByCorrelationId(chatId: string, correlationId: string): Message[] {
    return _.filter(this.chatMessages[chatId], {'correlation_id': correlationId});
  }
  getChatMessageByMessageId(messageId: string): Message {
    for (var chatId in this.chatMessages) {
      let cm = this.chatMessages[chatId];
      let result = _.find(cm, {'message_id': messageId});
      if (result) {
        return result;
      }
    }
  }
  getAllChatMessagesUnderChat(chatId: string): Message[] {
    return this.chatMessages[chatId] ? this.chatMessages[chatId] : [];
  }
  getLastestChatMessagesUnderChatByChatIdAndSize(chatId: string, size: number): Message[]  {
    let all = this.getAllChatMessagesUnderChat(chatId);
    return _.takeRight(all, size);
  }
  checkIfTimestampIsEarliestMessage(chatId: string, timestamp: string, filterSelectedChatMembers?: any, targetChat?: Chat): boolean {
    if (!timestamp) {
      // if no target timestamp, chat is empty, so it is earliest.
      return true;
    }
    let all = this.getAllChatMessagesUnderChat(chatId);

    if (filterSelectedChatMembers) {
      // filtered by user
      if (filterSelectedChatMembers.length) {
        all = this.filterMessageBySelectedUser(all, filterSelectedChatMembers, targetChat);
      }
    }
    
    let firstMsg = _.first(all);
    // console.log('firstMsg getAllChatMessagesUnderChat', firstMsg);
    if (firstMsg) {
      if (firstMsg.timestamp == timestamp) {
        return true;
      }
    }
    return false;
  }

  removeChatMessageUnderChatByMessageId(messageId: string): void {
    let targetMessage = this.getChatMessageByMessageId(messageId);
    if (!targetMessage) {
      return;
    }

    _.each(targetMessage.chat_ids, (chatId) => {
      this.chatMessages[chatId] = _.filter(this.chatMessages[chatId], (message) => {
        return message.message_id != messageId;
      });
    });
  }

  sortChatMessageUnderChat(chatId: string): void {
    this.chatMessages[chatId] = _.sortBy(this.chatMessages[chatId], ['timestamp']);
  }

  getLastMessageOfChat(chatId: string): Message {
    let messages = this.getAllChatMessagesUnderChat(chatId);
    if (messages) {
      for (var i = messages.length-1; i >= 0; i--) {
        let m = messages[i];
        if (m && 
            (m.type == MessageTypeConstant.TEXT ||
            m.type == MessageTypeConstant.ATTACHMENT ||
            m.type == MessageTypeConstant.LOCATION ||
            m.type == MessageTypeConstant.STICKER
          )
        ) {
          return this.getSingleParsedMsg(m);
        }
      }
    }
  }
  getFirstMessageOfChat(chatId: string): Message {
    let messages = this.getAllChatMessagesUnderChat(chatId);
    return messages ? messages[0] : null;
  }
  getUnreadCountOfChat(chatId: string): number {
    let unreads = this.getUnreadMessagesUnderChat(chatId);
    return unreads.length;
  }

  getSingleParsedMsg(m: Message): Message {
    m.isSentByMe = m.sent_by == this._accountManagerService.userId;
    if (this._utilitiesService.checkIfMessageExpired(m.expiry_time)) {
      m.isRecalled = true;
    }
    m.parsedBody = JSON.parse(m.body);
    m.isWhisper = (m.received_by !== "" && m.received_by) ? true : false;
    if (m.isWhisper) {
      m.whisperContact = this._userContactService.getUserContactByUserId(m.received_by);

      // parse important user for whisperContact
      m.whisperContact.importantLevel = this._userContactService.checkIfUserIsImportant(m.whisperContact.uid)
    }

    m.senderContact = this.getUserContact(m.sent_by);

    m.readByOtherCount = this._infoMessageService.getReadByOtherCountByMessageId(m.message_id);
    m.isReadByMe = this._infoMessageService.checkIfMessageIsReadByMe(m.message_id);

    m.emojis = this._infoMessageService.getEarliestMessageEmojisByMessageId(m.message_id);
    m.emojiDisplay = this._infoMessageService.getComputedEmojiDisplay(m);
    m.isSentEmoji = this._infoMessageService.checkIfMeSentAnEmoji(m);

    if (m.parsedBody.acknowledgement) {
      m.isAcked = this._infoMessageService.checkIfMessageIsAcked(m);
    }

    m.deliverByOtherCount = this._infoMessageService.getDeliverByOtherCountByMessageId(m.message_id);
    
    m.isStarredByMe = this._infoMessageService.checkIfMessageIsStarredByMe(m.message_id);

    m.annotations = this._infoMessageService.getAllMessageAnnotationsByMessageIdAndType(m.message_id, AnnotationType.ATTACHED_MESSAGE);
    m.standaloneAnnotations = this._infoMessageService.getAllMessageAnnotationsByMessageIdAndType(m.message_id, AnnotationType.STANDALONE_MESSAGE);
    // console.log('m.annotations', m.annotations);
    // console.log('m.standaloneAnnotations', m.standaloneAnnotations);
    return m;
  }

  getUserContact(userId): UserContact {
    let user = this._userContactService.getUserContactByUserId(userId);
    if (!user || _.isEmpty(user)) {
      user = new UserContact;
      // Get missing contact when required.
      if (userId && userId != "None") {
        this._userContactService.getContact([userId]);
      }
    }
    if (user.pic && user.pic != 'None') {
      this._fileFactoryService.getFileByAttachmentId(
        user.pic,
        (imageUrl) => {
          user.avatarImageSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }

    // parse important user
    user.importantLevel = this._userContactService.checkIfUserIsImportant(user.user_id)
    return user
  }
  
  getAllAttachmentUnderChatByType(chatId: string, targetType: number, isAttachmentIdOnly?: boolean, isSearchMode?: boolean): any[] {
    let attachments = [];
    let chatMessages: Message[] = [];

    if (isSearchMode) {
      chatMessages = this.getAllChatSearchedMessagesUnderChat(chatId);
    } else {
      chatMessages = this.getAllChatMessagesUnderChat(chatId);
    }

    let allAttachmentMessage = _.filter(chatMessages, (m) => m.type == MessageTypeConstant.ATTACHMENT);
    _.each(allAttachmentMessage, (m: Message) => {
      if (!this._utilitiesService.checkIfMessageExpired(m.expiry_time)) {
        if (m.attachments[0] && m.attachments[0].attachment_id) {
          let type = this._fileManagerService.getAttachmentType(m.attachments[0].attachment_id);
          if (type == targetType && m.parsedBody.is_encrypted === 0) {
            // just get non-encrypted attachment type message
            m.attachments[0].timestamp = m.timestamp;
            attachments.push(m.attachments[0]);
          }
          if (m.parsedBody.message) {
            this._imageHelperService.setImageCaptionById(m.attachments[0].attachment_id, m.parsedBody.message);
          }
        }
      }
    });
    if (isAttachmentIdOnly) {
      return _.map(attachments, 'attachment_id');
    }
    return attachments;
  }

  // Active Multi Chat Room Message
  initMessageSubject(chatId: string): void {
    let bs = new BehaviorSubject<Message[]>([]);
    
    if (!this.multiChatRoomMessageSubjects[chatId]) {
      this.multiChatRoomMessageSubjects[chatId] = bs;
    }
  }

  getMessageSubjectByChatId(chatId: string): BehaviorSubject<Message[]> {
    return this.multiChatRoomMessageSubjects[chatId];
  }

  tryUpdateMultiChatRoomMessageSubjects(allChatIds: string[], newMessages?: Message[]): void {
    _.forEach(allChatIds, (chat_id) => {
      if (chat_id in this.multiChatRoomMessageSubjects) {
        this.updateMessageSubjectByChatId(chat_id, newMessages);
      }
    })
  }

  updateMessageSubjectByChatId(chatId: string, newMessages?: Message[]): void {
    console.log('newMessages', newMessages);
    // if (this.multiChatRoomMessageSubjects[chatId]) {
    const bs$ = this.multiChatRoomMessageSubjects[chatId];
    // bs$.next(this.getTargetChatRoomMessageByChatId(chatId));

    const updatedMessages = newMessages ? newMessages : []
    bs$.next(updatedMessages);
    // }
  }

  removeMultiChatRoomBehaviorSubjectByChatId(chatId: string) {
    delete this.multiChatRoomMessageSubjects[chatId];
  }

  // Active Chat Room Message & Message under searching mode
  initActiveChatRoomMessageSubject(chatId: string, isSearchMode?: boolean): void {
    if (!isSearchMode) {
      this.activeChatRoomId = chatId;
      this.activeChatRoomMessages$ = new BehaviorSubject<Message[]>([]);

      this.updateActiveChatRoomMessageSubject();
    } else {
      // this.activeChatRoomSearchedMessages$ = new BehaviorSubject<ChatMessages>({});
      this.removeChatSearchedMessageByChatId(chatId);
      this.initActiveChatRoomSearchedMessagesSubject(chatId);

      // this.updateActiveChatRoomSearchedMessageSubject();
      this.updateActiveChatRoomSearchedMessageSubjectByChatId(chatId);
    }
  }
  deleteActiveChatRoomMessageSubject(): void {
    this.activeChatRoomId = '';
    this.activeChatRoomMessages$ = new BehaviorSubject<Message[]>([]);
    // this.activeChatRoomSearchedMessages$ = new BehaviorSubject<ChatMessages>({});
  }
  tryUpdateActiveChatRoomMessageSubject(allChatIds: string[]): void {
    // console.log('tryUpdateActiveChatRoomMessageSubject', allChatIds);
    // console.log('activeChatRoomId', this.activeChatRoomId);

    if (_.indexOf(allChatIds, this.activeChatRoomId) != -1) {
      this.updateActiveChatRoomMessageSubject();
      // this.updateActiveChatRoomSearchedMessageSubject(); // try to update message under searching mode
    }
  }
  updateActiveChatRoomMessageSubject(): void {
    if (this.activeChatRoomId) {
      this.activeChatRoomMessages$.next(this.getActiveChatRoomMessage());
    }
  }

  /* for updating all messages under searching mode */
  // updateActiveChatRoomSearchedMessageSubject(): void {
  //   // if (this.activeChatRoomId) {
  //     this.activeChatRoomSearchedMessages$.next({});
  //   // }
  // }

  // Active Chat Room Searched Message
  initActiveChatRoomSearchedMessagesSubject(chatId: string): void {
    let bs = new BehaviorSubject<Message[]>([]);
    
    if (!this.activeChatRoomSearchedMessagesSubjects[chatId]) {
      this.activeChatRoomSearchedMessagesSubjects[chatId] = bs;
    }
  }

  getActiveChatRoomSearchedMessageSubjectByChatId(chatId: string): BehaviorSubject<Message[]> {
    return this.activeChatRoomSearchedMessagesSubjects[chatId];
  }

  tryUpdateActiveChatRoomSearchedMessageSubjects(allChatIds: string[], newMessages?: Message[]): void {
    _.forEach(allChatIds, (chat_id) => {
      if (chat_id in this.activeChatRoomSearchedMessagesSubjects) {
        this.updateActiveChatRoomSearchedMessageSubjectByChatId(chat_id, newMessages);
      }
    })
  }

  updateActiveChatRoomSearchedMessageSubjectByChatId(chatId: string, newMessages?: Message[]): void {
    // if (this.multiChatRoomMessageSubjects[chatId]) {
    const bs$ = this.activeChatRoomSearchedMessagesSubjects[chatId];
    // bs$.next({});
    // bs$.next(this.getAllChatSearchedMessagesUnderChat(chatId));
    // }

    const updatedMessages = newMessages ? newMessages : []
    bs$.next(updatedMessages);
  }

  removeActiveChatRoomSearchedBehaviorSubjectByChatId(chatId: string) {
    delete this.activeChatRoomSearchedMessagesSubjects[chatId];
  }

  storeChatSearchedMessage(msgs: Message[], chatId: string): void {
    this.activeChatRoomSearchedMessages[chatId] = msgs
  }

  getAllChatSearchedMessagesUnderChat(chatId: string): Message[] {
    return this.activeChatRoomSearchedMessages[chatId] ? this.activeChatRoomSearchedMessages[chatId] : [];
  }

  getAllChatSearchedMessageUnderChat(chatId: string, currSearchMsgSize: number): Message[] {
    let targetSize = currSearchMsgSize + this._teamnoteConfigService.config.WEBCLIENT.AMQP.CHAT_LOAD_HISTORY_SIZE;
    let all = this.getAllChatSearchedMessagesUnderChat(chatId);
    return _.takeRight(all, targetSize);
  }

  // updateChatSearchedMessageSubject(): void {
  //   this.activeChatRoomSearchedMessages$.next(this.activeChatRoomSearchedMessages);
  // }

  removeChatSearchedMessageByChatId(chatId?: string): void {
    if (chatId) {
      if (chatId in this.activeChatRoomSearchedMessages) {
        delete this.activeChatRoomSearchedMessages[chatId];
      }
    } else {
      this.activeChatRoomSearchedMessages = {}
    }
    
    // this.updateChatSearchedMessageSubject();
  }

  getActiveChatRoomMessage(): Message[] {
    let msgs = this.getAllChatMessagesUnderChat(this.activeChatRoomId);
    return _.map(msgs, (m) => {
      return this.getSingleParsedMsg(m);
    });
  }

  getTargetChatRoomMessageByChatId(chatId: string): Message[] {
    let msgs = this.getAllChatMessagesUnderChat(chatId);
    return _.map(msgs, (m) => {
      return this.getSingleParsedMsg(m);
    });
  }

  // 
  addChatMessageToQueue(correlationId, routingKey, headers, body, callback) {
    this.queuingMessages[correlationId] = {
      routingKey: routingKey,
      headers: headers,
      body: body,
      correlationId: correlationId,
      callback: callback
    };
    this.queuingMessagesCorrelationId.push(correlationId);
  }
  removeChatMessageFromQueueByCorrelationId(correlationId: string) {
    delete this.queuingMessages[correlationId];
    this.queuingMessagesCorrelationId = _.without(this.queuingMessagesCorrelationId, correlationId);
    this.currentSendingMessageCorrelationId = null;
    clearTimeout(this.messageResendTimer);
    this.sendFirstMessageInQueue();
  }
  sendFirstMessageInQueue(isForceSent?: boolean): void {
    if (this.queuingMessagesCorrelationId.length == 0) {
      return;
    }

    let firstInQueueCorrelationId = _.first(this.queuingMessagesCorrelationId);
    let m = this.queuingMessages[firstInQueueCorrelationId];

    if (!this._accountManagerService.isEnableMultiAccountLogin()) {
      this._socketService.sendMessage(m.routingKey, m.headers, m.body, m.correlationId, m.callback);
    } else {
      this._socketService.sendMessage_v2(this._accountManagerService.userId, m.routingKey, m.headers, m.body, m.correlationId, m.callback);
    }

    this.currentSendingMessageCorrelationId = firstInQueueCorrelationId;

    // the first message in the message queue may not send immediately via web socket, then
    // try to send the message of message queue by order every second until sent them all successfully
    clearTimeout(this.messageResendTimer);
    this.messageResendTimer = setTimeout(() => {
      if (_.includes(this.queuingMessagesCorrelationId, this.currentSendingMessageCorrelationId)) {
        this.sendFirstMessageInQueue()
      }
    }, 1000);
  }

  // Search message
  searchMessageByKeywordApi(keyword: string, success: Function, failure: Function, chatId?: string, timestamp?: any, timestampFrom?: any): void {
    if (!keyword || keyword.length == 0) {
      return;
    }
    let url = TeamNoteApiConstant.MESSAGE.SEARCH;
    let params = {
      keyword: keyword.trim(),
      chat_id: null,
      timestamp: null,
      timestamp_from: null,
      size: 20
    };
    if (chatId) {
      params.chat_id = chatId;
    }
    if (timestamp) {
      params.timestamp = timestamp;
    }
    if (timestampFrom) {
      params.timestamp_from = timestampFrom;
    }

    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      failure,
      false,
      false,
      true
    );
  }

  /**
   * Load chat history via API
   * 
   * @param {string} chatId - target chat id
   * @param {*} messageTime - base message timestamp
   * @param {boolean} loadBeforeTimestamp - load before or after timestamp
   * @param {Function} success - success callback
   * @param {Function} failure - failure callback
   * @returns {void} 
   * @memberof ChatMessageService
   */
  chatLoadHistoryApi(chatId: string, messageTime: any, loadBeforeTimestamp: boolean, success: Function, failure: Function, isBackgroundTask?: boolean): void {
    if (!chatId || !messageTime) {
      return;
    }
    let url = TeamNoteApiConstant.MESSAGE.LOAD_HISTORY;
    let params = {
      chat_id: chatId,
      message_time: messageTime,
      load_before_timestamp: loadBeforeTimestamp ? 1 : 0,
      size: this._teamnoteConfigService.config.WEBCLIENT.AMQP.CHAT_LOAD_HISTORY_SIZE
    };
    this._teamnoteApiService.callApi(
      url,
      params,
      success,
      failure,
      false,
      false,
      isBackgroundTask
    );
  }

  /**
   * Parse loaded messages,
   * only return REAL messages,
   * add READ messages to infoMessageService
   * add STAR pr UNSTAR messages to infoMessageService
   * 
   * @param {any[]} msgs - messages from load history API
   * @returns {Message[]} 
   * @memberof ChatMessageService
   */
  parseSearchedMsgResultIntoMessages(msgs: any[]): Message[] {
    let realMsgs: Message[] = [];
    let msgReads: MessageRead[] = [];

    _.each(msgs, (m) => {
      switch (_.toInteger(m.type)) {
        case MessageTypeConstant.TEXT:
        case MessageTypeConstant.ATTACHMENT:
        case MessageTypeConstant.LOCATION:
        case MessageTypeConstant.STICKER:
        case MessageTypeConstant.JOIN_CHAT:
        case MessageTypeConstant.LEAVE_CHAT:
        case MessageTypeConstant.CHANGE_CHAT_ADMIN:
          realMsgs.push(m);
          break;
        case MessageTypeConstant.READ:
          this._infoMessageService.insertMessageReadStatus(m);
          break;
        case MessageTypeConstant.EMOJI:
        case MessageTypeConstant.UNEMOJI:
          this._infoMessageService.insertMessageEmojiStatus(m);
          break;
        case MessageTypeConstant.MESSAGE_STAR:
        case MessageTypeConstant.MESSAGE_UNSTAR:
          this._infoMessageService.insertMessageStarStatus(m);
          break;
        case MessageTypeConstant.MESSAGE_ACKNOWLEDGEMENT:
          this._infoMessageService.insertMessageAckStatus(m);
          break;
        case MessageTypeConstant.MESSAGE_ANNOTATION:
          this._infoMessageService.insertMessageAnnotationStatus(m);
          break;
      }
    });

    return realMsgs;
  }

  getHighestImportantLevelOfMessages(msgs: Message[]): number {
    let highest = 0;
    _.each(msgs, (m) => {
      m.parsedBody = JSON.parse(m.body)
      if (m.parsedBody.important_level > highest)
      highest = Math.max(highest, parseInt(m.parsedBody.important_level, 10))
    })
    return highest;
  }

  // checkIfMessageIsRecalled(messageId: string): boolean {
  checkIfMessageIsRecalled(msg: Message): boolean {
    // let msg = this.getChatMessageByMessageId(messageId);
    // logic here:
    // if can not find target message in the chat in this.chatMessages(whole messages)
    // then load history??
    if (this._utilitiesService.checkIfMessageExpired(msg.expiry_time)) {
      return true;
    }
    return false;
  }

  // filter message in multi-chatroom mode
  filterMessageBySelectedUser(messages: Message[], membersIds: string[], targetChat: Chat): Message[] {
    let filteredParsedMessages = [];
    filteredParsedMessages = _.filter(messages, (msg) => {
      if (msg.sent_by) {
        return _.includes(membersIds, msg.sent_by);
      } else {
        if (msg.date) {
          if (targetChat.isGroup) {
            return true;
          } else {
            return _.intersection(membersIds, targetChat.members).length
          }
        }
      }

      return msg;
    })
    // console.log('filteredParsedMessages', filteredParsedMessages);
    return filteredParsedMessages;
  }

  filterMessageByDateTimeRange(messages: Message[], timestamp_from: string): Message[] {
    return _.filter(messages, (m) => m.timestamp >= timestamp_from);;
  }

  clearMultiAccountChatMessages(): void {
    this._chatMessagesObjCollection = {};
  }

  initChatMessages_v2(accUserId: string): void {
    if (!this._chatMessagesObjCollection[accUserId]) {
      this._chatMessagesObjCollection[accUserId] = {
        chatMessages: {},
        unreadMessages: {},
        queuingMessages: {},
        queuingMessagesCorrelationId: [],
        currentSendingMessageCorrelationId: null
      }
    }
  }

  switchAccountChatMessages(accUserId: string): void {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    console.log('switchAccountChatMessages');
    
    this.chatMessages = cmc.chatMessages;
    this.unreadMessages = cmc.unreadMessages;

    this.updateChatMessageSubject();
    this.updateUnreadMessageSubject();

    this.resetChatRoomDataCallback();

    this.activeChatRoomSearchedMessages = {};
    this.activeChatRoomId = '';

    // this.updateChatSearchedMessageSubject();
    this.updateActiveChatRoomMessageSubject();

    this.multiChatRoomMessageSubjects = {};
    this.activeChatRoomSearchedMessagesSubjects = {};
    
    // let activeSearchModeChatIds = _.keys(this.activeChatRoomSearchedMessagesSubjects);
    // this.tryUpdateActiveChatRoomSearchedMessageSubjects(activeSearchModeChatIds);
  }

  getChatMessagesObjCollectionByAccountUserId(accUserId: string) {
    return this._chatMessagesObjCollection[accUserId]
  }

  getAllChatMessagesUnderChat_v2(chatId: string, accUserId: string): Message[] {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    return cmc.chatMessages[chatId] ? cmc.chatMessages[chatId] : [];
  }

  getChatMessageUnderChatByMessageId_v2(chatId: string, messageId: string, accUserId: string): Message[] {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    return _.filter(cmc.chatMessages[chatId], {'message_id': messageId});
  }
  getChatMessageUnderChatByCorrelationId_v2(chatId: string, correlationId: string, accUserId: string): Message[] {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    return _.filter(cmc.chatMessages[chatId], {'correlation_id': correlationId});
  }

  getChatMessageByMessageId_v2(messageId: string, accUserId: string): Message {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)

    for (var chatId in cmc.chatMessages) {
      let cm = cmc.chatMessages[chatId];
      let result = _.find(cm, {'message_id': messageId});
      if (result) {
        return result;
      }
    }
  }

  getUnreadMessageByMessageIdAndAccountUserId(messageId: string, accUserId: string) {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    return cmc.unreadMessages[messageId];
  }

  insertUnreadMessage_v2(message: Message, accUserId: string) {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    cmc.unreadMessages[message.message_id] = message;
  }
  removeUnreadMessageByMessageId_v2(messageId: string, accUserId: string) {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    delete cmc.unreadMessages[messageId];
  }

  removeUnreadMessageUnderChat_v2(chatId: string, accUserId: string) {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)

    _.each(cmc.unreadMessages, (message, messageId) => {
      if (message.chat_ids.indexOf(chatId) != -1) {
        this.removeUnreadMessageByMessageId_v2(messageId, accUserId);
      }
    });
  }

  removeChatMessageFromQueueByCorrelationId_v2(correlationId: string, accUserId: string) {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)

    delete cmc.queuingMessages[correlationId];
    cmc.queuingMessagesCorrelationId = _.without(cmc.queuingMessagesCorrelationId, correlationId);
    cmc.currentSendingMessageCorrelationId = null;
    clearTimeout(cmc.messageResendTimer);
    this.sendFirstMessageInQueue_v2(false, accUserId);
  }

  sendFirstMessageInQueue_v2(isForceSent?: boolean, accUserId?: string): void {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    
    if (cmc.queuingMessagesCorrelationId.length == 0) {
      return;
    }

    let firstInQueueCorrelationId = _.first(cmc.queuingMessagesCorrelationId);
    let m = cmc.queuingMessages[firstInQueueCorrelationId];
    this._socketService.sendMessage_v2(accUserId, m.routingKey, m.headers, m.body, m.correlationId, m.callback);
    cmc.currentSendingMessageCorrelationId = firstInQueueCorrelationId;

    // the first message in the message queue may not send immediately via web socket, then
    // try to send the message of message queue by order every second until sent them all successfully
    clearTimeout(cmc.messageResendTimer);
    cmc.messageResendTimer = setTimeout(() => {
      if (_.includes(cmc.queuingMessagesCorrelationId, cmc.currentSendingMessageCorrelationId)) {
        this.sendFirstMessageInQueue_v2(false, accUserId) 
      }
    }, 1000);
  }

  insertOrUpdateChatMessageUnderChat_v2(chatId: string, m: Message, accUserId: string): void {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    
    if (!cmc.chatMessages[chatId]) {
      cmc.chatMessages[chatId] = [];
    }
    // get message by chat_id & message_id in local
    let existing = this.getChatMessageUnderChatByMessageId_v2(chatId, m.message_id, accUserId);
    if (existing.length > 0) {
      // if message found with a message_id: 
      let targetMessage = existing[0];
      targetMessage = _.assign(targetMessage, m);
    } else {
      let newlySent = this.getChatMessageUnderChatByCorrelationId_v2(chatId, m.correlation_id, accUserId);
      if (newlySent.length > 0) {
        // received message without message_id but with a correlation_id
        let targetMessage = newlySent[0];
        targetMessage = _.assign(targetMessage, m);

        if (this._accountManagerService.userId === accUserId) {
          this.removeChatMessageFromQueueByCorrelationId(m.correlation_id);
        }
      } else {
        cmc.chatMessages[chatId].push(m);
      }
    }
  }

  sortChatMessageUnderChat_v2(chatId: string, accUserId: string): void {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)

    cmc.chatMessages[chatId] = _.sortBy(cmc.chatMessages[chatId], ['timestamp']);
  }

  updateChatMessage_v2(m: Message, accUserId: string): void {
    _.each(m.chat_ids, (chatId) => {
      this.insertOrUpdateChatMessageUnderChat_v2(chatId, m, accUserId);
      this.sortChatMessageUnderChat_v2(chatId, accUserId);
    });
    // this.updateChatMessageSubject();
  }

  updateUnreadMessageSubject_v2(accUserId: string): void {
    // this._loggerService.debug("Update unread message count: " + _.size(this.unreadMessages));
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    
    this._sideNavService.updateSideNavCountByKey_v2(
      ModuleKeyDefinition.CHAT,
      _.size(cmc.unreadMessages),
      accUserId
    )
    
  };

  removeChatMessageUnderChatByMessageId_v2(messageId: string, accUserId: string): void {
    let targetMessage = this.getChatMessageByMessageId_v2(messageId, accUserId);
    if (!targetMessage) {
      return;
    }

    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)

    _.each(targetMessage.chat_ids, (chatId) => {
      cmc.chatMessages[chatId] = _.filter(cmc.chatMessages[chatId], (message) => {
        return message.message_id != messageId;
      });
    });
  }

  getLastMessageOfChat_v2(chatId: string, accUserId: string): Message {
    let messages = this.getAllChatMessagesUnderChat_v2(chatId, accUserId);
    
    if (messages) {
      for (var i = messages.length-1; i >= 0; i--) {
        let m = messages[i];
        if (m && 
            (m.type == MessageTypeConstant.TEXT ||
            m.type == MessageTypeConstant.ATTACHMENT ||
            m.type == MessageTypeConstant.LOCATION ||
            m.type == MessageTypeConstant.STICKER
          )
        ) {
          return this.getSingleParsedMsg_v2(m, accUserId);
        }
      }
    }
  }

  getUnreadMessagesUnderChat_v2(chatId: string, accUserId: string) {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    
    let targetMessages = _.filter(cmc.unreadMessages, (m) => {
      return _.indexOf(m.chat_ids, chatId) !== -1 && !m.isLocalRead;
    });
    return targetMessages;
  }

  getUnreadCountOfChat_v2(chatId: string, accUserId: string): number {
    let unreads = this.getUnreadMessagesUnderChat_v2(chatId, accUserId);
    return unreads.length;
  }

  getUserContact_v2(userId, accUserId: string): UserContact {
    let user = this._userContactService.getUserContactByUserIdAndAccountUserId(userId, accUserId);
    if (!user || _.isEmpty(user)) {
      user = new UserContact;
      // Get missing contact when required.
      if (userId && userId != "None") {
        this._userContactService.getContact_v2([userId], accUserId);
      }
    }
    if (user.pic && user.pic != 'None') {
      this._fileFactoryService.getFileByAttachmentId(
        user.pic,
        (imageUrl) => {
          user.avatarImageSrc = imageUrl;
        },
        (err) => {},
        false,
        false,
        true
      );
    }

    // parse important user
    user.importantLevel = this._userContactService.checkIfUserIsImportant(user.user_id)
    return user
  }

  getSingleParsedMsg_v2(m: Message, accUserId: string): Message {
    m.isSentByMe = m.sent_by == accUserId;
    if (this._utilitiesService.checkIfMessageExpired(m.expiry_time)) {
      m.isRecalled = true;
    }
    m.parsedBody = JSON.parse(m.body);
    m.isWhisper = (m.received_by !== "" && m.received_by) ? true : false;
    if (m.isWhisper) {
      m.whisperContact = this._userContactService.getUserContactByUserIdAndAccountUserId(m.received_by, accUserId);

      // parse important user for whisperContact
      m.whisperContact.importantLevel = this._userContactService.checkIfUserIsImportant(m.whisperContact.uid)
    }

    m.senderContact = this.getUserContact_v2(m.sent_by, accUserId);

    m.readByOtherCount = this._infoMessageService.getReadByOtherCountByMessageId_v2(m.message_id, accUserId);
    m.isReadByMe = this._infoMessageService.checkIfMessageIsReadByMe_v2(m.message_id, accUserId);

    m.emojis = this._infoMessageService.getEarliestMessageEmojisByMessageId_v2(m.message_id, accUserId);
    m.emojiDisplay = this._infoMessageService.getComputedEmojiDisplay(m);
    m.isSentEmoji = this._infoMessageService.checkIfMeSentAnEmoji_v2(m, accUserId);

    if (m.parsedBody.acknowledgement) {
      m.isAcked = this._infoMessageService.checkIfMessageIsAcked_v2(m, accUserId);
    }

    m.deliverByOtherCount = this._infoMessageService.getDeliverByOtherCountByMessageId_v2(m.message_id, accUserId);
    
    m.isStarredByMe = this._infoMessageService.checkIfMessageIsStarredByMe_v2(m.message_id, accUserId);

    m.annotations = this._infoMessageService.getAllMessageAnnotationsByMessageIdAndType_v2(m.message_id, AnnotationType.ATTACHED_MESSAGE, accUserId);
    m.standaloneAnnotations = this._infoMessageService.getAllMessageAnnotationsByMessageIdAndType_v2(m.message_id, AnnotationType.STANDALONE_MESSAGE, accUserId);

    return m;
  }

  updateChatMessageSubject_v2(accUserId: string): void {
    let cmc = this.getChatMessagesObjCollectionByAccountUserId(accUserId)
    this.chatMessages$.next(cmc.chatMessages);
  }

  tryUpdateActiveChatRoomMessageSubject_v2(allChatIds: string[], accUserId: string): void {
    if (_.indexOf(allChatIds, this.activeChatRoomId) != -1) {
      this.updateActiveChatRoomMessageSubject_v2(accUserId);
      // this.updateActiveChatRoomSearchedMessageSubject(); // try to update message under searching mode
    }
  }
  updateActiveChatRoomMessageSubject_v2(accUserId: string): void {
    if (this.activeChatRoomId) {
      this.activeChatRoomMessages$.next(this.getActiveChatRoomMessage_v2(accUserId));
    }
  }

  getActiveChatRoomMessage_v2(accUserId: string): Message[] {
    let msgs = this.getAllChatMessagesUnderChat_v2(this.activeChatRoomId, accUserId);
    return _.map(msgs, (m) => {
      return this.getSingleParsedMsg_v2(m, accUserId);
    });
  }

  resetChatRoomDataCallback(): void {
    // reset all data in ChatRoomService
  }
}
