import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { DateTimeSelectorComponent } from './date-time-selector.component';
import { CommonMaterialModule } from '../common-material/common-material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonMaterialModule
  ],
  declarations: [
    DateTimeSelectorComponent
  ],
  providers: []
})

export class DateTimeSelectorModule { }
