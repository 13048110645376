import { Injectable } from '@angular/core';

import { FileUploaderComponent } from './file-uploader.component';
import { TnDialogService } from '../tn-dialog/tn-dialog.service';
import { FileUploadTarget } from './file-upload-target';
import { AttachmentTypeConstant } from '../../constants/attachment-type.constant';

import * as _ from 'lodash';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { WatermarkService } from '../watermark/watermark.service';

@Injectable()
export class FileUploaderService {

  constructor(
    private _tnDialogService: TnDialogService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _watermarkService: WatermarkService
  ) { }

  openFileUploaderModal(target: string, fileUploadFunction: Function, defaultFiles?: File[], isGetParsedFile?: boolean, validExtensions?: string[], validExtensionsDisplaysAddon?: string[], validExtensionsDisplaysSpec?: string[], isSingleFileUpload?: boolean): void {
    let validExtensionsDisplays = ['JPEG', 'JPG', 'PNG', 'PDF', 'DOC', 'XLS', 'PPT', 'MP4'];
    
    if (!_.isEmpty(validExtensionsDisplaysSpec)) {
      validExtensionsDisplays = validExtensionsDisplaysSpec
    }
    
    if (validExtensionsDisplaysAddon) {
      validExtensionsDisplays = _.union(validExtensionsDisplays, validExtensionsDisplaysAddon);
    }
    if (!validExtensions) {
      if (target) {
        switch (target) {
          case FileUploadTarget.CHATROOM:
            if (!this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_ALLOW_ALL_FILE_TYPE) {
              validExtensions = JSON.parse(this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.VALID_FILE_TYPES);
              validExtensionsDisplays = JSON.parse(this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.VALID_FILE_TYPES_DISPLAY);
            }
            break;
          case FileUploadTarget.DOCUMENT:
            validExtensions = _.union(AttachmentTypeConstant.DOC_TYPES,
              AttachmentTypeConstant.PDF_TYPES);
            validExtensionsDisplays = _.union(AttachmentTypeConstant.DOC_TYPES_DISPLAY,
              AttachmentTypeConstant.PDF_TYPES_DISPLAY)
            break;
          case FileUploadTarget.IMAGE:
            validExtensions = AttachmentTypeConstant.IMAGE_TYPES;
            validExtensionsDisplays = AttachmentTypeConstant.IMAGE_TYPES_DISPLAY;
            break;
        }
      }
    }

    this._watermarkService.updateWatemarkOverlayZIndexForDialogs(true);
    let dialogRef = this._tnDialogService.openTnDialog(FileUploaderComponent, {
      target: target, 
      fileUploadFunction: fileUploadFunction,
      defaultFiles: defaultFiles,
      isGetParsedFile: isGetParsedFile,
      validExtensions: validExtensions,
      validExtensionsDisplays: validExtensionsDisplays,
      isSingleFileUpload: isSingleFileUpload
    });

    dialogRef.beforeClosed().subscribe(result => {
      this._watermarkService.updateWatemarkOverlayZIndexForDialogs(false);
    });
  }

}
