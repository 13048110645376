export const ZhChsScheduleMessageStrings = {
  ADD_SCHEDULE_MESSAGE: '新增预定消息',
  EDIT_SCHEDULE_MESSAGE: '编辑预定消息',
  BULK_ACTION: '切换批量更新',

  USERS_SELECTION: '选择用户',
  USERS_SELECTION_COUNT: '{{COUNT}} 位用户已选',
  GROUPS_SELECTION: '选择群组',
  GROUPS_SELECTION_COUNT: '{{COUNT}} 个群组已选',

  SEARCH_PLACEHOLDER: '搜索预定消息',

  ERROR_MSG: {
    INCORRECT_DATE: '请检查时间戳是否正确',
    EMPTY_TEXT_MESSAGE: '请输入消息',
    EMPTY_ATTACHMENT_MESSAGE: '请上传附件',
    EMPTY_STICKER_MESSAGE: '请选择贴图',
    EMPTY_LOCATION_MESSAGE: '请选择位置',
    EMPTY_TARGETS: '请选择目标用户或聊天群组',
    INVALID_DELAY_INPUT: '请输入有效的值'
  },

  TIPS_MSG: {
    ADD_SUCCESS: '预定消息创建成功',
    UPDATE_SUCCESS: '预定消息更新成功',
    DELETE_SUCCESS: '预定消息删除成功',
    DELETE_CONFIRM: '确认删除预定消息？',
    DELAY_INPUT_CONFIRM: '请指定消息应延迟多久（分钟）。',
    SEND_SUCCESS: '发送审批预定消息成功',
    CANCEL_SUCCESS: '取消审批预定消息成功',
    RESCHEDULE_SUCCESS: '重新安排审批预定消息成功'
  },

  LOCATION_SELECT: '选择位置',
  STICKER_SELECT: '选择贴图',

  SCHEDULED_MESSAGE_TITLE: '等待确认的消息',

  STATUS: {
    WATING_FOR_APPROVAL: '等待确认'
  },

  TABLE: {
    EMPTY_MESSAGE_PLACEHOLDER: '没有待确认的审批预定消息',
    NO_SCHEDULE_MESSAGE_PLACEHOLDER: '没有预定消息',
    DATETIME: '预定时间',
    TARGETS: '消息接收者',
    MESSAGE_CONTENT: '消息内容',
    REQUIRED_CONFIRM: '需要确认',
    STATUS: '状态',
    ACTION: '操作'
  }
}
