import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Exercise } from './exercise.service';
import { ExerciseService } from './exercise.service';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';

@Component({
  selector: 'tn-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss']
})
export class ExerciseComponent {
  exercises: Exercise[] = [];
  accessToken: string = null;
  callback: Function = null;
  
  constructor(
    public dialogRef: MatDialogRef<ExerciseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _exerciseService: ExerciseService,
    private _localStorageManagerService: LocalStorageManagerService,
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize("50vw");
    this.exercises = this.data.exercises;
    this.accessToken = this.data.accessToken;
    this.callback = this.data.callback;
  }

  switchExercise(exercise: Exercise) {
    // this._exerciseService.startSingleExerciseLoginFlow(exercise, this.accessToken, this.callback);
    if (this.callback) {
      this.callback(exercise.company_domain, exercise.user_name);
    }
  }

}
