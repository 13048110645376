import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef, HostListener } from '@angular/core';
import { trigger, style, animate, keyframes, group, query, transition } from '@angular/animations'
import { ContextMenuService } from '../../../utilities/context-menu/context-menu.service';
import { Message } from '../../../models/message';
import { Chat } from '../../../models/chat';

const MyAnim = trigger('myanim', [
  // transition("void => *", [
  //   style({"opacity": 0}),
  //   animate(".3s", style({
  //     "opacity": 1
  //   }))
  // ]),
  /* array element delete animation  */
  transition('* => void', [
    style({ opacity: 1 }),
    animate(
      '.25s',
      style({
        opacity: 0,
        height: 0
      })
    )
  ])
])

@Component({
  selector: 'tn-starred-messages-list',
  templateUrl: './starred-messages-list.component.html',
  styleUrls: ['./starred-messages-list.component.scss'],
  animations: [MyAnim]
})
export class StarredMessagesListComponent implements OnInit {
  @Input() starredMessages: any[];
  @Input() isChatLoaded: boolean;
  @Input() isEnableReadTicks: boolean;
  @Input() isEnableStarMessage: boolean;
  @Input() isEnableImportantUsers: boolean;
  @Input() isLoadingHistory: boolean;
  @Input() isInChat: boolean;
  @Input() chat: Chat;
  @Output() onItemClick: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() updateStarredMessages: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() updateMessages: EventEmitter<string> = new EventEmitter<string>(null);

  @ViewChild('starMsgElement') starMsgElement: ElementRef;

  constructor(
    private _contextMenuService: ContextMenuService
  ) { }

  ngOnInit() {

  }

  ngOnChanges() {
    // console.log(this.starredMessages)
  }

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.mouseWheelFunc(event, 'chrome');
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.mouseWheelFunc(event, 'firefox');
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.mouseWheelFunc(event, 'ie');
  }

  mouseWheelFunc(event, from): void {
    let element = this.starMsgElement.nativeElement;
    let atBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1;
    if (atBottom && event.wheelDelta < 0 && !this.isLoadingHistory) {
      this.updateMessages.emit();
    }
  }

  onStarredMessageClick(m: Message): void {
    this.onItemClick.emit(m)
  }

  searchStarredMessage(keyword: string): void {
    this.updateStarredMessages.emit(keyword)
  }

  onMessageListScroll(event): void {
    this._contextMenuService.hideContextMenu()
  }

}
