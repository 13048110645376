export const ZhChtScheduleMessageStrings = {
  ADD_SCHEDULE_MESSAGE: '新增預定消息',
  EDIT_SCHEDULE_MESSAGE: '編輯預定消息',
  BULK_ACTION: '切換批量更新',

  USERS_SELECTION: '選擇用戶',
  USERS_SELECTION_COUNT: '{{COUNT}} 位用戶已選',

  GROUPS_SELECTION: '選擇群組',
  GROUPS_SELECTION_COUNT: '{{COUNT}} 個群組已選',

  SEARCH_PLACEHOLDER: '搜索預定消息',

  ERROR_MSG: {
    INCORRECT_DATE: '請檢查時間戳是否正確',
    EMPTY_TEXT_MESSAGE: '請輸入消息',
    EMPTY_ATTACHMENT_MESSAGE: '請上傳附件',
    EMPTY_STICKER_MESSAGE: '請選擇貼圖',
    EMPTY_LOCATION_MESSAGE: '請選擇位置',
    EMPTY_TARGETS: '請選擇目標用戶或聊天群組',
    INVALID_DELAY_INPUT: '請輸入有效的值'
  },

  TIPS_MSG: {
    ADD_SUCCESS: '預定消息創建成功',
    UPDATE_SUCCESS: '預定消息更新成功',
    DELETE_SUCCESS: '預定消息刪除成功',
    DELETE_CONFIRM: '確認刪除預定消息？',
    DELAY_INPUT_CONFIRM: '請指定消息應延遲多久（分鐘）。',
    
    SEND_SUCCESS: '發送審批預定消息成功',
    CANCEL_SUCCESS: '取消審批預定消息成功',
    RESCHEDULE_SUCCESS: '重新安排審批預定消息成功'
  },

  LOCATION_SELECT: '選擇位置',
  STICKER_SELECT: '選擇貼圖',

  SCHEDULED_MESSAGE_TITLE: '等待確認的消息',

  STATUS: {
    WATING_FOR_APPROVAL: '等待確認'
  },

  TABLE: {
    EMPTY_MESSAGE_PLACEHOLDER: '沒有待確認的審批預定消息',
    NO_SCHEDULE_MESSAGE_PLACEHOLDER: '沒有預定消息',
    DATETIME: '預定時間',
    TARGETS: '消息接收者',
    MESSAGE_CONTENT: '消息內容',
    REQUIRED_CONFIRM: '需要確認',
    STATUS: '狀態',
    ACTION: '操作'
  }
}
