import { EnChatStrings } from '../chat/strings/en-chat.strings';
import { EnChatroomStrings } from '../chat/chat-room/strings/en-chatroom.strings';
import { EnNewsStrings } from '../news/strings/en-news.strings';
import { EnContactStrings } from '../contact/strings/en-contact.strings';
import { EnQuestionnaireStrings } from '../questionnaire/strings/en-questionnaire.strings';
import { EnJobReportStrings } from '../job-report/strings/en-job-report.strings';
import { EnBroadcastStrings } from '../broadcast/strings/en-broadcast.string';
import { EnMenuStrings } from './menu/en-menu.strings';
import { EnWorkflowStrings } from '../workflow/strings/en-workflow.strings';
import { EnVideoStrings } from '../video-streaming/strings/en-video.strings';
import { EnWebdavStrings } from '../webdav-material/strings/en-webdav.strings';
import { EnStarredMessagesStrings } from '../starred-messages/strings/en-starred-messages.strings';
import { EnExerciseStrings } from '../exercise/strings/en-exercise.strings';
import { EnScheduleMessageStrings } from '../schedule-message/strings/en-schedule-message.strings';

export const EnWebclientString = {
  MENU: EnMenuStrings,

  CHAT: EnChatStrings,
  CHATROOM: EnChatroomStrings,
  NEWS: EnNewsStrings,
  CONTACT: EnContactStrings,
  QUESTIONNAIRE: EnQuestionnaireStrings,
  JOB_REPORT: EnJobReportStrings,
  BROADCAST: EnBroadcastStrings,
  WORKFLOW: EnWorkflowStrings,
  VIDEO: EnVideoStrings,
  WEBDAV: EnWebdavStrings,
  MESSAGE_STAR: EnStarredMessagesStrings,
  EXERCISE: EnExerciseStrings,
  SCHEDULE_MESSAGE: EnScheduleMessageStrings,
};
