import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TnNotificationService } from '../tn-notification.service';

@Component({
  selector: 'tn-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {

  promptInput: string = "";
  promptNumberInput: number = 1;

  @ViewChild("promptInputArea", {static: false}) promptInputArea: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<PromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _tnNotificationService: TnNotificationService,
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize('50vw');
  }

  ngAfterViewInit() {
    this.promptInputArea?.nativeElement.focus();
  }

  cancel() {
    if (this.data.cancelCallback) {
      this.data.cancelCallback();
    }
    this.dialogRef.close();
  }

  checkInput(): boolean {
    if (this.data.isNumberInput) {
      if (this.promptNumberInput == null || this.promptNumberInput < 1) {
        this._tnNotificationService.showCustomInfoByTranslateKey(
          'WEBCLIENT.SCHEDULE_MESSAGE.ERROR_MSG.INVALID_DELAY_INPUT'
        )

        return false;
      }
    }

    return true
  }

  submit() {
    if (!this.checkInput()) {
      return;
    }

    const result = !this.data.isNumberInput ? this.promptInput : this.promptNumberInput

    this.data.submitCallback(result);
    this.dialogRef.close();
  }

}
