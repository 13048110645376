import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from "../../../account/account.service";
import { WebclientLoginResponse } from "../../../login/models/webclient-login-response";
import { UserContact } from '../../../models/user-contact';
import { TeamnoteConfigService } from "../../../../app/configs/teamnote-config.service";
import { ExerciseService } from "../../exercise/exercise.service";
import { ModuleManagerService } from '../module/module-manager.service';
import { ModuleKeyDefinition } from '../../../constants/module.constant';
import _ from 'lodash'

interface LoggedInAccountsResponse {
  [userId: string]: WebclientLoginResponse;
}

@Injectable()
export class AccountManagerService {
  isLoggedIn: boolean = false;
  userName: string = '';
  userId: string = '';
  accessToken: string = '';
  companyDomain: string = '';

  selfContactPresence: UserContact = null;
  selfContactPresence$: BehaviorSubject<UserContact> = new BehaviorSubject<UserContact>(null);

  fullLoginResponse: Partial<WebclientLoginResponse> = null;

  allLoggedInAccountRes: LoggedInAccountsResponse = {};
  selfContactPresenceByAccount: {[userId: string]: UserContact} = {};

  constructor(
    private injector: Injector,
    private _accountService: AccountService,
    private _teamnoteConfigService: TeamnoteConfigService
  ) {
    this.init();
  }

  init(): void {
    this.isLoggedIn = false;
    this.userName = '';
    this.userId = '';
    this.accessToken = '';
    this.companyDomain = '';
    this.selfContactPresence = null;
    this.selfContactPresence$ = new BehaviorSubject<UserContact>(null);
    this.fullLoginResponse = null;
  }

  onLogout(): void {
    this.init();
  }

  updateSelfContactPresence(u): void {
    this.userName = u.name;
    this.selfContactPresence = u;
    this.updateSelfContactPresenceSubject();

    /* hide code for keeping main account info in class AccountService */
    // this._accountService.setUserAccount(u.name, u.pic, u.user_id);
  }
  updateSelfContactPresenceSubject(): void {
    this.selfContactPresence$.next(this.selfContactPresence);
  }

  storeLoginResponse(resp: WebclientLoginResponse): void {
    this.accessToken = resp.session_token;
    this.companyDomain = resp.user.company_domain || '';
    this.isLoggedIn = true;
    this.fullLoginResponse = resp;

    console.log('this.companyDomain', resp, this.companyDomain);
  }

  getAccountFieldByFieldName(fieldName: string): string {
    return this.fullLoginResponse.user[fieldName] ? this.fullLoginResponse.user[fieldName] : null;
  }

  getLoginFieldByFieldName(fieldName: string): number {
    if (!this.fullLoginResponse) {
      return null;
    }
    return this.fullLoginResponse[fieldName] ? this.fullLoginResponse[fieldName] : null;
  }

  /* For multi websocket connection */
  addLoggedInAccount(resp: WebclientLoginResponse): void {
    this.allLoggedInAccountRes[resp.user.user_id] = resp;
  }
  updateLoggedInAccount(resp: WebclientLoginResponse): void {
  }

  getLoggedInAccounResByUserId(userId: string): WebclientLoginResponse {
    return this.allLoggedInAccountRes[userId] || null;
  }

  switchAccountByUserId(accUserId: string, uc: UserContact): void {
    this.initActiveAccountByUserId(accUserId, uc)
  }

  initActiveAccountByUserId(accUserId: string, uc: UserContact): void {
    console.log('initActiveAccountByUserId', accUserId);
    let targetAccRes = this.getLoggedInAccounResByUserId(accUserId);
    // reset first
    /* hide code for keeping main account info in class AccountService */
    // sync private data in _accountService
    // this._accountService.switchUserAccount(targetAccRes);

    this.userId = targetAccRes.user?.user_id || accUserId;
    this.storeLoginResponse(targetAccRes)
    console.log(uc);
    this.updateSelfContactPresence(uc)
  }

  getAccountUserFieldByFieldNameByAccount(fieldName: string, accUserId: string): any {
    const targetAccRes = this.getLoggedInAccounResByUserId(accUserId);

    return targetAccRes.user[fieldName] ? targetAccRes.user[fieldName] : null;
  }

  updateSelfContactPresence_v2(u: UserContact, accUserId: string): void {
    this.selfContactPresenceByAccount[accUserId] = u;

    if (this.userId === accUserId) {
      this.userName = u.name;
      this.selfContactPresence = u;
      this.updateSelfContactPresenceSubject();
      /* hide code for keeping main account info in class AccountService */
      // this._accountService.setUserAccount(u.name, u.pic, u.user_id);
    }
  }

  getSelfContactPresenceByAccountUserId(accUserId: string): UserContact {
    return this.selfContactPresenceByAccount[accUserId] || null;
  }

  isEnableMultiAccountLogin(): boolean {
    // const _moduleManagerService = this.injector.get<ModuleManagerService>(ModuleManagerService); // solve the circular dependency
    // if (!_moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MULTIPLE_DOMAIN_LOGIN)) {
    //   return false;
    // }
    if (!this._accountService.checkIfRootUserModuleExists(ModuleKeyDefinition.MULTIPLE_DOMAIN_LOGIN)) {
      return false;
    }

    // return _.size(this.allLoggedInAccountRes) >= 2
    // return true;
    const _exerciseService = this.injector.get<ExerciseService>(ExerciseService); // solve the circular dependency
    return _exerciseService.exercises.length > 0;
  }

  onLogoutMultiAccounts(): void {
    console.log('clear allLoggedInAccountRes',);
    this.allLoggedInAccountRes = {};
    this.selfContactPresenceByAccount = {};
  }

}
