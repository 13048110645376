
<div class="exercise-selection-modal">
  <div class="modal-header">
    <h4 class="text-color-primary">{{'LOGIN.EXERCISE.LOGIN_EXERCISE' | translate}}</h4>
  </div>
  <div class="modal-body">
    <div class="exercise-list">
      <div class="exercise-item clickable" *ngFor="let ex of exercises" (click)="switchExercise(ex)">
        <div class="text-color-primary exercise-company-name">{{ ex.company_name }}</div>
        <div class="exercise-user">
          <i class="fa fa-fw fa-user-circle-o clickable"></i>
          <span>{{ ex.name }}</span>
        </div>
      </div>
      <!-- <mat-list>
        <mat-list-item *ngFor="let ex of exercises">
          <i class="fa fa-fw fa-user-circle-o text-color-primary clickable"></i>
          <div matListItemTitle>{{ ex.company_name }}</div>
        </mat-list-item>
      </mat-list> -->
      
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button class="btn btn-default" (click)="cancel()">{{'GENERAL.CANCEL' | translate}}</button>
    <button class="btn tn-button-primary" (click)="create()">{{'GENERAL.CONFIRM' | translate}}</button> -->
  </div>
</div>
