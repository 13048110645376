import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { Chat } from '../../../models/chat';
import { Message } from '../../../models/message';
import { UserContact } from '../../../models/user-contact';
import { LoggerService } from '../../../utilities/logger/logger.service';
import { ChatMessageService } from '../../services/data/messages/chat-message/chat-message.service';
import { MessagesService } from '../../services/data/messages/messages.service';

import * as _ from 'lodash';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { TimestampService } from '../../../utilities/timestamp/timestamp.service';

import subDays from 'date-fns/sub_days';
import { AccountManagerService } from '../../services/account/account-manager.service';
import { ContactPickerService } from '../../contact-picker/contact-picker.service';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { WebclientService } from '../../webclient.service';


@Component({
  selector: 'tn-chat-search',
  templateUrl: './chat-search.component.html',
  styleUrls: ['./chat-search.component.scss']
})
export class ChatSearchComponent implements OnInit {

  @Input() chat: Chat = null;
  @Input() keyword: string = null;
  @Input() isInMultiChatRoomMode: boolean = false;
  @Output() viewSearchedMessage: EventEmitter<{ msg: Message, prevMsg: Message, chatId: string } | Message> = new EventEmitter<{ msg: Message, prevMsg: Message, chatId: string } | Message>(null);
  @Output() exitSearchModeAndGoToChat: EventEmitter<{ chat: Chat} | Chat> = new EventEmitter<{ chat: Chat} | Chat>(null);
  @ViewChild('messagesWrapper', {static: false}) messagesWrapper: ElementRef;
  heightTracker = 0;
  title = '';

  searchedMessages: Message[] = [];
  filteredSearchedMessages: Message[] = [];
  searchKeyword = '';
  timestampPointer: any = null;
  lastSearchPointer: number = -1;
  isLoading = false;

  earliestTime: any = null;
  filteredUser: UserContact[] = []
  isBreakSearchingLoop: boolean = false;

  scrollItems: any;
  private scrollEvent$;

  @ViewChild('scrollWrapper', {static: false}) scrollWrapper: ElementRef;
  isEnableImportantUsers: boolean = false;

  scrollToBottom = true;
  @Input() targetMessage: Message;

  constructor(
    private _loggerService: LoggerService,
    private _chatMessageService: ChatMessageService,
    private _messageService: MessagesService,
    private _timestampService: TimestampService,
    private _accountManagerService: AccountManagerService,
    private _contactPickerService: ContactPickerService,
    private _userContactService: UserContactService,
    private _webclientService: WebclientService,
    private el: ElementRef,
  ) {
  }
  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'chrome');
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'firefox');
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'ie');
  }

  mouseWheelFunc(event, from): void {
    /* uncomment when using common message display list (not virtualScroller) */
    // const element = this.scrollWrapper.nativeElement;
    // this.scrollToBottom = (element.scrollHeight - element.scrollTop) === element.clientHeight;
    // // If scrolled to bottom, and is in searched message view mode, load history via API
    // if (this.scrollToBottom && event.wheelDelta < 0 && !this.isLoading) {
      
    //   this.searchChat(this.searchKeyword);
    // }
  }
  ngOnInit() {
    this.isEnableImportantUsers = this._webclientService.checkIfEnableImportantUsers();
    this.earliestTime = this.chat ? this.chat.create_date : this._accountManagerService.fullLoginResponse.user.create_date
    this.resetTimestampPointer();
  }
  ngOnChanges() {
  }
  resetTimestampPointer(): void {
    this.timestampPointer = this._timestampService.getNowSecondString();
  }
  chatRoomBack(): void {
    this._loggerService.debug('Chat room search back');
    this._loggerService.debug('Leaving search');
    if (!this.isInMultiChatRoomMode) {
      this.exitSearchModeAndGoToChat.emit(this.chat);
    } else {
      this.exitSearchModeAndGoToChat.emit({chat: this.chat});
    }
  }
  resetMsgSearch(): void {
    this.lastSearchPointer = -1;
    this.searchedMessages = [];
    this.filteredSearchedMessages = [];
    this.resetTimestampPointer();
  }
  searchChat(keyword: string): void {
    if (this.isBreakSearchingLoop) {
      // user already go to the target messaged when message searching is not completed
      return;
    }

    const originalKeyword = this.searchKeyword;
    this.searchKeyword = keyword;

    if (this.isLoading) {
      return;
    }

    if (!keyword || keyword.length === 0) {
      this.resetMsgSearch();
      return;
    }

    // a different keyword
    if (this.searchKeyword !== originalKeyword) {
      this.resetMsgSearch();
    }

    // const earliestTime = this.chat ? this.chat.create_date : this._accountManagerService.fullLoginResponse.user.create_date;
    // console.log('this.timestampPointer', this._timestampService.getMessageDateStringInChatrooom(this.timestampPointer))
    if (this.timestampPointer && !this._timestampService.checkIfTimeCorrectOrder(this.earliestTime, this.timestampPointer)) {
      // timestamp pointer is earlier than chat create date
      return;
    }

    const datePointer = this._timestampService.secondToDateObj(this.timestampPointer);
    const daysBefore = subDays(datePointer, 90);
    const daysBeforeTimestamp = this._timestampService.dateObjToSecondString(daysBefore);

    // console.error(datePointer, daysBeforeTimestamp);

    this.isLoading = true;
    this._chatMessageService.searchMessageByKeywordApi(
      this.searchKeyword,
      (msgs: Message[]) => {
        let sortedSearchedMsgs = this._messageService.filterAndSortSearchedMessages(msgs, this.searchKeyword)
        // console.log('sortedSearchedMsgs', _.cloneDeep(sortedSearchedMsgs))

        const all = _.union(this.searchedMessages, sortedSearchedMsgs);
        this.searchedMessages = _.uniq(all);

        /* 
          filter searched message by given users' id
          1. if no user selected, union previous searchedMessages with sortedSearchedMsgs
          2. If any user is selected, union previous searchedMessages with sortedSearchedMsgs that have been filtered by users
        */
        let tempFilteredMsgs = this.searchedMessages.slice()
        if (this.filteredUser.length) {
          let filteredUserIds: string[] = []
          filteredUserIds = _.map(this.filteredUser, 'uid')
          tempFilteredMsgs = _.filter(tempFilteredMsgs, (m) => _.includes(filteredUserIds, m.sent_by))
        }

        this.filteredSearchedMessages = _.union(this.filteredSearchedMessages, tempFilteredMsgs);

        this.isLoading = false;

        // console.log('filteredSearchedMessages', this.filteredSearchedMessages)
        /* 
          will update timestamp pointer to 90 days before when hit the follow conditions:
          1. no messages (server msgs) are returned within the timestamp;
          2. no messages that has been filtered (filter real chat msg) and sorted are returned within the timestamp;
        */
        if (msgs.length === 0 || sortedSearchedMsgs.length === 0) {
          // console.warn('no results');
          this.timestampPointer = daysBeforeTimestamp;
          this.searchChat(this.searchKeyword);
        } else if (msgs.length !== 0) {
          if (_.last(this.searchedMessages)) {
            this.timestampPointer = _.last(this.searchedMessages).timestamp;
          }

          if (this.filteredUser.length) {
            // may use ？ this.scrollToBottom = (element.scrollHeight - element.scrollTop) === element.clientHeight;
            if (this.filteredSearchedMessages.length > 7) {
              // when no of view port Items (messages) of virtualScroller large than 7, get search chat by wheel
              return;
            }

            // recursive to get message filtered by users until timestampPointer hit the creation time of chat room
            if (this.timestampPointer && !this._timestampService.checkIfTimeCorrectOrder(this.earliestTime, this.timestampPointer)) {
              return;
            }

            this.searchChat(this.searchKeyword);
          }
        }
       
      },
      (err) => {
        this.isLoading = false;
      },
      this.chat ? this.chat.chat_id : null,
      this.timestampPointer,
      daysBeforeTimestamp
    );
  }

  filterSearchedMsgs(contacts: any): void {
    this.filteredUser = _.cloneDeep(contacts)
    // this.resetMsgSearch();
    /* load filtered cached searchedMessages data */
    if (this.filteredUser.length) {
      this.filteredSearchedMessages = _.filter(this.searchedMessages, (fsm) => _.includes(_.map(this.filteredUser, 'uid'), fsm.sent_by));
    } else {
      this.filteredSearchedMessages = this.searchedMessages.slice()
    }

    this.searchChat(this.searchKeyword);
  }

  // loadMore(event: IPageInfo): void {
  loadMore(event: IPageInfo): void {
    if (this.isLoading) {
      return;
    }
    // console.warn(event);
    if (event.endIndex === -1) {
      return;
    }

    if (event.endIndex !== this.filteredSearchedMessages.length - 1) {
      return;
    }
    // if (this.lastSearchPointer != -1 && this.lastSearchPointer == event.endIndex) {
    //   return;
    // }

    this.lastSearchPointer = event.endIndex;
    // console.warn('search!');
    this.searchChat(this.searchKeyword);
  }

  messageOnClick(message: Message): void {
    this.isBreakSearchingLoop = true;

    if (!this.isInMultiChatRoomMode) {
      this.viewSearchedMessage.emit(message);
    } else {
      this.viewSearchedMessage.emit({msg: message, prevMsg: null, chatId: this.chat.chat_id});
    }
  }
}
