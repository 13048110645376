import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, ViewChildren, QueryList} from '@angular/core';
import { ChatService } from '../services/data/chat/chat.service';

import { Chat } from '../../models/chat';
import { LoggerService } from '../../utilities/logger/logger.service';
import { PasswordReloginService } from '../../utilities/password-relogin/password-relogin.service';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { Message } from '../../models/message';
import {ChatConstant} from '../../constants/chat.constant';
import {TnNotificationService} from '../../utilities/tn-notification/tn-notification.service';
import { Subscription } from 'rxjs';
import { WebclientService } from '../webclient.service';
import { CdkDragPlaceholder, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ContactPickerService } from '../contact-picker/contact-picker.service';
import { TnDialogService } from '../../utilities/tn-dialog/tn-dialog.service';
import { DateTimeSelectorService } from '../../utilities/date-time-selector/date-time-selector.service';
import { ChatListComponent } from './chat-list/chat-list.component';
import * as _ from 'lodash';

import { trigger, style, animate, keyframes, group, query, transition, animation, useAnimation } from '@angular/animations'
import { ChatRoomService } from './chat-room/chat-room.service';
import { MultiChatRoomService } from './multi-chat-room.service';
import { ChatMessageService } from '../services/data/messages/chat-message/chat-message.service';
import { UserContactService } from '../services/data/user-contact/user-contact.service';
import { UserContact } from '../../models/user-contact';
import { TimestampService } from '../../utilities/timestamp/timestamp.service';
import { ChatRoomComponent } from './chat-room/chat-room.component';

const FlexAnimation = trigger('myAnimationTrigger', [
  transition(':enter', [
    style({ opacity: 0 }), // { width: 0, opacity: 0 }
    animate(
      '.15s linear',
      style({
        // width: '100%',
        opacity: 1
      })
    )
  ]),
  transition(':leave', [
    style({ opacity: 1 }), // { width: '100%', opacity: 1 }
    animate(
      '.15s linear',
      style({
        // width: 0,
        opacity: 0
      })
    )
  ])
])
interface targetMessageByChat {
  [chatId: string]: Message;
}

@Component({
  selector: 'tn-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  // animations: [FlexAnimation]
})
export class ChatComponent implements OnInit {
  activeChat: Chat;
  targetMessage: Message = null;
  prevMessage: Message = null;

  isSearching: boolean = false;
  searchingChat: Chat = null;
  searchingKeyword: string = null;

  isPageVisible: boolean = true;
  reconnectedCount: number = 0;
  private activeChatSub: Subscription;
  private pageVisibleSub: Subscription;
  private reconnectCountSub: Subscription;
  private targetMessageSub: Subscription;

  isInMultiChatRoomMode: Boolean = false;
  chatroomModeToggleEvent = new EventEmitter();
  private activeMultiChatRoomsPanelSub: Subscription;

  activePanel: any = null;
  multiChatrooms: Chat[] = [];
  // extraChatrooms: Chat[] = [];
  isPanelNameEditing: boolean = false;
  tempPanelName: string = '';
  targetMessageGroupByChat: targetMessageByChat = {};
  prevMessageGroupByChat: targetMessageByChat = {};

  multiChatFilterOptions: any = {};
  prevFilterSettings: any = null;
  currFilterSettings: any = null;

  isMultiChatroomSearching: {[chatId: string]: boolean} = {};
  searchingMultiChat: {[chatId: string]: Chat} = {};
  searchingKeywordGroupByChat: {[chatId: string]: string} = {};

  enableAutoScrollByChat: {[chatId: string]: any} = {};

  @ViewChild('panelNameInput', {static: false}) panelNameInput: ElementRef;

  @ViewChildren('multiChatroomRef') multiChatroomRef: QueryList<ChatRoomComponent>;
  
  constructor(
    private _chatService: ChatService,
    private _loggerService: LoggerService,
    private _passwordReloginService: PasswordReloginService,
    private _tnNotificationService: TnNotificationService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _webclientService: WebclientService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _contactPickerService: ContactPickerService,
    private _tnDialogService: TnDialogService,
    private _chatRoomService: ChatRoomService,
    private _multiChatRoomService: MultiChatRoomService,
    private _chatMessageService: ChatMessageService,
    private _userContactService: UserContactService,
    private _dateTimeSelectorService: DateTimeSelectorService,
    private _timestampService: TimestampService,
  ) { 
    this.pageVisibleSub = this._webclientService.isPageVisible$.subscribe(visible => {
      this._loggerService.debug("Is Page Visible?: [[[ {visibility} ]]]".replace('{visibility}', visible ? 'Yes' : 'No'));
      // console.log('isPageVisible in chat.components', visible);
      this.isPageVisible = visible

      if (visible) {
        // console.log('Change Detector just reattached ---> [ restart to detect rendering ]\n------------------------------------------------------------------------')
        this._loggerService.debug('Change Detector just reattached --- restart to detect rendering')
        this._changeDetectorRef.reattach()
      } else {
        // console.log('Change Detector just detached ---> [ stop to detect rendering ]\n------------------------------------------------------------------------')
        this._loggerService.debug('Change Detector just detached --- stop to detect rendering')
        this._changeDetectorRef.detach()
      }
    });

    this.reconnectCountSub = this._webclientService.reconnectionStopCount$.subscribe(count => {
      // console.log('reconnectCountSub in chat.components', count);
      this.reconnectedCount = count
    });

    this.targetMessageSub = this._chatService.targetMessage$.subscribe(obj => {
      if (obj?.msg) {
        if (!this.isInMultiChatRoomMode) {
          // message is passed from starred messages page
          // this.messageCellOnClick(message);
          console.log('obj', obj);
          this.enterSearchModeAndGoToChat({msg: obj.msg, prevMsg: null})
        } else {
          console.log('starred msg enterSearchModeWithChatIdAndGoToChat');
          this.enterSearchModeWithChatIdAndGoToChat({msg: obj.msg, prevMsg: null, chatId: obj.chatId})
        }
      } else {
        this.clearTargetMessageProps()
      }
    });

    this.activeMultiChatRoomsPanelSub = this._multiChatRoomService.activeMultiChatRoomsPanel$.subscribe(panel => {
      console.log('initActivePanel');
      this.initActivePanel(panel);
    });
  }

  ngOnInit() {
    this._loggerService.debug("Chat ngOnInit");

    // try to get activeChat from chatService (enter from clicking contact)
    this.activeChatSub = this._chatService.activeChat$.subscribe(chat => {
      this.activeChat = chat;
    });
    this.goToActiveChat();
  }
  ngOnDestroy() {
    this.chatCellOnClick(null);

    this.activeChatSub.unsubscribe();
    this.pageVisibleSub.unsubscribe();
    this.reconnectCountSub.unsubscribe();

    this.clearTargetMessageProps()
    this._chatService.updateTargetMessageSubject(null)
    this.targetMessageSub.unsubscribe();

    this.exitMultiChatRoomMode(true);
    this.resetAutoScrollByChat();
    this.clearSearchModeGroupByChat();
    this.clearTargetMessageGroupByChat();
    this.activeMultiChatRoomsPanelSub.unsubscribe();
  }
  goToActiveChat() {
    this.activeChat = this._chatService.activeChat;
  }

  /**
   * Handle clicked on Chat cell in chat list
   *
   * @param {Chat} chat - target chat
   * @memberof ChatComponent
   */
  chatCellOnClick(chat: Chat): void {
    if (chat) {
      this._loggerService.log("Clicked on chat cell in chat list: " + chat.chat_id + chat.name);
    }
    this.targetMessage = null;

    let targetChat = chat;
    
    if (chat) {
      targetChat = this._chatService.getChatByChatId(chat.chat_id)
    }

    this.goToChat(targetChat);
    // this.goToChat(chat);
  }

  /**
   * Navigate to chat
   *
   * @param {Chat} chat - target chat
   * @memberof ChatComponent
   */
  goToChat(chat: Chat): void {
    this.activeChat = chat;
    this._chatService.updateActiveChatSubject(chat);
    this.clearChatSearchMode();

    // Update route meta data
    if (chat) {
      this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_META_DATA, chat.chat_id);
    }
  }

  /**
   * Handle clicked on searched message cell in chat list
   *
   * @param {Message} message - target searched message
   * @memberof ChatComponent
   */
  messageCellOnClick(message: Message, chatId?: string): void {
    if (!this.isInMultiChatRoomMode) {
      this.targetMessage = message;

      // Find corresponding chat
      let chat = this._chatService.getChatByChatId(message.chat_ids[0]);
      this.goToChat(chat);
    } else {
      this.targetMessageGroupByChat[chatId] = message;
      console.log('this.targetMessageGroupByChat', this.targetMessageGroupByChat);
      this.clearChatSearchModeInMultiChatMode(chatId);
    }

    // // Find corresponding chat
    // let chat = this._chatService.getChatByChatId(message.chat_ids[0]);
    // this.goToChat(chat);
  }

  /**
   * Handle exit search mode from chat room
   *
   * @param {Chat} chat - target chat
   * @memberof ChatComponent
   */
  exitSearchModeAndGoToChat(event: {chat: Chat, isQuickTravel: boolean, isNeedClearPrevMessageFirst?: boolean}): void {
    console.log(event);
    if (!this.isInMultiChatRoomMode) {
      this.targetMessage = null;
    } else {
      if (event.chat.chat_id in this.targetMessageGroupByChat) {
        console.log('set this.targetMessageGroupByChat[chat_id] to null');
        this.targetMessageGroupByChat[event.chat.chat_id] = null;
      }
    }
    
    if (event && event.isNeedClearPrevMessageFirst) {
      if (!this.isInMultiChatRoomMode) {
        this.prevMessage = null;
      } else {
        if (event.chat.chat_id in this.prevMessageGroupByChat) {
          console.log('set this.prevMessageGroupByChat[chat_id] to null');
          this.prevMessageGroupByChat[event.chat.chat_id] = null;
        }
      }
    }

    // console.log('isQuickTravel', this.prevMessage, event.isQuickTravel);
    if (this.prevMessage && event && event.isQuickTravel) {
      this.enterSearchModeAndGoToChat({msg: this.prevMessage, prevMsg: null});
      this.prevMessage = null;
    } else {
      if (event && event.chat) {
        if (!this.isInMultiChatRoomMode) {
          this.goToChat(event.chat);
        } 
      }
    }

    if (!this.isInMultiChatRoomMode) {
      this.clearChatSearchMode();
    } else {
      if (event && event.chat) {
        this.clearChatSearchModeInMultiChatMode(event.chat.chat_id);
      }
    }
  }

  enterSearchModeAndGoToChat(event: {msg: Message, prevMsg: Message}): void {
    this.prevMessage = event.prevMsg;
    this.messageCellOnClick(event.msg);
  }

  // Chat search
  enterChatSearchMode(chatKeyword: {chat: Chat, keyword: string}): void {
    if (this.isInMultiChatRoomMode) {
      console.log('chatKeyword', chatKeyword);
      const { chat, keyword } = chatKeyword;
      if (chat) {
        this.searchingKeywordGroupByChat[chatKeyword.chat.chat_id] = keyword;
        this.isMultiChatroomSearching[chatKeyword.chat.chat_id] = true;
      }
      return;
    }

    this.searchingChat = chatKeyword.chat;
    this.searchingKeyword = chatKeyword.keyword;
    this.isSearching = true;
  }
  clearChatSearchMode(): void {
    this.searchingChat = null;
    this.searchingKeyword = null;
    this.isSearching = false;
  }
  clearTargetMessageProps(): void {
    this.targetMessage = null;
    // this.clearTargetMessageGroupByChat();
    this.clearChatSearchMode();
  }
  viewSearchedMessage(message: Message): void {
    this.messageCellOnClick(message);
  }

  searchMessageOnClick(option: {isStart: boolean, isSeeMore: boolean, searchKeyword: string}): void {
    if (option) {
      if (option.isSeeMore) {
        this.enterChatSearchMode({chat: null, keyword: option.searchKeyword});
        return
      }
    } 

    this.enterChatSearchMode({chat: null, keyword: ""});
  }

  switchChatRoomMode(isMultiChatMode: boolean): void {
    this.isInMultiChatRoomMode = isMultiChatMode;
    setTimeout(() => {
      this.chatroomModeToggleEvent.emit(isMultiChatMode)
    }, 0);
  }

  toggleAutoScroll(event, chat: any) {
    if (this.enableAutoScrollByChat[chat.chat_id]) {
      this.enableAutoScrollByChat[chat.chat_id].enabled = !this.enableAutoScrollByChat[chat.chat_id]?.enabled
    } 
    else {  
      this.enableAutoScrollByChat[chat.chat_id] = { enabled: true }
    }
  }

  clearTargetAutoScrollByChatId(chatId: string) {
    if (this.enableAutoScrollByChat[chatId]) {
      delete this.enableAutoScrollByChat[chatId];
    }
  }

  onToggleMsgSendBar(event, chatroom): void {
    // console.log('this.multiChatroomRef.toArray()', this.multiChatroomRef.toArray());
    // show / hide send bar in parent component
    const targetChatroom = _.find(this.multiChatroomRef.toArray(), (ref) => ref?.chat.chat_id === chatroom.chat_id)

    if (targetChatroom) {
      // targetChatroom.showSendBar && targetChatroom.showSendBar();
      targetChatroom.toggleSendBar && targetChatroom.toggleSendBar();
    }
  }

  dropChatroom(event: CdkDragDrop<string[]>): void  {
    moveItemInArray(this.multiChatrooms, event.previousIndex, event.currentIndex);
    console.log(this.multiChatrooms);
    // this.scrollToPrevPos();
  }

  onDragStart(event: any, idx: number): void {
    // this.scrollToPrevPos();
    const previewEle = document.querySelector<HTMLElement>(
      '.cdk-drag-preview .messages-wrapper'
    );
    const placeholderEle = document.querySelector<HTMLElement>(
      '.cdk-drag-placeholder .messages-wrapper'
    );

    const draggingChat = this.multiChatrooms[idx];

    if (draggingChat) {
      if (placeholderEle) {
        placeholderEle.scrollTop = draggingChat.scrollTop;
      }

      if (previewEle) {
        previewEle.scrollTop = draggingChat.scrollTop;
      }
    }
  
  }
  cdkDragDropped(event): void {
    // console.log('cdkDragDropped', event);
    // this._changeDetectorRef.detach()
  }

  cdkDragReleased(event): void {
    // console.log('cdkDragReleased', event);
    // this._changeDetectorRef.detach()
  }

  onDragEnd(event): void {
    console.log('onDragEnd', event);
    this.scrollToPrevPos();
    // this._changeDetectorRef.reattach()
  }
 

  test(): void {
    // console.log('this.multiChatrooms', this.multiChatrooms);
    // console.log(this._chatMessageService.activeChatRoomSearchedMessages);
    // this._chatService.testInjector();
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex,
  //     );
  //   }
  // }

  scrollToPrevPos(): void {
    this._chatRoomService.tryTriggerScrollSubjects(_.map(this.multiChatrooms, 'chat_id'));
  }

  exitMultiChatRoomMode(shouldDiscard: boolean = false): void {
    if (!shouldDiscard) {
      this.storeCurrentPanel();
    }

    // this.clearSearchModeGroupByChat();
    // this.clearTargetMessageGroupByChat();
    // this._chatService.updateActiveChatSubject(null);
    // this._multiChatRoomService.updateActiveMultiChatRoomsPanelSubject(null);
    
  }


  selectChatRoom(event: any, chat: any): void {
    this._tnDialogService.openTnDialog(
      ChatListComponent,
      {
        isInMultiChatRoomMode: true,
        activeChatroomsChatId: _.map(this.multiChatrooms, 'chat_id'),
        callback: (targetChat: Chat) => {
          if (chat) {
            const currChatIndex = _.findIndex(this.multiChatrooms, { chat_id: chat.chat_id })
            
            this.resetChatroomScrollBarPos(currChatIndex);

            const deleteChat = this.multiChatrooms[currChatIndex];
            this.targetMessageGroupByChat[deleteChat.chat_id] = null;
            this.multiChatrooms.splice(currChatIndex, 1, targetChat)

            this.clearTargetAutoScrollByChatId(deleteChat.chat_id)
            this.clearChatSearchModeInMultiChatMode(deleteChat.chat_id);

            // this.updateFilter();
          } else {
            this.multiChatrooms.push(targetChat);
          }

          this.updateFilter();

          this._tnDialogService.closeAllDialogs();
        }
      },
      {
        width: '50vw',
        minWidth: '450px',
        maxWidth: '550px',
        height: '80vh',
        panelClass: 'dialog-padding',
      }
    );
  }

  removeChatroom(event: any, index: number): void {
    this.resetChatroomScrollBarPos(index)
    const deleteChat = this.multiChatrooms[index];
    this.targetMessageGroupByChat[deleteChat.chat_id] = null;
    this.multiChatrooms.splice(index, 1);
    
    this.clearTargetAutoScrollByChatId(deleteChat.chat_id)
    this.clearChatSearchModeInMultiChatMode(deleteChat.chat_id);

    this.updateFilter();
  }

  resetChatroomScrollBarPos(index: number): void {
    if (this.multiChatrooms[index]) {
      if ('scrollTop' in this.multiChatrooms[index]) {
        delete this.multiChatrooms[index]['scrollTop'];
      }
    }
  }

  removeCurrentPanel(): void {
    this._multiChatRoomService.removeMultiChatroomPanel(this.activePanel.panelId);
  }

  initActivePanel(panel: any): void {
    if (panel) {
      this.resetAutoScrollByChat();
      this.clearSearchModeGroupByChat();
      this.clearTargetMessageGroupByChat(); // exit search view mode for all chats
      this.updatePrevAndCurrFilterSettings(true);
      this.initMultiChatFilter();
      this.initMultiChatroomsData(panel);
      this.initAutoScrollForAllChats();

      this.switchChatRoomMode(true);
    } else {
      this.updatePrevAndCurrFilterSettings(true);
      this.initMultiChatroomsData();
      
      this.switchChatRoomMode(false);
    }
  }

  initMultiChatroomsData(panel?: any): void {
    if (panel) {
      console.log('activePanel', panel);
      this.tempPanelName = panel.name;
      this.activePanel = { 
        panelId: panel.panelId, 
        name: panel.name, 
      }
      this.multiChatrooms = panel.multiChatrooms ? [...panel.multiChatrooms] : [];
      // this.initChatLoadingHistoryFlag();
    } else {
      this.tempPanelName = '';
      this.activePanel = null;
      this.multiChatrooms = [];
    }
  }

  initChatLoadingHistoryFlag(): void {
    _.forEach(this.multiChatrooms, (chat) => {
      chat.isLoadingHistory = false;
    })
  }

  // isSomeChatsLoading = false
  // ngAfterContentInit() {
  //   this.isSomeChatsLoading = _.some(this.multiChatrooms, (chat) => {
  //     return chat.isLoadingHistory;
  //   })
  //   console.log('isSomeChatsLoading', this.isSomeChatsLoading);
  // }


  // get isSomeChatsLoading() {
  //   if (this.multiChatrooms.length === 0) {
  //     return false;
  //   }

  //   return _.some(this.multiChatrooms, (chat) => {
  //     return chat.isLoadingHistory;
  //   })
  // }

  updateChatLoadingHistoryState(event: {chatId: string, loadingState: boolean}): void {
    let chat = _.find(this.multiChatrooms, { chat_id: event.chatId })
    if (chat) {
      if (chat.isLoadingHistory != event.loadingState) {
        chat.isLoadingHistory = event.loadingState;
      }
    }
  }

  updateChatDraggableState(event: {chatId: string, disabledDrag: boolean}): void {
    let chat = _.find(this.multiChatrooms, { chat_id: event.chatId })
    if (chat) {
      chat.isDisabledDrag = event.disabledDrag;
    }
  }

  storeCurrentPanel(): void {
    if (!this.activePanel) {
      return;
    }

    this._multiChatRoomService.storeActiveMultiChatroomPanel(this.activePanel.panelId, this.activePanel.name, this.multiChatrooms);
  }

  startNameEditing(): void {
    this.isPanelNameEditing = true;
    setTimeout(() => {
      if (this.panelNameInput) {
        this.panelNameInput.nativeElement.focus();
      }
    }, 0);
  }
  endNameEditing(): void {
    this.isPanelNameEditing = false;
  }
  savePanelName(): void {
    if (this.tempPanelName.trim().length === 0) {
      this.discardPanelName();
      return;
    }

    this.activePanel.name = this.tempPanelName;
    this.endNameEditing();
  }
  discardPanelName(): void {
    this.tempPanelName = this.activePanel.name;
    this.endNameEditing();
  }

  clearTargetMessageGroupByChat(): void {
    this.targetMessageGroupByChat = {};
    this.prevMessageGroupByChat = {};
  }

  checkIfSomeChatIsInSearchMode(): boolean {
    return _.some(this.multiChatrooms, (chat) => {
      return this.isMultiChatroomSearching[chat.chat_id];
    })
  }

  resetAutoScrollByChat(): void {
    this.enableAutoScrollByChat = {};
  }

  initAutoScrollForAllChats(): void {
    const chatIds = _.map(this.multiChatrooms, 'chat_id')

    _.each(chatIds, (chat_id) => {
      this.enableAutoScrollByChat[chat_id] = {
        enabled: false
      }
    })
  }

  clearSearchModeGroupByChat(): void {
    this.isMultiChatroomSearching = {};
    this.searchingMultiChat = {};
    this.searchingKeywordGroupByChat = {};
  }

  clearChatSearchModeInMultiChatMode(chatId: string): void {
    this.isMultiChatroomSearching[chatId] = false;
    this.searchingMultiChat[chatId] = null;
    this.searchingKeywordGroupByChat[chatId] = null;
  }


  enterSearchModeWithChatIdAndGoToChat(event: {msg: Message, prevMsg: Message, chatId: string}): void {
    console.log('enterSearchModeWithChatIdAndGoToChat', event);
    this.prevMessageGroupByChat[event.chatId] = event.prevMsg;

    this.messageCellOnClick(event.msg, event.chatId);
  }

  initMultiChatFilter(): void {
    this.multiChatFilterOptions = {
      selectedChatMembers: [],
      selectedDateRange: null
    }

    this.updatePrevAndCurrFilterSettings();
  }

  getAllMembersUnderMultiChat(): string[] {
    let allMembers = [];

    _.each(this.multiChatrooms, (chat) => {
      if (_.isEmpty(chat.members)) {
        return; // continue
      }

      allMembers = _.union(allMembers, chat.members)
    })

    console.log('allMembers', allMembers);

    return allMembers
  }

  onFilterChanged(): void {
    
  }

  onOpenMembersFilter(): void {
    const allMembersIds = this.getAllMembersUnderMultiChat()
    let groupChatMembersContacts = _.map(allMembersIds, (m) => {
      return this._userContactService.getUserContactByUserId(m);
    });

    this._contactPickerService.openContactPicker(
      'WEBCLIENT.CHAT.SEARCH_UNFILTERED',
      false,
      false,
      true,
      (contacts: UserContact[]) => {
        
        // this.multiChatFilterOptions.selectedChatMembers = _.map(contacts, 'user_id');
        // this.multiChatFilterOptions = Object.assign({}, {
        //   selectedChatMembers: _.cloneDeep(contacts)
        // })
        if (this.checkIfSomeChatIsInSearchMode()) {
          this.clearSearchModeGroupByChat();
        } 

        this.multiChatFilterOptions = Object.assign({}, {
          selectedChatMembers: _.cloneDeep(contacts),
          selectedDateRange: _.cloneDeep(this.multiChatFilterOptions.selectedDateRange)
        })

        this.updatePrevAndCurrFilterSettings();

        // this.onFilterChanged.emit(contacts)
        this.checkIfNeedChatExitSearchingMode();
      },
      true,
      null,
      groupChatMembersContacts,
      false,
      this.multiChatFilterOptions.selectedChatMembers,
      true, // filter mode
      true // allow select all
    )
  }

  onOpenDateTimeSelection(): void {
    this._dateTimeSelectorService.openDateTimeSelectionDialog(
      'WEBCLIENT.CHAT.CHAT_GROUP.DATE_TIME_RANGE',
      this.multiChatFilterOptions?.selectedDateRange || null,
      (dateTimeSelection) => {
        console.log('dateTimeSelection', dateTimeSelection);
        
        if (this.checkIfSomeChatIsInSearchMode()) {
          this.clearSearchModeGroupByChat();
        } 

        if (_.isEqual(dateTimeSelection, this.multiChatFilterOptions?.selectedDateRange)) {
          console.log('selected date time range no changes');
          return;
        }

        // this.multiChatFilterOptions = {
        //   selectedChatMembers: [],
        //   selectedDateRange: dateTimeSelection
        // }

        this.multiChatFilterOptions = Object.assign({}, {
          selectedChatMembers: _.cloneDeep(this.multiChatFilterOptions.selectedChatMembers),
          selectedDateRange: dateTimeSelection
        })

        // this.multiChatFilterOptions.selectedDateRange = dateTimeSelection

        console.log('this.multiChatFilterOptions', this.multiChatFilterOptions);

        // this.updateFilter();

        this.updatePrevAndCurrFilterSettings();

        this.checkIfNeedChatExitSearchingMode();
      }
    )
  }

  dateTimeRangeDisplay(): string | boolean {
    if (_.isEmpty(this.multiChatFilterOptions.selectedDateRange)) {
      return false;
    }

    const { from, to } = this.multiChatFilterOptions.selectedDateRange;

    return `${this._timestampService.getDateTimeDisplay(from, true)} to ${this._timestampService.getDateTimeDisplay(to, true)}`
  }

  checkIfNeedChatExitSearchingMode(): void {
    console.log('checkIfNeedChatExitSearchingMode', this.multiChatFilterOptions);
    const { selectedChatMembers: prev_members, selectedDateRange: prev_date } = this.prevFilterSettings;
    const { selectedChatMembers: curr_members, selectedDateRange: curr_date } = this.currFilterSettings;
    console.log(prev_members, prev_date);
    console.log(curr_members, curr_date);


    _.each(this.multiChatrooms, (chat) => {
      const isFilteredMemberInChatWithTargetMessage = _.intersection(chat.members, _.map(this.multiChatFilterOptions.selectedChatMembers, 'user_id')).length
      const isDateTimeFiltering = !_.isEmpty(this.multiChatFilterOptions.selectedDateRange);

      console.log('isFilteredMemberInChatWithTargetMessage', isFilteredMemberInChatWithTargetMessage);
      console.log('isDateTimeFiltering', isDateTimeFiltering);
      if (isFilteredMemberInChatWithTargetMessage || isDateTimeFiltering) {
        if (isDateTimeFiltering) {
          if (!isFilteredMemberInChatWithTargetMessage) {
            // should check if need to entering filtering mode or not

            /* 
              isDateTimeFiltering: true
              isFilteredMemberInChatWithTargetMessage: false
            */

            if (_.isEmpty(curr_members)) {
              console.log(1);
              /* 
                prev_members: []/[xxx], prev_date: {xxx}
                curr_members: [], curr_date: {yyy}
              */
              this.generateFilterMsgPointAndEnterFilteringMode(chat, isDateTimeFiltering);
            } else if (_.isEqual(prev_members, curr_members)) {
              console.log(2);
              /* 
                prev_members: [xxx], prev_date: any_value
                curr_members: [xxx], curr_date: any_value
              */
              if (chat.chat_id in this.targetMessageGroupByChat) {
                this.targetMessageGroupByChat[chat.chat_id] = null;
              }
            } else if (_.isEqual(prev_date, curr_date)) {
              console.log(3);
              /* 
                prev_members: []/[xxx], prev_date: {xxx}
                curr_members: [xxx], curr_date: {xxx}
              */
              if (chat.chat_id in this.targetMessageGroupByChat) {
                this.targetMessageGroupByChat[chat.chat_id] = null;
              }
            } 
          } else {
            /* 
              isDateTimeFiltering: true && isFilteredMemberInChatWithTargetMessage: true
            */
            console.log(4);
            this.generateFilterMsgPointAndEnterFilteringMode(chat, isDateTimeFiltering);
          }
        } else {
          console.log(5);
          // isFilteredMemberInChatWithTargetMessage === true && isDateTimeFiltering === false
          this.generateFilterMsgPointAndEnterFilteringMode(chat, isDateTimeFiltering);
        }
      } else {
        // isFilteredMemberInChatWithTargetMessage === false && isDateTimeFiltering === false
        console.log(6);
        if (chat.chat_id in this.targetMessageGroupByChat) {
          this.targetMessageGroupByChat[chat.chat_id] = null;
        }
      }
    })

    // check if should all chat exit the searching mode
    // if (this.multiChatFilterOptions.selectedChatMembers.length === 0) {
      // console.log('check if should all chat exit the searching mode');
      // this.clearTargetMessageGroupByChat();
    // }
  }

  updateFilter(deleteChat?: Chat): void {
    const updatedAllIds = this.getAllMembersUnderMultiChat()

    const prevSelected = _.map(this.multiChatFilterOptions.selectedChatMembers, 'user_id')
    let updatedSelected = _.intersection(prevSelected, updatedAllIds)
    updatedSelected = _.map(updatedSelected, (m) => {
      return this._userContactService.getUserContactByUserId(m);
    });

    this.multiChatFilterOptions = Object.assign({}, {
      selectedChatMembers: updatedSelected,
      selectedDateRange: _.cloneDeep(this.multiChatFilterOptions.selectedDateRange)
    })

    console.log('updateFilter this.multiChatFilterOptions', _.cloneDeep(this.multiChatFilterOptions));

    this.updatePrevAndCurrFilterSettings();

    this.checkIfNeedChatExitSearchingMode();
  }

  updatePrevAndCurrFilterSettings(isReset?: boolean): void {
    if (!isReset) {
      this.prevFilterSettings = _.cloneDeep(this.currFilterSettings);
      this.currFilterSettings = _.cloneDeep(this.multiChatFilterOptions);
    } else {
      this.prevFilterSettings = null;
      this.currFilterSettings = null;
    }
  }

  generateFilterMsgPointAndEnterFilteringMode(chat: Chat, isDateTimeFiltering: boolean): void {
    // create a fake message with timestamp for loadHistoryApi but without message_id
    let newMessage = new Message;
    newMessage.timestamp = (_.now() / 1000).toString();
    // newMessage.isFilterPointer = true;


    const filterMsgPointer = chat.cacheLastMessage || this._chatMessageService.getLastestChatMessagesUnderChatByChatIdAndSize(chat.chat_id, 1)[0]
    //new latest message in the target chat with isFilterPointer flag

    console.log('filterMsgPointer', filterMsgPointer);

    newMessage = {
      ...filterMsgPointer,
      isFilterPointer: true
    }

   if (isDateTimeFiltering) {
      const { from, to } = this.multiChatFilterOptions.selectedDateRange;
      console.log('to', to);
      if (to < newMessage.timestamp) {
        newMessage.timestamp = to;
      }

      newMessage.timestamp_from = from;
    }
    
    console.log('create a template msg', newMessage);
    this.targetMessageGroupByChat[chat.chat_id] = newMessage;
  } 
}
