import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef, Inject } from '@angular/core';

import { Chat } from '../../../models/chat';
import { Message } from '../../../models/message';

import { ChatService } from '../../services/data/chat/chat.service';
import { ChatMessageService } from '../../services/data/messages/chat-message/chat-message.service';

import { ChatGroupNewComponent } from '../chat-group-new/chat-group-new.component';

import * as _ from 'lodash';
import { TnDialogService } from '../../../utilities/tn-dialog/tn-dialog.service';
import { LocalStorageManagerService } from '../../../utilities/local-storage/local-storage-manager.service';
import { ModuleManagerService } from '../../services/module/module-manager.service';
import { ContextMenuService } from '../../../utilities/context-menu/context-menu.service';
import { LoggerService } from '../../../utilities/logger/logger.service';
import { CollapsibleLists, CollapsibleListService, CHAT_LIST_CATEGORY_KEYS_LABEL } from '../../../utilities/collapsible-list/collapsible-list.service';
import { TeamNoteLocalStorageKeyConstants } from '../../../constants/local-storage-key.constant';
import { ModuleKeyDefinition } from '../../../constants/module.constant';
import { FtsChatService } from '../../../utilities/fts/chat/fts-chat.service';
import { BroadcastService } from '../../broadcast/broadcast.service';
import { MessageTypeConstant } from '../../../constants/message-type.constant';
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service';
import { AttachmentTypeConstant } from '../../../constants/attachment-type.constant';
import { MessagesService } from '../../services/data/messages/messages.service';
import { IPageInfo } from '@iharbeck/ngx-virtual-scroller';
import { SearchBarComponent } from '../../../utilities/search-bar/search-bar.component';
import { WebclientService } from '../../webclient.service';
import { UserContactService } from '../../services/data/user-contact/user-contact.service';
import { Subscription } from 'rxjs'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'tn-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {
  @Input() activeChat: Chat;
  @Input() isShowStarredMessageMenu: boolean;
  @Output() chatCellOnClick: EventEmitter<Chat> = new EventEmitter<Chat>();
  @Output() messageCellOnClick: EventEmitter<Message> = new EventEmitter<Message>();
  @Output() searchMessageOnClick: EventEmitter<{isStart: boolean, isSeeMore: boolean, searchKeyword: string}> = new EventEmitter<{isStart: boolean, isSeeMore: boolean, searchKeyword: string}>();
  @Output() switchChatRoomMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  isSortByGroup: boolean = false;
  searchKeyword: string = '';

  fullDisplayList: any[];
  scrollItems: any;

  chatLists: CollapsibleLists = {};
  CATEGORY_KEYS_LABEL = CHAT_LIST_CATEGORY_KEYS_LABEL;

  IS_ALLOW_CREATE_GROUP: boolean = false;
  IS_ALLOW_BROADCAST: boolean = false;

  isEnableImportantUsers: boolean = false;
  private isImportantUsersLoadedSub: Subscription

  // for searching messages
  searchedMessages: Message[] = [];
  @ViewChild('searchBar') searchBar: SearchBarComponent;

  // isMultiple: boolean = false;
  // selectedTargets: any[] = [];
  // callback: Function = null;

  isInMultiChatRoomMode: boolean = false;
  activeChatroomsChatId: string[] = [];
  chatroomSelectCallback: Function;
  private clearKeywordSubjectSub: Subscription

  constructor(
    private _chatService: ChatService,
    private _chatMessageService: ChatMessageService,
    private _tnDialogService: TnDialogService,
    private _localStorageManager: LocalStorageManagerService,
    private _moduleManagerService: ModuleManagerService,
    private _contextMenuService: ContextMenuService,
    private _loggerService: LoggerService,
    private _collapsibleListService: CollapsibleListService,
    private _ftsChatService: FtsChatService,
    private _broadcastService: BroadcastService,
    private _fileManagerService: FileManagerService,
    private _messageService: MessagesService,
    private _webclientService: WebclientService,
    private _userContactService: UserContactService,
    public dialogRef: MatDialogRef<ChatListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    // Try to restore chat list sorting config in cookies, if not exist, default by time
    let prevConfig = this._localStorageManager.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.CHAT_LIST_SORTING);
    this.isSortByGroup = (prevConfig == 't' || !prevConfig || prevConfig == undefined) ? false : true;

    // Init chat controls
    this.initChatControls();
    
    // Subscribe to chat, for chat name updates
    this._chatService.chats$.subscribe(chats => {
      this.updateChatList(this.searchKeyword);
    });

    // Subscribe to chat messages, for chat last message display
    this._chatMessageService.chatMessages$.subscribe(chatMessages => {
      this.updateChatList(this.searchKeyword);
    });

    this.isImportantUsersLoadedSub = this._userContactService.isImportantUsersLoaded$.subscribe((importantUsers) => {
      this.isEnableImportantUsers = this._webclientService.checkIfEnableImportantUsers();
      // this.updateChatList(this.searchKeyword);
      if (this.fullDisplayList) {
        this.fullDisplayList = _.cloneDeep(this.fullDisplayList) // render chat list
      }
    })

    this.clearKeywordSubjectSub = this._chatService.clearKeywordSubject$.subscribe((importantUsers) => {
      this.clearSearchKeyword()
    })
  }

  /**
   * Gather chat list related config / modules
   * 
   * @memberof ChatListComponent
   */
  ngOnInit() {
    // this.isMultiple = false;
    // this.callback = this.data.callback;
    // this.selectedTargets = this.data.selectedTargets || [];

    this.isInMultiChatRoomMode = this.data.isInMultiChatRoomMode;
    this.activeChatroomsChatId = this.data.activeChatroomsChatId;
    this.chatroomSelectCallback = this.data.callback;
    
    if (this.isInMultiChatRoomMode) {
      this.toggleDisplayMethod(false);
    } 
    // else if (this.isMultiple) {
    //   this.toggleDisplayMethod(true);
    // }

    this.IS_ALLOW_CREATE_GROUP = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.GROUP_CREATE);
    this.IS_ALLOW_BROADCAST = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.BROADCAST);
  }

  /**
   * Update chat list displays
   * 
   * @memberof ChatListComponent
   */
  ngOnChanges() {
    this.applyChatDisplayConfig();
    this.applyChatCollapseConfig();
    this.updateChatList(this.searchKeyword);
  }

  ngOnDestroy() {
    this.isImportantUsersLoadedSub.unsubscribe()
    this.clearKeywordSubjectSub.unsubscribe()
  }

  clearSearchKeyword(): void {
    this.searchKeyword = ''
    this.searchedMessages = [];
  }

  /**
   * Build collapsible chat list by definitions
   * 
   * @memberof ChatListComponent
   */
  initChatControls(): void {
    _.each(this.CATEGORY_KEYS_LABEL, (keyLabel, key) => {
      this.chatLists[keyLabel.key] = this._collapsibleListService.createCollapsibleList(keyLabel.label, [], keyLabel.allowCollapse);
    });
  }

  /**
   * Apply chat list category isDisplay based on chat list sorting method
   * 
   * @memberof ChatListComponent
   */
  applyChatDisplayConfig(): void {
    _.each(this.CATEGORY_KEYS_LABEL, (keyLabelObj, key) => {
      if (this.isSortByGroup) {
        this.chatLists[keyLabelObj.key].isDisplay = keyLabelObj.isGroup;
      } else {
        this.chatLists[keyLabelObj.key].isDisplay = keyLabelObj.isTime;
      }
    });
  }

  /**
   * Change chat list sorting method, update displays
   * 
   * @param {boolean} isSortByGroup - if chat list is sort by grouping
   * @memberof ChatListComponent
   */
  toggleDisplayMethod(isSortByGroup: boolean): void {
    this._loggerService.log("Set chat list sorting: isSortByGroup = " + isSortByGroup);
    this.isSortByGroup = isSortByGroup;

    if (!this.isInMultiChatRoomMode) {
      this._localStorageManager.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.CHAT_LIST_SORTING, isSortByGroup ? 'g' : 't');
    }
    this.applyChatDisplayConfig();
    this.buildFullGroupingChatListWithLabels();
  }

  // enterMultiChatRoomMode(): void {
  //   this.isInMultiChatRoomMode = true;
  //   this.switchChatRoomMode.emit(true);
  // }

  /**
   * Apply chat list collapse settings from cookies
   * 
   * @returns {void} 
   * @memberof ChatListComponent
   */
  applyChatCollapseConfig(): void {
    let all = this._localStorageManager.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.CHAT_LIST_COLLAPSING);
    if (!all) {
      return;
    }
    let keys = all.split(",");
    _.each(this.CATEGORY_KEYS_LABEL, (keyLabelObj, key) => {
      this.chatLists[keyLabelObj.key].isCollapsed = _.indexOf(keys, keyLabelObj.key) !== -1;
    });
  }

  /**
   * Update chat list collpase setting in cookies
   * 
   * @memberof ChatListComponent
   */
  updateChatCollapseConfig(): void {
    let keys = [];
    _.each(this.chatLists, (obj, key) => {
      if (obj.isCollapsed) {
        keys.push(key);
      }
    });
    this._localStorageManager.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.CHAT_LIST_COLLAPSING, keys);
  }

  /**
   * Toggle chat list category display
   * 
   * @param {string} key - target udpating category key
   * @param {boolean} allowCollapse - if category allows collapse
   * @returns {void} 
   * @memberof ChatListComponent
   */
  toggleCategoryDisplay(key: string, allowCollapse: boolean): void {
    if (!key || !allowCollapse) {
      return;
    }
    this.chatLists[key].isCollapsed = !this.chatLists[key].isCollapsed;
    this._loggerService.log("Toggled chat list collapse for category: " + key + " = " + this.chatLists[key].isCollapsed);
    this.updateChatCollapseConfig();
    this.buildFullGroupingChatListWithLabels();
  }

  /**
   * Find target chats of category by key
   * 
   * @param {string} key - category key
   * @memberof ChatListComponent
   */
  setChatUnderChatControlWithKey(key: string): void {
    let targetChats = [];
    switch (key) {
      case this.CATEGORY_KEYS_LABEL.FAVOURITE.key:
        targetChats = this._chatService.getFavouritedChats();
        break;
      case this.CATEGORY_KEYS_LABEL.ANNOUNCEMENT.key:
        targetChats = this._chatService.getAnnouncementChats();
        break;
      case this.CATEGORY_KEYS_LABEL.GROUP.key:
        targetChats = this._chatService.getGroupChats();
        break;
      case this.CATEGORY_KEYS_LABEL.INDIVIDUAL.key:
        targetChats = this._chatService.getIndividualChats();
        break;
      case this.CATEGORY_KEYS_LABEL.ALL.key:
        targetChats = this._chatService.getChatsForTimeChatList();
        break;
    }
    this.chatLists[key].items = this.getSearchedChats(targetChats);
  }

  /**
   * Update and search chat list, build a new full displaying list
   * 
   * If keyword is changed, search messages via API as well
   * 
   * @param {string} keyword - search keyword
   * @memberof ChatListComponent
   */
  updateChatList(keyword: string): void {
    let originalKeyword = this.searchKeyword;
    this.searchKeyword = keyword;
    _.each(this.CATEGORY_KEYS_LABEL, (keyLabel, key) => {
      this.setChatUnderChatControlWithKey(keyLabel.key);
    });
    this.buildFullGroupingChatListWithLabels();

    // Only search message if keyword is changed
    if (this.isInMultiChatRoomMode) {
      return;
    }
    
    if (this.searchKeyword != originalKeyword) {
      this.searchMessage();
    }
  }

  /**
   * Try to get all unread counts for category
   * Then build a full displaying list
   * 
   * @memberof ChatListComponent
   */
  buildFullGroupingChatListWithLabels() {
    _.each(this.chatLists, (obj, key) => {
      obj.unreadCounts = _.sumBy(obj.items, (c: Chat) => {
        return c.newMessageCount;
      });
    });

    // filter out the active chat under multi chatroom mode
    if (this.isInMultiChatRoomMode) {
      this.filterActiveChat();
    }

    this.fullDisplayList = this._collapsibleListService.buildFullDisplayListByLists(this.chatLists);

    // Append searched results
    this.appendSearchedResults();
  }

  /**
   * Search chats by keyword (use FTS)
   * 
   * @param {Chat[]} chats - origianl chat array
   * @returns {Chat[]} - filtered and sorted chat array
   * @memberof ChatListComponent
   */
  getSearchedChats(chats: Chat[]): Chat[] {
    let filtered: Chat[] = [];
    _.each(chats, (c) => {
      c = this._chatService.prepareChatForDisplay(c);
    });

    if (this.searchKeyword.length > 0) {
      filtered = this._ftsChatService.searchChat(this.searchKeyword, chats);
    } else {
      filtered = chats;
    }
    filtered = _.filter(filtered, (c) => {
      return c.displayName !== null && c.displayName !== undefined;
    });

    return this._chatService.sortChatsByLastMessageTimeAndName(filtered);
  }

  /**
   * Emit to parent when chat is clicked
   * 
   * @param {Chat} chat - clicked chat
   * @memberof ChatListComponent
   */
  chatOnClick(chat: Chat): void {
    if (this.isInMultiChatRoomMode) {
      if (this.chatroomSelectCallback) {
        this.chatroomSelectCallback(chat);
        return;
      }
    } 

    // if (this.isMultiple) {
    //   if (chat.isSelected) {
    //     chat.isSelected = false;
    //     this.selectedTargets = _.without(this.selectedTargets, chat);
    //   } else {
    //     chat.isSelected = true;
    //     this.selectedTargets = _.union(this.selectedTargets, [chat]);
    //   }
    // } else {
    //   this.chatCellOnClick.emit(chat);

    // }

    this.chatCellOnClick.emit(chat);
  }

  // close() {
  //   _.each(this.selectedTargets, (t) => {
  //     t.isSelected = false;
  //   });
  //   this.dialogRef.close();
  // }

  // cancel(): void {
  //   this.close();
  // }

  // confirm(): void {
  //   this.callback(this.selectedTargets);
  //   this.close();
  // }
  /**
   * Filter out the active chats
   * 
   * @memberof ChatListComponent
   */
  filterActiveChat() {
    if (this.activeChatroomsChatId && this.activeChatroomsChatId.length) {
      _.each(this.chatLists, (obj) => {
        obj.items = _.filter(obj.items, (chat) => !_.includes(this.activeChatroomsChatId, chat.chat_id))
      })
    }
  }

  // New Chat Group
  /**
   * Open "New Chat" dialog
   * 
   * @memberof ChatListComponent
   */
  openCreateGroupModal(): void {
    this._loggerService.log("Open new chat group dialog");
    let dialogRef = this._tnDialogService.openTnDialog(ChatGroupNewComponent);
  }

  openBroadcastModal(): void {
    this._loggerService.log("Open new broadcast dialog");
    this._broadcastService.openBroadcastDialog();
  }

  // Scroll Event
  /**
   * All Scrolling events
   * 
   * @param {*} event - mouse wheel event
   * @memberof ChatListComponent
   */
  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'chrome');
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'firefox');
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'ie');
  }

  /**
   * General mouse wheel update handler
   * 
   * @param {any} event - mouse wheel event
   * @param {any} from - browser type
   * @memberof ChatListComponent
   */
  mouseWheelFunc(event, from): void {
    this._contextMenuService.hideContextMenu();
  }

  /**
   * Search message using API
   * 
   * @returns {void} 
   * @memberof ChatListComponent
   */
  searchMessage(): void {
    if (this.searchKeyword.length == 0) {
      this.searchedMessages = [];
      this.buildFullGroupingChatListWithLabels();
      return;
    }
    this._chatMessageService.searchMessageByKeywordApi(
      this.searchKeyword,
      (msgs: Message[]) => {
        this.searchedMessages = this._messageService.filterAndSortSearchedMessages(msgs);

        this.buildFullGroupingChatListWithLabels();
      },
      (err) => {
        
      }
    );
  }

  loadMore(event: IPageInfo): void {
    if (this.isInMultiChatRoomMode) {
      return;
    }
    // if (this.isLoading) {
    //   return;
    // }
    // console.log(event.endIndex, this.searchedMessages.length)

    if (event.endIndex === -1) {
      return;
    }

    // '3' inclues 3 labels
    if (event.endIndex !== this.searchedMessages.length - 1) {
      return;
    }

    this.searchMessage();
  }

  /**
   * Append searched results to displaying list
   * 
   * @returns {void} 
   * @memberof ChatListComponent
   */
  appendSearchedResults(): void {
    if (this.searchedMessages.length == 0) {
      return;
    }
    let label = this._collapsibleListService.createListLabel(
      "WEBCLIENT.CHAT.HEADER_MESSAGE",
      "MESSAGE",
      true,
      this.searchedMessages.length
    );
    label.allowCollapse = false;
    this.fullDisplayList = _.union(this.fullDisplayList, [label], this.searchedMessages);
  }

  /**
   * Emit to parent when message is clicked
   * 
   * @param {Message} message 
   * @memberof ChatListComponent
   */
  messageOnClick(message: Message): void {
    this.messageCellOnClick.emit(message);
  }

  searchAllMessage(isSeeMore: boolean = false): void {
    // if (this.searchBar) {
    //   if (this.searchBar.clear instanceof Function) {
    //     // clear the global search keyword
    //     this.searchBar.clear();
    //   }
    // }

    this.searchMessageOnClick.emit({ isStart: true, isSeeMore: isSeeMore, searchKeyword: this.searchKeyword });
  }

}
