<div class="set-schedule-msg-modal">
  <div class="set-modal-header">
    <h4>{{ title | translate }}</h4>
  </div>

  <div class="set-modal-body">
    <div class="switcher-body">
      <!-- <section class="switcher-section"> -->
      <!-- [checked]="requiredConfirm" -->
      <mat-slide-toggle class="switcher-margin" [color]="'primary'" [disabled]="false" [(ngModel)]="requiredConfirm">
        Required Confirm
      </mat-slide-toggle>
      <!-- </section> -->
    </div>
    <div class="datetime-picker-body">
      <mat-form-field>
        <input
          matInput
          [ngxMatDatetimePicker]="picker"
          placeholder="Message Send Time"
          [formControl]="dateControl"
          [min]="minDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #picker
          [showSpinners]="true"
          [showSeconds]="false"
          [stepHour]="stepHour"
          [stepMinute]="stepMinute"
          [stepSecond]="stepSecond"
          [touchUi]="false"
          [color]="color"
          [enableMeridian]="false"
        >
          <ngx-mat-datepicker-actions>
            <button mat-button ngxMatDatepickerCancel>Cancel</button>
            <button mat-raised-button color="primary" ngxMatDatepickerApply>Apply</button>
          </ngx-mat-datepicker-actions>
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>

    <div class="sm-body">
      <mat-radio-group aria-label="Message Type" color="primary" [(ngModel)]="messageType">
        <mat-radio-button class="msg-type-choice-btn" *ngFor="let t of messageTypeSeletions" [value]="t.value">{{
          t.label
        }}</mat-radio-button>
      </mat-radio-group>

      <div>
        <!-- Text Message -->
        <mat-form-field *ngIf="messageType == realMessageTypes.text">
          <mat-label>Message Content</mat-label>
          <textarea matInput rows="7" [(ngModel)]="scheduleMessage"></textarea>
        </mat-form-field>

        <!-- Attachment Message -->
        <div *ngIf="messageType == realMessageTypes.attachment">
          <div class="field-attachment-wrapper">
            <div
              class="attachment-wrapper"
              [ngClass]="{
                'attachment-photo-wrapper': scheduleAttachmentMsg.type == attachmentTypes.photo,
                'attachment-video-wrapper': scheduleAttachmentMsg.type == attachmentTypes.video,
                'attachment-file-wrapper':
                  scheduleAttachmentMsg.type == attachmentTypes.file ||
                  scheduleAttachmentMsg.type == attachmentTypes.audio,
                'input-mode': isInput
              }"
              *ngIf="scheduleAttachmentMsg.fileObj || scheduleAttachmentMsg.attachment_id"
            >
              <div class="attachment-row">
                <!-- <div class="attachment" (click)="openAttachments(ansObj)"> -->
                <div class="attachment">
                  <div class="photo" *ngIf="scheduleAttachmentMsg.type == attachmentTypes.photo">
                    <div
                      class="photo-view"
                      *ngIf="scheduleAttachmentMsg.fileObj"
                      [ngStyle]="{ 'background-image': 'url(' + scheduleAttachmentMsg.fileObj.imagePreview + ')' }"
                    ></div>
                    <div
                      class="photo-view"
                      *ngIf="scheduleAttachmentMsg.imgSrc"
                      [ngStyle]="{ 'background-image': scheduleAttachmentMsg.imgSrc }"
                    ></div>
                  </div>
                  <div class="video" *ngIf="scheduleAttachmentMsg.type == attachmentTypes.video">
                    <div class="file-name">
                      <div class="file-icon form-attachment-video-icon"></div>
                      {{ scheduleAttachmentMsg.name }}
                    </div>
                  </div>
                  <div
                    class="file"
                    *ngIf="
                      scheduleAttachmentMsg.type == attachmentTypes.file ||
                      scheduleAttachmentMsg.type == attachmentTypes.audio
                    "
                  >
                    <div class="file-name">
                      <div class="file-icon form-attachment-file-icon"></div>
                      {{ scheduleAttachmentMsg.name }}
                    </div>
                  </div>
                </div>

                <div class="action-icon-wrapper">
                  <div class="form-delete-icon" (click)="removeAttachment(ansObj)"></div>
                </div>
              </div>
            </div>

            <div class="attachment-button-row" *ngIf="!scheduleAttachmentMsg.fileObj && !scheduleAttachmentMsg.attachment_id">
              <div class="buttons-wrapper" (click)="openFileUploader()">
                <div class="button">
                  <!-- <div class="form-attachment-file-icon"></div> -->
                  <i class="fa fa-upload" style="font-size: 20px"></i>
                  <span style="margin-left: 5px">{{ 'GENERAL.UPLOAD' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Location Message -->
        <div *ngIf="messageType == realMessageTypes.location">
          <div class="location-wrapper wrapper-margin-top" *ngIf="scheduleLocationMsg">
            <tn-location-message [body]="scheduleLocationMsg"></tn-location-message>
            <div class="action-icon-wrapper">
              <div class="form-delete-icon" (click)="removeLocation()"></div>
            </div>
          </div>
          <div
            class="buttons-wrapper wrapper-margin-top"
            (click)="openLocationSelectModal()"
            *ngIf="!scheduleLocationMsg"
          >
            <div class="button">
              <i class="fa fa-map-marker fa-fw" style="font-size: 20px"></i>
              <span>{{ 'WEBCLIENT.SCHEDULE_MESSAGE.LOCATION_SELECT' | translate }}</span>
            </div>
          </div>
        </div>

        <!-- Sticker Message -->
        <div *ngIf="messageType == realMessageTypes.sticker">
          <div class="sticker-container wrapper-margin-top" *ngIf="scheduleStickerMsg?.attachment_id">
            <div class="sticker-wrapper">
              <div class="sticker" [ngStyle]="{ 'background-image': scheduleStickerMsg.stickerBgUrl }"></div>
            </div>
            <div class="action-icon-wrapper">
              <div class="form-delete-icon" (click)="removeSticker()"></div>
            </div>
          </div>
          <div
            class="buttons-wrapper wrapper-margin-top"
            (click)="openStickerSelectionModal()"
            *ngIf="!scheduleStickerMsg"
          >
            <div class="sticker-button sticker clickable"></div>
            <span>{{ 'WEBCLIENT.SCHEDULE_MESSAGE.STICKER_SELECT' | translate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="targets-selection-body">
      <div
        class="filter-btn border-color-primary clickable"
        [ngClass]="{
          unfiltered: !targets.user_ids.length,
          'filtered theme-color-primary': targets.user_ids.length
        }"
        (click)="onUserContactsSelection()"
      >
        <!-- {{ 'WEBCLIENT.WORKFLOW.FILTER.TITLE' | translate }}
          <span class="filtered-count" *ngIf="targets.user_ids.length">{{ targets.user_ids.length }}</span>
          <i class="fa fa-user-o fa-1"></i> -->
        <ng-container *ngIf="targets.user_ids.length === 0">
          <!-- <div class="unfiltered-icon"></div> -->
          <i class="fa fa-fw fa-user-o"></i>
          <!-- <i class="fa fa-filter fa-1"></i> -->
          {{ 'WEBCLIENT.SCHEDULE_MESSAGE.USERS_SELECTION' | translate }}
        </ng-container>
        <ng-container *ngIf="targets.user_ids.length > 0">
          <!-- <div class="filtered-icon"></div> -->
          <i class="fa fa-fw fa-user-o"></i>
          <span>{{
            'WEBCLIENT.SCHEDULE_MESSAGE.USERS_SELECTION_COUNT' | translate : { COUNT: targets.user_ids.length }
          }}</span>
        </ng-container>
      </div>

      <div
        class="filter-btn border-color-primary clickable"
        [ngClass]="{
          unfiltered: !targets.chat_ids.length,
          'filtered theme-color-primary': targets.chat_ids.length
        }"
        (click)="onChatGroupsSelection()"
      >
        <!-- {{ 'WEBCLIENT.WORKFLOW.FILTER.TITLE' | translate }}
          <span class="filtered-count" *ngIf="targets.chat_ids.length">{{ targets.chat_ids.length }}</span>
          <i class="fa fa-user-o fa-1"></i> -->
        <ng-container *ngIf="targets.chat_ids.length === 0">
          <!-- <div class="unfiltered-icon"></div> -->
          <i class="fa fa-fw fa-users"></i>
          <!-- <i class="fa fa-filter fa-1"></i> -->
          {{ 'WEBCLIENT.SCHEDULE_MESSAGE.GROUPS_SELECTION' | translate }}
        </ng-container>
        <ng-container *ngIf="targets.chat_ids.length > 0">
          <!-- <div class="filtered-icon"></div> -->
          <i class="fa fa-fw fa-users"></i>
          <span>{{
            'WEBCLIENT.SCHEDULE_MESSAGE.GROUPS_SELECTION_COUNT' | translate : { COUNT: targets.chat_ids.length }
          }}</span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="tn-bottom-button-wrapper">
    <div class="action-button cancel-white clickable" (click)="cancel()">
      {{ 'GENERAL.CANCEL' | translate }}
    </div>
    <div class="action-button confirm clickable" (click)="confirm()">
      {{ 'GENERAL.CONFIRM' | translate }}
    </div>
  </div>
</div>
