import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { StickerService } from './sticker.service';
import { StickerCategory, Sticker } from '../../models/sticker';
import { each } from 'lodash';
import { FileFactoryService } from '../file-factory/file-factory.service';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'tn-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.scss']
})
export class StickerComponent implements OnInit {

  @Output() onStickerClick = new EventEmitter<any>()

  stickerCategoryArr: StickerCategory[] = []
  displayingStickerArr: Sticker[] = []

  isShowingRecent: boolean = true;
  recentStickers: Sticker[] = []

  isPickMode: boolean = false;
  callback: Function = null;

  constructor(
    private _stickerService: StickerService,
    private _fileFactoryService: FileFactoryService,
    public dialogRef: MatDialogRef<StickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this._stickerService.recentStickers$.subscribe(stickers => {
      this.recentStickers = stickers
    })
  }

  ngOnInit() {
    this.isPickMode = this.data.isPickMode;
    this.callback = this.data.callback;
    this.getStickerCategories()
  }

  getStickerCategories(): void {
    this._stickerService.getStickersCategories(
      (resp) => {
        this.stickerCategoryArr = resp

        if (this.isPickMode && this.stickerCategoryArr?.length) {
          this.onCategoryClick(this.stickerCategoryArr[0]);
        }
      },
      (err) => {

      }
    )
  }

  onRecentClick(): void {
    this.isShowingRecent = true;
  }

  onCategoryClick(category: StickerCategory): void {
    this.isShowingRecent = false;
    this._stickerService.getStickerByCategory(
      category.category_id,
      (resp: Sticker[]) => {
        each(resp, (s) => {
          this._fileFactoryService.getFileByAttachmentId(
            s.attachment_id,
            (imageUrl) => {
              s.stickerBgUrl = imageUrl
            },
            (err) => {},
            false,
            false,
            true
          )
        })
        this.displayingStickerArr = resp
      },
      (err) => {

      }
    )
  }

  onClick(sticker: Sticker): void {
    if (this.isPickMode) {
      if (this.callback) {
        this.callback(sticker);
        this.dialogRef.close();
      }
      
      return;
    }

    this._stickerService.addStickerToRecents(sticker)
    this.onStickerClick.emit(sticker);
  }

}
