export const EnChatroomStrings = {
  SETTINGS: "Settings",
  PHOTO: "Photo",
  VIDEO: "Video",
  DOCUMENT: "Document",
  MESSAGE_STAR: 'Starred Messages',

  EXPORT_CHAT: {
    TITLE: "Export chat messages",
    CHAT: "Chat: ",
    TIME: "Time: ",
    CHAT_INFO: "Export chat messages of chat: ",
    EXPORT_TIME_PERIOD: "Time period",
    RANGE_SELECT_MSG: "Please select a time period. Messages within this time period will be exported.",
    NO_TIME_PERIOD_ERROR_MSG: "Please select a time period",
    CONFIRM_MSG: "Are you sure to export messages from {{FROM}} to {{TO}}?",
    IN_PROGRESS_MSG: "Chat messages are exporting, you can check the progress in notification center at the top-right hand corner. You will be notified when the file is ready.",
    EXPORT_DONE_DOWNLOAD: "Done. Please click here to download.",
    EXPORT_READY_MSG: "Chat messages file is ready, you can download it in notification center.",
    EXPORT_WITH_WATERMARK: {
      TITLE: "Add watermark on attachments? ",
      TIP: "(Exporting with watermark will include photos only)"
    }
  },

  SEND: "Send",
  TEXTAREA_PLACEHOLDER: {
    IE: "Input message / Drag & Drop file here",
    OTHERS: "Input message / Paste image / Drag & Drop file here",
    TEXT_ONLY: "Input message",
    DROP_FILE_TIPS: "Drop file here"
  },
  MESSAGE_OPTION: {
    UPLOAD: "Upload",
    LOCATION: "Location",
    ANNOTATION: "Comment",
    EXIT_ANNOTATION: "Exit Comment Mode",
    ATTACH: "Attach",
    IMPORTANT: "Important",
    SMS: "SMS",
    SELECT_FROM_DOCUMENT_SHARING: "Select from Document Sharing"
  },

  WHISPER: "Whisper",
  NAME_WHISPER_LABEL: "{{USER}} (whisper)",

  READ: "Read",
  READ_GROUP: "Read {{NUM}}",

  NEW_MESSAGE: {
    ONE: "New Message ({{NUM}})",
    MULTIPLE: "New Messages ({{NUM}})"
  },
  UNREAD_MESSAGE_HEADER: "UNREAD MESSAGES",

  ONLINE: "Online",
  LAST_SEEN: "Last seen at ",
  NOT_EXIST: "Chatroom does not exist",
  
  JOIN_CHAT: "{{ACTOR}} added {{USERS}} to the group",
  LEAVE_GROUP: "{{USERS}} left the group",
  ADMIN_CHANGE: {
    ONE: "{{USER}} is now group admin",
    MULTIPLE: "{{USERS}} are now group admins"
  },
  ADMIN_REMOVED: {
    ONE: "{{USER}} is no longer a group admin",
    MULTIPLE: "{{USERS}} are no longer group admins"
  },
  MESSAGE_RECALLED: {
    ME: "You recalled a message",
    OTHERS: "{{USER}} recalled a message"
  },

  CLASSIFICATION_LEVEL: {
    AUTH_TITLE: "You are entering a Confidential chat room. Messages will be deleted when you leave the chat room. Please authenticate again to accept and enter the chatroom.",
    DESC: "Messages classified as: ",
    LEVEL0: "Normal",
    LEVEL1: "Internal", // origin: Restricted
    LEVEL2: "Restricted" // origin: Confidential
  },

  ENCRYPTED_MESSAGE: {
    AUTH_TITLE: "Please authenticate before viewing secure messages",
    MESSAGE_PREVIEW: "Secure message, press to view",
    STARRED_MESSAGE_PREVIEW: "Secure message, please view in the chatroom"
  },

  OUT_OF_OFFICE: {
    UNTIL: "Out of office until ",
    COUNT: {
      ONE: "{{NUM}} people is out of office",
      MULTIPLE: "{{NUM}} people are out of office"
    }
  },

  REPLY: {
    YOU: "You",
    IMAGE: "Image",
    VIDEO: "Video",
    AUDIO: "Voice Message",
    DOCUMENT: "Document",
    LOCATION: "Location",
    STICKER: "Sticker"
  },

  GROUP_INFO_ITEM: {
    ADMIN: "Group Admin",
    OUT_OF_OFFICE: "Out of office"
  },

  MESSAGE_OPTIONS: {
    INFO: "Message info",
    ACKNOWLEDGEMENT_INFO: 'Message report status',
    FORWARD: "Forward",
    // SELECT: "Select Message(s)",
    SELECT: "Forward",
    DOWNLOAD: "Download",
    RECALL: "Recall message",
    REPLY: "Reply",
    REPORT: "Report",
    STAR: 'Star message',
    UNSTAR: 'Unstar message',
    COPY: 'Copy',
    EMOJI: 'Send emoji',
    DELETE: "Delete",
    ANNOTATION: "Comment",
  },

  EMOJI_INFO: {
    TITLE: "Emoji Details",
    PREVIEW_TITLE: "All {{emoji_count}} emoji(s)"
  },

  MESSAGE_INFO: {
    TITLE: "Message Info",
    READ_HEADER: "Read by",
    DELIVERY_HEADER: "Delivered to",
    UNREAD_HEADER: "Unread",
    ACKNOWLEDGED_HEADER: 'Acknowledged',
    SMS_HEADER: "SMS Status"
  },

  RECALL: {
    SUCCESS: "Message recalled successfully",
    FAIL: "Message recall failed"
  },

  ANNOTATION: {
    RECALL_SUCCESS: "Annotation recalled successfully",
    RECALL_FAIL: "Annotation recall failed",
  },

  FORWARD: {
    CONFIRM_MSG: "Confirm forward message to {{chat}}?",
    SUCCESS_MSG: "Messages forwarded"
  },

  DELETE: {
    CONFIRM_MSG: "Delete this message?"
  },

  ACK: {
    ACKED: "Acknowledged",
    ACK: "Acknowledge"
  },

  FALLBACK_STATUS: {
    PENDING: 'Pending',
    SENT: 'Sent',
    ERROR: 'Error'
  },

  MULTI_CHAT: {
    SWITCH_CHAT: "Switch",
    PANEL_NAME_PLACEHOLDER: 'Panel Name',
    NO_MESSAGE_TIPS: 'No results were found',
    STOP_LOADING: 'Click to stop the searching...',
  }
}
