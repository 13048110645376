import { Injectable } from '@angular/core';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { TeamNoteApiConstant } from '../../constants/api.constant';
import { without, union, each, remove, find, sortBy } from 'lodash';
import { Sticker, StickerCategory } from '../../models/sticker';
import { BehaviorSubject } from 'rxjs';
import { FileFactoryService } from '../file-factory/file-factory.service';
import { TnDialogService } from '../tn-dialog/tn-dialog.service';
import { StickerComponent } from './sticker.component';
import { AccountService } from '../../account/account.service';

@Injectable({
  providedIn: 'root'
})
export class StickerService {

  recentStickers: Sticker[] = []
  recentStickers$: BehaviorSubject<Sticker[]> = new BehaviorSubject<Sticker[]>([])

  constructor(
    private _teamnoteApiService: TeamnoteApiService,
    private _fileFactoryService: FileFactoryService,
    private _tnDialogService: TnDialogService,
    private _accountService: AccountService,
  ) { }

  openStickerSelectionDialog(isPickMode: boolean, callback?: Function): void {
    let dialogRef = this._tnDialogService.openTnDialog(
      StickerComponent,
      { 
        isPickMode: isPickMode,
        callback: callback
      },
      {
        width: '65vw',
        minWidth: '800px',
        maxWidth: '1000px',
        height: '80vh'
      }
    )
  }

  getStickersCategories(success: Function, failure: Function): void {
    let url = TeamNoteApiConstant.STICKER.CATEGORIES;
    let param = {
      company_domain: this._accountService.companyDomain,
      access_token: this._accountService.accessToken
    }

    this._teamnoteApiService.callApi(
      url,
      param,
      (resp: StickerCategory[]) => {
        resp.sort((a, b) => {
          return a.order - b.order;
        })

        each(resp, (cat) => {
          this._fileFactoryService.getFileByAttachmentId(
            cat.tray_image_id,
            (imageUrl) => {
              cat.iconBgUrl = imageUrl
            },
            (err) => {},
            false,
            false,
            true
          )
        })

        success(resp)
      },
      failure,
      true
    );
  }

  getStickerByCategory(categoryId: string, success: Function, failure: Function): void {
    let url = TeamNoteApiConstant.STICKER.STICKERS;
    let param = {
      category_id: categoryId,
      company_domain: this._accountService.companyDomain,
      access_token: this._accountService.accessToken
    }

    this._teamnoteApiService.callApi(
      url,
      param,
      (resp: Sticker[]) => {

        resp = sortBy(resp, ['order'])

        success(resp)
      },
      failure,
      true
    );
  }

  updateRecentStickerSubject(): void {
    this.recentStickers$.next(this.recentStickers);
  }
  addStickerToRecents(sticker: Sticker): void {
    // let originalSticker = find(this.recentStickers, {attachment_id: sticker.attachment_id});
    this.recentStickers = without(this.recentStickers, sticker)
    this.recentStickers = union([sticker], this.recentStickers)
    this.updateRecentStickerSubject();
  }

  getRecentStickers(): Sticker[] {
    return this.recentStickers;
  }
}
