import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModuleKeyDefinition, WebclientSideNavConfigurations, Module, ModuleKeyMapping } from '../../constants/module.constant';

import * as _ from 'lodash';
import { ModuleManagerService } from '../../webclient/services/module/module-manager.service';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { TeamNoteLocalStorageKeyConstants } from '../../constants/local-storage-key.constant';
import { LocalStorageManagerService } from '../local-storage/local-storage-manager.service';
import { TeamnoteApiService } from '../../api/teamnote-api.service'
import { PageUrlConstant } from '../../constants/page-url.constant';
import { RouteParamService } from '../route-param/route-param.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoggerService } from '../logger/logger.service';
import { AccountManagerService } from '../../webclient/services/account/account-manager.service';

class SideNavByAccount {
  unreadCount?: number = 0;
  unreadCount$?: BehaviorSubject<number> = new BehaviorSubject<number>(this.unreadCount);
}
interface SideNavObjCollection {
  [userId: string]: SideNavByAccount
}

export class SideNavItem {
  key: string;
  link: string;
  url?: string;
  iconClass: string;
  imgUrl?: string;
  name: string;
  nameStatic: string;
  index?: number;
  params?: any;

  isDisplay?: boolean;
  sort_key?: string;
  isExtraCustomizedNav?: boolean;

  isActive?: boolean;
  count?: number;

  // possible fields of app config module
  id?: string; // key
  icon_id?: string; // 
  side_menu_name?: string;
  type?: string;
  is_super_connector?: boolean;
  module_display?: string;


  notification?: Notification;
  news?: News;

  webview?: WebView
  _i18n?: any;

  iconUrl?: string; // imgUrl

  constructor(key?: string, link?: string, iconClass?: string, name?: string, nameStatic?: string, index?: number, params?: any, isDisplay?: boolean) {
    this.key = key;
    this.link = link;
    this.iconClass = iconClass;
    this.name = name;
    this.nameStatic = nameStatic;
    this.index = index;
    this.params = params;
    this.isDisplay = isDisplay;
  }
}

export interface Notification {
  is_notification: boolean;
}

export interface News {
  news_category_ids: string[];
}

export interface WebView {
  is_navbar_visible?: boolean,
  navbar_color?: string,
  is_browser_bar_visible?: boolean,
  allow_download?: boolean,
  can_use_offline?: boolean,
  skip_ssl_verify?: boolean,
}

export class SideNavModule extends SideNavItem {}

@Injectable()
export class SideNavService {

  sideNavItems: SideNavItem[] = [];
  sideNavItems$: BehaviorSubject<SideNavItem[]> = new BehaviorSubject<SideNavItem[]>(this.sideNavItems);

  sideNavModuleKey: string[] = [];
  sideNavModules: SideNavModule[] = [];

  currentActiveItemKey: string = null;

  unreadCount: number = 0;
  unreadCount$: BehaviorSubject<number> = new BehaviorSubject<number>(this.unreadCount);
  
  _sideNavObjCollection: SideNavObjCollection = {};
  allUnreadCountByAccount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private _moduleManagerService: ModuleManagerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _teamnoteApiService: TeamnoteApiService,
    private _routeParamService: RouteParamService,
    private sanitizer: DomSanitizer,
    private _router: Router,
    private _loggerService: LoggerService,
    private _accountManagerService: AccountManagerService
  ) { }


  resetSideNavs(): void {
    this.sideNavItems = [];
    this.sideNavModules = [];

    this.sideNavModuleKey = [];
    this.sideNavModules = [];
    this.currentActiveItemKey = null;

    this.updateSideNavArrSubject();
  }

  resetSideNavs_v2(accUserId: string): void {
    if (!this._sideNavObjCollection[accUserId]) {
      this._sideNavObjCollection[accUserId] = {
        unreadCount: 0,
        unreadCount$: new BehaviorSubject<number>(0)
      }
    }
  }

  setUpSideNav(callback?: Function): void {
    this.resetSideNavs();
    this.setupModuleMenu();

    if (this.sideNavItems.length === 0 && !this.isModuleExist()) {
      /* use default side nav setting */
      this._moduleManagerService.appConfigModules = this._moduleManagerService.modules
      this.setupCoreMenu(); // call when there is no any app config setup in portal (safeguard)
      this.setupModuleMenu();
      this.setupCompanyMenu();
      this.sortAllModules(true, callback);
    } else {
      /* use side nav setup set by app config */
      this.setupAppConfigModules(callback)
    }
  }

  updateSideNavArrSubject(): void {
    this.sideNavItems$.next(this.sideNavItems);
    
  }

  setupCoreMenu(): void {
    // if (_.indexOf(this.companyModules, this.ALL_MODULES.NEWS.KEY) !== -1) {
      let news: SideNavItem = this.getSideNavItemByModuleKey(ModuleKeyDefinition.NEWS);
      this.sideNavItems.push(news);
    // }

    let chat: SideNavItem = this.getSideNavItemByModuleKey(ModuleKeyDefinition.CHAT);
    this.sideNavItems.push(chat);

    if (this._accountManagerService.isEnableMultiAccountLogin()) {
      // if (this.checkIfModuleExists(ModuleKeyDefinition.SCHEDULE_MESSAGE)) {
      if (this._moduleManagerService.checkIfExerciseRoleModuleExists(ModuleKeyDefinition.SCHEDULE_MESSAGE)) {
        let scheduled_message: SideNavItem = this.getSideNavItemByModuleKey(ModuleKeyDefinition.SCHEDULE_MESSAGE);
        this.sideNavItems.push(scheduled_message);
      }
    }
    
    let contact: SideNavItem = this.getSideNavItemByModuleKey(ModuleKeyDefinition.CONTACT);
    this.sideNavItems.push(contact);
  }

  getSideNavItemByModuleKey(key: string): SideNavItem {
    let target = WebclientSideNavConfigurations[key];
    return this.getSideNavItemByModule(target);
  }

  getSideNavItemByModule(m: Module): SideNavItem {
    if (m && m.PATH) {
      let moduleMappingKey = ModuleKeyMapping[m.KEY]; // mapping for specific module key

      return {
        key: m.KEY,
        link: "./" + m.PATH,
        iconClass: m.ICON_CLASS,
        imgUrl: m.imgUrl,
        name: "WEBCLIENT.MENU." + m.NAME,
        params: m.PARAMS,
        nameStatic: "",
        isActive: false,
        count: 0,
        isDisplay: true,
        type: 'module',
        sort_key: moduleMappingKey || m.KEY
      };
    } else {
      return null;
    }
  }

  removeModuleByModuleKey(moduleKey: string): void {
    let target = _.find(this.sideNavItems, {key: moduleKey});
    if (target) {
      target.isDisplay = false;
    }
  }

  showModuleByModuleKey(moduleKey: string): void {
    let target = _.find(this.sideNavItems, {key: moduleKey});
    if (!target) {
      return
    }
    target.isDisplay = true;
  }

  isCheckUserHasModulePermission(moduleName: string): boolean {
    let hasPermission = _.indexOf(this._moduleManagerService.modules, moduleName) !== -1

    return hasPermission
  }

  isCheckModuleInModuleConfig(moduleName: string): boolean {
    let isModuleMatched = _.find(this._moduleManagerService.config_modules, (value, key) => {
      return key === moduleName
    })

    return !!isModuleMatched
  }

  setupModuleMenu(): void {
    let m: any = [];
    // console.log('config_modules', this._moduleManagerService.config_modules); 
    
    _.each(this._moduleManagerService.appConfigModules, (cm) => {
      // if (cm == ModuleKeyDefinition.NEWS) {
      //   return;
      // }

      // if (!this.isCheckUserHasModulePermission(cm)) {
      //   return
      // }

      // if (!this.isCheckModuleInModuleConfig(cm)) {
      //   return
      // }

      let navItem = this.getSideNavItemByModuleKey(cm);

      if (navItem) {
        m.push(navItem);
      } else {
        if (!this.isCheckModuleInModuleConfig(cm)) {
          return
        }

        this.sideNavModuleKey.push(cm);
      }
    });

    // console.log(m);
    // this.sideNavItems = _.union(this.sideNavItems, m);
    this.sideNavItems = _.unionBy(this.sideNavItems, m, 'key');
  }

  setupCompanyMenu(): void {
    let extra = this._teamnoteConfigService.config.WEBCLIENT.SIDE_NAV.SIDE_NAV_EXTRA_MODULES;
    let companyM = [];
    _.each(extra, (m) => {
      if (m.TO_BE_REPLACE_MODULE) {
        this.removeModuleByModuleKey(m.TO_BE_REPLACE_MODULE);
      }
      let navItem = this.getSideNavItemByModule(m);
      navItem.isExtraCustomizedNav = true;
      if (navItem) {
        companyM.push(navItem);
      }
    });
    // this.sideNavItems = _.union(this.sideNavItems, companyM);
    this.sideNavItems = _.unionBy(this.sideNavItems, companyM, 'key');
  }

  /**
   * sorting all modules
   * 
   * @param {boolean} isDefaultSetting - use the default sorting when using default module setting
   */
  sortAllModules(isDefaultSetting: boolean = false, switchAccountCallback?: Function): void {
    if (isDefaultSetting) {
      let finalNav = [];
      _.each(this.sideNavItems, (n) => {
        n.index = this._moduleManagerService.getOrderIndexOfModule(n.key);
        if (n.isExtraCustomizedNav || n.index != -1) {
          finalNav.push(n);
        }
      });

      // safeguard for filtering the duplicate module
      let moduleIdx = _.findIndex(finalNav, ['isDisplay', false])
      if (moduleIdx !== -1) {
        finalNav.splice(moduleIdx, 1)
      }

      finalNav = _.uniqBy(finalNav, 'link') // safeguard for avoiding dupliate module with same route link

      this.sideNavItems = finalNav;
    } else {
      // add sorting index for each module
      _.forEach(this.sideNavItems, (module) => {
        module.index = this.getIndexOfModule(module.sort_key)
      })

      let tempSideNavItems = _.sortBy(this.sideNavItems, 'index')
      this.sideNavItems = _.uniqBy(tempSideNavItems, 'key');
      // this.sideNavItems = _.sortBy(this.sideNavItems, 'index')
    }

    if (switchAccountCallback) {
      switchAccountCallback()
    }
    // console.log(this.sideNavItems)

    if (this.isCheckCurrentKeyValid()) {
      let prev: string = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);
      
      this.updateActiveSideNav(prev);
      // this.goToActiveItemPath()
    } else {
      // this.currentActiveItemKey = this._teamnoteConfigService.config.WEBCLIENT.GENERAL.WEBCLIENT_DEFAULT_PAGE;
      
      // safeguard for default active item
      for (let i = 0; i < this.sideNavItems.length; i++) {
        if (this.sideNavItems[i].type === 'separator' || this.sideNavItems[i].type === 'label') {
          continue
        } 

        if (this.sideNavItems[i].type === 'module' && !this.sideNavItems[i].link) {
          continue
        }

        this.currentActiveItemKey = this.sideNavItems[i].key

        break
      }
  
      this.updateActiveSideNav(this.currentActiveItemKey);
      
      this.goToActiveItemPath()
    }
  }

  goToActiveItemPath() {
    let activeItem = _.find(this.sideNavItems, (module) => {
      return module.key === this.currentActiveItemKey
    })

    let targetRoute: string = '/' + PageUrlConstant.WEBCLIENT.BASE;

    if (!activeItem || !activeItem.link) {
      targetRoute = this.clearActiveItemKeyAndSetPathToWebclientBase()
      this._router.navigateByUrl(targetRoute);
      return
    }
    // targetRoute = '/' + PageUrlConstant.WEBCLIENT.BASE + activeItem.link.slice(1)
    targetRoute = targetRoute + activeItem.link.slice(1)

    if (activeItem.is_super_connector) {
      if (activeItem.url) {
        if (activeItem.module_display === 'embed_iframe') {
          targetRoute = `/webclient/teamflare_app/${activeItem.url}`
  
          this._router.navigate(['../' + PageUrlConstant.WEBCLIENT.BASE + activeItem.link.slice(1), activeItem.url]);
          return
        } else {
          // show module in the external tab
          // targetRoute = '/' + PageUrlConstant.WEBCLIENT.BASE
          // this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);
          // this.currentActiveItemKey = null

          targetRoute = this.clearActiveItemKeyAndSetPathToWebclientBase()
          this.updateActiveSideNav(this.currentActiveItemKey);
        }
      } else { 
        targetRoute = this.clearActiveItemKeyAndSetPathToWebclientBase()
        this.updateActiveSideNav(this.currentActiveItemKey);
      }
    } else if (activeItem.type === 'module') {
      if (!activeItem.link) {
        this.updateActiveSideNav(this.currentActiveItemKey);
      }
    }

    this._router.navigateByUrl(targetRoute);
  }

  clearActiveItemKeyAndSetPathToWebclientBase(): string {
    let baseRoute = '/' + PageUrlConstant.WEBCLIENT.BASE
    this._localStorageManagerService.removeCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);
    this.currentActiveItemKey = null

    return baseRoute
  }

  isCheckCurrentKeyValid(): boolean {
    let isCurrentKeyValid: boolean = false
    let prev = this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE);

    _.forEach(this.sideNavItems, (module) => {
      if (prev === module.key) {
        isCurrentKeyValid = true

        if (module.module_display === 'external_tab') {
          // console.log(module)
          isCurrentKeyValid = false
        }
      } 
    })

    return isCurrentKeyValid
  }
  
  async setupAppConfigModules(callback?: Function) {

    // filtering the valid module (feature)
    _.forEach(this._moduleManagerService.config_modules, (module_obj: SideNavModule, module_key) => {
      let isModuleExist = _.find(this.sideNavModuleKey, (key_name) => {
        // return key_name === module_obj.id
        return key_name === module_key
      })

      module_obj.sort_key = module_key; // add side_menu_key as the sort key

      if (isModuleExist) {
        let feature_obj = _.find(this._moduleManagerService.available_features, (feature) => {
          // Module Key / Feature ID is same with Feature ID
          return module_obj.id === feature.feature_id
        })

        if (feature_obj) {
          module_obj.url = feature_obj.url
        }

        this.sideNavModules.push(module_obj)
      }
    })

    this.addTeamFlareModuleDisplaySafeGuard();

    try {
      this.sideNavModules = await this.getModuleIconSrcByIconId(this.sideNavModules);
      this.sideNavItems = await this.getLocalModuleIconSrcByIconId(this.sideNavItems);

      this.combineSideNavItemsAndModules()
      this.translateSideNavModuleName()
      this.sortAllModules(false, callback);
    } catch (err) {
      // alert(err)
      this._loggerService.error(err);
    }
  }

  addTeamFlareModuleDisplaySafeGuard(): void {
    _.forEach(this.sideNavModules, (module) => {
      if (module.type === "super_connector" && !module.module_display) {
        // add module display setup for each module as safeguard
        module.module_display = 'external_tab';
      }
    })
  }

  async getLocalModuleIconSrcByIconId(originModules): Promise<any> {
    let localModuleListWithIcon = originModules.map(module => {
      let localModuleInConfig: any = _.find(this._moduleManagerService.config_modules, (module_obj, key) => {
        return module.key === key
      })

      if (!localModuleInConfig) {
        return module
      }

      let parsedModuleObj = { ...module }

      if (localModuleInConfig._i18n && !_.isEmpty(localModuleInConfig._i18n)) {
        parsedModuleObj._i18n = localModuleInConfig._i18n
      }

      return new Promise((resolve, reject) =>{ 
        if (localModuleInConfig.icon_id) {
          this._teamnoteApiService.getFileByAttachmentId(
            localModuleInConfig.icon_id,
            (imgSrc, fileName) => {
              let type = imgSrc.headers.get('Content-Type');
  
              let blob = new Blob([imgSrc.body], { type: type });
              let urlCreator = window.URL;
              let blobUrl = urlCreator.createObjectURL(blob);

              let parsedIconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
              
              parsedModuleObj.imgUrl = parsedIconUrl

              resolve(parsedModuleObj)
            },
            (err) => { 
              console.error(err); 
              reject(err); 
            }
          );
        } else {
          resolve(parsedModuleObj)
        }
      })
    })

    return Promise.all(localModuleListWithIcon);
  }

  async getModuleIconSrcByIconId(moduleList): Promise<any> {
    let ModuleListWithIcon = moduleList.map(module => {
      let image_id = module.icon_id;

      let parsedModuleObj = { 
        ...module, 
        key: module.id,
        link: "./" + (module.module_display === 'embed_iframe' ? 'teamflare_app' : module.id),
        iconClass: '',
        // imgUrl: parsedIconUrl,
        name: module.side_menu_name,
        params: module.PARAMS,
        nameStatic: "",
        isActive: false,
        count: 0,
        // isDisplay: ('is_browser_bar_visible' in module.webview) ? module.webview.is_browser_bar_visible : true,
        isDisplay: true
      }

      if (module.type === 'module' && !module.is_super_connector) {
        parsedModuleObj.link = null
      }

      delete parsedModuleObj.id
      delete parsedModuleObj.side_menu_name
      delete parsedModuleObj.icon_id
      
      return new Promise((resolve, reject) =>{ 
        if (image_id !== '') {
          this._teamnoteApiService.getFileByAttachmentId(
            image_id,
            (imgSrc, fileName) => {
              // console.log(imgSrc);
              let type = imgSrc.headers.get('Content-Type');
  
              let blob = new Blob([imgSrc.body], { type: type });
              let urlCreator = window.URL;
              let blobUrl = urlCreator.createObjectURL(blob);

              let parsedIconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
              
              parsedModuleObj.imgUrl = parsedIconUrl
              
              delete parsedModuleObj.iconUrl

              resolve(parsedModuleObj)
            },
            (err) => { 
              console.error(err); 
              reject(err); 
            }
          );
        } else {
          parsedModuleObj.imgUrl = ''

          delete parsedModuleObj.iconUrl
          // resolve({ ...module, iconUrl: '' })
          resolve(parsedModuleObj)
        }
      })
    })

    return Promise.all(ModuleListWithIcon);
  }

  combineSideNavItemsAndModules(): void {
    _.forEach(this.sideNavModules, (module) => {
      this.sideNavItems.push(module)
    })
  }

  getIndexOfModule(module_name: string): number {
    return _.findIndex(this._moduleManagerService.appConfigModules, (key_name) => {
      return module_name === key_name;
    })
  }

  translateSideNavModuleName() {
    let moduleNameRegex = /[^A-Za-z0-9\u4e00-\u9fa5[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/g

    this._loggerService.debug(`translateSideNavModuleName Result: ${JSON.stringify(_.cloneDeep(this.sideNavItems))}`);

    _.forEach(this.sideNavItems, (module) => {
      if (module.name) {
        module.name = module.name.replace(moduleNameRegex, ' ')
      }

      if ('_i18n' in module) {
        _.forEach(module._i18n, (value, key) => {
          // if (value.side_menu_name !== '') {
          if (!_.isEmpty(value.side_menu_name)) {
            value.side_menu_name = value.side_menu_name.replace(moduleNameRegex, ' ')
          }
        })
      }
    })
  }

  isModuleExist(): boolean {
    let result = false

    _.forEach(this._moduleManagerService.config_modules, (value, key_name) => {
      _.forEach(this.sideNavModuleKey, (key) => {
        if (key_name === key) {
          result = true
        }
      })
    })

    return result
  }

  updateSideNavs(): void {
    this.sideNavItems = _.sortBy(this.sideNavItems, ['index', 'name']);
    this.updateSideNavArrSubject();
  }

  updateActiveSideNav(newActiveSideNavKey: string): void {
    _.each(this.sideNavItems, (n) => {
      n.isActive = n.key == newActiveSideNavKey;
    });

    this.currentActiveItemKey = newActiveSideNavKey;
    this._localStorageManagerService.setCookiesByKey(TeamNoteLocalStorageKeyConstants.USER_CONFIG_COOKIES.ROUTE_MODULE, newActiveSideNavKey);

    this.updateSideNavArrSubject();
  }

  updateSideNavCountByKey(key: string, count: number): void {
    let targetNav = _.find(this.sideNavItems, {key: key});
    if (targetNav) {
      targetNav.count = count;
    }
    this.updateSideNavArrSubject();
    this.getWebClientUnreadCount();
  }

  appendNewSideNavFromBaseKey(newSideNav: SideNavItem, baseKey: string, fractionIndex: number): void {
    let baseNav = _.find(this.sideNavItems, {key: baseKey});

    // check if new nav exist already.
    let targetNav = _.find(this.sideNavItems, {key: newSideNav.key});
    if (targetNav) {
      targetNav = _.assign(targetNav, newSideNav);
      targetNav.isDisplay = true;
      targetNav.index = baseNav.index + fractionIndex;
      this.updateSideNavs();
      return;
    }

    newSideNav.index = baseNav.index + fractionIndex;
    this.sideNavItems.push(newSideNav);
    this.updateSideNavs();
  }

  // Unread count
  getWebClientUnreadCount(): void {
    let count = 0;
    _.each(this._teamnoteConfigService.config.WEBCLIENT.SIDE_NAV.SIDE_NAV_UNREAD_COUNTS_MODULES, (key) => {
      let nav = _.find(this.sideNavItems, {key: key});
      if (nav) {
        count += nav.count;
      }
    });
    this.unreadCount = count;
    this.updateUnreadCountSubject();
  }

  updateUnreadCountSubject(): void {
    this.unreadCount$.next(this.unreadCount);
  }

  // multi account
  getSideNavObjCollectionByAccountUserId(accUserId: string) {
    return this._sideNavObjCollection[accUserId]
  }

  updateSideNavCountByKey_v2(key: string, unreadCount: number, accUserId?: string): void {
    this.updateUnreadCountSubjectForTargetAccount(accUserId, unreadCount);

    // update unread count for side menu item when target account is the active account
    if (this._accountManagerService.userId === accUserId) {
      let targetNav = _.find(this.sideNavItems, {key: key});
      if (targetNav) {
        targetNav.count = unreadCount;
      }
      
      this.updateSideNavArrSubject();
    }

    // this.getWebClientUnreadCount_v2(unreadCount, accUserId);
  }

  getWebClientUnreadCount_v2(unreadCount: number, accUserId?: string): void {
    // let count = 0;
    // _.each(this._teamnoteConfigService.config.WEBCLIENT.SIDE_NAV.SIDE_NAV_UNREAD_COUNTS_MODULES, (key) => {
    //   let nav = _.find(this.sideNavItems, {key: key});
    //   if (nav) {
    //     count += nav.count;
    //   }
    // });

    let snc = this.getSideNavObjCollectionByAccountUserId(accUserId)
    snc.unreadCount = unreadCount;
  }

  updateUnreadCountSubjectForTargetAccount(accUserId: string, unreadCount?: number): void {
    let snc = this.getSideNavObjCollectionByAccountUserId(accUserId)
    this.allUnreadCountByAccount$.next(unreadCount)
  }
}
