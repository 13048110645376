import { Message} from './../../../../models/message';
import { Component, OnInit, Input, HostListener, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

import { Chat } from '../../../../models/chat';

import { ChatRoomService } from '../chat-room.service';

import * as _ from 'lodash';
import { AccountManagerService } from '../../../services/account/account-manager.service';
import { ContextMenuService, ContextMenuItem, EmojiMenuItem } from '../../../../utilities/context-menu/context-menu.service';
import { LocalStorageManagerService } from '../../../../utilities/local-storage/local-storage-manager.service';
import { ModuleManagerService } from '../../../services/module/module-manager.service';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TnDialogService } from '../../../../utilities/tn-dialog/tn-dialog.service';
import { MessageInfoComponent } from '../message-info/message-info.component';
import { MessageEmojiInfoComponent } from '../message-emoji-info/message-emoji-info.component';
import { EmojiMenuComponent } from '../../../../utilities/emoji/emoji-menu/emoji-menu.component';
import { UserContact } from '../../../../models/user-contact';
import { ContactPickerService } from '../../../contact-picker/contact-picker.service';
import { LoggerService } from '../../../../utilities/logger/logger.service';
import { CHAT_ROOM_MODE } from '../chat-room.component';
import { MessageTypeConstant } from '../../../../constants/message-type.constant';
import { UserConstant } from '../../../../constants/user.constant';
import { PresenceTypeConstant } from '../../../../constants/presence-type.constant';
import { TeamNoteLocalStorageKeyConstants } from '../../../../constants/local-storage-key.constant';
import { ModuleKeyDefinition } from '../../../../constants/module.constant';
import { TnNotificationService } from '../../../../utilities/tn-notification/tn-notification.service';
import { TeamnoteConfigService } from '../../../../configs/teamnote-config.service';
import { ChatMessageService } from '../../../services/data/messages/chat-message/chat-message.service';
import { WebclientService } from '../../../webclient.service';
import { SocketService } from '../../../../webclient/services/socket/socket.service';
import { EmojiService } from '../../../../utilities/emoji/emoji.service';
import { MessagesService } from '../../../services/data/messages/messages.service';
import { TranslateManagerService } from '../../../../utilities/translate/translate-manager.service';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../../../../utilities/service/utilities.service';
import { MultiChatRoomService } from '../../multi-chat-room.service';


var format = require('date-fns/format');

@Component({
  selector: 'tn-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @Input() messages: Message[];
  @Input() chat: Chat;
  @Input() scrollToBottom: boolean;
  @Input() isLoadingHistory: boolean;
  @Input() isUnlockedEncryptedMessage: boolean;
  @Input() isAckToRead: boolean;
  @Input() isEnableMsgAck: boolean;
  @Input() isEnableStarMessage: boolean;
  @Input() isEnableMessageDelete: boolean;
  @Input() isEnableMessageAnnotation: boolean;
  @Input() isEnableImportantUsers: boolean;
  @Input() isDisableEncrypyedMsgDownload: boolean;
  @Input() isEnableChatroomAutoScroll: { isMsgSendAction?: boolean; isNewMsgAction?: boolean } | null;
  @Input() enableAutoScroll: any;
  @Input() selectedMessageIds: string[];
  @Input() chatRoomMode: number;
  @Input() annotatingMessage: Message;
  @Input() lastMessagePointer: Message;
  @Input() isInMultiChatRoomMode: boolean;
  @Input() multiChatFilterOptions: any;

  @ViewChild('messagesWrapper', {static: false}) messagesWrapper: ElementRef;
  @ViewChild('messageRow', {static: false}) messageRow: ElementRef;

  @Output() onMessageClick = new EventEmitter<Message>();
  @Output() onUserAvatarClick = new EventEmitter<UserContact>();
  @Output() toggleReply = new EventEmitter<{message: Message, chatId?: string}>();
  @Output() toggleAnnotate = new EventEmitter<{message: Message, chatId?: string}>();
  @Output() onEncryptedMsgUnlock = new EventEmitter<Message>();
  @Output() updateSelectedMessages = new EventEmitter<string[]>();
  @Output() updateChatRoomMode = new EventEmitter<number>();
  @Output() onMessageParentClick = new EventEmitter<{msg: Message, prevMsg: Message}>();
  @Output() onChatSearchByKeyword = new EventEmitter<string>();
  @Output() updateScrollBarPosToBottom = new EventEmitter<void>();
  @Output() triggerBottomRightMenuShouldDisplay = new EventEmitter<string>();

  parsedMessages: Message[];
  MSG_TYPE = MessageTypeConstant
  CHAT_ROOM_MODE = CHAT_ROOM_MODE;

  isShowMessageID: boolean = false;
  isEnableReadTicks: boolean = false;
  isEnableMsgEmoji: boolean = false;

  contextMenus: ContextMenuItem[] = [];
  emojiMenus: EmojiMenuItem = null;

  heightTracker: number = 0;

  showUserField: boolean;
  userField: any;
  IMPORTANT_LEVEL = UserConstant.IMPORTANT_LEVEL;

  private currI18nKeySub: Subscription;
  currI18nKey: string = "en";

  // annotatingMessage: Message = null;

  constructor(
    private _chatRoomService: ChatRoomService,
    private _accountManagerService: AccountManagerService,
    private _contextMenuService: ContextMenuService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _moduleManagerService: ModuleManagerService,
    private _dialog: MatDialog,
    private _tnDialogService: TnDialogService,
    private _contactPickerService: ContactPickerService,
    private _loggerService: LoggerService,
    private _tnNotificationService: TnNotificationService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _chatMessageService: ChatMessageService,
    private _webClientService: WebclientService,
    private _emojiService: EmojiService,
    private _messageService: MessagesService,
    private _translateManagerService: TranslateManagerService,
    private _socketService: SocketService,
    private _utilitiesService: UtilitiesService,
    private _multiChatRoomService: MultiChatRoomService,
  ) {
    this.currI18nKeySub = this._translateManagerService.currentTranslation$.subscribe(lang => {
      let currLang = this._translateManagerService.getCurrentLangI18nKey()
      switch (currLang) {
        case 'en':
          this.currI18nKey = 'en'
          break;
        case 'zh-Hant':
          this.currI18nKey = 'zh-Hant'
          break;
        case 'zh-Hans':
          this.currI18nKey = 'zh-Hans'
          break;
      }
    });
  }

  ngOnInit() {
    this.isEnableReadTicks = this._webClientService.checkIfEnableMessageReadTicks();
    this.isShowMessageID = this._teamnoteConfigService.config.WEBCLIENT.CHATROOM.IS_SHOW_MESSAGE_ID; 
    this.isEnableMsgEmoji = this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_EMOJI)  
    this.getUserField();
  }

  ngOnChanges() {
    // console.log('this.enableAutoScroll', this.enableAutoScroll);
    let filterMessages = this.messages.slice();
    
    if (this.isInMultiChatRoomMode && this.multiChatFilterOptions) {
      filterMessages = this.getMemberFilterMessages(filterMessages);
      filterMessages = this.getDateTimeFilterMessages(filterMessages);
    }

    this.parsedMessages = this._chatRoomService.getParsedMessages(filterMessages, this.lastMessagePointer);
    // console.log('parsedMessages', this.parsedMessages);

    if (!this.checkIfNeedToScrollToMessageBottom()) {
      return
    }
    
    this.scrollToMessageBottom();
  }

  ngAfterViewChecked() {
    if (!this.checkIfNeedToScrollToMessageBottom()) {
      return
    }

    this.scrollToMessageBottom();
  }

  ngOnDestroy() {
    this.currI18nKeySub.unsubscribe();
  }

  get totalChatMembers() {
    return this.chat.members.length - 1; // except me
  }

  checkIfNeedToScrollToMessageBottom(): boolean {
    if (this.enableAutoScroll?.enabled) {
      if (!_.isEmpty(this.isEnableChatroomAutoScroll)) {
        if (!this.isEnableChatroomAutoScroll.isNewMsgAction) {
          return false
        }
      }
    } else {
      if (!_.isEmpty(this.isEnableChatroomAutoScroll)) {
        if (!this.isEnableChatroomAutoScroll.isMsgSendAction) {
          return false
        }
      }
    }

    return true;
  }

  setSelectStatusOfMessage() {
    _.each(this.parsedMessages, (m) => {
      m.isSelected = _.indexOf(this.selectedMessageIds, m.message_id) != -1;
    });
  }

  scrollToMessageBottom(): void {
    if (this.scrollToBottom) {
      try {
        let newHeight = this.messagesWrapper.nativeElement.clientHeight;
        
        if (newHeight == this.heightTracker) {
          return;
        }
        // console.warn('scrolling to bottom...');
        this.messagesWrapper.nativeElement.scrollIntoView(false);
        this.heightTracker = newHeight;

        if (this.isInMultiChatRoomMode) {
          setTimeout(() => {
            this.updateScrollBarPosToBottom.emit();
          }, 10);
        }
      } catch(err) { }
    }
  }

  onEmojiDetailClick(event: MouseEvent, m: Message, emojiStr: string, emojiIdx: number): void {
    event.stopPropagation();
    // if (emojiIdx === 2) {
    if (emojiIdx === 2 || m.isSentByMe) {
      // if click on emoji count bubble on message, then go to emoji detail
      this.onMessageEmojiInfo(m);
    } else {
      // if clicking on emoji on message sent by others, then send/unsend/swap the target emoji 
      if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_EMOJI)) {
        this._emojiService.addToRecent(emojiStr);
        this._messageService.onEmojiClick(m, m.isSentEmoji, emojiStr);
      }
    }
  }

  onClick(event: MouseEvent, message: Message, isRealOnClick: boolean): void {
    // Prevent parent div click event
    event.stopPropagation();

    if (!isRealOnClick) {
      this.onContextMenu(event, message);
      return;
    }

    // Do not handle click event if chat room is not in normal mode
    if (this.chatRoomMode != CHAT_ROOM_MODE.NORMAL) {
      this._loggerService.debug("Clicked on message when chat room is not normal mode, passing...");
      return;
    }

    if (!this.isUnlockedEncryptedMessage && message.parsedBody.is_encrypted) {
      this.onEncryptedMsgUnlock.emit(message);
    } else {
      this.onMessageClick.emit(message);
    }
  }

  getEmojiMenu(m: Message): void {
    let emojiClickCallback = (emoji) => {
      this._emojiService.addToRecent(emoji);
      // let unicodeEmoji = this._emojiService.getUnicodeByEmoji(emoji);
      this._messageService.onEmojiClick(m, m.isSentEmoji, emoji);
    }

    let emojiSendCallback = () => this.onSendEmoji(m)

    // this.emojiMenus = this._emojiService.buildEmojiMenuInDropDownMenu(m, emojiClickCallback, emojiSendCallback)
    
    this.emojiMenus = {
      ...this._emojiService.buildEmojiMenuInDropDownMenu(m),
      emojiClickCallback: emojiClickCallback,
      emojiSendCallback: emojiSendCallback
    }
  }

  getContextMenu(m: Message): void {
    this.contextMenus = [];

    // Text message copy
    if (m.type == MessageTypeConstant.TEXT) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.COPY',
        action: () => this.onTextMsgCopy(m)
      });
    }

    //message emoji info
    // check permission for sending message emoji
    if (this.checkIfNeedShowEmojiDetail(m)) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.EMOJI_INFO.TITLE',
        action: () => this.onMessageEmojiInfo(m)
      });
    }

    // message info
    if (m.isSentByMe || 
      (this.chat.t === PresenceTypeConstant.GROUP_BROADCAST && this.chat.admins.indexOf(this._accountManagerService.userId) !== -1)) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.INFO',
        action: () => this.onMessageInfo(m)
      });
    }
    if (this.isEnableMsgAck) {
      if (!m.isSentByMe && m.parsedBody.acknowledgement && m.parsedBody.acknowledgement.mode !== 'disabled') {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.ACKNOWLEDGEMENT_INFO',
          action: () => this.onAcknowledgementInfo(m)
        });
      }
    }
    // reply
    if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_REPLY)) {
      if (!this.isMemberFiltering && !this.isDateTimeFiltering) {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.REPLY',
          action: () => this.onReply(m)
        });
      }
    }
    // forward
    if (this.isUnlockedEncryptedMessage || !m.parsedBody.is_encrypted) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.SELECT',
        action: () => this.onForward(m)
      });
    }

    // Star message
    // if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_STAR)) {
    if (this.isEnableStarMessage && m.message_id) {
      let config = {
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.STAR',
        action: () => this.onStar(m)
      }

      if (m.isStarredByMe) {
        config = {
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.UNSTAR',
          action: () => this.onUnStar(m)
        }
      }

      this.contextMenus.push(config);
    }
    // }

    // Message Annotation
    if (this.isEnableMessageAnnotation) {
      if (!this.isMemberFiltering && !this.isDateTimeFiltering) {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.ANNOTATION',
          action: () => this.onAnnotate(m)
        });
      }
    }

    // recall
    if (m.isSentByMe) {
      let recallPeriod = parseInt(this._localStorageManagerService.getCookiesByKey(TeamNoteLocalStorageKeyConstants.COOKIES.MESSAGE_RECALL_PERIOD));
      if (recallPeriod == -1 || (parseFloat(m.timestamp) + recallPeriod) > _.now() / 1000) {
        this.contextMenus.push({
          label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.RECALL',
          action: () => this.onRecall(m)
        });
      }      
    }
    // report
    if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_REPORT)) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.REPORT',
        action: () => this.onReport(m)
      });
    }
    // delete
    if (this.isEnableMessageDelete) {
      this.contextMenus.push({
        label: 'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.DELETE',
        action: () => this.onDelete(m)
      });
    }
  }

  onContextMenu(event, m: Message): void {
    event.preventDefault();
    // Do not handle click event if chat room is not in normal mode
    if (this.chatRoomMode != CHAT_ROOM_MODE.NORMAL) {
      this._loggerService.debug("Right-Clicked on message when chat room is not normal mode, passing...");
      return;
    }

    if (!this.isUnlockedEncryptedMessage && m.parsedBody.is_encrypted) {
      this._loggerService.debug("Right-Clicked on encrypted message, passing...");
      return;
    }

    this._loggerService.log("Open context menu on message: " + m.message_id);

    if (this._moduleManagerService.checkIfModuleExists(ModuleKeyDefinition.MESSAGE_EMOJI)) {
      this.isEnableMsgEmoji = true
      this.getEmojiMenu(m);
    }
    this.getContextMenu(m);

    this._contextMenuService.showContextMenu(
      event.clientX,
      event.clientY,
      this.contextMenus,
      m.message_id,
      this.emojiMenus,
      this.isEnableMsgEmoji,
      this.messageRow ? this.messageRow.nativeElement.clientWidth : null
    )
  }
  onTextMsgCopy(m: Message): void {
    this._loggerService.log("Clicked copy on message: " + m.message_id);
    // this.toggleReply.emit(m);
    this.copyContent(m.parsedBody.message || '')
  }
  onReply(m: Message): void {
    this._loggerService.log("Clicked reply on message: " + m.message_id);
    if (!this.isInMultiChatRoomMode) {
      this.toggleReply.emit({message: m});
    } else {
      this.toggleReply.emit({message: m, chatId: this.chat.chat_id});
    }
  }
  onForward(m: Message): void {
    this._loggerService.log("Clicked forward on message: " + m.message_id);
    this.updateChatRoomMode.emit(CHAT_ROOM_MODE.FORWARD);
    this.onSelect(m);
  }
  onSendEmoji(m: Message): void {
    this._loggerService.log("Clicked emoji selection menu on message: " + m.message_id);

    let DialogData: any = {
      onEmojiSelectFunction: (emoji) => {
        // let unicodeEmoji = this._emojiService.getUnicodeByEmoji(emoji);
        this._messageService.onEmojiClick(m, m.isSentEmoji, emoji);
      }
    }

    if (m.isSentEmoji) {
      DialogData.sentEmoji = m.isSentEmoji
    }

    let dialogRef = this._tnDialogService.openTnDialog(EmojiMenuComponent, DialogData);
  }

  onMessageEmojiInfo(m: Message): void {
    this._loggerService.log("Clicked emoji detail on message: " + m.message_id);
    let dialogRef = this._tnDialogService.openTnDialog(MessageEmojiInfoComponent, {
      message: m,
      chat: this.chat
    });
  }

  onMessageInfo(m: Message): void {
    this._loggerService.log("Clicked message info on message: " + m.message_id);
    let dialogRef = this._tnDialogService.openTnDialog(MessageInfoComponent, {
      message: m,
      chat: this.chat,
    });
  }
  onAcknowledgementInfo(m: Message): void {
    this._loggerService.log("Clicked Message report status on message: " + m.message_id);
    
    let dialogRef = this._tnDialogService.openTnDialog(MessageInfoComponent, {
      message: m,
      chat: this.chat,
      isShowMessageAckOnly: true
    });
  }
  onRecall(m: Message): void {
    this._loggerService.log("Clicked recall on message: " + m.message_id);
    this._chatRoomService.recallMessage(m.message_id);
  }
  onReport(m: Message): void {
    this._loggerService.log("Clicked report on message: " + m.message_id);
    // TODO: confirm first
    this._chatRoomService.reportMessage(m.message_id);
  }
  onDelete(m: Message): void {
    this._loggerService.log("Clicked delete on message: " + m.message_id);
    this._tnNotificationService.showConfirmByTranslateKey(
      "WEBCLIENT.CHATROOM.DELETE.CONFIRM_MSG",
      () => {
        this._chatRoomService.deleteMessage(this.chat.chat_id, m.message_id);
      }
    );
  }

  onStar(m: Message): void {
    this._loggerService.log("Clicked star on message: " + m.message_id);
    this._chatRoomService.starMessage(this.chat.chat_id, m.message_id);
  }

  onUnStar(m: Message): void {
    this._loggerService.log("Clicked unstar on message: " + m.message_id);
    this._chatRoomService.unstarMessage(this.chat.chat_id, m.message_id);
  }

  onAnnotate(m: Message): void {
    this._loggerService.log("Clicked annotation on message: " + m.message_id);

    if (!this.isInMultiChatRoomMode) {
      this.toggleAnnotate.emit({message: m});
    } else {
      this.toggleAnnotate.emit({message: m, chatId: this.chat.chat_id});
    }
  }

  recallAnnotation(event, a) {
    // event.preventDefault();
    event.stopPropagation();

    this._chatRoomService.recallAnnotationMessage(a.message_annotation_id)
  }

  // Contact Card
  onUserClick(contact: UserContact): void {
    // Do not handle click event if chat room is not in normal mode
    if (this.chatRoomMode != CHAT_ROOM_MODE.NORMAL) {
      this._loggerService.debug("Clicked on sender avatar when chat room is not normal mode, passing...");
      return;
    }

    this.onUserAvatarClick.emit(contact);
  }

  // Selection
  onSelect(m: Message): void {
    if (m.isSelected) {
      this.selectedMessageIds = _.without(this.selectedMessageIds, m.message_id);
    } else {
      if (!this.isDisableEncrypyedMsgDownload) {
        this.selectedMessageIds = _.union(this.selectedMessageIds, [m.message_id]);
      } else {
        if (!m.parsedBody.is_encrypted) {
          this.selectedMessageIds = _.union(this.selectedMessageIds, [m.message_id]);
        }
      }
    }
    this.setSelectStatusOfMessage();
    this.updateSelectedMessages.emit(this.selectedMessageIds);
  }

  // parent message
  onParentMessage(event: MouseEvent, msg: Message, prevMsg: Message): void {
    if (this.isInMultiChatRoomMode) {
      if (this.isMemberFiltering || this.isDateTimeFiltering) {
        return;
      }

      event.stopPropagation();
    }

    // if (this._chatMessageService.checkIfMessageIsRecalled(msg.message_id)) {
    //   return;
    // }
    if (this._chatMessageService.checkIfMessageIsRecalled(msg)) {
      return;
    }
    this.onMessageParentClick.emit({msg: msg, prevMsg: prevMsg});
  }

  // hashtag
  onHashtagClick(text: string): void {
    this.onChatSearchByKeyword.emit(text);
  }

  // message ID
  getMessageIdDisplay(message: Message): string {
    if (!this.isShowMessageID) {
      return;
    }
    if (!message || !message.message_id) {
      return;
    }
    return `#${format(parseFloat(message.timestamp) * 1000, 'YYYYMMDD-HHmm')}-${message.message_id.substr(0, 4)}`
  }

  // ack
  ackMessage(m: Message): void {
    this._chatRoomService.sendReadForSingleMessage(m.message_id);
  }

  sendMessageAck(m: Message): void {
    // check if multi account login is enabled
    if (this._accountManagerService.isEnableMultiAccountLogin()) {
      if (!this._socketService.isTargetAccountWebSocketConnected()) {
        this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.WEBSOCKET.OFFLINE');
        // this._loggerService.debug('Web socket is disconnected..., stop to send emoji');
        return;
      }
    } else {
      if (!this._socketService._isConnected) {
        this._tnNotificationService.showCustomWarningByTranslateKey('GENERAL.WEBSOCKET.OFFLINE');
        this._loggerService.debug('Web socket is disconnected..., stop to send emoji');
        return;
      }
    }

    if (m.isAcked) {
      return
    }

    this._chatRoomService.sendAckForSingleMessage(m);
  }

  getUserField(): void {
    if (Object.keys(this._webClientService.display_user_fields_in_chat).length === 0 || this._webClientService.display_user_fields_in_chat.constructor === Object){
      this.showUserField = false;
    }
    else {
      let role : any;
      this.showUserField = true;
      role = this._webClientService.display_user_fields_in_chat;
      this.userField = role;
    }
  }

  async copyContent(content: string) {
    try {
      this._utilitiesService.copyValueToClipboard(content);
      // if (navigator.clipboard) {
      //   await window.navigator.clipboard.writeText(content)
      // }
    } catch (err) {
      this._tnNotificationService.showSystemError();
    }
  }

  checkIfNeedShowEmojiDetail(message: Message): boolean {
    if (_.isEmpty(message.emojiDisplay)) {
      return false
    }

    return true
  }

  getEmojiPreviewCount(m: Message): number {
    return m.emojis.length - m.emojiDisplay[0].count - m.emojiDisplay[1].count
  }

  // filter parsedMessage ??
  getMemberFilterMessages(filterMessages: Message[]): Message[] {
    // if (this.isInMultiChatRoomMode) {
      // if (this.multiChatFilterOptions) {
    const membersIds = _.map(this.multiChatFilterOptions.selectedChatMembers, 'user_id');
    const isNeedToFilter = membersIds.length;
    if (isNeedToFilter) {
      return this._chatMessageService.filterMessageBySelectedUser(filterMessages, membersIds, this.chat);
    }
      // }
    // }

    return filterMessages;
  }

  getDateTimeFilterMessages(filterMessages: Message[]): Message[] {
    // if (this.isInMultiChatRoomMode) {
      // if (this.multiChatFilterOptions) {
      const dateTimeRange = this.multiChatFilterOptions.selectedDateRange;

      if (!_.isEmpty(dateTimeRange)) {
        return this._chatMessageService.filterMessageByDateTimeRange(filterMessages, dateTimeRange.from);
      }
      // }
    // }

    return filterMessages;
  }

  get isMemberFiltering() {
    if (this.multiChatFilterOptions) {
      return this.multiChatFilterOptions.selectedChatMembers.length
    }

    return false
  }

  get isDateTimeFiltering() {
    if (this.multiChatFilterOptions) {
      return !_.isEmpty(this.multiChatFilterOptions.selectedDateRange)
    }

    return false
  }
}
