export const EnMenuStrings = {
  MY_PROFILE: "My Profile",

  SETTINGS: {
    OUT_OF_OFFICE: {
      TITLE: "Out Of Office",
      NOW_TO: "Now to"
    }
  },

  NEWS: "News",
  CHATS: "Chats",
  CONTACTS: "Contacts",
  PROFILE: "My Profile",
  DUTY_ROSTER: "Duty Roster",
  TRAINING: "Document Sharing",
  CORPORATE_MATERIAL: "Document Sharing",
  QUESTIONNAIRE: "Questionnaire",
  STORE_REPORT: "Store Report",
  JOB_DISPATCH: "Job Dispatch",
  SCHEDULER: "Scheduler",
  EVENT: "Event",
  NEWS_CATEGORY: "News Category",
  WORKFLOW: "Workflow",
  VIDEO_STREAMING: "Video Streaming",
  WEBDAV_MATERIAL: "WEBDAV",
  MESSAGE_STAR: 'Starred Messages',
  SCHEDULE_MESSAGE: 'Scheduled Message Management',
}
