import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Chat } from '../../models/chat';
import { UserContact } from '../../models/user-contact';
import { ChatService } from '../services/data/chat/chat.service';

import * as _ from 'lodash';
import { DataManagerService } from '../services/data/data-manager.service';
import { SearchFilterService } from '../../utilities/search-filter/search-filter.service';
import { LoggerService } from '../../utilities/logger/logger.service';
import { TnNotificationService } from '../../utilities/tn-notification/tn-notification.service';
import { CollapsibleListService, CollapsibleLists, CHAT_LIST_CATEGORY_KEYS_LABEL } from '../../utilities/collapsible-list/collapsible-list.service';
import { TeamNoteGeneralConstant } from '../../constants/general.constant';
import { PresenceTypeConstant } from '../../constants/presence-type.constant';
import { FtsContactService } from '../../utilities/fts/contact/fts-contact.service';
import { FtsChatService } from '../../utilities/fts/chat/fts-chat.service';

export const CONTACT_PICKER_ACTION = {
  FORWARD: 'forward',
  STORE_REPORT: 'store_report',
  WORKFLOW: 'workflow'
};

@Component({
  selector: 'tn-contact-picker',
  templateUrl: './contact-picker.component.html',
  styleUrls: ['./contact-picker.component.scss']
})
export class ContactPickerComponent implements OnInit {

  header: string = '';
  isAnnouncement: boolean = false;
  isChat: boolean = false;
  isContact: boolean = false;
  isMultiple: boolean = false;
  isUserGroup: boolean = false;
  callback: Function;

  selectedContacts: any [] = [];
  selectedChats: any [] = [];
  isFilterMode: boolean = false;
  isAllowSelectAll: boolean = false;
  isSelectedInited: boolean = false;
  
  pickerAction: any;

  announcements: Chat[] = [];
  chats: Chat[] = [];
  contacts: UserContact[] = [];
  selectionData: any[] = [];

  filteredChats: Chat[] = [];

  filteredContacts: UserContact[] = [];

  fullDisplayList: any[];
  scrollItems: any;
  
  chatLists: CollapsibleLists = {};

  selectedTargets: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ContactPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _chatService: ChatService,
    private _dataManagerService: DataManagerService,
    private _searchFilterService: SearchFilterService,
    private _loggerService: LoggerService,
    private _tnNotificationService: TnNotificationService,
    private _collapsibleListService: CollapsibleListService,
    private _ftsContactService: FtsContactService,
    private _ftsChatService: FtsChatService
  ) { }

  ngOnInit() {
    this.dialogRef.updateSize('50vw');

    this.dialogRef.afterClosed().subscribe(() => {
      this.close();
    });
    
    this.header = this.data.header;
    this.isAnnouncement = this.data.isAnnouncement;
    this.isChat = this.data.isChat;
    this.isContact = this.data.isContact;
    this.isMultiple = this.data.isMultiple;
    this.callback = this.data.callback;
    this.pickerAction = this.data.pickerAction;
    this.selectionData = this.data.selectionData;
    this.isUserGroup = this.data.isUserGroup;
    this.selectedContacts = this.data.selectedContacts;
    this.selectedChats = this.data.selectedChats;
    this.isFilterMode = this.data.isFilterMode;
    this.isAllowSelectAll = this.data.isAllowSelectAll;

    console.log('this.selectedChats', this.selectedChats);

    if (this.selectionData) {
      this.buildAllSelection();
    } else {
      if (this.isAnnouncement) {
        this.getAnnouncementChats();
      }
      if (this.isChat) {
        this.getAllChats();
      }
      if (this.isContact) {
        this.getAllContacts();
      }
    }
    this.search();
  }

  initSelectedChats(): void {
    const selectedChatIds = _.map(this.selectedChats, 'chat_id');
  
    _.forEach(this.fullDisplayList, (chat) => {
      if (chat.isLabel) {
        return; // same as continue in for loop
      }
      // TODO: if there is more than 1 type in chatList, need to deal with separately 
      if (_.includes(selectedChatIds, chat.chat_id)) {
        chat.isSelected = true;
        this.selectedTargets = _.union(this.selectedTargets, [chat]);
      } else {
        chat.isSelected = false
      }
    })

    this.sortListBySelectedChat();
  }

  initSelectedUsers(): void {
    let selectedUserIds = []

    _.forEach(this.selectedContacts, (c) => {
      switch (c.t) {
        case PresenceTypeConstant.USER_CONTACT:
          selectedUserIds.push(c.uid)
          break;
      
        case PresenceTypeConstant.INDIVIDUAL_CHAT:
        case PresenceTypeConstant.GROUP_CHAT:
          
          break;
      }
    })

    _.forEach(this.fullDisplayList, (item) => {
      if (item.isLabel) {
        return; // same as continue in for loop
      }
      // TODO: if there is more than 1 type in chatList, need to deal with separately 
      if (_.includes(selectedUserIds, item.uid)) {
        item.isSelected = true;
        this.selectedTargets = _.union(this.selectedTargets, [item]);
      } else {
        item.isSelected = false
      }
      
    })

    this.sortListBySelectedUser()
  }

  getAnnouncementChats() {
    this.announcements = this._chatService.getAllAnnouncementGroupChats();
    let labelObj = CHAT_LIST_CATEGORY_KEYS_LABEL.ANNOUNCEMENT;
    this.chatLists[labelObj.key] = this._collapsibleListService.createCollapsibleList(labelObj.label, this.announcements);
  }

  getAllChats() {
    // default only group chat
    this.chats = this._chatService.getAllNormalGroupChats();
    let labelObj = CHAT_LIST_CATEGORY_KEYS_LABEL.GROUP;
    this.chatLists[labelObj.key] = this._collapsibleListService.createCollapsibleList(labelObj.label, this.chats);
  
    console.log('getAllChats', _.cloneDeep(this.chatLists));
  }

  getAllContacts() {
    this.contacts = this._dataManagerService.getAllContactUsersUnderUserGroup(TeamNoteGeneralConstant.ROOT_USER_GROUP_ID);
    let labelObj = CHAT_LIST_CATEGORY_KEYS_LABEL.CONTACT;
    this.chatLists[labelObj.key] = this._collapsibleListService.createCollapsibleList(labelObj.label, this.contacts);
  }

  buildAllSelection(): void {
    let labelObj = this.isUserGroup ? CHAT_LIST_CATEGORY_KEYS_LABEL.USER_GROUP : CHAT_LIST_CATEGORY_KEYS_LABEL.USER;
    this.chatLists[labelObj.key] = this._collapsibleListService.createCollapsibleList(labelObj.label, this.selectionData);
  }

  search(keyword?: string) {
    _.each(this.chatLists, (obj, key) => {
      let items;
      let isUserContact = false;
      switch (key) {
        case CHAT_LIST_CATEGORY_KEYS_LABEL.ANNOUNCEMENT.key:
          items = this.announcements;
          break;
        case CHAT_LIST_CATEGORY_KEYS_LABEL.GROUP.key:
          items = this.chats;
          break;
        case CHAT_LIST_CATEGORY_KEYS_LABEL.CONTACT.key:
          items = this.contacts;
          isUserContact = true;
          break;
        case CHAT_LIST_CATEGORY_KEYS_LABEL.USER.key:
          items = this.selectionData;
          isUserContact = true;
          break;
        case CHAT_LIST_CATEGORY_KEYS_LABEL.USER_GROUP.key:
          items = this.selectionData;
          break;
      }
      this.searchItems(key, items, keyword, isUserContact);
    });

    this.buildFullList();
  }

  buildFullList() {
    this.fullDisplayList = this._collapsibleListService.buildFullDisplayListByLists(this.chatLists);

    // if (!_.isEmpty(this.selectedContacts)) {
    this.sortListBySelectedUser()

    if (this.isSelectedInited) {
      return
    }
    
    if (this.isContact) {
      this.initSelectedUsers();
    }
    
    if (this.isChat) {
      this.initSelectedChats();
      console.log('isFilterMode', this.isFilterMode);
    }

    this.isSelectedInited = true;
    // }
  }

  sortListBySelectedUser(): void {
    const label = this.fullDisplayList.shift();
    this.fullDisplayList = _.orderBy(this.fullDisplayList, ['isSelected', 'name'], ['desc', 'asc']);
    this.fullDisplayList.unshift(label);
  }

  sortListBySelectedChat(): void {
    const label = this.fullDisplayList.shift();
    this.fullDisplayList = _.orderBy(this.fullDisplayList, ['isSelected', 'name'], ['desc', 'asc']);
    this.fullDisplayList.unshift(label);
  }

  searchItems(key: string, items: any[], keyword?: string, isUserContact?: boolean) {
    let filtered: any[] = [];
    if (!keyword || keyword.length == 0) {
      filtered = items;
    } else {
      if (isUserContact) {
        filtered = this._ftsContactService.searchContact(keyword, items);
      } else {
        filtered = this._ftsChatService.searchChat(keyword, items);
      }
    }
    if (isUserContact) {
      filtered = this._searchFilterService.sortDataByRelevanceByName(filtered, keyword);
    } else {
      if (this.pickerAction == CONTACT_PICKER_ACTION.FORWARD) {
        filtered = this._chatService.sortChatsByLastMessageTimeAndName(filtered);
      } else {
        filtered = this._searchFilterService.sortDataByRelevanceByName(filtered, keyword);
      }
    }

    this.chatLists[key].items = filtered;
  }

  toggleCategoryDisplay(key: string): void {
    if (!key) {
      return;
    }
    this.chatLists[key].isCollapsed = !this.chatLists[key].isCollapsed;
    this.buildFullList();
  }

  close() {
    _.each(this.selectedTargets, (t) => {
      t.isSelected = false;
    });
    this.dialogRef.close();
  }

  onChatClick(chat: Chat): void {
    this._loggerService.log("Clicked chat in contact picker:" + chat.chat_id + chat.name);

    if (this.isMultiple) {
      if (chat.isSelected) {
        chat.isSelected = false;
        this.selectedTargets = _.without(this.selectedTargets, chat);
      } else {
        chat.isSelected = true;
        this.selectedTargets = _.union(this.selectedTargets, [chat]);
      }
    } else {
      this.callback(chat);
      this.close();
    }
  }

  onContactClick(contact: any): void {
    if (contact.user_id) {
      this._loggerService.log("Clicked contact in contact picker:" + contact.user_id + contact.name);
    } else {
      this._loggerService.log("Clicked user group in contact picker:" + contact.user_group_id + contact.name);
    }
    if (this.isMultiple) {
      if (contact.isSelected) {
        contact.isSelected = false;
        this.selectedTargets = _.without(this.selectedTargets, contact);
      } else {
        contact.isSelected = true;
        this.selectedTargets = _.union(this.selectedTargets, [contact]);
      }
    } else {
      this.callback(contact);
      this.close();
    }
  }

  selectAllOnClick(event: MouseEvent): void {
    event.stopPropagation();

    let isSelectAll = _.every(this.fullDisplayList, (item) => {
      if (item.isLabel) {
        return true;
      }

      return item.isSelected
    })

    _.forEach(this.fullDisplayList, (data) => {
      if (data.isLabel) {
        return
      }

      if (isSelectAll) {
        data.isSelected = false;
        this.selectedTargets = _.without(this.selectedTargets, data)
      } else {
        data.isSelected = true;
        this.selectedTargets = _.union(this.selectedTargets, [data]);
      }
    })
  }

  // isMultiple
  cancel() {
    this.close();
  }

  confirm() {
    if (this.isSelectionNoChanges) {
      return;
    }

    if (this.selectedTargets.length == 0 && !this.isFilterMode) {
      return;
    }

    if (this.pickerAction == CONTACT_PICKER_ACTION.FORWARD) {
      let allTargetName = _.map(this.selectedTargets, 'name');
      allTargetName.sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      this._tnNotificationService.showConfirmByTranslateKey(
        'WEBCLIENT.CHATROOM.FORWARD.CONFIRM_MSG',
        () => {
          this.callback(this.selectedTargets, this.isMultiple);
          this.close();
        },
        null,
        {chat: _.join(allTargetName, ", ")}
      );
    } else {
      this.callback(this.selectedTargets);
      this.close();
    }
  }

  get isSelectionNoChanges() {
    let prevSelectedUserId = _.map(this.selectedContacts, 'user_id');
    let currSelectedUserId = _.map(this.selectedTargets, 'user_id');

    if (this.isChat) {
      prevSelectedUserId = _.map(this.selectedChats, 'chat_id');
      currSelectedUserId = _.map(this.selectedTargets, 'chat_id');
    }

    return _.isEqual(prevSelectedUserId, currSelectedUserId);
  }

}
