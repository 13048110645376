import { Injectable } from '@angular/core';
import { MessageDelivery } from '../../../../../models/message-delivery';
import { MessageRead } from '../../../../../models/message-read';
import { MessageEmoji } from '../../../../../models/message-emoji';
import { Emoji } from '../../../../../models/emoji';
import { MessageAck } from '../../../../../models/message-acknowledgement';
import { Message } from '../../../../../models/message';
import { MessageStar } from '../../../../../models/message-star';
import { MessageAnnotate } from '../../../../../models/message-annotate';
import { BehaviorSubject } from 'rxjs';

import * as _ from 'lodash';
import { AccountManagerService } from '../../../account/account-manager.service';
import { UserContactService } from '../../user-contact/user-contact.service';
import { TeamnoteApiService } from '../../../../../api/teamnote-api.service';
import { MessageTypeConstant } from '../../../../../constants/message-type.constant';
import { TeamNoteApiConstant } from '../../../../../constants/api.constant';
import { EmojiService } from '../../../../../utilities/emoji/emoji.service';

class InfoMessageByAccount {
  messageDeliveries?: MessageDelivery[] = [];
  messageReads?: MessageRead[] = [];
  messageEmojis?: MessageEmoji[] = [];
  messageAcks?: MessageAck[] = [];
  messageStars?: MessageStar[] = [];
  messageAnnotations?: MessageAnnotate[] = [];
}

interface InfoMessagesObjCollection {
  [userId: string]: InfoMessageByAccount
}

@Injectable()
export class InfoMessageService {

  messageDeliveries: MessageDelivery[] = [];
  messageReads: MessageRead[] = [];
  messageEmojis: MessageEmoji[] = [];
  messageAcks: MessageAck[] = [];
  messageStars: MessageStar[] = [];
  messageAnnotations: MessageAnnotate[] = [];

  starredMsgsUpdate: MessageStar = null;
  starredMsgsUpdate$: BehaviorSubject<MessageStar> = new BehaviorSubject<MessageStar>(null);

  _infoMessagesObjCollection: InfoMessagesObjCollection = {};
  
  constructor(
    private _accountManagerService: AccountManagerService,
    private _userContactService: UserContactService,
    private _teamnoteApiService: TeamnoteApiService,
    private _emojiService: EmojiService
  ) { }

  initInfoMessages() {
    this.messageDeliveries = [];
    this.messageReads = [];
    this.messageEmojis = [],
    this.messageAcks = [],
    this.messageStars = []
    this.messageAnnotations = [];
  }

  // ----- API -----
  getMessageDetailAPI(messageId: string, success: Function, failure: Function) {
    let url = TeamNoteApiConstant.MESSAGE.MESSAGE_DETAIL;
    let params = {
      message_id: messageId
    };
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  // ----- Read -----
  insertMessageReadStatus(mr: MessageRead): void {
    let messageIds = mr.body.split(' ');
    _.each(messageIds, (id) => {
      let r: MessageRead = {
        body: id,
        sent_by: mr.sent_by,
        timestamp: mr.timestamp,
        type: mr.type
      };
      if (!_.find(this.messageReads, r)) {
        this.messageReads.push(r);
      }
    });
  }

  getMessageReadsByMessageId(messageId: string): MessageRead[] {
    return _.filter(this.messageReads, (mr: MessageRead) => {
      return mr.body == messageId;
    });
  }

  getUnqiueEarliestMessageReadsByMessageId(messageId: string): any[] {
    let reads = this.getMessageReadsByMessageId(messageId);
    let groupByUser = _.groupBy(reads, 'sent_by');
    return _.map(groupByUser, (datas) => {
      let sorted = _.sortBy(datas, 'timestamp');
      let data = sorted[0];
      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserId(data.sent_by),
        timestamp: data.timestamp
      };
    });
  }

  getReadByOtherCountByMessageId(messageId: string): number {
    let reads = this.getUnqiueEarliestMessageReadsByMessageId(messageId);
    let notMyself = _.filter(reads, (r) => {
      return r.sent_by !== this._accountManagerService.userId;
    });
    return notMyself.length;
  }

  checkIfMessageIsReadByMe(messageId: string): boolean {
    let reads = this.getUnqiueEarliestMessageReadsByMessageId(messageId);
    let myRead = _.filter(reads, (r) => {
      return r.sent_by === this._accountManagerService.userId;
    });
    return myRead.length > 0;
  }

  // ----- Delivery -----
  insertMessageDeliveryStatus(md: MessageDelivery): void {
    let messageIds = md.body.split(' ');
    _.each(messageIds, (id) => {
      let d: MessageDelivery = {
        body: id,
        sent_by: md.sent_by,
        timestamp: md.timestamp,
        type: md.type
      };
      if (!_.find(this.messageDeliveries, d)) {
        this.messageDeliveries.push(d);
      }
    });
  }

  getMessageDeliveriesByMessageId(messageId: string): MessageDelivery[] {
    return _.filter(this.messageDeliveries, (md: MessageDelivery) => {
      return md.body == messageId;
    });
  }

  getUnqiueEarliestMessageDeliveriesByMessageId(messageId: string): any[] {
    let deliveries = this.getMessageDeliveriesByMessageId(messageId);
    let groupByUser = _.groupBy(deliveries, 'sent_by');
    return _.map(groupByUser, (datas) => {
      let sorted = _.sortBy(datas, 'timestamp');
      let data = sorted[0];
      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserId(data.sent_by),
        timestamp: data.timestamp
      };
    });
  }

  getDeliverByOtherCountByMessageId(messageId: string): number {
    let delivers = this.getUnqiueEarliestMessageDeliveriesByMessageId(messageId);
    let notMyDeliver = _.filter(delivers, (r) => {
      return r.sent_by !== this._accountManagerService.userId;
    });

    return notMyDeliver.length;
  }

  // ----- Emoji -----
  insertMessageEmojiStatus(me: MessageEmoji): void {
    let { content, message_id } = JSON.parse(me.body)

    let e: MessageEmoji = {
      message_id: message_id,
      content: this._emojiService.decodeUtf8(content),
      sent_by: me.sent_by,
      timestamp: String(me.timestamp),
      type: me.type
    };

    let currentEmojiRecord = _.find(this.messageEmojis, { message_id: e.message_id, sent_by: e.sent_by });

    switch (_.toInteger(e.type)) {
      case MessageTypeConstant.EMOJI:
        if (!currentEmojiRecord) {
          this.messageEmojis.push(e)
        } else {
          if (e.timestamp > currentEmojiRecord.timestamp) {
            // const index = _.indexOf(this.messageEmojis, currentEmojiRecord);
            // _.set(this.messageEmojis, index, e);

            Object.assign(currentEmojiRecord, e) // change the data of original address
          }
        }
        
        break;

      case MessageTypeConstant.UNEMOJI:
        if (!currentEmojiRecord) break;

        let deleteIndex = _.findIndex(this.messageEmojis, { message_id: e.message_id, sent_by: e.sent_by })
        if (deleteIndex !== -1) {
          this.messageEmojis.splice(deleteIndex, 1)
        }

        break;
    }

    // console.log(this.messageEmojis);
  }

  getMessageEmojisByMessageId(messageId: string): MessageEmoji[] {
    return _.filter(this.messageEmojis, (me: MessageEmoji) => {
      return me.message_id == messageId;
    });
  }

  getEmojiCounts(emojis: Emoji[]): any {
    let emoji_count = {}

    _.forEach(emojis, (e) => {
      if (!emoji_count[e.content]) {
        emoji_count[e.content] = 1
      } else {
        emoji_count[e.content] += 1
      }
    })

    return emoji_count
  }

  getEarliestMessageEmojisByMessageId(messageId: string): MessageEmoji[] {
    let emojisSent = this.getMessageEmojisByMessageId(messageId)
    let emojiRecordByUser = _.groupBy(emojisSent, 'sent_by');

    // safeguard for avoiding show duplicate emoji
    return _.map(emojiRecordByUser, (datas) => {
      // swap >> find the latest emoji sent record for each user and return it
      let sorted = _.orderBy(datas, ['timestamp'], ['desc']);
      
      return { ...sorted[0] }
    })
  }

  getComputedEmojiDisplay(message: Message): Emoji[] {
    let emojiCounts = this.getEmojiCounts(message.emojis)
    
    let emojis = _.map(message.emojis, (emo) =>{ 
      let emojiCount = _.find(emojiCounts, (count, key) => {
        return key === emo.content
      })

      return {
        ...emo,
        count: emojiCount ? emojiCount : 0
      }
    })

    return _.uniqBy(_.orderBy(emojis, ['timestamp'], ['desc']), 'content');
  }

  getMyEmoji(emojis): Emoji {
    return _.find(emojis, (e) => e.sent_by === this._accountManagerService.userId)
  }

  checkIfMeSentAnEmoji(message: Message): boolean | string {
    let sentEmojiByMe = this.getMyEmoji(message.emojis)
    // return sentEmojiByMe ? true : false
    return sentEmojiByMe ? sentEmojiByMe.content : false
  }

  checkIfSentEmojiIsDuplicate(message: Message, emojiStr: string): boolean {
    let sentEmojiByMe = this.getMyEmoji(message.emojis)
    return sentEmojiByMe.content === emojiStr
  }

  // ----- Message Acknowledgement -----
  insertMessageAckStatus(ma: MessageAck): void {
    let messageIds = ma.body.split(' ');
    _.each(messageIds, (id) => {
      let a: MessageAck = {
        body: id,
        sent_by: ma.sent_by,
        timestamp: ma.timestamp,
        type: ma.type
      };
      if (!_.find(this.messageAcks, a)) {
        this.messageAcks.push(a);
      }
    });
  }

  getMessageAcksByMessageId(messageId: string): MessageAck[] {
    return _.filter(this.messageAcks, (ma: MessageAck) => {
      return ma.body == messageId;
    });
  }

  getUnqiueEarliestMessageAcksByMessageId(messageId: string): any[] {
    let acks = this.getMessageAcksByMessageId(messageId);
    let acksByUser = _.groupBy(acks, 'sent_by');
    return _.map(acksByUser, (datas) => {
      let sortedAcks = _.sortBy(datas, 'timestamp');
      let data = sortedAcks[0];

      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserId(data.sent_by),
        timestamp: data.timestamp
      };
    });
  }

  checkIfMessageIsAcked(message: Message): boolean {
    let { mode } = message.parsedBody.acknowledgement
    let acks = this.getUnqiueEarliestMessageAcksByMessageId(message.message_id)

    let myAck = _.filter(acks, a => {
      return a.sent_by === this._accountManagerService.userId
    })

    switch (mode) {
      case 'first':
        return acks.length > 0 || myAck.length > 0
      case 'many':
        return myAck.length > 0
      case 'disabled':
        return false
    }
  }

  // ----- Message Star & Message Unstar -----
  insertMessageStarStatus(ms: MessageStar): void {
    let messageIds = ms.body.split(' ');
    _.each(messageIds, (id) => {
      let s: MessageStar = {
        body: id,
        sent_by: ms.sent_by,
        timestamp: ms.timestamp,
        type: ms.type
      };

      let msgStarRecord = _.find(this.messageStars, { body: s.body, sent_by: s.sent_by });

      switch (_.toInteger(s.type)) {
        case MessageTypeConstant.MESSAGE_STAR:
          if (!msgStarRecord) {
            this.messageStars.push(s)
          }

          break;
  
        case MessageTypeConstant.MESSAGE_UNSTAR:
          // has received the message unstar type message (131)
          // indacates that the server has been updated
          if (s.sent_by === this._accountManagerService.userId) {
            // check if need to refresh starred message list of chat or global
            this.starredMsgUpdatedSubject(s);
          }

          if (!msgStarRecord) return;
  
          let deleteIndex = _.findIndex(this.messageStars, { body: s.body, sent_by: s.sent_by })
          if (deleteIndex !== -1) {
            this.messageStars.splice(deleteIndex, 1)
          }

          break;
      }
  
    });
    // console.log(this.messageStars)
  }

  getMessageStarsByMessageId(messageId: string): MessageStar[] {
    return _.filter(this.messageStars, (ms: MessageStar) => {
      return ms.body == messageId;
    });
  }

  getUnqiueLatestMessageStarsByMessageId(messageId: string): any[] {
    let stars = this.getMessageStarsByMessageId(messageId);
    let groupByUser = _.groupBy(stars, 'sent_by');
    return _.map(groupByUser, (datas) => {
      let sorted = _.orderBy(datas, ['timestamp'], ['desc']);
      let data = sorted[0];
      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserId(data.sent_by),
        timestamp: data.timestamp
      };
    });
  }

  checkIfMessageIsStarredByMe(messageId: string): boolean {
    let stars = this.getUnqiueLatestMessageStarsByMessageId(messageId);
    let myStar = _.filter(stars, (r) => {
      return r.sent_by === this._accountManagerService.userId;
    });
    return myStar.length > 0;
  }

  starredMsgUpdatedSubject(ms: MessageStar): void {
    this.starredMsgsUpdate = ms;
    this.starredMsgsUpdate$.next(this.starredMsgsUpdate);
  }

  // ----- Annotation -----
  insertMessageAnnotationStatus(mant: any): void {
    // console.log('insertMessageAnnotationStatus', mant);
    // let messageIds = mant.body.split(' ');
    let ant: MessageAnnotate = null;
    if (mant.parent_message_id) {
      ant = {
        parent_message_id: mant.parent_message_id,
        body: mant.body,
        sent_by: mant.sent_by,
        timestamp: mant.timestamp,
        type: Number(mant.type),
        annotation_type: mant.annotation_type,
        is_recalled: mant.is_recalled
      };
    } else if (mant.message_id) {
      let annotateBody = JSON.parse(mant.body);
      // console.log('annotateBody', annotateBody);
      ant = {
        parent_message_id: annotateBody.parent_message_id,
        body: annotateBody.message,
        sent_by: mant.sent_by,
        timestamp: mant.timestamp,
        type: Number(mant.type),
        annotation_type: annotateBody.annotation_type,
        is_recalled: mant.is_recalled
      };
    }
    
    let targetAnnotation = _.find(this.messageAnnotations, { message_annotation_id: mant.message_annotation_id, sent_by: mant.sent_by })

    if (!targetAnnotation) {
      this.messageAnnotations.push(ant);
    } else {
      Object.assign(targetAnnotation, mant) // change the data of original address
    }

    // console.log('this.messageAnnotations', this.messageAnnotations);
  }

  getMessageAnnotationsByMessageId(messageId: string): MessageAnnotate[] {
    return _.filter(this.messageAnnotations, (ant: MessageAnnotate) => {
      return ant.parent_message_id == messageId;
    });
  }

  getAllMessageAnnotationsByMessageIdAndType(messageId: string, type: number): any[] {
    let annotations = this.getMessageAnnotationsByMessageId(messageId);
    annotations = _.filter(annotations, { annotation_type: type, is_recalled: 0 })
    
    let allAnnotations = _.map(annotations, (anno) => {
      return {
        sent_by: anno.sent_by,
        user: this._userContactService.getUserContactByUserId(anno.sent_by)?.name,
        timestamp: anno.timestamp,
        content: anno.body,
        isSentByMe: anno.sent_by === this._accountManagerService.userId,
        message_annotation_id: anno.message_annotation_id
      };
    })
    
    return allAnnotations.length ? _.sortBy(allAnnotations, 'timestamp') : null;

    // let groupByUser = _.groupBy(annotations, 'sent_by');
    // return _.map(groupByUser, (datas) => {
    //   let sorted = _.sortBy(datas, 'timestamp');
    //   let data = sorted[0];
    //   return {
    //     sent_by: data.sent_by,
    //     user: this._userContactService.getUserContactByUserId(data.sent_by),
    //     timestamp: data.timestamp
    //   };
    // });
  }
  
  deleteStarredMsgUpdatedSubject(): void {
    this.starredMsgsUpdate = null;
    // this.starredMsgsUpdate$ = BehaviorSubject<MessageStar> = new BehaviorSubject<MessageStar>(null);
  }

  // ----- Message Star & Message Unstar v2 -----
  insertMessageStarStatus_v2(ms: MessageStar, accUserId: string): void {
    let messageIds = ms.body.split(' ');
    _.each(messageIds, (id) => {
      let s: MessageStar = {
        body: id,
        sent_by: ms.sent_by,
        timestamp: ms.timestamp,
        type: ms.type
      };

      let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

      let msgStarRecord = _.find(imc.messageStars, { body: s.body, sent_by: s.sent_by });

      switch (_.toInteger(s.type)) {
        case MessageTypeConstant.MESSAGE_STAR:
          if (!msgStarRecord) {
            imc.messageStars.push(s)
          }

          break;
  
        case MessageTypeConstant.MESSAGE_UNSTAR:
          // has received the message unstar type message (131)
          // indacates that the server has been updated
          if (s.sent_by === accUserId && this._accountManagerService.userId === accUserId) {
            // check if need to refresh starred message list of chat or global
            this.starredMsgUpdatedSubject(s);
          }

          if (!msgStarRecord) return;
  
          let deleteIndex = _.findIndex(imc.messageStars, { body: s.body, sent_by: s.sent_by })
          if (deleteIndex !== -1) {
            imc.messageStars.splice(deleteIndex, 1)
          }

          break;
      }
  
    });
    // console.log(this.messageStars)
  }

  getMessageStarsByMessageId_v2(messageId: string, accUserId: string): MessageStar[] {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    return _.filter(imc.messageStars, (ms: MessageStar) => {
      return ms.body == messageId;
    });
  }

  getUnqiueLatestMessageStarsByMessageId_v2(messageId: string, accUserId: string): any[] {
    let stars = this.getMessageStarsByMessageId_v2(messageId, accUserId);
    let groupByUser = _.groupBy(stars, 'sent_by');
    return _.map(groupByUser, (datas) => {
      let sorted = _.orderBy(datas, ['timestamp'], ['desc']);
      let data = sorted[0];
      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserIdAndAccountUserId(data.sent_by, accUserId),
        timestamp: data.timestamp
      };
    });
  }

  checkIfMessageIsStarredByMe_v2(messageId: string, accUserId: string): boolean {
    let stars = this.getUnqiueLatestMessageStarsByMessageId_v2(messageId, accUserId);
    let myStar = _.filter(stars, (r) => {
      return r.sent_by === accUserId;
    });
    return myStar.length > 0;
  }

  clearMultiAccountInfoMessages(): void {
    this._infoMessagesObjCollection = {};
  }

  initInfoMessages_v2(accUserId: string) {
    if (!this._infoMessagesObjCollection[accUserId]) {
      this._infoMessagesObjCollection[accUserId] = {
        messageDeliveries: [],
        messageReads: [],
        messageEmojis: [],
        messageAcks: [],
        messageStars: [],
        messageAnnotations: []
      }
    }
  }

  switchAccountInfoMessages(accUserId: string): void {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);
    
    this.messageDeliveries = imc.messageDeliveries
    this.messageReads = imc.messageReads
    this.messageEmojis = imc.messageEmojis
    this.messageAcks = imc.messageAcks
    this.messageStars = imc.messageStars
    this.messageAnnotations = imc.messageAnnotations

    this.deleteStarredMsgUpdatedSubject();
  }

  getInfoMessagesObjCollectionByAccountUserId(accUserId: string) {
    return this._infoMessagesObjCollection[accUserId];
  }

  // Read v2
  insertMessageReadStatus_v2(mr: MessageRead, accUserId: string): void {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    let messageIds = mr.body.split(' ');
    _.each(messageIds, (id) => {
      let r: MessageRead = {
        body: id,
        sent_by: mr.sent_by,
        timestamp: mr.timestamp,
        type: mr.type
      };
      if (!_.find(imc.messageReads, r)) {
        imc.messageReads.push(r);
      }
    });
  }

  getMessageReadsByMessageId_v2(messageId: string, accUserId: string): MessageRead[] {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    return _.filter(imc.messageReads, (mr: MessageRead) => {
      return mr.body == messageId;
    });
  }

  getUnqiueEarliestMessageReadsByMessageId_v2(messageId: string, accUserId: string): any[] {
    let reads = this.getMessageReadsByMessageId_v2(messageId, accUserId);
    let groupByUser = _.groupBy(reads, 'sent_by');
    return _.map(groupByUser, (datas) => {
      let sorted = _.sortBy(datas, 'timestamp');
      let data = sorted[0];
      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserIdAndAccountUserId(data.sent_by, accUserId),
        timestamp: data.timestamp
      };
    });
  }

  getReadByOtherCountByMessageId_v2(messageId: string, accUserId: string): number {
    let reads = this.getUnqiueEarliestMessageReadsByMessageId_v2(messageId, accUserId);
    let notMyself = _.filter(reads, (r) => {
      return r.sent_by !== accUserId;
    });
    return notMyself.length;
  }

  checkIfMessageIsReadByMe_v2(messageId: string, accUserId: string): boolean {
    let reads = this.getUnqiueEarliestMessageReadsByMessageId_v2(messageId, accUserId);
    let myRead = _.filter(reads, (r) => {
      return r.sent_by === accUserId;
    });
    return myRead.length > 0;
  }

  // ----- Delivery v2 -----
  insertMessageDeliveryStatus_v2(md: MessageDelivery, accUserId: string): void {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    let messageIds = md.body.split(' ');
    _.each(messageIds, (id) => {
      let d: MessageDelivery = {
        body: id,
        sent_by: md.sent_by,
        timestamp: md.timestamp,
        type: md.type
      };
      if (!_.find(imc.messageDeliveries, d)) {
        imc.messageDeliveries.push(d);
      }
    });
  }

  getMessageDeliveriesByMessageId_v2(messageId: string, accUserId: string): MessageDelivery[] {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    return _.filter(imc.messageDeliveries, (md: MessageDelivery) => {
      return md.body == messageId;
    });
  }

  getUnqiueEarliestMessageDeliveriesByMessageId_v2(messageId: string, accUserId: string): any[] {
    let deliveries = this.getMessageDeliveriesByMessageId_v2(messageId, accUserId);
    let groupByUser = _.groupBy(deliveries, 'sent_by');
    return _.map(groupByUser, (datas) => {
      let sorted = _.sortBy(datas, 'timestamp');
      let data = sorted[0];
      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserIdAndAccountUserId(data.sent_by, accUserId),
        timestamp: data.timestamp
      };
    });
  }

  getDeliverByOtherCountByMessageId_v2(messageId: string, accUserId: string): number {
    let delivers = this.getUnqiueEarliestMessageDeliveriesByMessageId_v2(messageId, accUserId);
    let notMyDeliver = _.filter(delivers, (r) => {
      return r.sent_by !== accUserId;
    });

    return notMyDeliver.length;
  }

  // ----- Emoji v2 -----
  insertMessageEmojiStatus_v2(me: MessageEmoji, accUserId: string): void {
    let { content, message_id } = JSON.parse(me.body)

    let e: MessageEmoji = {
      message_id: message_id,
      content: this._emojiService.decodeUtf8(content),
      sent_by: me.sent_by,
      timestamp: String(me.timestamp),
      type: me.type
    };

    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    let currentEmojiRecord = _.find(imc.messageEmojis, { message_id: e.message_id, sent_by: e.sent_by });

    switch (_.toInteger(e.type)) {
      case MessageTypeConstant.EMOJI:
        if (!currentEmojiRecord) {
          imc.messageEmojis.push(e)
        } else {
          if (e.timestamp > currentEmojiRecord.timestamp) {
            // const index = _.indexOf(this.messageEmojis, currentEmojiRecord);
            // _.set(this.messageEmojis, index, e);

            Object.assign(currentEmojiRecord, e) // change the data of original address
          }
        }
        
        break;

      case MessageTypeConstant.UNEMOJI:
        if (!currentEmojiRecord) break;

        let deleteIndex = _.findIndex(imc.messageEmojis, { message_id: e.message_id, sent_by: e.sent_by })
        if (deleteIndex !== -1) {
          imc.messageEmojis.splice(deleteIndex, 1)
        }

        break;
    }

    // console.log(this.messageEmojis);
  }

  getMessageEmojisByMessageId_v2(messageId: string, accUserId: string): MessageEmoji[] {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);
    
    return _.filter(imc.messageEmojis, (me: MessageEmoji) => {
      return me.message_id == messageId;
    });
  }

  getEarliestMessageEmojisByMessageId_v2(messageId: string, accUserId: string): MessageEmoji[] {    
    let emojisSent = this.getMessageEmojisByMessageId_v2(messageId, accUserId)
    let emojiRecordByUser = _.groupBy(emojisSent, 'sent_by');

    // safeguard for avoiding show duplicate emoji
    return _.map(emojiRecordByUser, (datas) => {
      // swap >> find the latest emoji sent record for each user and return it
      let sorted = _.orderBy(datas, ['timestamp'], ['desc']);
      
      return { ...sorted[0] }
    })
  }

  getMyEmoji_v2(emojis, accUserId: string): Emoji {
    return _.find(emojis, (e) => e.sent_by === accUserId)
  }

  checkIfMeSentAnEmoji_v2(message: Message, accUserId: string): boolean | string {
    let sentEmojiByMe = this.getMyEmoji_v2(message.emojis, accUserId)
    // return sentEmojiByMe ? true : false
    return sentEmojiByMe ? sentEmojiByMe.content : false
  }

  // ----- Message Acknowledgement v2 -----
  insertMessageAckStatus_v2(ma: MessageAck, accUserId): void {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    let messageIds = ma.body.split(' ');
    _.each(messageIds, (id) => {
      let a: MessageAck = {
        body: id,
        sent_by: ma.sent_by,
        timestamp: ma.timestamp,
        type: ma.type
      };
      if (!_.find(imc.messageAcks, a)) {
        imc.messageAcks.push(a);
      }
    });
  }

  getMessageAcksByMessageId_v2(messageId: string, accUserId: string): MessageAck[] {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    return _.filter(imc.messageAcks, (ma: MessageAck) => {
      return ma.body == messageId;
    });
  }

  getUnqiueEarliestMessageAcksByMessageId_v2(messageId: string, accUserId: string): any[] {
    let acks = this.getMessageAcksByMessageId_v2(messageId, accUserId);
    let acksByUser = _.groupBy(acks, 'sent_by');
    return _.map(acksByUser, (datas) => {
      let sortedAcks = _.sortBy(datas, 'timestamp');
      let data = sortedAcks[0];

      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserIdAndAccountUserId(data.sent_by, accUserId),
        timestamp: data.timestamp
      };
    });
  }

  checkIfMessageIsAcked_v2(message: Message, accUserId: string): boolean {
    let { mode } = message.parsedBody.acknowledgement
    let acks = this.getUnqiueEarliestMessageAcksByMessageId_v2(message.message_id, accUserId)

    let myAck = _.filter(acks, a => {
      return a.sent_by === accUserId
    })

    switch (mode) {
      case 'first':
        return acks.length > 0 || myAck.length > 0
      case 'many':
        return myAck.length > 0
      case 'disabled':
        return false
    }
  }

  // ----- Annotation v2 -----
  insertMessageAnnotationStatus_v2(mant: any, accUserId: string): void {
    // console.log('insertMessageAnnotationStatus_v2', mant);
    // let messageIds = mant.body.split(' ');
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    let ant: MessageAnnotate = null;
    if (mant.parent_message_id) {
      ant = {
        message_annotation_id: mant.message_annotation_id,
        parent_message_id: mant.parent_message_id,
        body: mant.body,
        sent_by: mant.sent_by,
        timestamp: mant.timestamp,
        type: Number(mant.type),
        annotation_type: mant.annotation_type,
        is_recalled: mant.is_recalled
      };
    } else if (mant.message_id) {
      let annotateBody = JSON.parse(mant.body);

      ant = {
        message_annotation_id: mant.message_annotation_id,
        parent_message_id: annotateBody.parent_message_id,
        body: annotateBody.message,
        sent_by: mant.sent_by,
        timestamp: mant.timestamp,
        type: Number(mant.type),
        annotation_type: annotateBody.annotation_type,
        is_recalled: mant.is_recalled
      };
    }

    let targetAnnotation = _.find(imc.messageAnnotations, { message_annotation_id: mant.message_annotation_id, sent_by: mant.sent_by })
    
    if (!targetAnnotation) {
      imc.messageAnnotations.push(ant);
    } else {
      Object.assign(targetAnnotation, mant) // change the data of original address
    }

    // console.log('imc.messageAnnotations v2', imc.messageAnnotations);
  }

  getMessageAnnotationsByMessageId_v2(messageId: string, accUserId: string): MessageAnnotate[] {
    let imc = this.getInfoMessagesObjCollectionByAccountUserId(accUserId);

    return _.filter(imc.messageAnnotations, (ant: MessageAnnotate) => {
      return ant.parent_message_id == messageId;
    });
  }

  getAllMessageAnnotationsByMessageIdAndType_v2(messageId: string, type: number, accUserId: string): any[] {
    let annotations = this.getMessageAnnotationsByMessageId_v2(messageId, accUserId);
    annotations = _.filter(annotations, { annotation_type: type, is_recalled: 0 })
    
    let allAnnotations = _.map(annotations, (anno) => {
      return {
        sent_by: anno.sent_by,
        user: this._userContactService.getUserContactByUserId(anno.sent_by)?.name,
        timestamp: anno.timestamp,
        content: anno.body,
        isSentByMe: anno.sent_by === this._accountManagerService.userId,
        message_annotation_id: anno.message_annotation_id
      };
    })
    
    return allAnnotations.length ? _.sortBy(allAnnotations, 'timestamp') : null;

    // let groupByUser = _.groupBy(annotations, 'sent_by');
    // return _.map(groupByUser, (datas) => {
    //   let sorted = _.sortBy(datas, 'timestamp');
    //   let data = sorted[0];
    //   return {
    //     sent_by: data.sent_by,
    //     user: this._userContactService.getUserContactByUserId(data.sent_by),
    //     timestamp: data.timestamp
    //   };
    // });
  }

}
