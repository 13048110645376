import { Injectable } from '@angular/core'
import { TnDialogService } from '../tn-dialog/tn-dialog.service'
import { DateTimeSelectorComponent } from './date-time-selector.component'

@Injectable()
export class DateTimeSelectorService {
  constructor(private _tnDialogService: TnDialogService) {}

  openDateTimeSelectionDialog(header: string, selectedDateTimeRange: any, callback: Function): void {
    let dialogRef = this._tnDialogService.openTnDialog(
      DateTimeSelectorComponent,
      { 
        header: header,
        selectedDateTimeRange: selectedDateTimeRange,
        callback: callback
      },
      {
        width: '50vw',
        minWidth: '450px',
        maxWidth: '575px',
        height: '80vh'
      }
    )
  }
}
