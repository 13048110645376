<div class="chat-room-container" 
  (click)="onClickChatroomAction()"
  ng2FileDrop
  [uploader]="draggingFileUploader"
  (fileOver)="fileOver($event)"
  (onFileDrop)="onFileDrop($event)">

  <div class="chatroom-overlay drag-file-overlay" *ngIf="isDraggingFileOver">
    <i class="fa fa-fw fa-2x fa-upload"></i>
    {{'WEBCLIENT.CHATROOM.TEXTAREA_PLACEHOLDER.DROP_FILE_TIPS' | translate}}
  </div>

  <div class="chatroom-overlay relogin-overlay" *ngIf="reloginAction">
    <tn-password-relogin
      [titleTranslateKey]="reloginOverlayTitle"
      [isInMultiChatRoomMode]="isInMultiChatRoomMode"
      [reloginAction]="reloginAction"
      (loginSuccess)="onReloginSuccess($event)"
    >
    </tn-password-relogin>
  </div>

  <div class="chatroom-overlay security-overlay">
    <canvas #securityOverlayCanvas></canvas>
  </div>
  
  <ng-container *ngIf="!reloginAction">
    <!-- No Message Overlay -->
    <div [@fadeAnimationTrigger] class="chatroom-overlay no-message-overlay" *ngIf="isShowNoMessageOverlay">
      <i class="fa fa-fw fa-2x fa-search"></i>
      <span style="margin-top: 10px;">{{'WEBCLIENT.CHATROOM.MULTI_CHAT.NO_MESSAGE_TIPS' | translate}}</span>
    </div>
  
    <!-- Message Loading Overlay -->
    <div class="chatroom-overlay message-loading-overlay" *ngIf="isLoadingMessageUnderFilteringMode">
      <div class="clickable stop-loading-tips" *ngIf="!isNeedStopAutoLoading" (click)="stopLoadingHistoryLoop()">
        <i class="fa fa-fw fa-2x fa-times"></i>
        <span >{{'WEBCLIENT.CHATROOM.MULTI_CHAT.STOP_LOADING' | translate}}</span>
      </div>
    </div>

    <div class="chat-room-header" [ngClass]="{'multi-chat-room-page-title': isInMultiChatRoomMode}">
      <tn-page-title
        [title]="chat.displayName"
        [userField]="chat.isGroup ? null : chat.chatTarget[userField]"
        [showUserField]="chat.isGroup ? null : showUserField"
        [allowClick]="true"
        (onTitleClick)="onChatTitleClick()"
        [isInMultiChatRoomMode]="isInMultiChatRoomMode"
      >
        <span class="title-addon">
          <ng-container *ngIf="chat.chatTarget && chat.chatTarget.deleted">
            {{'WEBCLIENT.CONTACT.DELETED_POSTFIX' | translate}}
          </ng-container>
        </span>

        <div class="left-control" *ngIf="!isInMultiChatRoomMode">
          <div class="back-button clickable" (click)="chatRoomBack()">
            <i class="fa fa-fw fa-angle-left"></i>
          </div>
        </div>

        <div class="title-desc" *ngIf="!isGroupChat && chat.chatTarget">
          <ng-container *ngIf="chat.chatTarget.is_online">
            {{'WEBCLIENT.CHATROOM.ONLINE' | translate}}
          </ng-container>
          <ng-container *ngIf="!chat.chatTarget.is_online && chat.chatTarget.last_seen && chat.chatTarget.last_seen != -1">
            {{'WEBCLIENT.CHATROOM.LAST_SEEN' | translate}} {{chat.chatTarget.last_seen | tnDate: 'CHAT_LAST_SEEN'}}
          </ng-container>
        </div>

        <div class="right-control">
          <div class="btn-group tn-dropdown-group" dropdown placement="bottom right" *ngIf="!isShowNoMessageOverlay">
            <button dropdownToggle type="button" class="dropdown-toggle tn-dropdown-button" (click)="onOpenChatroomMenu($event)">
              <i class="fa fa-angle-down fa-fw"></i>
            </button>
            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
              <li role="menuitem">
                <div class="tn-dropdown-item" (click)="openChatPhoto()">
                  <i class="fa fa-fw fa-picture-o"></i> {{'WEBCLIENT.CHATROOM.PHOTO' | translate}}
                </div>
              </li>
              <li role="menuitem">
                <div class="tn-dropdown-item" (click)="openChatVideo()">
                  <i class="fa fa-fw fa-video-camera"></i> {{'WEBCLIENT.CHATROOM.VIDEO' | translate}}
                </div>
              </li>
              <li role="menuitem">
                <div class="tn-dropdown-item" (click)="openChatDocument()">
                  <i class="fa fa-fw fa-file-pdf-o"></i> {{'WEBCLIENT.CHATROOM.DOCUMENT' | translate}}
                </div>
              </li>
              <li role="menuitem">
                <div class="tn-dropdown-item" (click)="toggleChatGroupSettings()">
                  <i class="fa fa-fw fa-cog"></i> {{'WEBCLIENT.CHATROOM.SETTINGS' | translate}}
                </div>
              </li>
              <li role="menuitem" *ngIf="isEnableStarMessage && !isMemberFiltering && !isDateTimeFiltering">
                <div class="tn-dropdown-item" (click)="openStarredMessageMenu()">
                  <i class="fa fa-fw fa-star"></i> {{'WEBCLIENT.MENU.MESSAGE_STAR' | translate}}
                </div>
              </li>
              <!-- <ng-container *ngIf="!isInMultiChatRoomMode; else multiChatroomMode"> -->
              <ng-container *ngIf="!isMemberFiltering && !isDateTimeFiltering">
                <li role="menuitem" *ngIf="isEnableSearch">
                  <div class="tn-dropdown-item" (click)="searchInChat()">
                    <i class="fa fa-fw fa-search"></i> {{'GENERAL.SEARCH' | translate}}
                  </div>
                </li>
              </ng-container>
              <li *ngIf="isEnableExportChat">
                <div class="tn-dropdown-item" (click)="toggleExportChatMessageOption()">
                  <i class="fa fa-fw fa-download"></i> {{'WEBCLIENT.CHATROOM.EXPORT_CHAT.TITLE' | translate}}
                </div>
              </li>
            </ul>
            <!-- <ng-template #multiChatroomMode>
              <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                <li role="menuitem">
                  <div class="tn-dropdown-item" (click)="openChatroomList()">
                    <i class="fa fa-fw fa-exchange"></i> {{'WEBCLIENT.CHATROOM.MULTI_CHAT.SWITCH_CHAT' | translate}}
                  </div>
                </li>
              </ul>
            </ng-template> -->
          </div>
        </div>
      </tn-page-title>   
    </div>

    <div class="chat-room-header-label-wrapper security-level-label level{{chat.security_level}}" 
      *ngIf="chat.security_level">
      {{'WEBCLIENT.CHATROOM.CLASSIFICATION_LEVEL.DESC' | translate}}
      {{'WEBCLIENT.CHATROOM.CLASSIFICATION_LEVEL.LEVEL' + chat.security_level | translate}}
    </div>

    <div class="chat-room-header-label-wrapper out-of-office-label" *ngIf="numOfOutOfOfficeMember > 0">
      <ng-container *ngIf="!isGroupChat">
        {{'WEBCLIENT.CHATROOM.OUT_OF_OFFICE.UNTIL' | translate}}{{outOfOfficeExpiredTimestamp | tnDate: 'OUT_OF_OFFICE_UNTIL_DATE'}}
      </ng-container>
      <ng-container *ngIf="isGroupChat">
        <ng-container *ngIf="numOfOutOfOfficeMember == 1">
          {{'WEBCLIENT.CHATROOM.OUT_OF_OFFICE.COUNT.ONE' | translate: {'NUM': numOfOutOfOfficeMember} }}
        </ng-container>
        <ng-container *ngIf="numOfOutOfOfficeMember > 1">
          {{'WEBCLIENT.CHATROOM.OUT_OF_OFFICE.COUNT.MULTIPLE' | translate: {'NUM': numOfOutOfOfficeMember} }}
        </ng-container>
      </ng-container>
    </div>

    <div class="chat-room-content">  
      <div class="floating-date" [ngClass]="{'hidden': !isShowFloatingDate, 'visible': isShowFloatingDate}">
        <div class="info-row">
          <div class="info-msg">
            {{floatingDateValue}}
          </div>
        </div>
      </div>
      <div class="messages-wrapper chatroom-bg chatroom-bg-color" #messagesElement (scroll)="onMessagesScroll($event)">
        <tn-messages 
          [chat]="chat" 
          [messages]="messages" 
          [scrollToBottom]="scrollToBottom" 
          [isLoadingHistory]="isLoadingHistory"
          [isUnlockedEncryptedMessage]="isUnlockedEncryptedMessage"
          [isAckToRead]="isAckToRead"
          [isEnableMsgAck]="isEnableMsgAck"
          [isEnableStarMessage]="isEnableStarMessage"
          [isEnableMessageDelete]="isEnableMessageDelete"
          [isEnableMessageAnnotation]="isEnableMessageAnnotation"
          [isEnableImportantUsers]="isEnableImportantUsers"
          [isDisableEncrypyedMsgDownload]="isDisableEncrypyedMsgDownload"
          [enableAutoScroll]="enableAutoScroll"
          [isEnableChatroomAutoScroll]="isEnableChatroomAutoScroll"
          [chatRoomMode]="chatRoomMode"
          [(selectedMessageIds)]="selectedMessageIds"
          [lastMessagePointer]="lastMessagePointer"
          (onMessageClick)="onMessageClick($event)"
          (toggleReply)="toggleReply($event)"
          [annotatingMessage]="annotatingMessage"
          (toggleAnnotate)="toggleAnnotate($event)"
          (updateChatRoomMode)="updateChatRoomMode($event)"
          (updateSelectedMessages)="updateSelectedMessages($event)"
          (onUserAvatarClick)="onUserAvatarClick($event)"
          (onEncryptedMsgUnlock)="onEncryptedMsgUnlock($event)"
          (onMessageParentClick)="onMessageParentClick($event)"
          (onChatSearchByKeyword)="onChatSearchByKeyword($event)"
          [isInMultiChatRoomMode]="isInMultiChatRoomMode"
          [multiChatFilterOptions]="multiChatFilterOptions"
          (updateScrollBarPosToBottom)="updateScrollBarPosToBottom($event)"
          (triggerBottomRightMenuShouldDisplay)="triggerBottomRightMenuShouldDisplay($event)">
        </tn-messages>
      </div>

      <div class="bottom-right-menu-wrapper" #bottomRightMenu *ngIf="shouldBottomRightMenuDisplay && !isShowNoMessageOverlay">
        <div class="quick-travel-wrapper clickable" (click)="onQuickTravelClick($event)">
          <div class="quick-travel-icon">
            <i class="fa fa-fw fa-2x fa-angle-down"></i>
          </div>
          <div class="unread-bubble" *ngIf="chat.newMessageCount > 0">
            {{chat.newMessageCount}}
          </div>
        </div>
      </div>


      <!-- mention bar -->
      <div class="chat-room-mention-wrapper" *ngIf="isInMentionMode && mentionSelectionList.length > 0">
        <div class="item-wrapper" *ngFor="let item of mentionSelectionList">
          <tn-contact-cell
            [contact]="item"
            [isShowDesc]="false"
            (contactOnClick)="selectMentionUser($event)">
          </tn-contact-cell>
        </div>
      </div>
      <!-- hashtag bar -->
      <div class="chat-room-hashtag-wrapper" *ngIf="isInHashtagMode && hashtagSelectionList.length > 0">
        <div class="item-wrapper" *ngFor="let item of hashtagSelectionList">
          <div class="tag text-color-primary clickable" (click)="selectHashtag(item)">
            {{item}}
          </div>
        </div>
      </div>
    </div>

    <!-- reply bar -->
    <div class="chat-room-reply-wrapper"
      [ngClass]="{'whispering': whisperingTarget}" 
      *ngIf="replyingMessage">
      <tn-message-parent [message]="replyingMessage"></tn-message-parent>
      <div class="reply-cancel clickable">
        <i class="fa fa-fw fa-times-circle fa-lg" (click)="toggleReply(null)"></i>
      </div>
    </div>

    <!-- annotate bar -->
    <div class="chat-room-reply-wrapper annotating" *ngIf="annotatingMessage && chatRoomMode == CHAT_ROOM_MODE.NORMAL">
      <i class="fa fa-fw fa-commenting-o fa-lg" style="margin: 0 10px;"></i>
      <tn-message-parent [message]="annotatingMessage"></tn-message-parent>
      <div class="reply-cancel clickable">
        <i class="fa fa-fw fa-times-circle fa-lg" (click)="toggleAnnotate(null)"></i>
      </div>
    </div>

    <!-- whisper bar -->
    <div class="chat-room-whisper-wrapper" *ngIf="whisperingTarget">
      <div class="whisper-label">
        {{'WEBCLIENT.CHATROOM.WHISPER' | translate}}
      </div>
      <div class="whisper-target">
        <div class="avatar default-contact-avatar" [ngStyle]="{'background-image': whisperingTarget.avatarImageSrc}"></div> 
        <div class="name">{{whisperingTarget.name}}</div>
      </div>
      <div class="whisper-cancel">
        <i class="fa fa-fw fa-times-circle fa-lg clickable" (click)="toggleWhisper(null)"></i>
      </div>
    </div>

    <div class="chat-room-footer"
      [ngClass]="{'whispering': whisperingTarget}">
      <ng-container *ngIf="isShowSendBarInMultiChatroomMode || !isInMultiChatRoomMode">
        <div class="message-send-bar" *ngIf="chatRoomMode == CHAT_ROOM_MODE.NORMAL || chatRoomMode == CHAT_ROOM_MODE.ANNOTATE">

          <div class="message-option-bar" *ngIf="!whisperingTarget">
            <div class="option" (click)="openUploadFilesModal(null)" *ngIf="chatRoomMode == CHAT_ROOM_MODE.NORMAL">
              <label>
                <i class="fa fa-upload fa-fw"></i> {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.UPLOAD' | translate}}
              </label>
            </div>
            <div class="option" (click)="openLocationSelectModal()" *ngIf="chatRoomMode == CHAT_ROOM_MODE.NORMAL">
              <label>
                <i class="fa fa-map-marker fa-fw"></i> {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.LOCATION' | translate}}
              </label>
            </div>
              
            <div class="option" (click)="toggleStandaloneAnnotationMode()" *ngIf="isEnableMessageAnnotation">
              <label *ngIf="chatRoomMode == CHAT_ROOM_MODE.NORMAL">
                <i class="fa fa-commenting fa-fw"></i> {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.ANNOTATION' | translate}}
              </label>
              <label *ngIf="chatRoomMode == CHAT_ROOM_MODE.ANNOTATE">
                <i class="fa fa-times fa-fw"></i> {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.EXIT_ANNOTATION' | translate}}
              </label>
            </div>
            <div class="option" (click)="openSelectFromDoc()" *ngIf="isEnabledSelectFromDoc && isEnableAttachCorporateMaterial">
              <label>
                <i class="fa fa-paperclip fa-fw"></i> {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.SELECT_FROM_DOCUMENT_SHARING' | translate}}
              </label>
            </div>
            <div class="option" (click)="openAttachModal()" *ngIf="isEnableAttach">
              <label>
                <i class="fa fa-paperclip fa-fw"></i> {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.ATTACH' | translate}}
              </label>
            </div>
            <div class="option" *ngIf="isEnableImportant">
              <label [ngClass]="{'text-red': isImportant}">
                <input type="checkbox" [(ngModel)]="isImportant" />
                {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.IMPORTANT' | translate}}
              </label> 
            </div>
            <div class="option" *ngIf="isEnableSms">
              <label>
                <input type="checkbox" [(ngModel)]="isSms" />
                {{'WEBCLIENT.CHATROOM.MESSAGE_OPTION.SMS' | translate}}
              </label> 
            </div>
            <ng-container *ngIf="isShowSendBarInMultiChatroomMode">
              <div style="flex: 1 1 0;"></div>
              <div class="option clickable" (click)="disableSendBar($event)">
                <i class="fa fa-fw fa-times"></i>
              </div>
            </ng-container>
          </div>
          <div class="message-content-bar">
            <div class="sticker-button-wrapper" *ngIf="isEnableSticker && !isOpenStickerSelection && chatRoomMode == CHAT_ROOM_MODE.NORMAL">
              <div class="sticker-button sticker clickable" (click)="toggleStickerSelection()"></div>
            </div>
  
            <div class="encrypted-msg-lock-wrapper" *ngIf="isAllowEncryptedMessage && chatRoomMode == CHAT_ROOM_MODE.NORMAL">
              <div class="encrypted-msg-lock clickable" 
                [ngClass]="{'on': isToggledEncryptedMessage, 'off': !isToggledEncryptedMessage}" 
                (click)="toggleEncryptedMessage()">
              </div>
            </div>
    
            <input #fileInput type="file" name="file" (change)="handleFileInputChange($event)" multiple/>
            <ng-container *ngIf="!isOpenStickerSelection">
              <div class="input-area">
                <textarea 
                  *ngIf="!isEnableMarkdownMessageInput || (isEnableMarkdownMessageInput && (chatRoomMode == CHAT_ROOM_MODE.ANNOTATE || annotatingMessage))"
                  [disabled]="chatRoomMode == CHAT_ROOM_MODE.ANNOTATE && !annotatingMessage"
                  #messageInputTextarea
                  class="form-control" 
                  [placeholder]="inputTextareaPlaceholerTranslateKey | translate"
                  [(ngModel)]="inputMessage" 
                  (keydown)="inputKeyDown()" 
                  (keyup)="inputKeyUp($event)"
                  (keydown.enter)="inputKeyEnter(false)" 
                  (keydown.shift.enter)="inputKeyEnter(true)"
                  (click)="inputMouseClick()"
                  (paste)="onInputAreaPasteEvent($event)"
                  (blur)='saveTextMessageDraft()'>
                </textarea>

                <div 
                  *ngIf="isEnableMarkdownMessageInput && (chatRoomMode != CHAT_ROOM_MODE.ANNOTATE && !annotatingMessage)"
                  #editableDiv
                  placeholder="Input message / Paste image / Drag & Drop file here"
                  class="editable-content"
                  contenteditable="true"
                  (input)="onInput($event)"
                  (click)="inputMouseClick()"
                  (beforeinput)="handleMenuUndoRedo($event)"
                  (keydown)="onKeydown($event)"
                  (keydown.enter)="inputKeyEnter(false)" 
                  (keydown.shift.enter)="onEditableDivInputKeyEnter(true)"
                  (keyup)="onEditableDivInputKeyUp($event)"
                  (compositionstart)="onCompositionStart($event)"
                  (compositionend)="onCompositionEnd($event)"
                  (paste)="onInputAreaPasteEvent($event)"
                  (blur)='onEditableDivInputBlur()'>
                  <!-- <br> -->
                </div>
              </div>
  
              <div class="send-button" (click)="send()">
                <div>
                  {{'WEBCLIENT.CHATROOM.SEND' | translate}}
                </div>
                <div *ngIf="isSms" [ngClass]="{'text-red': inputMessage.length > getLengthLimitTip()}">
                  ({{inputMessage.length}} / {{getLengthLimitTip()}})
                </div>
              </div>
            </ng-container>
  
            <div class="sticker-selector-wrapper" *ngIf="isOpenStickerSelection">
              <div class="sticker-button-wrapper">
                <div class="sticker-button text clickable" (click)="toggleStickerSelection()"></div>
              </div>
              <tn-sticker (onStickerClick)="onStickerClick($event)"></tn-sticker>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="message-forward-actions tn-bottom-button-wrapper" *ngIf="chatRoomMode != CHAT_ROOM_MODE.NORMAL && chatRoomMode != CHAT_ROOM_MODE.ANNOTATE">
        <div class="action-button cancel clickable" 
          (click)="cancelSelection()">
          {{'GENERAL.CANCEL' | translate}}
        </div>
        <div class="action-button confirm"
             *ngIf="isAllowAttachmentSave && downloadableSelections.length > 0"
             [ngClass]="{'clickable': downloadableSelections.length > 0, 'unclickable': downloadableSelections.length == 0}"
             (click)="downloadSelection()">
          <span *ngIf="chatRoomMode == CHAT_ROOM_MODE.FORWARD">
            {{'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.DOWNLOAD' | translate}}
          </span>
          &nbsp;({{downloadableSelections.length}})
        </div>
        <div class="action-button confirm" 
          [ngClass]="{'clickable': selectedMessageIds.length > 0, 'unclickable': selectedMessageIds.length == 0}"
          (click)="confirmSelection()">
          <span *ngIf="chatRoomMode == CHAT_ROOM_MODE.FORWARD">
            {{'WEBCLIENT.CHATROOM.MESSAGE_OPTIONS.FORWARD' | translate}}
          </span>
          &nbsp;({{selectedMessageIds.length}})
        </div>
      </div>
    </div>

  </ng-container>
</div>

