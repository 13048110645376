import { Component, OnInit, ViewChild, ElementRef, HostListener, OnChanges, SimpleChanges } from '@angular/core';

import { NewsMessageService } from '../services/data/messages/news-message/news-message.service';

import { Message } from '../../models/message';

import { NewsService } from './news.service';

import * as _ from 'lodash';
import { News, NewsCustomRouteParam } from '../../models/news';
import { LoggerService } from '../../utilities/logger/logger.service';
import { MessageTypeConstant } from '../../constants/message-type.constant';
import { TeamnoteConfigService } from '../../configs/teamnote-config.service';
import { RouteParamService } from '../../utilities/route-param/route-param.service';

import { TranslateManagerService } from '../../utilities/translate/translate-manager.service';
import { SocketService } from '../services/socket/socket.service';
import { AccountManagerService } from '../services/account/account-manager.service';

@Component({
  selector: 'tn-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit{
  newsMessages: Message[] = [];

  // News Enhancement
  newsHttp: any[] = [];
  isEnableNewsHttp: boolean = false;
  newsSize: number = 20;
  newsPage: number;
  newsCategoryList: any[] = [];
  currentCategory: number = 0;
  isSwitchingCategory: boolean = false;
  i18nKey: string;
  isCarousel: boolean = false;
  carouselCategory: any;
  carouselNews: any[] = [];
  isCarouselEnd: boolean = false;


  isInModal = false;

  isEnableEmoji: boolean = false;
  isEnableComment: boolean = false;
  isEnableNewsContentPreview: boolean = false;

  isLoadedInitHistory: boolean = false;
  isLoadingHistory: boolean = false;
  loadHistoryTimeout: number = 1000;

  isAtNewsEarliest: boolean = false;

  MIN_HEIGHT = {
    PADDING: 10,
    IMAGE: 0,
    CONTENT: 70,
    EMOJI: 46,
    COMMENT: 40
  };
  cellMinHeight = this.MIN_HEIGHT.IMAGE + this.MIN_HEIGHT.CONTENT + this.MIN_HEIGHT.PADDING;

  // Param
  paramSub: any;
  customParam: NewsCustomRouteParam = new NewsCustomRouteParam(null, null, "NEWS", false);
  defaultTitleKey: string = "NEWS";
  pageTitle: string = null;
  pageTitleKey: string = null;

  constructor(
    private _newsMessageService: NewsMessageService,
    private _newsService: NewsService,
    private _loggerService: LoggerService,
    private _teamnoteConfigService: TeamnoteConfigService,
    private _routeParamService: RouteParamService,
    private _translateManagerService: TranslateManagerService,
    private _socketService: SocketService,
    private _accountManagerService: AccountManagerService,
  ) {
    this.paramSub = this._routeParamService.currentRouteParam$.subscribe(
      params => {
        this.pageTitle = null;
        this.customParam = params;
        this.pageTitleKey = this.defaultTitleKey;
        if (this.customParam) {
          if (this.customParam.titleTranslateKey) {
            this.pageTitleKey = this.customParam.titleTranslateKey;
          }
          if (this.customParam.title) {
            this.pageTitle = this.customParam.title;
          }
        }
        this.checkCurrentCategoryModule();
        this.calculateCellMinHeight();
        if (this.isLoadedInitHistory) {
          this.handleNewsMessageSubjectUpdate();
        }
        this.isAtNewsEarliest = false;
        if (this.newsElement) {
          this.newsElement.nativeElement.scrollTop = 0;
        }
        this._translateManagerService.currentTranslation$.subscribe(lang => {
          if (this.isEnableNewsHttp){
            this.i18nKey = this._translateManagerService.getCurrentLangI18nKey();
            this.updateNewsContent();
            this.updateCategoryName();
          }
        });

      }
    );
  }

  ngOnInit() {
    this.newsSize = 20;
    this.newsPage = 0;
    this.i18nKey = this._translateManagerService.getCurrentLangI18nKey();
    this._loggerService.debug("News ngOnInit");
    // check module
    this._newsService.checkNewsModule();
    this.checkCurrentCategoryModule();
    this.calculateCellMinHeight();
    // check enable_news_http
    this.isEnableNewsHttp = this._newsService.checkNewsHttp();
    this.isEnableNewsContentPreview = this._newsService.isEnableNewsContentPreview;
    console.log('isEnableNewsHttp', this.isEnableNewsHttp);
    if (this.isEnableNewsHttp) {
      this._newsService.getNewsCategory(this.i18nKey).then(() => {
        this.sortCategory(this._newsService.newsCategoryList);
        console.log('this.newsCategoryList', _.cloneDeep(this.newsCategoryList));
        this.getNews(this.newsSize, this.newsPage, this.newsCategoryList[this.currentCategory].category_id);
      });
    } else {
      this._newsMessageService.newsMessages$.subscribe(allNews => {
        console.log('handleNewsMessageSubjectUpdate');
        this.handleNewsMessageSubjectUpdate();
      });
      // if no news, call load history
      if (this.newsMessages.length < this._teamnoteConfigService.config.WEBCLIENT.AMQP.NEWS_LOAD_HISTORY_SIZE) {
        console.log('this.loadNewsHistory();');
        this.loadNewsHistory();
      }
      this.isLoadedInitHistory = true;
    }
  }

  checkCurrentCategoryModule() {
    this.isEnableEmoji = (this.customParam && this.customParam.isReadOnly) ? false : this._newsService.isEnableEmoji;

    this.isEnableComment = (this.customParam && this.customParam.isReadOnly) ? false : this._newsService.isEnableComment;
  }

  calculateCellMinHeight() {
    this.cellMinHeight = this.MIN_HEIGHT.IMAGE + this.MIN_HEIGHT.CONTENT + this.MIN_HEIGHT.PADDING;
    if (this.isEnableEmoji) {
      this.cellMinHeight += this.MIN_HEIGHT.EMOJI;
    }
    if (this.isEnableComment) {
      this.cellMinHeight += this.MIN_HEIGHT.COMMENT;
    }
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  handleNewsMessageSubjectUpdate(): void {
    // let latestNews = news ? news : this._newsMessageService.getAllNews();
    let latestNews: Message[];
    if (this.customParam) {
      if (this.customParam.chatIdKey) {
        this.customParam.chatId = this._newsMessageService.buildNewsCategoryIdByKey(this.customParam.chatIdKey);
      }
    }

    latestNews = this._newsService.loadLocalNewsHistory(this.newsMessages.length, this.customParam ? this.customParam.chatId : null);
    this.sortNews(latestNews);

    if (this.isLoadingHistory) {
      this.isLoadingHistory = false;
    }
  }

  sortNews(news: Message[]): void {
    this.newsMessages = _.orderBy(news, 'timestamp', 'desc');
  }

  parseMessage(message: Message): News {
    if (!message) {
      return;
    }
    if (message.type != MessageTypeConstant.NEWS && message.type != MessageTypeConstant.VOTE) {
      // Not News / Vote
      return;
    }
    if (this._newsService.checkIfNewsExpired(message)) {
      // Expired
      return;
    }

    return this._newsService.parseMessageForDisplay(message);
  }

  @ViewChild('newsElement', {static: true}) newsElement: ElementRef;
  @ViewChild('categoryBar', {static: false}) categoryBar: ElementRef;

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'chrome');
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'firefox');
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    // console.log(event);
    this.mouseWheelFunc(event, 'ie');
  }

  mouseWheelFunc(event, from): void {
    // Send read when user scroll news list
    if (!this.isEnableNewsHttp) {
          this.sendRead();
    }

    let element = this.newsElement.nativeElement;
    let atBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1;
    let atTop = element.scrollTop == 0;
    if (atBottom && event.wheelDelta < 0 && !this.isLoadingHistory) {
      this.loadNewsHistory();
    }
  }

  loadNewsHistory(): void {
    if (this.isLoadingHistory || this.isAtNewsEarliest) {
      return;
    }
    this.isLoadingHistory = true;

    if (this.isEnableNewsHttp){
      this.newsPage += 1;
      this.getNews(this.newsSize, this.newsPage, this.newsCategoryList[this.currentCategory].category_id);
    }else {
      if (
        this.newsMessages.length === 0 || 
        (
          this.newsMessages.length > 0 && 
          this._newsService.checkIfTimestampIsEarliestNews(
            _.last(this.newsMessages).timestamp,
            this.customParam ? this.customParam.chatId : null
          )
        )
      ) {
        this._newsService.loadNewsHistory(
          this.customParam ? this.customParam.chatId : null,
          (bodyLength) => {
            if (bodyLength <= 1) {
              this.isAtNewsEarliest = true;
            }
            this.handleNewsMessageSubjectUpdate();
          }
        );
      } else {
        this.handleNewsMessageSubjectUpdate();
      }
    }
  }

  sendRead(): void {
    // check if multi account login is enabled
    if (this._accountManagerService.isEnableMultiAccountLogin()) {
      if (!this._socketService.isTargetAccountWebSocketConnected()) {
        return
      }
    } else {
      // Do not send news read if the WebSocket is not connected
      if (!this._socketService._isConnected) {
        return;
      }
    }

    this._newsService.sendBatchReadOfNews(this.customParam ? this.customParam.chatId : null);
  }

  // News Enhancement
  getNews(size?: number, page?: number, category?: string): void {
    this._newsService.getNews(this.isLoadingHistory, size, page, category, this.i18nKey)
      .then((status) => {
        if (status === 'Max') {
          this.isAtNewsEarliest = true;
          this.isLoadingHistory = false;
          this.isSwitchingCategory = false;
        } else {
          this._newsService.newsHttp.forEach(news => {
            this.newsHttp.push(news);
          });
          this.isAtNewsEarliest = false;
          this.isLoadingHistory = false;
          this.isSwitchingCategory = false;
        }
        this.newsHttp.forEach(news => {
          if (!news.is_read_list) {
            if (news.type === 'News') {
              this.setNewsReadList(news.news_id);
            }
            if (news.type === 'Vote') {
              this.setVoteReadList(news.vote_id);
            }
          }
        });
      });
  }
  selectCategory(order: number) {
    this.newsHttp = [];
    this.isSwitchingCategory = true;
    this.currentCategory = order;
    this.isLoadingHistory = false;
    this.newsPage = 0;
    this.getNews(this.newsSize, this.newsPage, this.newsCategoryList[order].category_id);
  }
  sortCategory(category: any) {
    this.newsCategoryList = _.orderBy(category, 'order');
    this.checkIsCarousel();
  }
  setNewsReadList(newsId: string){
    this._newsService.setNewsReadList(newsId);
  }
  setVoteReadList(voteId: string){
    this._newsService.setVoteReadList(voteId);
  }
  updateCategoryName() {
    this._newsService.getNewsCategory(this.i18nKey).then(() => {
      this.sortCategory(this._newsService.newsCategoryList);
    });
  }
  updateNewsContent() {
    let tempList = [];
    for (let i = 0; i <= this.newsPage; ++i) {
      this._newsService.getNews(
          this.isLoadingHistory, this.newsSize, i, this.newsCategoryList[this.currentCategory].category_id, this.i18nKey)
      .then(() => {
        this._newsService.newsHttp.forEach(news => {
          tempList.push(news);
        });
        this.newsHttp = tempList;
      });
    }
  }
  checkIsCarousel() {
    this.newsCategoryList.forEach( category => {
      if (category.config.is_carousel){
        this.carouselCategory = category;
        this.getNewsCarousel(category);
      }
    });
    let index = this.newsCategoryList.indexOf(this.carouselCategory);
    this.newsCategoryList.splice(index, 1);
  }
  getNewsCarousel(category: any): void {
    this._newsService.getNews(false, undefined, 0, category.category_id, this.i18nKey)
    .then(() => {
      this.carouselNews.splice(0, this.carouselNews.length);
      this._newsService.newsHttp.forEach( news => {
        this.carouselNews.push(news);
      });
      this.isCarousel = true;

      // if there is no news in carouselNews, then no need to show the news carousel
      if (this.carouselNews.length == 0) {
        this.isCarousel = false;
      }
    });
  }

}
