import { Injectable } from '@angular/core';
import { TnDialogService } from '../../utilities/tn-dialog/tn-dialog.service';
import { BroadcastComponent } from './broadcast.component';
import { TeamnoteApiService } from '../../api/teamnote-api.service';
import { LocalStorageManagerService } from '../../utilities/local-storage/local-storage-manager.service';
import { AccountManagerService } from '../services/account/account-manager.service';

@Injectable()
export class BroadcastService {

  constructor(
    private _tnDialogService: TnDialogService,
    private _teamnoteApiService: TeamnoteApiService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _accountManagerService: AccountManagerService,
  ) { }

  openBroadcastDialog(): void {
    this._tnDialogService.openTnDialog(BroadcastComponent);
  }

  getBroadcastGroupList(success: Function, failure: Function): void {
    let url = "/broadcast_group";
    let params = {};
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  getBroadcastGroupById(broadcastUserGroupId: string, success: Function, failure: Function): void {
    let url = "/broadcast_group/get";
    let params = {
      user_group_id: broadcastUserGroupId
    };
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  setBroadcastGroup(group: any, success: Function, failure: Function): void {
    let url = "/broadcast_group/set";
    this._teamnoteApiService.callApi(url, group, success, failure);
  }

  deleteBroadcastGroupById(broadcastUserGroupId: string, success: Function, failure: Function): void {
    let url = "/broadcast_group/delete";
    let params = {
      user_group_id: broadcastUserGroupId
    };
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  sendBroadcastMessage(messageType: number, messageBody: string, targetIds: string[], success: Function, failure: Function): void {
    let url = "/broadcast_message";

    let device_token = this._localStorageManagerService.getDeviceToken();
    if (this._accountManagerService.isEnableMultiAccountLogin()) {
      device_token = this._accountManagerService.getAccountUserFieldByFieldNameByAccount(
        'device_token',
        this._accountManagerService.userId
      )
    }

    let params = {
      device_token: device_token,
      type: messageType,
      body: messageBody,
      target_ids: JSON.stringify(targetIds)
    };
    this._teamnoteApiService.callApi(url, params, success, failure);
  }
}
