import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { TimestampService } from '../../../utilities/timestamp/timestamp.service'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { TnNotificationService } from '../../../utilities/tn-notification/tn-notification.service'

import { NgxMatDateAdapter, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker'
import { NgxMatMomentAdapter, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter'
import { ContactPickerService } from '../../contact-picker/contact-picker.service'

import moment from 'moment'
import _ from 'lodash'

import { ChatListComponent } from '../../chat/chat-list/chat-list.component'
import { TnDialogService } from '../../../utilities/tn-dialog/tn-dialog.service'
import { ScheduledMessageService } from '../schedule-message.service'
import { AccountManagerService } from '../../services/account/account-manager.service'
import { setSeconds } from 'date-fns'
import { setMilliseconds } from 'date-fns'
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { MessageTypeConstant } from '../../../constants/message-type.constant'
import { FieldAttachmentTypeMapping } from '../../questionnaire/questionnaire-view/question-attachment/question-attachment.component'
import { FileUploaderService } from '../../../utilities/file-uploader/file-uploader.service'
import { FileManagerService } from '../../../utilities/file-manager/file-manager.service'
import { LocationSelectorService } from '../../../utilities/location-selector/location-selector.service'
import { StickerService } from '../../../utilities/sticker/sticker.service'

import { MessageLocationBody } from '../../../models/message-location-body'
import { ParsedFile } from '../../../utilities/file-uploader/file-uploader.component'

const DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD, HH:mm'
  },
  display: {
    dateInput: 'YYYY-MM-DD, HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD, HH:mm',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}

@Component({
  selector: 'tn-schedule-message-set',
  templateUrl: './schedule-message-set.component.html',
  styleUrls: ['./schedule-message-set.component.scss'],
  providers: [
    {
      provide: NgxMatDateAdapter,
      useClass: NgxMatMomentAdapter,
      deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class ScheduleMessageSetComponent {
  title: string = 'WEBCLIENT.SCHEDULE_MESSAGE.ADD_SCHEDULE_MESSAGE'

  targetScheduleMessage: any = null
  callback: Function | null = null

  // date: moment.Moment;
  disabled = false
  // showSpinners = true;
  // showSeconds = false;
  // touchUi = false;
  // enableMeridian = false;
  minDate = new Date()
  // maxDate: moment.Moment;
  stepHour = 1
  stepMinute = 5
  stepSecond = 1
  color = 'primary'

  dateControl = new FormControl(moment())

  scheduleMessage: string = ''
  messageType: number = 2
  requiredConfirm: boolean = true

  targets: any = {
    user_ids: [],
    chat_ids: []
  }

  attachmentTypes = FieldAttachmentTypeMapping
  validFileExtensions: string[] = []
  // allowAttachmentType: string[] = ['photo', 'video', 'file', 'audio']
  allowAttachmentType: string[] = ['photo', 'video', 'audio']

  originalAttchment = null;
  scheduleAttachmentMsg = {
    attachment_id: null,
    imgSrc: null,
    // description: string,
    name: null,
    fileObj: null,
    type: null
  }

  realMessageTypes = {
    text: MessageTypeConstant.TEXT,
    attachment: MessageTypeConstant.ATTACHMENT,
    sticker: MessageTypeConstant.STICKER,
    location: MessageTypeConstant.LOCATION
  }

  scheduleLocationMsg: MessageLocationBody = null

  scheduleStickerMsg: any = null

  messageTypeSeletions: any = []

  constructor(
    public dialogRef: MatDialogRef<ScheduleMessageSetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _timestampService: TimestampService,
    private _tnNotificationService: TnNotificationService,
    private _contactPickerService: ContactPickerService,
    private _tnDialogService: TnDialogService,
    private _scheduledMessageService: ScheduledMessageService,
    private _accountManagerService: AccountManagerService,
    private _fileUploaderService: FileUploaderService,
    private _fileManagerService: FileManagerService,
    private _locationSelectorService: LocationSelectorService,
    private _stickerService: StickerService
  ) {}

  ngOnInit() {
    // this.dialogRef.afterClosed().subscribe(() => {
    //   this.dialogRef.close();
    // });
    this.buildMessageTypeSeletions()
    this.getAllValidExtensions()

    this.targetScheduleMessage = this.data.targetScheduleMessage
    this.callback = this.data.callback

    /* editing schedule message */
    if (this.targetScheduleMessage) {
      console.log('this.targetScheduleMessage', this.targetScheduleMessage);
      this.parseScheduleMessage()
    }
  }

  buildMessageTypeSeletions(): void {
    this.messageTypeSeletions = _.map(this.realMessageTypes, (type, name) => {
      return {
        label: name.charAt(0).toUpperCase() + name.slice(1),
        value: type
      }
    })
  }

  /* Attachment message */
  getAllValidExtensions(): void {
    this.validFileExtensions = []

    _.each(this.allowAttachmentType, type => {
      let extensions = this._fileManagerService.getAvailableFileExtensionByType(this.attachmentTypes[type])
      this.validFileExtensions = _.union(this.validFileExtensions, extensions)
    })
  }

  openFileUploader(): void {
    this._fileUploaderService.openFileUploaderModal(
      null,
      (files: ParsedFile[]) => {
        console.log('files', files)
        const fileObj = files[0]

        console.log('fileObj', fileObj)

        this.scheduleAttachmentMsg = {
          attachment_id: null,
          imgSrc: null,
          // description: string,
          name: fileObj.name,
          fileObj: fileObj,
          type: this._fileManagerService.getAttachmentType(fileObj.name)
        }
      },
      null,
      true,
      this.validFileExtensions,
      [],
      this.validFileExtensions,
      true
    )
  }

  removeAttachment(): void {
    this.scheduleAttachmentMsg = {
      attachment_id: null,
      imgSrc: null,
      name: null,
      fileObj: null,
      type: null
    }
  }

  async uploadAttachment(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._fileManagerService.apiUploadFile(
        this.scheduleAttachmentMsg.fileObj.file,
        (fileId, imageCaption) => {
          // Callback function of upload, send message now!
          resolve({ fileId, imageCaption })
        },
        true
      )
    })
  }

  /* Location message */
  onFinishSelectLocation(locationBody: MessageLocationBody): void {
    this.scheduleLocationMsg = locationBody
    console.log('scheduleLocationMsg', this.scheduleLocationMsg)
  }

  openLocationSelectModal(): void {
    this._locationSelectorService.openLocationSelectorModal(location => this.onFinishSelectLocation(location))
  }

  removeLocation(): void {
    this.scheduleLocationMsg = null
  }

  /* Sticker message */
  openStickerSelectionModal(): void {
    console.log('openStickerSelectionModal')
    this._stickerService.openStickerSelectionDialog(true, sticker => {
      console.log('selected sticker', sticker)
      this.scheduleStickerMsg = sticker
    })
  }

  removeSticker(): void {
    this.scheduleStickerMsg = null
  }

  parseMessageByType(): void {
    try {
      const messageBody = JSON.parse(this.targetScheduleMessage.message)
      
      switch (this.messageType) {
        case MessageTypeConstant.TEXT:
          this.scheduleMessage = messageBody.message;
          break;
        case MessageTypeConstant.ATTACHMENT:
          this.originalAttchment = messageBody;

          this.scheduleAttachmentMsg = {
            attachment_id: messageBody.attachment_id,
            imgSrc: this._fileManagerService.getBackgroundImgSrcByAttachmentId(messageBody.attachment_id),
            name: messageBody.filename,
            fileObj: null,
            type: this._fileManagerService.getAttachmentType(messageBody.filename)
          }

          break;
        case MessageTypeConstant.STICKER:
          this.scheduleStickerMsg = {
            attachment_id: messageBody.attachment_id,
            stickerBgUrl: this._fileManagerService.getBackgroundImgSrcByAttachmentId(messageBody.attachment_id)
          }

          break;
        case MessageTypeConstant.LOCATION:
          this.scheduleLocationMsg = messageBody;

          break;
      }
    } catch (error) {
      // this._tnNotificationService.showSystemError()
      if (this.messageType === this.realMessageTypes.text) {
        this.scheduleMessage = this.targetScheduleMessage.message;
      }
    }
  }

  parseScheduleMessage(): void {
    this.title = 'WEBCLIENT.SCHEDULE_MESSAGE.EDIT_SCHEDULE_MESSAGE'
    this.messageType = this.targetScheduleMessage.message_type
    this.parseMessageByType();

    this.requiredConfirm = this.targetScheduleMessage.require_confirm
    this.dateControl = new FormControl(
      moment(new Date(parseFloat(this.targetScheduleMessage.scheduled_send_time) * 1000))
    )

    this.targets = {
      user_ids: _.cloneDeep(this.targetScheduleMessage.recipients),
      chat_ids: _.cloneDeep(this.targetScheduleMessage.chats)
    }
  }

  onUserContactsSelection(): void {
    this._contactPickerService.openContactPicker(
      'WEBCLIENT.SCHEDULE_MESSAGE.USERS_SELECTION',
      false,
      false,
      true,
      contacts => {
        console.log(contacts)
        this.targets.user_ids = _.cloneDeep(contacts)
      },
      true,
      null,
      null,
      false,
      this.targets.user_ids,
      true
    )
  }

  onChatGroupsSelection(): void {
    this._contactPickerService.openContactPicker(
      'WEBCLIENT.SCHEDULE_MESSAGE.GROUPS_SELECTION',
      true,
      true,
      false,
      targetChats => {
        this.targets.chat_ids = _.cloneDeep(targetChats)
      },
      true,
      null,
      null,
      false,
      [],
      true,
      false,
      this.targets.chat_ids
    )
  }

  cancel(): void {
    this.dialogRef.close()
  }

  checkInput(): boolean {
    const dateTimeValie = this.dateControl?.status === 'VALID' && this.dateControl?.value

    if (!dateTimeValie) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.SCHEDULE_MESSAGE.ERROR_MSG.INCORRECT_DATE')
      return false
    }

    switch (this.messageType) {
      case MessageTypeConstant.TEXT:
        if (this.scheduleMessage.trim() === '') {
          this._tnNotificationService.showCustomWarningByTranslateKey(
            'WEBCLIENT.SCHEDULE_MESSAGE.ERROR_MSG.EMPTY_TEXT_MESSAGE'
          )
          return false
        }

        break
      case MessageTypeConstant.ATTACHMENT:
        if (!this.scheduleAttachmentMsg) {
          this._tnNotificationService.showCustomWarningByTranslateKey(
            'WEBCLIENT.SCHEDULE_MESSAGE.ERROR_MSG.EMPTY_ATTACHMENT_MESSAGE'
          )
          return false
        }

        if (!this.scheduleAttachmentMsg.attachment_id && !this.scheduleAttachmentMsg.fileObj) {
          this._tnNotificationService.showCustomWarningByTranslateKey(
            'WEBCLIENT.SCHEDULE_MESSAGE.ERROR_MSG.EMPTY_ATTACHMENT_MESSAGE'
          )
          return false
        }

        break
      case MessageTypeConstant.STICKER:
        if (!this.scheduleStickerMsg) {
          this._tnNotificationService.showCustomWarningByTranslateKey(
            'WEBCLIENT.SCHEDULE_MESSAGE.ERROR_MSG.EMPTY_STICKER_MESSAGE'
          )
          return false
        }

        break
      case MessageTypeConstant.LOCATION:
        if (!this.scheduleLocationMsg) {
          this._tnNotificationService.showCustomWarningByTranslateKey(
            'WEBCLIENT.SCHEDULE_MESSAGE.ERROR_MSG.EMPTY_LOCATION_MESSAGE'
          )
          return false
        }

        break
    }

    if (!this.targets.user_ids.length && !this.targets.chat_ids.length) {
      this._tnNotificationService.showCustomWarningByTranslateKey('WEBCLIENT.SCHEDULE_MESSAGE.ERROR_MSG.EMPTY_TARGETS')
      return false
    }

    return true
  }

  async confirm(): Promise<any> {
    if (!this.checkInput()) {
      return
    }

    let user_ids = _.map(this.targets.user_ids, 'user_id')
    let chat_ids = _.map(this.targets.chat_ids, 'chat_id')

    let timestampWithoutSec = setSeconds(this.dateControl.value.toDate(), 0)
    timestampWithoutSec = setMilliseconds(timestampWithoutSec, 0)

    let timestamp = this._timestampService.dateObjToSecondString(timestampWithoutSec)

    console.log(timestamp)
    let message = null

    switch (this.messageType) {
      case MessageTypeConstant.TEXT:
        message = {
          message: this.scheduleMessage
        }

        break
      case MessageTypeConstant.ATTACHMENT:
        // update attachment first
        if (this.scheduleAttachmentMsg.fileObj) {
          const { fileId, imageCaption } = await this.uploadAttachment()
          console.log('attachment upload result', fileId)
          message = {
            attachment_id: fileId,
            size: this.scheduleAttachmentMsg.fileObj.file.size,
            content_type: this.scheduleAttachmentMsg.fileObj.file.type,
            filename: this.scheduleAttachmentMsg.fileObj.name
          }
        } else if (this.scheduleAttachmentMsg.attachment_id) {
          message = this.originalAttchment;
        }

        break
      case MessageTypeConstant.STICKER:
        message = { attachment_id: this.scheduleStickerMsg.attachment_id }
        break
      case MessageTypeConstant.LOCATION:
        message = _.cloneDeep(this.scheduleLocationMsg)

        break
    }

    console.log('final message', message)

    if (!this.targetScheduleMessage) {
      // console.log('addScheduleMessage')
      this._scheduledMessageService.addScheduleMessage(
        this._accountManagerService.companyDomain,
        this.messageType,
        message,
        user_ids,
        chat_ids,
        this._accountManagerService.userId,
        timestamp,
        this.requiredConfirm ? 1 : 0,
        resp => {
          // console.log(resp)
          this._tnNotificationService.showCustomInfoByTranslateKey('WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.ADD_SUCCESS')
          this.cancel()

          if (this.callback) {
            this.callback()
          }
        },
        err => {
          console.log(err)
          this._tnNotificationService.showSystemError()
        }
      )
    } else {
      console.log('updateScheduleMessage')
      this._scheduledMessageService.updateScheduleMessage(
        this.targetScheduleMessage.scheduled_message_id,
        this._accountManagerService.companyDomain,
        this.messageType,
        message,
        user_ids,
        chat_ids,
        this._accountManagerService.userId,
        timestamp,
        this.requiredConfirm ? 1 : 0,
        this.targetScheduleMessage.status !== 0 ? 0 : null,
        updateResp => {
          console.log(updateResp)
          this._tnNotificationService.showCustomInfoByTranslateKey('WEBCLIENT.SCHEDULE_MESSAGE.TIPS_MSG.UPDATE_SUCCESS')
          this.cancel()

          if (this.callback) {
            this.callback()
          }
        },
        err => {
          console.log(err)
          this._tnNotificationService.showSystemError()
        }
      )
    }
  }
}
