import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class AccountService {

  name: string = '';
  userId: string = '';
  userName: string = '';
  picId: string = '';
  accessToken: string = '';
  companyDomain: string = '';
  modules: any = [];

  userAccount: any = null;
  userAccount$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  fullLoginResponse: any = null;

  constructor() { }

  init(): void {
    this.name = '';
    this.userId = '';
    this.userName = '';
    this.picId = '';
    this.accessToken = '';
    this.companyDomain = '';
    this.modules = [];
  }

  setUserAccount(name: string, picId: string, userId: string, userName?: string): void {
    if (name) {
      this.name = name;
    }
    if (picId) {
      this.picId = picId;
    }
    if (userId) {
      this.userId = userId;
    }
    if (userName) {
      this.userName = userName;
    }
    this.updateUserAccountSubject();
  }

  setFullLoginResponse(resp: any): void {
    this.fullLoginResponse = resp;
    this.accessToken = resp.session_token;
    this.companyDomain = resp.user.company_domain;
    this.modules = _.map(resp.user.module, 'module_key');

    // console.log('setFullLoginResponse', this.modules);
  }

  updateUserAccountSubject(): void {
    this.userAccount$.next(this.name);
  }

  checkIfRootUserModuleExists(targetModule: string): boolean {
    return _.indexOf(this.modules, targetModule) !== -1;
  }

  getMainAccountLoginFieldByFieldName(fieldName: string): number {
    if (!this.fullLoginResponse) {
      return null;
    }

    return this.fullLoginResponse[fieldName] ? this.fullLoginResponse[fieldName] : null;
  }

  switchUserAccount(loginRes: any): void {
    this.userName = loginRes.user.user_name
    this.setFullLoginResponse(loginRes);
  }
}
