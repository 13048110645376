import { NgModule } from '@angular/core'
import { UtilitiesModule } from '../../utilities/utilities.module'
import { CommonModule } from '@angular/common'
import { ScheduleMessageComponent } from './schedule-message.component'
import { ScheduledMessageService } from './schedule-message.service'

import { ScheduleMessageSetComponent } from './schedule-message-set/schedule-message-set.component'
import { ScheduleMessageSetService } from './schedule-message-set/schedule-message-set.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ChatRoomModule } from '../chat/chat-room/chat-room.module'

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, UtilitiesModule, ChatRoomModule],
  declarations: [ScheduleMessageComponent, ScheduleMessageSetComponent],
  providers: [ScheduledMessageService, ScheduleMessageSetService],
  exports: []
})
export class ScheduleMessageModule {}
